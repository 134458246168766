define('comunidades-nafin/controllers/application', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		init: function init() {
			//let self = this._super(...arguments);
			var self = this;
			$.ajax({
				url: constant.APIURL + 'usuario/datospersonales/',
				type: 'POST',
				contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
				xhrFields: {
					withCredentials: true
				},
				success: function success(r) {
					var data = JSON.parse(r);
					if (!data.fatal) {
						self.session.set('isAuthenticated', true);

						if (data.foto.indexOf('http') != 0) {
							data.foto = constant.BASEURL + data.foto;
						}
						self.session.set('userData', data);

						if (data.rol_k != 3 && data.rol_k != 5) {
							self.session.set('isAdmin', true);
						} else if (data.rol_k == 7) {
							self.session.set('isTutor', true);
						}
						//console.log(data);
					}
				},
				error: function error(e) {
					console.log(e);
				}
			});

			if (!this.socket.socket.enabled) {
				this.socket.socket = io.connect(constant.SOCKET, { "forceNew": true });
				this.socket.socket.connect();
				this.socket.socket.nuevo = true;
				this.socket.socket.chat_room = '';
				this.socket.socket.community_room = '';
			}
		}
	});
});