define('comunidades-nafin/components/courses-cards', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		offset: 0,

		deshabilitarBotonMasCursos: function deshabilitarBotonMasCursos() {
			Ember.$('#boton-mostrar-mas-cursos-usuario').hide();
		},

		didInsertElement: function didInsertElement() {
			var self = this;

			Ember.$.ajax({
				url: constant.APIURL + '/comunidad/listarMisInscripcionesRa',
				type: 'POST',
				xhrFields: {
					withCredentials: true
				},
				contentType: 'application/x-www-form-urlencoded',
				data: 'params={"agrupar":"agrupar_por_cursos"}',
				success: function success(r) {

					var r = JSON.parse(r);
					Object.keys(r.data).forEach(function (idx) {

						if (r.data[idx].porcentaje_avance) {
							var porcentaje = parseInt(r.data[idx].porcentaje_avance);
							r.data[idx].porcentaje_avance = porcentaje;
							r.data[idx].porcentaje_avance_css = Ember.String.htmlSafe('width:' + porcentaje + '%');
						}

						if (r.data[idx].estatus === "8") {
							r.data[idx].imagen = "/img/cursos/aprobado.png";
						}

						/*
       *	Obtenemos la imagen de la ruta de aprendizaje, a patir de los siguienttes criterios:
       *
       *	Si existe una referencia a una imagen desde la relación local ( curso_imagen ), para
       *  la ruta de aprendizaje, se le da prioidad, en caso contario, se toma la imagen poveniente
       *  del servicio.
       * /
      	if (curso_imagen) {
      		if (curso_imagen[r.data[idx].ra_k]) {
      			r.data[idx].imagen_raprendizaje = curso_imagen[r.data[idx].ra_k];
      		}
      }
      	/*
       * Generamos la URL absoluta de la imagen, hacia el sitio en donde se encuetran los recursos gráficos.
       */

						r.data[idx].imagen_raprendizaje = solveImageURL(r.data[idx].imagen_raprendizaje);

						if (r.data[idx].nombre.length > 50) {
							var nomb = r.data[idx].nombre.toString();
							r.data[idx].nombre = nomb.substr(0, 47) + "...";
							console.log(r.data[idx].nombre);
						}
					});
					//console.log(r.data)
					if (constant.FIREBASE_SCORE != '') {
						//Vamos por la calificación de los cursos
						Ember.$.ajax({
							url: constant.FIREBASE_SCORE,
							type: 'POST',
							data: {
								"method": "score_courses_resume"
							},
							success: function success(score_courses_resume) {

								self.set("allCoursesScores", score_courses_resume.data);

								//Se le agrega la votación desde Firebase
								r.data.forEach(function (item, idx) {
									/*r.data[idx].imagen_raprendizaje = curso_imagen[r.data[idx].grupo_k];*/
									if (score_courses_resume.data) {
										if (typeof score_courses_resume.data[r.data[idx].ra_k] != 'undefined' && score_courses_resume.data[r.data[idx].ra_k] != null) {
											r.data[idx].ranking_raprendizaje_score = Math.round(score_courses_resume.data[r.data[idx].ra_k].rate);
										} else {
											r.data[idx].ranking_raprendizaje_score = 0;
										}
									} else {
										r.data[idx].ranking_raprendizaje_score = 0;
									}
								});

								//Se ordennan por ranking
								//r.data = r.data.sort((b,a) => (a.ranking_raprendizaje_score > b.ranking_raprendizaje_score) ? 1 : ((b.ranking_raprendizaje_score > a.ranking_raprendizaje_score) ? -1 : 0));
								//Se ordennan por nombre
								r.data = r.data.sort(function (a, b) {
									return a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0;
								});

								self.set("allCourses", r.data);
								//r.data = r.data.sort((a, b) => { return (a.ranking_raprendizaje < b.ranking_raprendizaje) ? 1 : ((b.ranking_raprendizaje < a.ranking_raprendizaje) ? -1 : 0); });
								var cursosV = [];
								for (var i = 0; i < 12; i++) {
									if (r.data[self.offset + i]) {
										cursosV.push(r.data[self.offset + i]);
									}
								}

								self.set("cursosV", cursosV);
								self.offset += 12;
								setTimeout(function () {
									Ember.$('#loading').fadeOut('slow');
									lazyLoad.update();
								}, 3000);
							}
						});
					} else {
						r.data = r.data.sort(function (a, b) {
							return a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0;
						});
						self.set("allCourses", r.data);
						//r.data = r.data.sort((a, b) => { return (a.ranking_raprendizaje < b.ranking_raprendizaje) ? 1 : ((b.ranking_raprendizaje < a.ranking_raprendizaje) ? -1 : 0); });
						var cursosV = [];
						for (var i = 0; i < 12; i++) {
							if (r.data[self.offset + i]) {
								cursosV.push(r.data[self.offset + i]);
								console.log("nombre: " + cursosV.nombre);
								/*if (cursosV.nombre.length > 50) {
        	console.log(val.substr(0, 40) + "...");
        	return val.substr(0, 40) + "...";
        	
        } else {
        	return val
        }*/
							}
						}
						self.set("cursosV", cursosV);
						self.offset += 12;
						setTimeout(function () {
							Ember.$('#loading').fadeOut('slow');
							lazyLoad.update();
						}, 3000);
					}
					Ember.$('#loading').fadeOut('slow');
					//console.log(r.data)
				},
				error: function error(e) {
					console.log(e);
				}
			});
		},

		numOfTimes: Ember.computed('times', function () {
			return [1, 2, 3, 4, 5];
		}),
		didRender: function didRender() {
			Ember.$('.8').removeAttr('hidden');
		},
		actions: {
			mostrarMasCursos: function mostrarMasCursos() {

				var allCourses = this.get("allCourses");
				var cursosV_temporal_array = [];
				var _self = this;
				var cuenta = 0;

				allCourses.forEach(function (_curso_record, _index, _cursos_lista_array) {
					cuenta += 1;
					/*
      *	Obtenemos la imagen de la ruta de aprendizaje, a patir de los siguienttes criterios:
      *
      *	Si existe una referencia a una imagen desde la relación local ( curso_imagen ), para
      *  la ruta de aprendizaje, se le da prioidad, en caso contario, se toma la imagen poveniente
      *  del servicio.
      * /
     	if (curso_imagen) {
     		if (curso_imagen[Ember.get(_curso_record, 'ra_k')]) {
     			Ember.set(_curso_record, 'imagen_raprendizaje', curso_imagen[Ember.get(_curso_record, 'ra_k')]);
     		}
     }
     	/*
      * Generamos la URL absoluta de la imagen, hacia el sitio en donde se encuetran los recursos gráficos.
      */

					Ember.set(_curso_record, 'imagen_raprendizaje', solveImageURL(_curso_record.imagen_raprendizaje));

					if (_index < _self.offset + 12) {

						if (_curso_record) {

							cursosV_temporal_array.pushObject(_curso_record);
						}
					}
				});

				this.offset += 12;

				this.set("cursosV", cursosV_temporal_array);
				/*
    La forma en cómo esta solucionado el mostrarMasCursos() se debe cambiar ya que para que desaparezca dicho botón, 
    se tuvo que realizar una solución parcial debido a que no se tiene el suficiente control en el listado de los cursos.
    */
				if (cuenta == cursosV_temporal_array.length) {
					_self.deshabilitarBotonMasCursos();
				}
			}
		}
	});
});