define('comunidades-nafin/components/diagnostic-card', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		didInsertElement: function didInsertElement() {
			sessionStorage.setItem('seccion-anterior', Ember.$(".titulo-seccion").text());
			var self = this._super.apply(this, arguments);
			var url_page = new URLSearchParams(window.location.search);
			var tipo = url_page.get('tipo');
			if (tipo != null && tipo == "misDiagnosticos") self.set("diagnostic_state", false);else self.set("diagnostic_state", true);
			Ember.$.ajax({
				url: constant.APIURL + 'evaluacionesdinamicas/evaluaciondinamica/listarevaluacionesdinamicasdesdecatalogoinscritasporusuario',
				type: 'POST',
				xhrFields: {
					withCredentials: true
				},
				contentType: 'application/x-www-form-urlencoded',
				success: function success(response) {
					var r = JSON.parse(response);
					if (r.numFilas != 0) {
						Object.keys(r.data).forEach(function (idx) {

							if (r.data[idx].ultimo_estatus == "1" || r.data[idx].ultimo_estatus == "60") {
								r.data[idx].cursoIniciado = true;
								if (r.data[idx].ultimo_estatus == "1") {
									r.data[idx].evaluacionPendiente = true;
								} else {
									r.data[idx].evaluacionPendiente = false;
								}
							} else {
								r.data[idx].cursoIniciado = false;
							}
							r.data[idx].inicioElCurso = true;
							if (r.data[idx].ultimo_fecha_hora_termino != null || r.data[idx].ultimo_fecha_hora_termino != undefined) {
								var date_time = r.data[idx].ultimo_fecha_hora_termino.split(' ');
								var date = date_time[0].split('-');
								var month = date[1];
								if (month === "01") {
									r.data[idx].monthFormath = "Enero";
								} else if (month === "02") {
									r.data[idx].monthFormath = "Febrero";
								} else if (month === "03") {
									r.data[idx].monthFormath = "Marzo";
								} else if (month === "04") {
									r.data[idx].monthFormath = "Abril";
								} else if (month === "05") {
									r.data[idx].monthFormath = "Mayo";
								} else if (month === "06") {
									r.data[idx].monthFormath = "Junio";
								} else if (month === "07") {
									r.data[idx].monthFormath = "Julio";
								} else if (month === "08") {
									r.data[idx].monthFormath = "Agosto";
								} else if (month === "09") {
									r.data[idx].monthFormath = "Septiembre";
								} else if (month === "10") {
									r.data[idx].monthFormath = "Octubre";
								} else if (month === "11") {
									r.data[idx].monthFormath = "Noviembre";
								} else {
									r.data[idx].monthFormath = "Diciembre";
								}
								r.data[idx].dayFormath = date[2];
								r.data[idx].yearFormath = date[0];
							} else {
								r.data[idx].ultimo_fecha_hora_termino = r.data[idx].ultimo_fecha_hora_inicio;
								var date_time = r.data[idx].ultimo_fecha_hora_termino.split(' ');
								var date = date_time[0].split('-');
								var month = date[1];
								if (month === "01") {
									r.data[idx].monthFormath = "Enero";
								} else if (month === "02") {
									r.data[idx].monthFormath = "Febrero";
								} else if (month === "03") {
									r.data[idx].monthFormath = "Marzo";
								} else if (month === "04") {
									r.data[idx].monthFormath = "Abril";
								} else if (month === "05") {
									r.data[idx].monthFormath = "Mayo";
								} else if (month === "06") {
									r.data[idx].monthFormath = "Junio";
								} else if (month === "07") {
									r.data[idx].monthFormath = "Julio";
								} else if (month === "08") {
									r.data[idx].monthFormath = "Agosto";
								} else if (month === "09") {
									r.data[idx].monthFormath = "Septiembre";
								} else if (month === "10") {
									r.data[idx].monthFormath = "Octubre";
								} else if (month === "11") {
									r.data[idx].monthFormath = "Noviembre";
								} else {
									r.data[idx].monthFormath = "Diciembre";
								}
								r.data[idx].dayFormath = date[2];
								r.data[idx].yearFormath = date[0];
							}

							r.data[idx].ruta_imagen = solveImageURL(r.data[idx].ruta_imagen);

							if (r.data[idx].ultimo_calificacion != null && r.data[idx].ultimo_calificacion != undefined) {
								r.data[idx].ultimo_calificacion = parseFloat(r.data[idx].ultimo_calificacion).toFixed(2);
							} else {
								r.data[idx].ultimo_calificacion = parseFloat(r.data[idx].pimer_calificacion).toFixed(2);
							}

							console.log(r.data[idx]);
						});
						self.setDiagnostico(r.data);
					} else {
						self.withoutDiagnostic();
					}
					//console.log(r.data);
				},
				error: function error(e) {
					console.log(e);
				}
			});

			Ember.$.ajax({
				url: constant.APIURL + '/evaluacionesdinamicas/evaluaciondinamica/listarevaluacionesdinamicasdesdecatalogodisponibleporusuario',
				type: 'POST',
				async: false,
				xhrFields: {
					withCredentials: true
				},
				contentType: 'application/x-www-form-urlencoded',
				success: function success(response) {
					var r = JSON.parse(response);
					console.log(r);
					setInterval(function () {
						if (r.numFilas == 0) {
							self.withoutDiagnosticAvailable();
						}
					}, 0);
					Object.keys(r.data).forEach(function (idx) {
						r.data[idx].ruta_imagen = solveImageURL(r.data[idx].ruta_imagen);
					});
					self.set('all_diagnostics', r.data);
					//console.log(r.data);
				},
				error: function error(e) {
					console.log(e);
				}
			});
		},
		setDiagnostico: function setDiagnostico(data) {
			//console.log(data);
			this.set('diagnostico', data);

			Ember.$('#btns .btn_circle').click(function () {
				Ember.$('#btns .btn_circle').toggleClass('active');
			});

			if (this.get('mis_diagnosticos')) {
				this.send('my_diagnostic');
			}
		},

		withoutDiagnosticAvailable: function withoutDiagnosticAvailable() {
			var html = '';
			Ember.$('#tarjeta_diagnostico_empresarial').html(html);
		},
		withoutDiagnostic: function withoutDiagnostic() {
			var html = '';

			html += '<div class="col-12 text-center margin_ud_10px">';
			html += '<h2>No ha inscrito ningun diagnóstico</h2>';
			html += '</div>';

			Ember.$('#sin_inscripciones').html(html);
		},
		withoutDate: function withoutDate() {
			var html = '';
			var idx_fecha = 0;

			html += '<p class="card-text">No ha terminado el diagnóstico</p>';

			Ember.$('.margin_title_diag #fecha_aplicacion_' + idx_fecha++).html(html);
		},
		withoutGrade: function withoutGrade() {
			var html = '';
			var idx_calif = 0;

			html += '<p class="card-text absolute bottom right margin_10px padding_10px">Sin calificación</p>';

			Ember.$('#calificacion_' + idx_calif++).html(html);
		},
		actions: {
			diagnostic: function diagnostic() {
				this.set("diagnostic_state", true);
			},
			my_diagnostic: function my_diagnostic() {
				this.set("diagnostic_state", false);
			},
			search: function search(event) {

				var input = Ember.$('#inputSearchDiagnostic').val();

				//console.log('Buscando ' + input);
				if (input.length < 3) {

					Ember.$('.card-search').each(function () {
						Ember.$(this).parent().parent().parent().show();
					});
				} else {

					Ember.$('.card-search').each(function () {

						var text = Ember.$(this).text().toLowerCase();

						if (text.search(input.toLowerCase()) > -1) {
							Ember.$(this).parent().parent().parent().show();
						} else {
							Ember.$(this).parent().parent().parent().hide();
						}
					});
				}
			}
		}
	});
});