define('comunidades-nafin/routes/directorio', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		last_mensaje_k: 0,
		usuario_receptor: 0,
		conversacion: 0,
		token_k: '',
		beforeModel: function beforeModel() {
			var promise = new Ember.RSVP.Promise(function (resolve) {
				Ember.run.later(this, function () {
					resolve("ok");
				}, 1000);
			});
			return promise;
		},

		iniciar_socket: function iniciar_socket() {
			var self = this;
			if (self.usuario_receptor != 0) {
				Ember.$.ajax({
					url: constant.APIURL + 'conversaciones/agregarConversacionUsuario',
					type: 'POST',
					contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
					xhrFields: {
						withCredentials: true
					},
					data: 'usuario_k=' + self.usuario_receptor,
					success: function success(r) {
						var respons = JSON.parse(r);
						self.conversacion = respons.data[0].conversacion_k;
						self.token_k = respons.data[0].token;
						self.controller.socket.socket.emit('switchRoom', respons.data[0].token);
					},
					error: function error(e) {
						console.log(e);
					}
				});
			}
		},
		clickRow: function clickRow(controller) {
			var self = this;
			var contactoOn = 0;

			self.controller.socket.socket.emit('switchRoom', 'no_room');

			setTimeout(function () {

				Ember.$("#directoriotable tbody tr td").click(function () {
					var usuario_k = Ember.$(this).parent().attr('id');
					var column_num = parseInt(Ember.$(this).index()) + 1;
					var row_num = parseInt(Ember.$(this).parent().index()) + 1;
					var id = '#' + usuario_k;

					self.usuario_receptor = 0;

					if (column_num == 1) {
						if (Ember.$(this).data("favorito") === 0 && Ember.$(this).find("i").hasClass("fa-heart-o")) {
							Ember.$('#loading').fadeIn('slow');
							Ember.$(this).html('<i class="fa fa-heart">');
							//Enviar a favoritos en el backend
							Ember.$.ajax({
								url: constant.APIURL + 'contacto/agregar',
								type: 'POST',
								contentType: 'application/x-www-form-urlencoded',
								data: 'usuario_k=' + usuario_k,
								xhrFields: {
									withCredentials: true
								},
								success: function success(r) {
									Ember.$('#loading').fadeOut('slow');
									Ember.$(id).data("favorito", 1);
								},
								error: function error(e) {
									console.log(e);
								}
							});
						}
					} else if (column_num == 2 || column_num == 3) {
						Ember.$("#contacto").hide();
						self.usuario_receptor = usuario_k;
						//Desplegar información en ficha lateral
						Ember.$.ajax({
							url: constant.APIURL + 'usuario/mostrarDetalleContacto',
							type: 'POST',
							contentType: 'application/x-www-form-urlencoded',
							data: 'usuario_k=' + usuario_k,
							xhrFields: {
								withCredentials: true
							},
							success: function success(r) {
								var respons = JSON.parse(r);
								console.log(respons.data[0].extras.data.tipo_usuario);
								if (respons.data[0].extras.data.tipo_usuario == 1) {
									controller.set('tipoUsuario', true);
								} else {
									controller.set('tipoUsuario', false);
								}

								var contactoObject = {
									usuario: respons.data[0].usuario_k,
									email: respons.data[0].email,
									foto: solveImageURL(respons.data[0].foto),
									nombre: respons.data[0].nombre,
									apellido_paterno: respons.data[0].apellido_paterno,
									apellido_materno: respons.data[0].apellido_materno,
									telefono: respons.data[0].telefonos.data[0] ? respons.data[0].telefonos.data[0].numero : '',
									empresa: respons.data[0].empresa,
									calle: respons.data[0].direccion.data[0].direccion,
									colonia: respons.data[0].direccion.data[0].colonia,
									cp: respons.data[0].direccion.data[0].cp,
									ciudad: respons.data[0].direccion.data[0].ciudad,
									estado: respons.data[0].direccion.data[0].estado,
									sitio: respons.data[0].extras.data.web
								};
								controller.set('contactos', [contactoObject]);
								contactoOn = 1;
								Ember.$("#contacto").show();
								self.iniciar_socket();
							},
							error: function error(e) {
								console.log(e);
							}
						});
					}
					Ember.$("button").click(function () {
						Ember.$("#contacto").hide();
						var estadoMenu = Ember.$(this).val();
						if (estadoMenu == "1" && contactoOn == 1) {
							controller.set('contactos', ['']);
							Ember.$("#contacto").hide();
							contactoOn = 0;
						} else if (estadoMenu == "2" && contactoOn == 1) {
							controller.set('contactos', ['']);
							Ember.$("#contacto").hide();
							contactoOn = 0;
						} else if (estadoMenu == "3" && contactoOn == 1) {
							controller.set('contactos', ['']);
							Ember.$("#contacto").hide();
							contactoOn = 0;
						} else if (contactoOn == 0) {
							Ember.$("#contacto").show();
						}
					});
				});
			}, 0);
		},

		generarToken: function generarToken() {
			var num_caracteres = 32;
			var cadena = '';
			var todos = Array('a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0');
			for (var inner_cont = 0; inner_cont < num_caracteres; inner_cont++) {
				var posicion = parseInt(Math.random() * 10000000 % todos.length);
				cadena += todos[posicion];
			}
			return cadena;
		},
		sendComment: function sendComment() {
			var self = this;
			var mensaje = Ember.$('#message_text_modal').val();
			Ember.$('#chat_box_component div div div .text_center').trigger('click');

			var token = self.generarToken();
			//event.stopPropagation();
			var sendChat = {
				'tipoInterfaz': "grouptabs",
				'generoMujer': 1,
				'conversacion_k': self.conversacion,
				'usuario_emisor_k': self.session.userData.usuario_k,
				'usuario_creacion': self.session.userData.usuario_k,
				'usuario_receptor_k': self.usuario_receptor,
				'mensaje': mensaje,
				'token': token,
				'estatus': 1,
				'token_conversacion': self.token_k,
				'foto': self.session.userData.foto,
				"nombre": self.session.userData.nombre,
				"mensaje_k": self.last_mensaje_k += 1
			};
			self.controller.socket.socket.emit('sendChat', JSON.stringify(sendChat));
			Ember.$('#message_text_modal').val('');
		},
		setupController: function setupController(controller) {
			var self = this;
			Ember.$('#chat_box_component').hide();
			controller.set('heart_state', false);
			Ember.$.ajax({
				url: constant.APIURL + 'contacto/listarPublicos',
				type: 'GET',
				contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
				xhrFields: {
					withCredentials: true
				},
				success: function success(r) {
					var r = JSON.parse(r);
					//var fav = []
					Ember.$.ajax({
						url: constant.APIURL + 'contacto/listarPublicos',
						type: 'POST',
						contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
						data: 'start=0&limit=' + r.numFilas,
						xhrFields: {
							withCredentials: true
						},
						success: function success(r) {
							var respons = JSON.parse(r);
							function sortjsonarray(arr, prop) {
								if (arr == null) {
									return [];
								}
								if (!Array.isArray(arr)) {
									throw new TypeError('sort-json-array expects an array.');
								}
								if (arguments.length === 1) {
									return arr.sort();
								}
								if (arguments[2] == null || arguments[2] == "asc") {
									return arr.sort(compare(prop, 1));
								} else {
									throw new TypeError('Wrong argument.');
								}
							}
							function compare(attr, value) {
								if (value) {
									return function (a, b) {
										var x = a[attr] === null ? "" : "" + a[attr],
										    y = b[attr] === null ? "" : "" + b[attr];
										return x < y ? -1 : x > y ? 1 : 0;
									};
								} else {
									return function (a, b) {
										var x = a[attr] === null ? "" : "" + a[attr],
										    y = b[attr] === null ? "" : "" + b[attr];
										return x < y ? 1 : x > y ? -1 : 0;
									};
								}
							}
							var sort = sortjsonarray(respons.data, 'nombre');

							controller.set('directorio', sort);
							// FUNCION DE BUSQUDA
							Ember.$('#directorio_busqueda').on("keyup", function () {
								var input = Ember.$('#directorio_busqueda').val().toLowerCase();
								Ember.$("#directoriotable tr").filter(function () {
									Ember.$(this).toggle(Ember.$(this).text().toLowerCase().indexOf(input) > -1);
								});
							});
							// CLICK ALGUNA FILA
							self.clickRow(controller, self);
							//self.iniciar_socket();
							Ember.$('#loading').fadeOut('slow');
						},
						error: function error(e) {
							console.log(e);
						}
					});
				},
				error: function error(e) {
					console.log(e);
				}
			});

			self.controller.socket.socket.on('updateLeido', function (data) {
				console.log(JSON.parse(data));
			});
		},
		actions: {
			directorio: function directorio() {
				Ember.$("#contacto").hide();
				Ember.$("#grupos").find("button.active").removeClass("active");
				Ember.$("#directorio").addClass("active");

				var self = this;
				var controller = self.controller;
				controller.set('heart_state', false);
				Ember.$.ajax({
					url: constant.APIURL + 'contacto/listarPublicos',
					type: 'GET',
					contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
					xhrFields: {
						withCredentials: true
					},
					success: function success(r) {

						var r = JSON.parse(r);
						Ember.$('#loading').fadeIn('slow');
						Ember.$.ajax({
							url: constant.APIURL + 'contacto/listarPublicos',
							type: 'POST',
							contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
							data: 'start=0&limit=' + r.numFilas,
							xhrFields: {
								withCredentials: true
							},
							success: function success(r) {

								var respons = JSON.parse(r);
								function sortjsonarray(arr, prop) {
									if (arr == null) {
										return [];
									}
									if (!Array.isArray(arr)) {
										throw new TypeError('sort-json-array expects an array.');
									}
									if (arguments.length === 1) {
										return arr.sort();
									}
									if (arguments[2] == null || arguments[2] == "asc") {
										return arr.sort(compare(prop, 1));
									} else {
										throw new TypeError('Wrong argument.');
									}
								}
								function compare(attr, value) {
									if (value) {
										return function (a, b) {
											var x = a[attr] === null ? "" : "" + a[attr],
											    y = b[attr] === null ? "" : "" + b[attr];
											return x < y ? -1 : x > y ? 1 : 0;
										};
									} else {
										return function (a, b) {
											var x = a[attr] === null ? "" : "" + a[attr],
											    y = b[attr] === null ? "" : "" + b[attr];
											return x < y ? 1 : x > y ? -1 : 0;
										};
									}
								}
								var sort = sortjsonarray(respons.data, 'nombre');
								controller.set('directorio', sort);
								self.clickRow(controller);
								Ember.$('#loading').fadeOut('slow');
							},
							error: function error(e) {
								console.log(e);
							}
						});
					},
					error: function error(e) {
						console.log(e);
					}
				});
			},
			favoritos: function favoritos() {
				Ember.$("#contacto").hide();
				Ember.$("#grupos").find("button.active").removeClass("active");
				Ember.$("#favoritos").addClass("active");
				var self = this;
				var controller = self.controller;
				controller.set('heart_state', true);
				Ember.$('#loading').fadeIn('slow');
				Ember.$.ajax({
					url: constant.APIURL + 'contacto/listarMisContactos',
					type: 'GET',
					contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
					xhrFields: {
						withCredentials: true
					},
					success: function success(r) {
						var r = JSON.parse(r);
						Ember.$.ajax({
							url: constant.APIURL + 'contacto/listarMisContactos',
							type: 'POST',
							contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
							data: 'start=0&limit=' + r.numFilas,
							xhrFields: {
								withCredentials: true
							},
							success: function success(r) {
								var respons = JSON.parse(r);
								function sortjsonarray(arr, prop) {
									if (arr == null) {
										return [];
									}
									if (!Array.isArray(arr)) {
										throw new TypeError('sort-json-array expects an array.');
									}
									if (arguments.length === 1) {
										return arr.sort();
									}
									if (arguments[2] == null || arguments[2] == "asc") {
										return arr.sort(compare(prop, 1));
									} else {
										throw new TypeError('Wrong argument.');
									}
								}
								function compare(attr, value) {
									if (value) {
										return function (a, b) {
											var x = a[attr] === null ? "" : "" + a[attr],
											    y = b[attr] === null ? "" : "" + b[attr];
											return x < y ? -1 : x > y ? 1 : 0;
										};
									} else {
										return function (a, b) {
											var x = a[attr] === null ? "" : "" + a[attr],
											    y = b[attr] === null ? "" : "" + b[attr];
											return x < y ? 1 : x > y ? -1 : 0;
										};
									}
								}
								var sort = sortjsonarray(respons.data, 'nombre');
								controller.set('directorio', sort);
								self.clickRow(controller);
								Ember.$('#loading').fadeOut('slow');
							},
							error: function error(e) {
								console.log(e);
							}
						});
					},
					error: function error(e) {
						console.log(e);
					}
				});
			},
			tutores: function tutores() {
				Ember.$("#contacto").hide();
				Ember.$("#grupos").find("button.active").removeClass("active");
				Ember.$("#tutores").addClass("active");
				var self = this;
				var controller = self.controller;
				controller.set('heart_state', false);
				Ember.$('#loading').fadeIn('slow');
				Ember.$.ajax({
					url: constant.APIURL + 'contacto/listarPublicosTutores',
					type: 'POST',
					contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
					data: 'params=%7B%22tipo_contacto%22%3A%223%22%7D',
					xhrFields: {
						withCredentials: true
					},
					success: function success(r) {

						var respons = JSON.parse(r);
						function sortjsonarray(arr, prop) {
							if (arr == null) {
								return [];
							}
							if (!Array.isArray(arr)) {
								throw new TypeError('sort-json-array expects an array.');
							}
							if (arguments.length === 1) {
								return arr.sort();
							}
							if (arguments[2] == null || arguments[2] == "asc") {
								return arr.sort(compare(prop, 1));
							} else {
								throw new TypeError('Wrong argument.');
							}
						}
						function compare(attr, value) {
							if (value) {
								return function (a, b) {
									var x = a[attr] === null ? "" : "" + a[attr],
									    y = b[attr] === null ? "" : "" + b[attr];
									return x < y ? -1 : x > y ? 1 : 0;
								};
							} else {
								return function (a, b) {
									var x = a[attr] === null ? "" : "" + a[attr],
									    y = b[attr] === null ? "" : "" + b[attr];
									return x < y ? 1 : x > y ? -1 : 0;
								};
							}
						}
						var sort = sortjsonarray(respons.data, 'nombre');
						controller.set('directorio', sort);
						self.clickRow(controller);
						Ember.$('#loading').fadeOut('slow');
					},
					error: function error(e) {
						console.log(e);
					}
				});
			},
			nuevos: function nuevos() {
				Ember.$("#contacto").hide();
				Ember.$("#grupos").find("button.active").removeClass("active");
				Ember.$("#nuevos").addClass("active");
				var self = this;
				var controller = self.controller;
				controller.set('heart_state', false);
				Ember.$('#loading').fadeIn('slow');
				Ember.$.ajax({
					url: constant.APIURL + 'contacto/listarPublicosNuevos',
					type: 'POST',
					contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
					xhrFields: {
						withCredentials: true
					},
					success: function success(r) {

						var respons = JSON.parse(r);
						function sortjsonarray(arr, prop) {
							if (arr == null) {
								return [];
							}
							if (!Array.isArray(arr)) {
								throw new TypeError('sort-json-array expects an array.');
							}
							if (arguments.length === 1) {
								return arr.sort();
							}
							if (arguments[2] == null || arguments[2] == "asc") {
								return arr.sort(compare(prop, 1));
							} else {
								throw new TypeError('Wrong argument.');
							}
						}
						function compare(attr, value) {
							if (value) {
								return function (a, b) {
									var x = a[attr] === null ? "" : "" + a[attr],
									    y = b[attr] === null ? "" : "" + b[attr];
									return x < y ? -1 : x > y ? 1 : 0;
								};
							} else {
								return function (a, b) {
									var x = a[attr] === null ? "" : "" + a[attr],
									    y = b[attr] === null ? "" : "" + b[attr];
									return x < y ? 1 : x > y ? -1 : 0;
								};
							}
						}
						var sort = sortjsonarray(respons.data, 'nombre');
						controller.set('directorio', sort);
						self.clickRow(controller);
						Ember.$('#loading').fadeOut('slow');
					},
					error: function error(e) {
						console.log(e);
					}
				});
			},
			mandar: function mandar() {
				var self = this;
				Ember.$('#chat_box_component').show();
				Ember.$('#message_text').val('');
				Ember.$('#mensaje').modal('toggle');
				self.sendComment();
			}
		}
	});
});