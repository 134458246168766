define('comunidades-nafin/routes/reportes', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        beforeModel: function beforeModel() {
            var promise = new Ember.RSVP.Promise(function (resolve) {
                Ember.run.later(this, function () {
                    resolve("ok");
                }, 1000);
            });
            return promise;
        },
        setupController: function setupController(controller) {
            this.controller = controller;
            $.ajax({
                url: constant.APIURL + 'reportes/admin/listarReportesReporteador',
                type: 'POST',
                xhrFields: {
                    withCredentials: true
                },
                data: 'start=0&limit=100',
                contentType: 'application/x-www-form-urlencoded',
                success: function success(r) {
                    var respons = JSON.parse(r),
                        categos = [],
                        actual,
                        indice = -1;

                    //armar el arreglo como lo requiere ember
                    respons.data.forEach(function (element, index) {

                        if (actual != element.categoria_k) {
                            indice++;
                            actual = element.categoria_k;
                            categos.push({
                                categoria_k: element.categoria_k,
                                nombre: element.categoria,
                                cuenta: 0,
                                cuentaString: 'Registro',
                                childrens: []
                            });
                        }

                        categos[indice].childrens.push(element);
                        categos[indice].cuenta++;
                        categos[indice].cuentaString = categos[indice].cuenta == 1 ? 'Registro' : ' Registros';
                    });

                    console.log(categos);

                    controller.set('categorias', categos);
                }
            });
        },


        getFiltroObject: function getFiltroObject() {
            var unindexed_array = $('#filtrosdiv').serializeArray();
            var indexed_array = {};

            $.map(unindexed_array, function (n, i) {
                if (n['value'] != 'xxxx') {
                    indexed_array[n['name']] = n['value'];
                } else {
                    indexed_array[n['name']] = '';
                }
            });

            return indexed_array;
        },

        parametrosConFormato: function parametrosConFormato(values) {

            var cadena = values.toString();

            //quitar {" y "} del principio y del final
            cadena = cadena.replace(/{"/g, '');
            cadena = cadena.replace(/"}/g, '');
            //reemplaza ":" por :
            cadena = cadena.replace(/":"/g, '---');
            //reemplaza "," por "
            cadena = cadena.replace(/","/g, '"');
            //reemplaza espacio (no interpretado en chrome) por %20
            cadena = cadena.replace(/ /g, "%20");

            return cadena;
        },

        loadDatosReporte: function loadDatosReporte(obj) {
            var self = this;
            //let start = self.get('start');
            //let params = self.get('params');
            $.ajax({
                url: constant.APIURL + 'reportes/reporteador/getReporteData',
                type: 'POST',
                xhrFields: {
                    withCredentials: true
                },
                contentType: 'application/x-www-form-urlencoded',
                data: 'start=' + obj.start + '&limit=' + constant.PAGESIZE + '&filtros=' + obj.params + '&reporte_k=' + obj.reporte_k,
                success: function success(r) {

                    var respons = JSON.parse(r);

                    var html = '';
                    var columnas = self.columnas; //.set('columnas');

                    if (respons.data.length == 0) {
                        //si no hay datos
                        $('#paginadorload').addClass('hidden');
                        $('#paginadorloading').addClass('hidden');
                    } else {
                        respons.data.forEach(function (fila) {
                            //para cada uno de los registros
                            html += '<tr >'; //id=' + c.consulta_k + '
                            columnas.forEach(function (coc) {
                                html += '<td>' + fila[coc.dataindex] + '</td>';
                            });
                            html += '</tr>';
                        });

                        $('#tableBody').append(html);
                        $('#paginadorload').removeClass('hidden');
                        $('#paginadorloading').addClass('hidden');
                    }
                    /*respons.data.forEach((idx) => {
                        respons.data[idx].contadorListar = num++;
                        var c = respons.data[idx];
                         html += '<tr id=' + c.consulta_k + '>'
                        html += '<th scope="row">' + (start - limit + c.contadorListar) + '</th>'
                        html += '<td>' + c.titulo + '</td>'
                        html += '<td>' + c.tipo + '</td>'
                        html += '<td>' + c.comunidad + '</td>'
                        html += '<td>' + c.usuario_asignado + '</td>'
                        html += '<td>' + c.edo_resolucion + '</td>'
                        html += '<td>' + c.fecha_hora_creacion + '</td>'
                        html += '<td>' + c.fecha_hora_modificacion + '</td>'
                        html += '<td>' + c.prioridad + '</td>'
                        html += '</tr>'
                    });
                    $('#tableBody').append(html);
                    /*$('#tableBody tr').click(function() {
                        let id = $(this).attr('id');
                        self.transitionTo('consultas/consulta-especifica',id);
                    });*/
                },
                error: function error(e) {
                    console.log(e);
                }
            });
        },


        actions: {
            buscarConFiltro: function buscarConFiltro() {
                var filtro = JSON.stringify(this.getFiltroObject());
                console.info(filtro);
                this.start = 0;
                $('#paginadorload').addClass('hidden');
                $('#paginadorloading').removeClass('hidden');
                $('#tableBody').html('');

                this.loadDatosReporte({
                    reporte_k: this.reporte_k,
                    start: 0,
                    params: filtro
                });
            },

            cargarMas: function cargarMas(params) {
                params = params || '';
                $('#paginadorload').addClass('hidden');
                $('#paginadorloading').removeClass('hidden');
                this.start += constant.PAGESIZE;
                this.loadDatosReporte({
                    reporte_k: this.reporte_k,
                    start: this.start,
                    params: params
                });
            },

            mostrarReporte: function mostrarReporte(reporte_k) {

                var self = this;
                if (self.reporte_k != reporte_k) {
                    self.reporte_k = reporte_k;

                    self.start = 0;

                    $('#paginadorload').addClass('hidden');
                    $('#paginadorloading').removeClass('hidden');
                    $.ajax({
                        url: constant.APIURL + 'reportes/reporteador/getReporte',
                        type: 'POST',
                        xhrFields: {
                            withCredentials: true
                        },
                        contentType: 'application/x-www-form-urlencoded',
                        data: 'reporte_k=' + reporte_k,
                        success: function success(r) {
                            var respons = JSON.parse(r);
                            console.log(respons);

                            var num = 1;
                            var html = '';
                            var columnas = respons.columnas;
                            var filtros = respons.filtros;

                            self.controller.set('columnas', columnas);
                            self.columnas = columnas;

                            $('#titleReport').html(respons.reporte.nombre);
                            $('#tableBody').html('');

                            self.loadDatosReporte({
                                reporte_k: reporte_k,
                                start: 0,
                                params: ''
                            });

                            var filtrosHtml = '';

                            filtros.forEach(function (rec) {
                                //para cada uno de los filtros
                                filtrosHtml += '<div class="input-group col-12 col-md-6 col-xl-4 margin_ud_10px">';

                                if (rec.xtype == 'textfield') filtrosHtml += '<input type="text" class="form-control" name="' + rec.nombre_variable + '" placeholder="' + rec.nombre_etiqueta + '" />';
                                if (rec.xtype == 'combo') {
                                    filtrosHtml += '<select id="filtro' + rec.filtro_k + '" class="form-control" name="' + rec.nombre_variable + '" placeholder="' + rec.nombre_etiqueta + '" /></select>';

                                    $.ajax({
                                        url: constant.APIURL + 'reportes/reporteador/getComboData',
                                        type: 'POST',
                                        xhrFields: {
                                            withCredentials: true
                                        },
                                        contentType: 'application/x-www-form-urlencoded',
                                        data: 'reporte_k=' + rec.reporte_k + '&filtro_k=' + rec.filtro_k,
                                        success: function success(r) {
                                            var respons = JSON.parse(r);

                                            var select = '<option value="xxxx">Seleccionar...</option>';
                                            respons.data.forEach(function (val) {
                                                select += '<option value="' + val.value + '">' + val.label + '</option>';
                                            });
                                            $('#filtro' + rec.filtro_k).append(select);
                                        }
                                    });
                                }

                                filtrosHtml += '</div>';
                            });

                            if (filtrosHtml != '') {
                                $('#filtrosdiv').html(filtrosHtml);
                                $('#btnBuscar').removeClass('hidden');
                                $('#btnExportar').removeClass('hidden');
                            } else {
                                $('#filtrosdiv').html('');
                                $('#btnBuscar').addClass('hidden');
                                $('#btnExportar').addClass('hidden');
                            }
                        },
                        error: function error(e) {
                            console.log(e);
                        }
                    });
                }
            },

            exportToExcel: function exportToExcel() {
                var self = this;
                var values = this.getFiltroObject();
                values.reporte_k = this.reporte_k;
                values.email = self.session.userData.email;
                /*if (this.clase && this.record) {
                    values.xm3n = this.record.data.usuario_k;
                    var parametros = this.parametrosConFormato(Ext.encode(values));
                    window.open(Ext.URL + "/usuario/export/" + parametros);
                } else {*/
                //var parametros = this.parametrosConFormato( JSON.stringify(values));
                $.ajax({
                    url: constant.APIURL + "reportes/reporteador/requestexportandsendmail/" + JSON.stringify(values),
                    type: 'GET',
                    xhrFields: {
                        withCredentials: true
                    },
                    contentType: 'application/x-www-form-urlencoded',
                    data: '',

                    success: function success(r) {
                        var respons = JSON.parse(r);
                        console.log(respons);
                        if (values.email != '') {

                            if (respons.success == true) {
                                $('#modal-avisos .modal-header').text('Mensaje del sistema');
                                $('#modal-avisos .modal-body').text(respons.msg);
                                $('#modal-avisos').modal('show');
                            } else {

                                showModal({ titulo: 'Error en sistema.', mensaje: 'Ups! acaba de suceder algo inesperado. Por favor intente nuevamente en otro momento.' });
                            }
                        }
                    },
                    error: function error(e) {
                        console.log(e);
                    }
                });
                //}
            }
        }
    });
});