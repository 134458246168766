define("comunidades-nafin/components/examples-components/react-example", ["exports", "comunidades-nafin/react-component"], function (exports, _reactComponent) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var Greeter = function Greeter(_ref) {
    var name = _ref.name;
    return React.createElement(
      "h2",
      null,
      "Hello from ",
      name,
      " on Ember component!!!"
    );
  };

  exports.default = _reactComponent.default.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.reactRender(React.createElement(Greeter, { name: "React" }));
    }
  });
});