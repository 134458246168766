define('comunidades-nafin/routes/rutas-aprendizaje-especifico', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		beforeModel: function beforeModel() {
			var promise = new Ember.RSVP.Promise(function (resolve) {
				Ember.run.later(this, function () {
					resolve("ok");
				}, 1000);
			});
			return promise;
		},


		actions: {
			rutas_objetivo: function rutas_objetivo() {
				this.transitionTo('rutas-aprendizaje-especifico.rutas-objetivo');
			},
			rutas_agrupamiento: function rutas_agrupamiento() {
				this.transitionTo('rutas-aprendizaje-especifico.rutas-agrupamiento');
			},
			rutas_alumnos: function rutas_alumnos() {
				this.transitionTo('rutas-aprendizaje-especifico.rutas-alumnos');
			},
			rutas_documentos: function rutas_documentos() {
				this.transitionTo('rutas-aprendizaje-especifico.rutas-documentos');
			}
		}

	});
});