define('comunidades-nafin/components/admin-components/eventos-nuevo-evento', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        /**
         * unmask - Función utilitaria.
         *
         * Función utilitaria.
         *
         *
         * @author  [YRM] Maurilio Cruz Morales
         *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
         *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
         *              Tel. celular (55) 6702 6252
         *
         * @date    2018-10-04
         * @param   void
         * @return  void
         */

        setFormActions: function setFormActions() {

            Ember.$('input[name="evento-fecha-inicio"],input[name="evento-fecha-fin"]').on('blur', function (e) {

                if (Ember.$(this).val() != '') {

                    var ok = /^(\d{4})(-)([0][1-9]|[1][0-2])(-)([0][1-9]|[12][0-9]|3[01])$/.test(Ember.$(this).val());
                    if (!ok) {
                        Ember.$(this).val('');Ember.$(this).focus();
                    }
                }
            });

            Ember.$('input[name="evento-hora-inicio"],input[name="evento-hora-fin"]').on('blur', function (e) {

                if (Ember.$(this).val() != '') {

                    var ok = /^([1][0]|[0-1][1-9]|[2][0-3])(:)([0-5][0-9])((:)([0-5][0-9])){0,1}$/.test(Ember.$(this).val());
                    if (!ok) {
                        Ember.$(this).val('');Ember.$(this).focus();
                    }
                }
            });

            var self = this;
            Ember.$('#evento-hora-inicio').timepicker({
                timeFormat: 'HH:mm',
                zindex: 1151
            });

            Ember.$('#evento-hora-fin').timepicker({
                timeFormat: 'HH:mm',
                zindex: 1151
            });

            Ember.$("#evento-fecha-inicio").datepicker({
                dateFormat: "yy-mm-dd",
                minDate: 0
            });
            Ember.$("#evento-fecha-fin").datepicker({
                dateFormat: "yy-mm-dd"
            });

            Ember.$('#evento-fecha-inicio').on('change', function (e) {
                self.lockDates(e);
            });

            Ember.$('#evento-fecha-fin').on('change', function (e) {
                self.lockDates(e);
            });
        },

        /**
         * didReceiveAttrs - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
         *
         *
         * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
         *
         *
         * @author  [YRM] Maurilio Cruz Morales
         *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
         *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
         *              Tel. celular (55) 6702 6252
         *
         * @date    2018-10-04
         * @param   void
         * @return  void
         */

        didReceiveAttrs: function didReceiveAttrs(config) {

            this._super(config);
            this.set('store_evento', this.store_evento);
        },

        /**
         * Lorem ipsum dolor sit - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
         *
         *
         * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
         *
         *
         * @author  [YRM] Maurilio Cruz Morales
         *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
         *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
         *              Tel. celular (55) 6702 6252
         *
         * @date    2018-10-04
         * @param   void
         * @return  void
         */

        didInsertElement: function didInsertElement() {

            this.setFormActions();
            Ember.run.schedule('afterRender', function () {
                unmask();
            });
        },

        lockDates: function lockDates(e) {

            var dtToday = new Date();
            var month = dtToday.getMonth() + 1;
            var day = dtToday.getDate();
            var year = dtToday.getFullYear();
            if (month < 10) month = '0' + month.toString();
            if (day < 10) day = '0' + day.toString();

            var maxDate = year + '-' + month + '-' + day;
            var chosseDate = Ember.$(e.target).val();

            if (chosseDate < maxDate) {
                Ember.$('#evento-fecha-inicio').val(maxDate);
            }
        },


        /**
         * Lorem ipsum dolor sit - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
         *
         *
         * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
         *
         *
         * @author  [YRM] Maurilio Cruz Morales
         *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
         *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
         *              Tel. celular (55) 6702 6252
         *
         * @date    2018-10-04
         * @param   void
         * @return  void
         */

        afterCreateEvent: function afterCreateEvent(response_text, store_evento) {

            unmask();
            Ember.$('#modalFormNewEvent').modal('hide');
            showModal({ titulo: 'Evento Agregado.', mensaje: 'El evento ' + store_evento.nombre + ' Se ha agregado correctamente.' });

            this.onAfterCreateEvent({
                callbackFunction: function callbackFunction() {},
                storeEvent: store_evento
            });
        },

        /**
         * afterUpdateEvent - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
         *
         *
         * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
         *
         *
         * @author  [YRM] Maurilio Cruz Morales
         *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
         *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
         *              Tel. celular (55) 6702 6252
         *
         * @date    2018-10-04
         * @param   void
         * @return  void
         */

        afterUpdateEvent: function afterUpdateEvent(response_text, store_evento) {

            unmask();
            Ember.$('#modalFormNewEvent').modal('hide');
            showModal({ titulo: 'Evento Editado.', mensaje: 'El evento ' + store_evento.nombre + ' Se ha editado correctamente.' });

            this.onAfterUpdateEvent({
                callbackFunction: function callbackFunction() {},
                storeEvent: store_evento
            });
        },

        /**
         * Lorem ipsum dolor sit - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
         *
         *
         * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
         *
         *
         * @author  [YRM] Maurilio Cruz Morales
         *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
         *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
         *              Tel. celular (55) 6702 6252
         *
         * @date    2018-10-04
         * @param   void
         * @return  void
         */

        afterDeleteEvent: function afterDeleteEvent(response_text, store_evento) {

            unmask();
            Ember.$('#modalConfirmDeleteEvent').modal('hide');
            showModal({ titulo: 'Evento Eliminado.', mensaje: 'El evento ' + store_evento.nombre + ' Se ha eliminado correctamente.' });

            this.onAfterDeleteEvent({
                callbackFunction: function callbackFunction() {},
                storeEvent: store_evento
            });
        },

        /**
         * Lorem ipsum dolor sit - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
         *
         *
         * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
         *
         *
         * @author  [YRM] Maurilio Cruz Morales
         *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
         *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
         *              Tel. celular (55) 6702 6252
         *
         * @date    2018-10-04
         * @param   void
         * @return  void
         */

        onErrorAjax: function onErrorAjax(e) {
            Ember.$('#modalConfirmDeleteEvent').modal('hide');
            unmask();
            showModalError();
            console.log(e);
        },

        /**
         * actions - Objeto de definiciones de acciones (funciones) disponibles.
         *
         *
         * Objeto de definiciones de acciones (funciones) disponibles.
         *
         *
         * @author  [YRM] Maurilio Cruz Morales
         *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
         *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
         *              Tel. celular (55) 6702 6252
         *
         * @date    2018-10-04
         */

        actions: {

            /**
             * Lorem ipsum dolor sit - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
             *
             *
             * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
             *
             *
             * @author  [YRM] Maurilio Cruz Morales
             *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
             *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
             *              Tel. celular (55) 6702 6252
             *
             * @date    2018-10-04
             * @param   void
             * @return  void
             */

            saveEvent: function saveEvent(store_evento) {

                Ember.$('#loading').fadeIn('slow');

                var _self = this;
                var _store_evento = store_evento;
                var _missing_value = '';

                Ember.set(store_evento, 'fecha', Ember.$('#evento-fecha-inicio').val());
                Ember.set(store_evento, 'hora', Ember.$('#evento-hora-inicio').val());
                Ember.set(store_evento, 'fecha_fin', Ember.$('#evento-fecha-fin').val());
                Ember.set(store_evento, 'hora_fin', Ember.$('#evento-hora-fin').val());

                if (_missing_value == '' && store_evento.nombre == '') {
                    _missing_value = 'Titulo de evento';
                }
                if (_missing_value == '' && store_evento.lugar == '') {
                    _missing_value = 'Lugar de evento';
                }
                if (_missing_value == '' && store_evento.fecha == '') {
                    _missing_value = 'Fecha de inicio';
                }
                if (_missing_value == '' && store_evento.hora == '') {
                    _missing_value = 'Hora de inicio';
                }
                if (_missing_value == '' && store_evento.fecha_fin == '') {
                    _missing_value = 'Fecha de fin';
                }
                if (_missing_value == '' && store_evento.hora_fin == '') {
                    _missing_value = 'Hora de fin';
                }
                if (_missing_value == '' && store_evento.descripcion == '') {
                    _missing_value = 'Descripción';
                }

                if (_missing_value != '') {

                    Ember.set(store_evento, "hasError", true);
                    Ember.set(store_evento, "errorMessage", 'el campo ' + _missing_value + ' es requerido.');
                    unmask();
                    return;
                }

                /*
                 *
                 */

                var _is_editing = Number.isInteger(parseInt(store_evento.calendario_k)) && parseInt(store_evento.calendario_k) != 0 ? true : false; // por convención un evento nuevo tiene atributo de evento_k = 0.
                var _metodo = '';
                var _data_string = '';

                if (_is_editing) {

                    /*
                     * Estamos agregando un evento.
                     */

                    _metodo = 'actualizar';
                    _data_string = 'nombre=' + store_evento.nombre + '&lugar=' + store_evento.lugar + '&fecha=' + store_evento.fecha + '&hora=' + store_evento.hora + '&fecha_fin=' + store_evento.fecha_fin + '&hora_fin=' + store_evento.hora_fin + '&descripcion=' + store_evento.descripcion + '&anfitrion_k=' + store_evento.anfitrion_k + '&i=' + store_evento.calendario_k;
                } else {

                    /*
                     * Estamos editando un evento.
                     */
                    _metodo = 'agregarEventoPublico';
                    _data_string = 'nombre=' + store_evento.nombre + '&lugar=' + store_evento.lugar + '&fecha=' + store_evento.fecha + '&hora=' + store_evento.hora + '&fecha_fin=' + store_evento.fecha_fin + '&hora_fin=' + store_evento.hora_fin + '&descripcion=' + store_evento.descripcion;
                }

                Ember.$.ajax({
                    url: constant.APIURL + 'calendario/' + _metodo,
                    type: 'POST',
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    data: _data_string,
                    xhrFields: {
                        withCredentials: true
                    },
                    success: function success(response_text) {

                        var response_json = JSON.parse(response_text);

                        if (response_json.success == true) {

                            if (_is_editing) {

                                _self.afterUpdateEvent(response_text, response_json.data);
                            } else {

                                _self.afterCreateEvent(response_text, response_json.data);
                            }
                        } else {

                            _self.onErrorAjax(response_text);
                        }
                    },
                    error: function error(response) {
                        _self.onErrorAjax(response);
                    }

                });
            },

            /**
             * Lorem ipsum dolor sit - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
             *
             *
             * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
             *
             *
             * @author  [YRM] Maurilio Cruz Morales
             *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
             *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
             *              Tel. celular (55) 6702 6252
             *
             * @date    2018-10-04
             * @param   void
             * @return  void
             */

            deleteEvent: function deleteEvent(store_evento) {

                Ember.$('#loading').fadeIn('slow');

                var _self = this;
                var _store_evento = store_evento;

                var _metodo = 'eliminar';
                var _data_string = '&i=' + store_evento.calendario_k;

                Ember.$.ajax({
                    url: constant.APIURL + 'calendario/' + _metodo,
                    type: 'POST',
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    data: _data_string,
                    xhrFields: {
                        withCredentials: true
                    },
                    success: function success(response_text) {
                        _self.afterDeleteEvent(response_text, _store_evento);
                    },
                    error: function error(response) {
                        _self.onErrorAjax(response);
                    }
                });
            }

        }

    });
});