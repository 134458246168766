define("comunidades-nafin/components/admin-components/usuarios-admin-usuarios", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		actions: {
			agregarUsuario: function agregarUsuario() {
				/*txt_nomina=123456789&username=usuariocreado2&txt_nombre=Pedro&txt_apaterno=Angeles&txt_amaterno=Romero
    &cb_puesto=1&cb_departamento=1&cb_oficina=1&cb_rol=5&txt_email=correousuario%40mail.com&txt_pass=123456789*/
				/*Traer valores directo del form con jquery*/
				var validation = {};

				if ($("#nuevonomina").val() != "") var nuevonomina = $("#nuevonomina").val();else validation.nomina = "El campo Nomina es obligatorio";
				if ($("#nuevopass").val() != "") var nuevopass = $("#nuevopass").val();else validation.password = "El campo Password es obligatorio";
				if ($("#nuevonombre").val() != "") var nuevonombre = $("#nuevonombre").val();else validation.nombre = "El campo Nombre es obligatorio";
				if ($("#nuevoapellido").val() != "") var nuevoapellido = $("#nuevoapellido").val();else validation.apellido = "El campo Apellido Paterno es obligatorio";
				if ($("#nuevomaterno").val() != "") var nuevomaterno = $("#nuevomaterno").val();else validation.materno = "El campo Apellido Materno es obligatorio";
				if ($("#nuevopuesto").val() != 0) var nuevopuesto = $("#nuevopuesto").val();else validation.puesto = "El campo Puesto es obligatorio";
				if ($("#nuevodepartamento").val() != 0) var nuevodepartamento = $("#nuevodepartamento").val();else validation.departamento = "El campo Departamento es obligatorio";
				if ($("#nuevooficina").val() != 0) var nuevooficina = $("#nuevooficina").val();else validation.oficina = "El campo Oficina es obligatorio";
				if ($("#nuevorol").val() != 0) var nuevorol = $("#nuevorol").val();else validation.rol = "El campo Rol es obligatorio";
				if ($("#nuevocorreo").val() != 0) var nuevocorreo = $("#nuevocorreo").val();else validation.correo = "El campo Correo es obligatorio";
				if ($("#nuevousername").val() != "") var nuevousername = $("#nuevousername").val();else validation.username = "El campo Username es obligatorio";

				if (Object.keys(validation).length > 0) {
					console.log(validation);$("#errortext").html("Hay campos por llenar");$("#errortext").fadeOut('slow');return false;
				}

				var datasendnu = {
					txt_nomina: nuevonomina,
					username: nuevousername,
					txt_nombre: nuevonombre,
					txt_apaterno: nuevoapellido,
					txt_amaterno: nuevomaterno,
					cb_puesto: nuevopuesto,
					cb_departamento: nuevodepartamento,
					cb_oficina: nuevooficina,
					cb_rol: nuevorol,
					txt_email: nuevocorreo,
					txt_pass: nuevopass
				};

				$.ajax({
					url: constant.APIURL + 'usuario/agregar',
					type: 'POST',
					xhrFields: {
						withCredentials: true
					},
					contentType: 'application/x-www-form-urlencoded',
					data: datasendnu,
					/*{success:false, error : 'Lo sentimos, ya existe un usuario registrado con esa N&oacute;mina.'}*/
					success: function success(r) {
						$('#showModalNuevoUsuario').modal('hide');
						var respun = r;
						console.log(respun);
					},
					error: function error(e) {
						console.log(e);
					}
				});
			}, //agregarUsuario
			editarUsuario: function editarUsuario() {
				/*txt_nomina=123456789&username=usuariocreado2&txt_nombre=Pedro&txt_apaterno=Angeles&txt_amaterno=Romero
    &cb_puesto=1&cb_departamento=1&cb_oficina=1&cb_rol=5&txt_email=correousuario%40mail.com&txt_pass=123456789*/
				/*Traer valores directo del form con jquery*/
				var validation = {};

				if ($("#nuevonomina").val() != "") var nuevonomina = $("#nuevonomina").val();else validation.nomina = "El campo Nomina es obligatorio";
				/*if($("#nuevopass").val() != "")
    	var nuevopass 		= $("#nuevopass").val();
    else
    	validation.password = "El campo Password es obligatorio";*/
				if ($("#nuevonombre").val() != "") var nuevonombre = $("#nuevonombre").val();else validation.nombre = "El campo Nombre es obligatorio";
				if ($("#nuevoapellido").val() != "") var nuevoapellido = $("#nuevoapellido").val();else validation.apellido = "El campo Apellido Paterno es obligatorio";
				if ($("#nuevomaterno").val() != "") var nuevomaterno = $("#nuevomaterno").val();else validation.materno = "El campo Apellido Materno es obligatorio";
				if ($("#nuevopuesto").val() != 0) var nuevopuesto = $("#nuevopuesto").val();else validation.puesto = "El campo Puesto es obligatorio";
				if ($("#nuevodepartamento").val() != 0) var nuevodepartamento = $("#nuevodepartamento").val();else validation.departamento = "El campo Departamento es obligatorio";
				if ($("#nuevooficina").val() != 0) var nuevooficina = $("#nuevooficina").val();else validation.oficina = "El campo Oficina es obligatorio";
				if ($("#nuevorol").val() != 0) var nuevorol = $("#nuevorol").val();else validation.rol = "El campo Rol es obligatorio";
				if ($("#nuevocorreo").val() != 0) var nuevocorreo = $("#nuevocorreo").val();else validation.correo = "El campo Correo es obligatorio";
				if ($("#nuevousername").val() != "") var nuevousername = $("#nuevousername").val();else validation.username = "El campo Username es obligatorio";
				if ($("#idusuario").val() != "") var idusuario = $("#idusuario").val();else validation.idusuario = "El usuario es incorrecto";

				if (Object.keys(validation).length > 0) {
					console.log(validation);$("#errortext").html("Hay campos por llenar");$("#errortext").fadeOut('slow');return false;
				}

				var datasendnu = {
					txt_nomina: nuevonomina,
					username: nuevousername,
					txt_nombre: nuevonombre,
					txt_apaterno: nuevoapellido,
					txt_amaterno: nuevomaterno,
					cb_puesto: nuevopuesto,
					cb_departamento: nuevodepartamento,
					cb_oficina: nuevooficina,
					cb_rol: nuevorol,
					txt_email: nuevocorreo,
					//txt_pass: nuevopass,
					i: idusuario
				};

				// datasendnu = JSON.stringify(datasendnu);
				$.ajax({
					url: constant.APIURL + 'usuario/editar',
					type: 'POST',
					xhrFields: {
						withCredentials: true
					},
					contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
					data: datasendnu,
					success: function success(r) {

						var edit = r;
						if (edit == "{success:true}") {
							$("#showModalNuevoUsuario").modal("hide");
							$("#button-search-users").trigger("click");
						}
					},
					error: function error(e) {
						console.log(e);
					}
				});
			} //editarUsuario
		}
	});
});