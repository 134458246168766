define('comunidades-nafin/routes/tipos-de-respuesta', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        beforeModel: function beforeModel() {
            var promise = new Ember.RSVP.Promise(function (resolve) {
                Ember.run.later(this, function () {
                    resolve("ok");
                }, 1000);
            });
            return promise;
        }
    });
});