define('comunidades-nafin/initializers/authentication', ['exports'], function (exports) {
				'use strict';

				Object.defineProperty(exports, "__esModule", {
								value: true
				});
				exports.initialize = initialize;


				var Session = Ember.Object.extend({
								isAuthenticated: false,
								isAdmin: false,
								isTutor: false,
								userData: {}
				});

				// ROLES
				// Human Talent Admin					1
				// Administrador Nafin					2
				// Administrador Nafin Diagnostico		3
				// Administrador Nafin Notificaciones	4
				// Alumnos Nafin 						5
				// Tutor Nafin							6
				// Alumnos								7

				function initialize(application) {

								application.register('session:main', Session);
								application.inject('controller', 'session', 'session:main');
								application.inject('component', 'session', 'session:main');
								application.inject('route', 'session', 'session:main');

								Ember.$(document).ajaxComplete(function (e, r) {
												//console.log( "Triggered ajaxComplete handler.",response );
												var response = {};
												try {
																response = JSON.parse(r.responseText);
												} catch (ex) {
																console.log(ex);
												}
												if (response.fatal) {
																Ember.$('#modal-avisos').modal('show');
																Ember.$('.modal-backdrop').css({ 'opacity': '1' });
																Ember.$('#modal-avisos .modal-body').html('No ha iniciado sesión o esta ya ha finalizado, deberá iniciar sesión desde el entorno público en el menú "Iniciar Sesión"');
																Ember.$("#modal-avisos").on("hidden.bs.modal", function () {
																				document.location.href = constant.LANDINGURL;
																});
																setTimeout(function () {
																				Ember.$('#loading').fadeOut();
																}, 1000);
																setTimeout(function () {
																				document.location.href = constant.LANDINGURL;
																}, 5000);
												}
								});
				}

				exports.default = {
								initialize: initialize
				};
});