define('comunidades-nafin/components/events-list', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		didInsertElement: function didInsertElement() {
			var self = this._super.apply(this, arguments);
			var d = new Date();
			var day = d.getDate();
			var month = d.getMonth();
			if (month < 10) {
				month = '0' + month;
			}
			var year = d.getFullYear();

			var date = new Date();
			var newDate = new Date(date.setDate(date.getDate() + 30));
			var newDay = newDate.getDate();
			var newYear = newDate.getFullYear();
			var newMonth = newDate.getMonth();

			Ember.$.ajax({
				url: constant.APIURL + '/calendario/listarEventosPorUsuario',
				type: 'POST',
				xhrFields: {
					withCredentials: true
				},
				contentType: 'application/x-www-form-urlencoded',
				data: 'params={"start":"' + year + '-' + month + '-' + day + '","end":"' + newYear + '-' + newMonth + '-' + newDay + '"}',
				success: function success(response) {
					var data = JSON.parse(response);
					console.log(response);
					self.formatter(data.data, self);
				},
				error: function error(e) {
					console.log(e);
				}
			});
		},
		didRender: function didRender() {
			Ember.$('.carousel-item:first-child').addClass('active');
		},

		//Dar formato deseado a mes y fechas
		formatter: function formatter(data, self) {
			Object.keys(data).forEach(function (idx) {
				var date = data[idx].fecha.split('-');
				var month = date[1];
				data[idx].monthFormath = self.monthFormatter(month);
				data[idx].dayFormath = date[2];
				data[idx].formatt_hora = data[idx].hora.substr(0, 5);
				data[idx].formatt_hora_fin = data[idx].hora_fin.substr(0, 5);
			});
			self.setMonth(data, self);
		},

		// Asignar el més en el que nos encontramos
		monthFormatter: function monthFormatter(month) {
			if (month === "01") {
				return "Enero";
			} else if (month === "02") {
				return "Febrero";
			} else if (month === "03") {
				return "Marzo";
			} else if (month === "04") {
				return "Abril";
			} else if (month === "05") {
				return "Mayo";
			} else if (month === "06") {
				return "Junio";
			} else if (month === "07") {
				return "Julio";
			} else if (month === "08") {
				return "Agosto";
			} else if (month === "09") {
				return "Septiembre";
			} else if (month === "10") {
				return "Octubre";
			} else if (month === "11") {
				return "Noviembre";
			} else {
				return "Diciembre";
			}
		},
		setMonth: function setMonth(data, self) {
			self.set('event', data);
		}
	});
});