define("comunidades-nafin/routes/historico", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        actions: {
            goBack: function goBack() {
                // document.location.href = '/diagnostico-empresarial';
                // this.transitionTo('diagnostico-empresarial', 1);


                var url_page = new URLSearchParams(window.location.search);
                if (document.referrer.search("/preguntas-diagnostico") > -1) {
                    window.location.href = "/diagnostico-empresarial/0?tipo=misDiagnosticos";
                } else if (url_page.get('tipo') != null && document.referrer.search("tipo=misDiagnosticos") == -1) {
                    window.location.href = document.referrer + "?tipo=misDiagnosticos";
                } else {
                    history.back();
                }
            }
        }
    });
});