define('comunidades-nafin/routes/administracion-cursos-curso-especifico', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    /**
     * beforeModel - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
     *
     *
     * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-04
     * @param   void
     * @return  void
     */

    beforeModel: function beforeModel() {
      var promise = new Ember.RSVP.Promise(function (resolve) {
        Ember.run.later(this, function () {
          resolve("ok");
        }, 1000);
      });
      return promise;
    },


    /**
     * setupController - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
     *
     *
     * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-04
     * @param   controller controller referencia al controlador de notificaciones.
     * @return  void
     */

    setupController: function setupController(controller) {

      this._super.apply(this, arguments);

      controller.set('ra_k', window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1));

      this.loadStoreCurso(controller);
    },


    /**
     * loadStoreCurso - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
     *
     *
     * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-04
     * @param   controller controller referencia al controlador de notificaciones.
     * @return  void
     */

    loadStoreCurso: function loadStoreCurso(controller) {

      var ra_k = controller.get('ra_k');
      var data_string = 'params={"ra_k":"' + ra_k + '"}';

      var _self = this;
      var _controller = controller;

      $.ajax({
        url: constant.APIURL + 'lms/raprendizaje/listar',
        type: 'POST',
        async: false,
        xhrFields: { withCredentials: true },
        contentType: 'application/x-www-form-urlencoded',
        data: data_string,
        success: function success(response_text) {

          var data = JSON.parse(response_text);
          var raprendizaje_object = data.data[0];

          /*
           *  Obtenemos la imagen de la ruta de aprendizaje, a patir de los siguienttes criterios:
           *
           *  Si existe una referencia a una imagen desde la relación local ( curso_imagen ), para
           *  la ruta de aprendizaje, se le da prioidad, en caso contario, se toma la imagen poveniente
           *  del servicio.
           */

          if (curso_imagen) {
            if (curso_imagen[raprendizaje_object.ra_k]) {
              raprendizaje_object.ruta_imagen = curso_imagen[raprendizaje_object.ra_k];
            }
          }

          /*
           * Generamos la URL absoluta de la imagen, hacia el sitio en donde se encuetran los recursos gráficos.
           */

          raprendizaje_object.ruta_imagen = solveImageURL(raprendizaje_object.ruta_imagen);
          raprendizaje_object.categoria = raprendizaje_object.categoria_ra;

          _controller.set('storeCurso', raprendizaje_object);
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },

    /**
     * actions - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
     *
     *
     * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-04
     */

    actions: {
      showEditCourseModal: function showEditCourseModal(store) {
        console.log('showEditCourseModal', store);
        $('#modalFormNewLearningPath').modal('show');
      },

      afterSaveLearningPath: function afterSaveLearningPath(callback_function) {

        /*
         * Conociendo que el servidor devuelve los registros de cursos dando prioridad a los últimos creados. aprovechamos esto
         * para refrescar el stote en modo <<busqueda activa>> con un filtro de cadena vacía. para asi agregar el último elemneto 
         * agregado.
         */

        /*
         * Guardamos las variables que se tienen en <<searchActive>> y <<filtro_string>>
         */

        var _searchActive = this.controller.get("searchActive");
        var filtro_string = this.controller.get("filtro_string");

        /* 
         * Seteamos variables y hacemos el re-loading de cursos.
         */

        this.controller.set("searchActive", true);
        this.controller.set("filtro_string", "");

        this.loadStoreCurso(this.controller);

        /*
         * En caso de haber recidibo desde el una función de callback, la ejecutamos.
         */

        if (isFunction(callback_function)) {
          callback_function();
        }

        /*
          * Recuperamos las variables que se tenían en <<searchActive>> y <<filtro_string>>
          */

        this.controller.set("searchActive", _searchActive);
        this.controller.set("filtro_string", filtro_string);
      },
      bindScopeToCursosAdminCursosComponent: function bindScopeToCursosAdminCursosComponent(_scope_cursos_admin_cursos_component) {

        this.cursosAdminCursosScope = _scope_cursos_admin_cursos_component;
      },

      /*
       * goBack - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
       *
       *
       * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
       *
       *
       * @author  [YRM] Maurilio Cruz Morales
       *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *              Tel. celular (55) 6702 6252
       *
       * @date    2018-10-04
       * @param   void
       * @return  void
       */

      goBack: function goBack() {

        history.back();
      }
    }

  });
});