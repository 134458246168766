define('comunidades-nafin/helpers/searchable-select-highlight-match', ['exports', 'ember-searchable-select/helpers/searchable-select-highlight-match'], function (exports, _searchableSelectHighlightMatch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _searchableSelectHighlightMatch.default;
    }
  });
  Object.defineProperty(exports, 'searchableSelectHighlightMatch', {
    enumerable: true,
    get: function () {
      return _searchableSelectHighlightMatch.searchableSelectHighlightMatch;
    }
  });
});