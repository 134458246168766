define('comunidades-nafin/components/searchable-select', ['exports', 'ember-searchable-select/components/searchable-select'], function (exports, _searchableSelect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _searchableSelect.default;
    }
  });
});