define('comunidades-nafin/routes/faqs', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        /**
         * setupController -
         * @param controller
         */

        setupController: function setupController(controller) {

            controller.set('isAdmin', this.session.get('isAdmin'));
            controller.set('theme', window.constant.THEME);
        }
    });
});