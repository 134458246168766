define('comunidades-nafin/components/news-cards', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        didInsertElement: function didInsertElement() {
            var self = this._super.apply(this, arguments);

            var url_page = window.location.pathname;
            var comunidad_id = url_page.substring(url_page.lastIndexOf('/') + 1);
            var params = new URLSearchParams(window.location.search);
            var noticias_k = params.get('noticia_k');
            console.log(noticias_k);
            //data: 'params={"noticia_k":"106"}',
            //data: 'params={"comunidad_k":"' + comunidad_id +'"}',

            if (isNaN(parseInt(comunidad_id))) {
                Ember.$.ajax({
                    url: constant.APIURL + 'noticia/listarMisNoticias',
                    type: 'POST',
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    xhrFields: {
                        withCredentials: true
                    },
                    success: function success(r) {
                        var respons = JSON.parse(r);
                        if (respons.numFilas == 0) {
                            self.withoutNews();
                        }
                        //self.setNews(respons.data);
                        respons.data.forEach(function (item, idx) {
                            respons.data[idx].imagen = solveImageURL(respons.data[idx].imagen_crop);
                        });
                        //console.log(respons.data);
                        self.formatter(respons.data, self);
                    },
                    error: function error(e) {
                        console.log(e);
                    }
                });
            } else {
                Ember.$.ajax({
                    url: constant.APIURL + 'noticia/listarMisNoticias',
                    type: 'POST',
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    xhrFields: {
                        withCredentials: true
                    },
                    data: 'params={"comunidad_k":"' + comunidad_id + '"}',
                    success: function success(r) {
                        var respons = JSON.parse(r);
                        if (respons.numFilas == 0) {
                            self.withoutNews();
                        }
                        //self.setNews(respons.data);
                        respons.data.forEach(function (item, idx) {
                            respons.data[idx].imagen = solveImageURL(respons.data[idx].imagen_crop);
                        });
                        //console.log(respons.data);
                        self.formatter(respons.data, self);
                    },
                    error: function error(e) {
                        console.log(e);
                    }
                });
            }
        },
        didRender: function didRender() {
            Ember.$('.carousel-item:first-child').addClass('active');
            Ember.$('.carousel').carousel({ interval: 5000 });
        },
        setNews: function setNews(r, self) {
            self.set('news-cards', r);
        },


        //Dar formato deseado a mes y fechas
        formatter: function formatter(data, self) {
            Object.keys(data).forEach(function (idx) {
                //var test = new Date(data);
                var date_time = data[idx].timestamp_creacion.split(" ");

                var date = date_time[0].split('-');
                var month = date[1];
                data[idx].weekDay = self.timeStamp(date_time[0]);
                data[idx].monthFormath = self.monthFormatter(month);
                data[idx].dayFormath = date[2];
                data[idx].yearFormath = date[0];
                //console.log(date[0]);
                //data[idx].formatt_hora = data[idx].hora.substr(0,5);
                //data[idx].formatt_hora_fin = data[idx].hora_fin.substr(0,5);
            });
            //console.log(data);
            self.setNews(data, self);
        },

        // Asignar el més en el que nos encontramos
        monthFormatter: function monthFormatter(month) {
            if (month === "01") {
                return "Enero";
            } else if (month === "02") {
                return "Febrero";
            } else if (month === "03") {
                return "Marzo";
            } else if (month === "04") {
                return "Abril";
            } else if (month === "05") {
                return "Mayo";
            } else if (month === "06") {
                return "Junio";
            } else if (month === "07") {
                return "Julio";
            } else if (month === "08") {
                return "Agosto";
            } else if (month === "09") {
                return "Septiembre";
            } else if (month === "10") {
                return "Octubre";
            } else if (month === "11") {
                return "Noviembre";
            } else {
                return "Diciembre";
            }
        },
        timeStamp: function timeStamp(date) {
            var array = date.split('-');
            var weeks = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];
            var newDate = array[1] + "/" + array[2] + "/" + array[0];
            var temp = new Date(newDate);
            return weeks[temp.getDay()];
        },

        withoutNews: function withoutNews() {
            var html = '';

            html += '<div class="carousel-item">';
            html += '<div class="card box_shado ">';
            html += '<div class="card-body">';
            html += '<div class="row">';
            html += '<div class="col-12 margin_ud_10px"><br><h2>Sin noticias asociadas</h2></div>';
            html += '</div>';
            html += '</div>';
            html += '</div>';
            html += '</div>';

            Ember.$('#caruselActive').html(html);
        },
        actions: {
            nextSlide: function nextSlide() {
                Ember.$('.carousel-inner.box_shado.news-cards').carousel('next');
            },
            prevSlide: function prevSlide() {
                Ember.$('.carousel-inner.box_shado.news-cards').carousel('prev');
            },
            trackingNoticias: function trackingNoticias(noticia_k) {
                /*console.log(noticia_k);
                var self = this._super(...arguments);
                $.ajax({
                    url: constant.APIURL + 'noticia/agregarTrackingNoticia',
                    type: 'POST',
                    contentType: 'application/x-www-form-urlencoded',
                    //data: 'params=%7B%22comunidad_k%22%3A-1%2C%22tipo%22%3A1%7D',
                    xhrFields: {
                        withCredentials: true
                    },
                    data: 'params={"noticia_k":"' + noticia_k + '"}',
                    success(success){
                        console.log(success);
                        //self.transitionTo('noticias');
                        document.location = "/noticias?noticia_k="+noticia_k;
                    },
                    error(e){
                        document.location = "/noticias?noticia_k=" + noticia_k;
                        console.error(e);
                    }
                    
                });*/
            }
        }

    });
});