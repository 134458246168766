define('comunidades-nafin/routes/inicio', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		beforeModel: function beforeModel() {
			var promise = new Ember.RSVP.Promise(function (resolve) {
				Ember.run.later(this, function () {
					resolve("ok");
				}, 0);
			});
			return promise;
		},

		actions: {
			goCourse: function goCourse() {
				document.location.href = '/cursos';
			},
			goNews: function goNews() {
				document.location.href = '/noticias';
			},
			goEvents: function goEvents() {
				document.location.href = '/eventos';
			},
			goCommunities: function goCommunities() {
				document.location.href = '/comunidades';
			}
		}
	});
});