define('comunidades-nafin/components/notifications-list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        /*
         * @var start_init Limite inferior en el paginado del listado de elementos.
         */

        start_int: 0,

        /**
         * validarNotificacionActiva - Evalua en sistema que el recurso hacia el cual esta dirigiendose la notificación este
         * disponible.
         *
         * Evalua en sistema que el recurso hacia el cual esta dirigiendose la notificación este disponible.
         *
         *
         * @author  [YRM] Maurilio Cruz Morales
         *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
         *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
         *              Tel. celular (55) 6702 6252
         *
         * @date    2018-10-04
         * @param   object params_object Objeto de la notificación a evaluar.
         * @return  object Objeto con las evalauciones sobre la valides de la notificación
         */

        validarNotificacionActiva: function validarNotificacionActiva(params_object) {

            var val = {};
            var _self = this;

            $.ajax({
                url: constant.APIURL + 'comunidad/validarNotificacionRecursoActivo',
                async: false,
                type: 'POST',
                scope: this,
                contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                data: 'params=' + JSON.stringify(params_object),
                xhrFields: { withCredentials: true },
                success: function success(r) {

                    var respuesta = JSON.parse(r);
                    val = respuesta;
                },

                error: function error(e) {

                    $('#button-show-more-notifications').removeAttr('disabled');
                    _self.showModalError();
                    console.log(e);
                }

            });

            return val;
        },

        /**
         * showModalError - Función utilitaria, Muestra un mensaje generico de error en pantalla.
         *
         * Función utilitaria, Muestra un mensaje generico de error en pantalla.
         *
         *
         * @author  [YRM] Maurilio Cruz Morales
         *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
         *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
         *              Tel. celular (55) 6702 6252
         *
         * @date    2018-10-04
         * @param   void
         * @return  void
         */

        showModalError: function showModalError() {

            showModal({ titulo: 'Error en sistema.', mensaje: 'Ups! acaba de suceder algo inesperado. Por favor intente nuevamente en otro momento.' });

            return;
        },

        /**
         * didReceiveAttrs - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
         *
         *
         * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
         *
         *
         * @author  [YRM] Maurilio Cruz Morales
         *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
         *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
         *              Tel. celular (55) 6702 6252
         *
         * @date    2018-10-04
         * @param   void
         * @return  RSVP.Promise
         */

        didReceiveAttrs: function didReceiveAttrs() {

            this._super.apply(this, arguments);
        },

        /**
         * didInsertElement - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
         *
         *
         * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
         *
         *
         * @author  [YRM] Maurilio Cruz Morales
         *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
         *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
         *              Tel. celular (55) 6702 6252
         *
         * @date    2018-10-04
         * @param   void
         * @return  void
         */

        didInsertElement: function didInsertElement() {

            $('#button-show-more-notifications').attr("disabled", "disabled");

            var start_int = 0;

            this.set("start_int", start_int);

            var _self = this;

            $.ajax({
                url: constant.APIURL + 'notificaciones/listarNotificacionesUsuarioHtml',
                type: 'POST',
                contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                data: 'start=' + start_int + '&limit=' + constant.PAGESIZE,
                xhrFields: {
                    withCredentials: true
                },
                success: function success(r) {

                    var data = JSON.parse(r);

                    if (data.data.length > 0) {

                        var notificaciones_array = data.data.map(function (_notificacion_object) {
                            _notificacion_object.is_visible = true;return _notificacion_object;
                        });

                        _self.set("notificaciones_store", notificaciones_array);

                        /*
                         *	Posterior a la ejecución, se incrementa el limite del para la próxima petición a servidor.
                         */

                        if (data.data.length > 0) {

                            var start_int = _self.get("start_int");
                            start_int = start_int + constant.PAGESIZE;
                            _self.set("start_int", start_int);
                        }

                        var configurations = _self.get('configurations');

                        Ember.run.schedule('afterRender', function () {
                            _self.send('renderImage');
                        });

                        $('#button-show-more-notifications').removeAttr('disabled');
                    }
                },

                error: function error(e) {
                    $('#button-show-more-notifications').removeAttr('disabled');
                    _self.showModalError();
                    console.log(e);
                }

            });
        },

        /**
         * actions - Objeto de definiciones de acciones (funciones) disponibles para notificaciones.
         *
         *
         * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
         *
         *
         * @author  [YRM] Maurilio Cruz Morales
         *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
         *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
         *              Tel. celular (55) 6702 6252
         *
         * @date    2018-10-04
         */

        actions: {

            /**
             * renderImage - Acción utilitaria. Garantiza que la URL de la imagenes, tenga una URL absoluta.
             *
             *
             * Acción utilitaria. Garantiza que la URL de la imagenes, tenga una URL absoluta.
             *
             *
             * @author  [YRM] Maurilio Cruz Morales
             *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
             *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
             *              Tel. celular (55) 6702 6252
             *
             * @date    2018-10-04
             * @param   void
             * @return  void
             */

            renderImage: function renderImage() {
                $(".list-group-item img").each(function () {
                    if ($(this).attr("src").indexOf(constant.BASERECURSOSURL) == -1) {
                        $(this).attr("src", constant.BASERECURSOSURL + $(this).attr("src"));
                        $(this).attr("width", "35px");
                        $(this).parent().removeAttr("style").addClass("col-2");
                        $(this).parent().next().addClass("col-10");
                    }
                });
                //$('#loading').fadeOut('slow');
            },

            /**
             * goNotification - Redirecciona la aplicación hacia el path correspondiente referenciado por el tipo de notificacion.
             *
             * Redirecciona la aplicación hacia el path correspondiente referenciado por el tipo de notificacion. Adicionalmente
             * evalua reglas de negocio sobre acciones previas al redireccionamiento.
             *
             *
             * @author  [YRM] Maurilio Cruz Morales
             *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
             *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
             *              Tel. celular (55) 6702 6252
             *
             * @date    2018-09-19
             * @param   void
             * @return  void
             */

            goNotification: function goNotification(notificacion_record) {

                $('#loading').fadeIn('slow');

                var _redirect_path = '';
                var params_object = JSON.parse(notificacion_record.params);
                switch (notificacion_record.tipo_notificacion) {

                    case 'NOTIFICACIONES_CONVERSACIONES_MENSAJE_NUEVO':

                        /*
                         *	El Objeto notificacion_record, en su campo params contiene los siguiente campos.
                         *
                         * 	params_object.conversacion_k
                         *	params_object.token
                         *	params_object.usuario_k
                         *	params_object.
                         *
                         */

                        showModal({ titulo: 'Redireccionamiento no integrado.', mensaje: 'Esta funcionalidad se encuentra en desarrollo.' });

                        _redirect_path = '';

                        break;

                    case 'NOTIFICACIONES_NOTICIA_NUEVA':

                        var validacion = this.validarNotificacionActiva(params_object);

                        if (validacion) {

                            _redirect_path = '/noticias?noticia_k=' + params_object.noticia_k;
                        } else {

                            _redirect_path = '/recurso-no-disponible';
                        }

                        break;

                    case 'NOTIFICACIONES_FOROS_PREGUNTA_NUEVA':

                        var validacion = this.validarNotificacionActiva(params_object);

                        if (validacion) {

                            _redirect_path = '/foros/' + params_object.foro_k + '?pregunta_k=' + params_object.foro_pregunta_k;
                        } else {

                            _redirect_path = '/recurso-no-disponible';
                        }

                        break;

                    case 'NOTIFICACIONES_FOROS_RESPUESTA_NUEVA':

                        var validacion = this.validarNotificacionActiva(params_object);

                        if (validacion) {

                            _redirect_path = '/foros/' + params_object.foro_k + '?pregunta_k=' + params_object.foro_pregunta_k + 'respuesta_k=' + params_object.foro_respuesta_k;
                        } else {

                            _redirect_path = '/recurso-no-disponible';
                        }

                        break;

                    case "NOTIFICACIONES_COMUNIDAD_NUEVOS_USUARIOS":
                    case "NOTIFICACIONES_COMUNIDAD_NUEVOS_TUTORES":
                    case "NOTIFICACIONES_COMENTARIO_NUEVO":
                    case "NOTIFICACIONES_RESPUESTA_COMENTARIO":
                    case "NOTIFICACIONES_COMUNIDAD_NUEVA":

                        var validacion = this.validarNotificacionActiva(params_object);

                        if (validacion) {

                            if (validacion.inscrito == "1") {

                                _redirect_path = '/comunidades/' + params_object.comunidad_k;
                            } else {

                                this.set('notificacion_record', params_object);

                                /*
                                 *  El siguiente es un workaround. para asegurar que la ventana modal se encuentre a nivel del elemento body.
                                 */
                                var element = $('#modal_inscribir_comunidad-' + this.get('elementId')).detach();
                                $('body').append(element);

                                /*
                                 *  Mostramos la ventana modal.
                                 */
                                $('#modal_inscribir_comunidad-' + this.get('elementId')).modal('show');

                                _redirect_path = '';
                            }
                        } else {

                            _redirect_path = '/recurso-no-disponible';
                        }

                        break;

                    case 'NOTIFICACIONES_NUEVA_ASIGNACION_CONSULTA':
                    case 'NOTIFICACIONES_COMENTARIO_CONSULTA':
                    case 'NOTIFICACIONES_CIERRE_CONSULTA':
                    case 'NOTIFICACIONES_DESASIGNACION_CONSULTA':
                    case 'NOTIFICACIONES_EDICION_CONSULTA':
                    case 'NOTIFICACIONES_INICIAR_ATENCION_CONSULTA':
                    case 'NOTIFICACIONES_DETENER_ATENCION_CONSULTA':
                    case 'NOTIFICACIONES_RESOLVER_CONSULTA':
                    case 'NOTIFICACIONES_REABRIR_CONSULTA':

                        if (this.validarNotificacionActiva(params_object)) {

                            _redirect_path = '/consultas/' + params_object.consulta_k;
                        } else {

                            _redirect_path = '/recurso-no-disponible';
                        }

                        break;

                    case 'NOTIFICACIONES_COMUNIDAD_NUEVOS_ELEMENTOS_ASOCIADOS':

                        var validacion = this.validarNotificacionActiva(params_object);

                        if (validacion.success) {

                            _redirect_path = '/comunidades/' + params_object.ra_k;
                        } else {

                            _redirect_path = '/recurso-no-disponible';
                        }

                        break;

                    case 'NOTIFICACIONES_EVENTOS':

                        _redirect_path = '/eventos?evento_k=' + params_object.calendario_k;
                        break;

                    case 'NOTIFICACIONES_ELIMINACION_ELEMENTO_ASOCIADO':
                        _redirect_path = '/recurso-no-disponible';
                        break;

                    case 'NOTIFICACIONES_ELIMINACION_TUTORES':
                        _redirect_path = '/recurso-no-disponible';
                        break;

                    default:
                        _redirect_path = '/recurso-no-disponible';
                        break;

                }

                if (_redirect_path != '') {

                    document.location.href = _redirect_path;
                }

                $('#loading').fadeOut('slow');
            },

            /**
             * deleteNotification - Elimina una notificación.
             *
             *
             * Elimina una notificación. Realiza la eliminacion a nivel visual y solicita la eliminación de la notificacion
             * hacia la plataforma SHT. En caso de ocurrir un error en la notificación, visualmente genera un roolback,
             * mostrandolo nuevamente en pantalla.
             *
             *
             * @author  [YRM] Maurilio Cruz Morales
             *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
             *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
             *              Tel. celular (55) 6702 6252
             *
             * @date    2018-09-19
             * @param   object notificacion_record Objeto de la notificación a eliminar.
             * @return  void
             */

            deleteNotification: function deleteNotification(notificacion_record) {

                Ember.set(notificacion_record, "is_visible", false);

                var _self = this;

                $.ajax({
                    url: constant.APIURL + 'notificaciones/eliminarNotificacionUsuario',
                    type: 'POST',
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    data: 'i=' + notificacion_record.notificacion_usuario_k,
                    xhrFields: {
                        withCredentials: true
                    },
                    error: function error(e) {

                        Ember.set(notificacion_record, "is_visible", true);
                        _self.showModalError();
                        console.log(e);
                    }

                });
            },

            /**
             * inscribirUsuarioEnRA - Inscribe al usuario de la sesión activa a la Ruta de Aprendizaje seleccionada.
             *
             *
             * Inscribe al usuario de la sesión activa a la Ruta de Aprendizaje seleccionada.
             *
             *
             * @author  [YRM] Maurilio Cruz Morales
             *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
             *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
             *              Tel. celular (55) 6702 6252
             *
             * @date    2018-09-19
             * @param   object notificacion Objeto de la notificación. Contiene inforrmación de la Ruta de Aprendizaje a la
             *          que se requiere inscribir al usuario.
             * @return  void
             */

            inscribirUsuarioEnRA: function inscribirUsuarioEnRA(notificacion_record) {

                $('#modal_inscribir_comunidad-' + this.get('elementId')).modal('hide');
                $('#loading').fadeIn('slow');

                var _self = this;

                $.ajax({
                    url: constant.APIURL + 'comunidad/inscribirUsuarioAComunidad',
                    type: 'POST',
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    data: 'comunidad_k=' + notificacion_record.comunidad_k,
                    xhrFields: {
                        withCredentials: true
                    },
                    success: function success(r) {

                        //$('#loading').fadeOut('slow');
                        var _redirect = 'comunidades/' + notificacion_record.comunidad_k;
                        document.location.href = _redirect;
                    },

                    error: function error(e) {

                        $('#button-show-more-notifications').removeAttr('disabled');
                        //$('#loading').fadeOut('slow');
                        _self.showModalError();
                        console.log(e);
                    }

                });
            },

            /**
             * showMoreNotifications - Carga mas notificaciones provenientes de la plataforma SHT.
             *
             *
             * Carga mas notificaciones provenientes de la plataforma SHT.
             *
             *
             * @author  [YRM] Maurilio Cruz Morales
             *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
             *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
             *              Tel. celular (55) 6702 6252
             *
             * @date    2018-09-19
             * @param   void
             * @return  void
             */

            showMoreNotifications: function showMoreNotifications() {

                $('#loading').fadeIn('slow');
                $('#button-show-more-notifications').attr("disabled", "disabled");

                var start_int = this.get("start_int");
                var _self = this;

                $.ajax({
                    url: constant.APIURL + 'notificaciones/listarNotificacionesUsuarioHtml',
                    type: 'POST',
                    scope: this,
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    data: 'start=' + start_int + '&limit=' + constant.PAGESIZE,
                    xhrFields: {
                        withCredentials: true
                    },

                    success: function success(r) {

                        var data = JSON.parse(r);

                        if (data.data.length > 0) {

                            var noticias_previas = _self.get("notificaciones_store");

                            data.data.forEach(function (_data) {

                                _data.is_visible = true;
                                noticias_previas.pushObject(_data);
                            });

                            _self.set("notificaciones_store", noticias_previas);

                            /*
                             *	Posterior a la ejecución, se incrementa el limite del para la próxima petición a servidor.
                             */

                            if (data.data.length > 0) {

                                var start_int = _self.get("start_int");
                                start_int = start_int + constant.PAGESIZE;
                                _self.set("start_int", start_int);

                                $('#button-show-more-notifications').removeAttr('disabled');
                            }

                            Ember.run.schedule('afterRender', function () {
                                _self.send('renderImage');
                            });
                        } else {

                            $('#button-show-more-notifications').text('Ups! No hay mas notficaciones que mostrar.');
                        }
                        $('#loading').fadeOut('slow');
                    },

                    error: function error(e) {

                        $('#button-show-more-notifications').removeAttr('disabled');
                        $('#loading').fadeOut('slow');

                        _self.showModalError();

                        console.log(e);
                    }

                });
            }

        }

    });
});