define('comunidades-nafin/components/examples-components/react-imported-example/demo-modal', ['exports', 'react', 'react-aria-modal'], function (exports, _react, _reactAriaModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var DemoModal = function (_React$Component) {
    _inherits(DemoModal, _React$Component);

    function DemoModal() {
      var _ref;

      var _temp, _this, _ret;

      _classCallCheck(this, DemoModal);

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = DemoModal.__proto__ || Object.getPrototypeOf(DemoModal)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
        modalActive: false
      }, _this.activateModal = function () {
        _this.setState({ modalActive: true });
      }, _this.deactivateModal = function () {
        _this.setState({ modalActive: false });
      }, _this.getApplicationNode = function () {
        return document.getElementById('ember-application');
      }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(DemoModal, [{
      key: 'render',
      value: function render() {
        var _this2 = this;

        var modal = this.state.modalActive ? _react.default.createElement(
          _reactAriaModal.default,
          {
            titleText: this.props.title,
            onExit: this.deactivateModal,
            initialFocus: '#demo-one-deactivate',
            getApplicationNode: this.getApplicationNode,
            underlayStyle: { paddingTop: '2em' }
          },
          _react.default.createElement(
            'div',
            { className: '', role: 'dialog' },
            _react.default.createElement(
              'div',
              { className: 'modal-dialog', role: 'document' },
              _react.default.createElement(
                'div',
                { className: 'modal-content' },
                _react.default.createElement(
                  'div',
                  { className: 'modal-header' },
                  _react.default.createElement(
                    'h2',
                    { id: 'demo-two-title', className: 'modal-title' },
                    this.props.title
                  ),
                  _react.default.createElement(
                    'button',
                    { type: 'button', className: 'close', 'data-dismiss': 'modal', 'aria-label': 'Close', onClick: this.deactivateModal },
                    _react.default.createElement(
                      'span',
                      { 'aria-hidden': 'true' },
                      '\xD7'
                    )
                  )
                ),
                _react.default.createElement(
                  'div',
                  { className: 'modal-body' },
                  _react.default.createElement(
                    'p',
                    null,
                    'Here is a modal',
                    ' ',
                    _react.default.createElement(
                      'a',
                      { href: '#' },
                      'with'
                    ),
                    ' ',
                    _react.default.createElement(
                      'a',
                      { href: '#' },
                      'some'
                    ),
                    ' ',
                    _react.default.createElement(
                      'a',
                      { href: '#' },
                      'focusable'
                    ),
                    ' ',
                    'parts.'
                  ),
                  _react.default.createElement('input', { onChange: function onChange(e) {
                      return _this2.props.onTextChange(e.target.value);
                    }, value: this.props.title })
                ),
                _react.default.createElement(
                  'div',
                  { className: 'modal-footer' },
                  _react.default.createElement(
                    'button',
                    { type: 'button', className: 'btn btn-secondary', 'data-dismiss': 'modal', id: 'demo-one-deactivate', onClick: this.deactivateModal },
                    'Close'
                  )
                )
              )
            )
          )
        ) : false;

        return _react.default.createElement(
          'div',
          null,
          _react.default.createElement(
            'button',
            { onClick: this.activateModal },
            'activate modal'
          ),
          modal
        );
      }
    }]);

    return DemoModal;
  }(_react.default.Component);

  exports.default = DemoModal;
});