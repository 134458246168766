define('comunidades-nafin/routes/foros', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model(params) {
			return new Ember.RSVP.Promise(function (resolve) {
				Ember.run.later(function () {
					resolve({ params: params });
				}, 500);
			});
		},
		setupController: function setupController(controller, model) {
			console.log('setupController', model);

			if (model.params.foroid) {
				console.log('Request especifico');
				controller.set('tipo', 'especifico');
			} else {
				console.log('Request generico');
				controller.set('tipo', 'generico');
			}
		},
		renderTemplate: function renderTemplate(controller, model) {
			if (model.params.foroid) {
				this.render('foros/foro-especifico', {
					//into: 'application',
					//outlet: '',
					//controller: ''
					//model: ''
				});
			} else {
				this.render('foros');
			}
		}
	});
});