define('comunidades-nafin/routes/recurso-no-disponible', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({

		setupController: function setupController(controller) {

			controller.set('url_imagen', solveImageURL('img/imageConstruc/working.png'));
		},

		actions: {
			goNotifications: function goNotifications() {
				this.transitionTo('notificaciones');
			}
		}

	});
});