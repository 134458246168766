define('comunidades-nafin/routes/cambiar-contrasenia', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		beforeModel: function beforeModel() {
			var promise = new Ember.RSVP.Promise(function (resolve) {
				Ember.run.later(this, function () {
					resolve("ok");
				}, 1000);
			});
			return promise;
		},
		actions: {
			updatePW: function updatePW() {
				Ember.$.ajax({
					url: constant.APIURL + 'usuario/datospersonales/',
					type: 'POST',
					contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
					xhrFields: {
						withCredentials: true
					},
					success: function success(r) {
						var respons = JSON.parse(r);
						var opw = md5(Ember.$('#current-password').val());
						var npw = Ember.$('#new-password').val();
						var npwr = Ember.$('#new-password-retype').val();
						if (respons.password == opw && npw == npwr && md5(npw) != opw) {

							Ember.$.ajax({
								url: constant.APIURL + 'comunidad/getdatosperfil',
								type: 'GET',
								contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
								xhrFields: {
									withCredentials: true
								},
								success: function success(r) {
									var responsUsuario = JSON.parse(r);
									var i = responsUsuario.data[0].usuario_k;
									//var txtNomina = responsUsuario.data[0].nomina;
									var username = responsUsuario.data[0].username;
									var txt_nombre = responsUsuario.data[0].nombre;
									var txt_apaterno = responsUsuario.data[0].apellido_paterno;
									var txt_amaterno = responsUsuario.data[0].apellido_materno;
									//var cb_puesto = responsUsuario.data[0].puesto;
									//var cb_departamento = responsUsuario.data[0].departamento_k;
									//var cb_oficina;
									var cb_rol = responsUsuario.data[0].rol_k;
									var txt_email = respons.email;

									console.log(responsUsuario.data);
									Ember.$.ajax({
										url: constant.APIURL + 'usuario/editar',
										type: 'POST',
										contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
										// data: 'i=' + i + '&txt_nomina=' + txtNomina + '&username=' + username + '&txt_nombre=' + txt_nombre + '&txt_apaterno=' + txt_apaterno +
										// 	'&txt_amaterno=' + txt_amaterno + '&cb_puesto=' + cb_puesto + '&cb_departamento=' + cb_departamento +
										// 	'&cb_oficina=' + cb_oficina + '&cb_rol=' + cb_rol + '&txt_email=' + txt_email + '&txt_pass=' + npw,
										data: 'i=' + i + '&username=' + username + '&txt_nombre=' + txt_nombre + '&txt_apaterno=' + txt_apaterno + '&txt_amaterno=' + txt_amaterno + '&cb_rol=' + cb_rol + '&txt_email=' + txt_email + '&txt_pass=' + npw,
										xhrFields: {
											withCredentials: true
										},
										success: function success(r) {

											Ember.$('#alertSend').html('<div class="alert alert-success alert-dismissible fade show" role="alert">\
                                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">\
                                                        <span aria-hidden="true">&times;</span>\
                                                    </button>\
                                                    <strong>Cambio de contraseña exitoso.</strong>\
                                                </div>');
											opw = Ember.$('#current-password').val('');
											npw = Ember.$('#new-password').val('');
											npwr = Ember.$('#new-password-retype').val('');
										},
										error: function error(e) {
											console.log(e);
										}
									});
								},
								error: function error(e) {
									console.log(e);
								}
							});
						} else {

							var mensaje = '';
							//respons.password == opw && npw == npwr
							if (!respons.password || !opw || !npwr) {
								mensaje = 'Todos los valores son requeridos';
							} else if (respons.password != opw) {
								mensaje = 'Por favor introduzca correctamente su contraseña actual';
								Ember.$("#current-password").val("").focus();
							} else if (npw != npwr) {
								mensaje = 'La confirmacion de nueva contraseña no coincide';
								Ember.$("#new-password-retype").val("").focus();
							} else if (opw == md5(npw)) {
								mensaje = 'La nueva contraseña es identica a su contraseña actual';
							} else {
								mensaje = 'Se ha presentado un error, por favor verifique la información proporcionada';
							}
							;
							Ember.$('#alertSend').html('<div class="alert alert-danger alert-dismissible fade show" role="alert">\
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">\
                                    <span aria-hidden="true">&times;</span>\
                                </button>\
                                <strong>' + mensaje + '</strong>\
                            </div>');
						}
					},
					error: function error(e) {
						console.log(e);
					}
				});
			}
		}

	});
});