define('comunidades-nafin/controllers/foros/foro-especifico', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			goBack: function goBack() {
				history.back();
			}
		}
	});
});