define('comunidades-nafin/initializers/socket', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;


  var SocketObject = Ember.Object.extend({ socket: {} });

  function initialize(application) {
    // application.inject('route', 'foo', 'service:foo');

    application.register('socket:main', SocketObject);
    application.inject('controller', 'socket', 'socket:main');
    application.inject('component', 'socket', 'socket:main');
  }

  exports.default = {
    initialize: initialize
  };
});