define('comunidades-nafin/helpers/searchable-select-get', ['exports', 'ember-searchable-select/helpers/searchable-select-get'], function (exports, _searchableSelectGet) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _searchableSelectGet.default;
    }
  });
  Object.defineProperty(exports, 'searchableSelectGet', {
    enumerable: true,
    get: function () {
      return _searchableSelectGet.searchableSelectGet;
    }
  });
});