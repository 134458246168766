define('comunidades-nafin/routes/foros/foro-especifico', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        beforeModel: function beforeModel() {
            var promise = new Ember.RSVP.Promise(function (resolve) {
                Ember.run.later(this, function () {
                    resolve("ok");
                }, 1000);
            });
            return promise;
        },

        arrayColor: ['000', '9C27B0', 'b0272b', 'ff9800', '009688', 'ff5722'],
        setupController: function setupController(controller) {
            var self = this;

            var endpoint = 'comunidad/listarMisComunidades';
            if (this.session.isAdmin) {
                endpoint = 'comunidad/listar';
            }
            Ember.$.ajax({
                url: constant.APIURL + endpoint,
                type: 'POST',
                xhrFields: {
                    withCredentials: true
                },
                contentType: 'application/x-www-form-urlencoded',
                success: function success(response) {
                    var data = JSON.parse(response).data;
                    controller.set('comunidades', data);
                    self.agregar_n_preguntas();
                    Ember.$('#loading').fadeOut('slow');
                },
                error: function error(e) {
                    console.log(e);
                }
            });
        },
        agregar_n_preguntas: function agregar_n_preguntas() {},

        actions: {
            goBackForum: function goBackForum() {
                history.back();
            },
            recent_foro: function recent_foro() {
                document.location.href = '/foros';
                /*
                $('#loading').fadeIn('slow');
                this._super(...arguments);
                let self = this;
                let endpoint = 'comunidad/listarMisComunidades';
                if(self.session.isAdmin){
                endpoint = 'comunidad/listar';
                }
                var recents = '{"data":[';
                $.ajax({
                url: constant.APIURL + endpoint,
                    type: 'POST',
                    async:false,
                xhrFields: {
                withCredentials: true
                },
                contentType: 'application/x-www-form-urlencoded',
                success(response) {
                var r = JSON.parse(response);
                //self.set('comunidades',data);
                Object.keys(r.data).forEach((idx) =>{
                $.ajax({
                url: constant.APIURL + 'foro/listarPreguntasRecientes',
                                type: 'POST',
                                async:false,
                xhrFields: {
                withCredentials: true
                },
                contentType: 'application/x-www-form-urlencoded',
                data: 'params={"comunidad_k":"'+r.data[idx].comunidad_k+'"}',
                success(response) {
                var r_p = JSON.parse(response);
                if (r_p.data.length > 0) {
                var date_time = r_p.data[0].timestamp_creacion.split(' ');
                var date = date_time[0].split('-');
                if (r_p.data[0].pregunta == "undefined") {
                r_p.data[0].pregunta = 'No hay pregunta asociada';
                }
                r_p.data[0].dayFormath = date[2];
                r_p.data[0].monthFormath = date[1];
                r_p.data[0].yearFormath = date[0];
                var secondColor = Math.floor((Math.random() * 6));
                if (r_p.data[0] != 'foto') {
                var nombre = r_p.data[0].full_name.split('');
                var img = nombre[0].toUpperCase();
                r_p.data[0].foto = "https://dummyimage.com/600x400/" + self.arrayColor[secondColor] + "/fff.jpg&text=" + img;
                }
                recents += JSON.stringify(r_p.data[0])+',';
                						}
                				}, 
                error(e) {
                console.log(e);
                }
                });
                });
                					
                ///self.controller.set('foro_reciente', recents);
                },
                error(e) {
                console.log(e);
                }
                })
                let recents_foros = JSON.parse(recents.slice(0,-1)+']}');
                console.log(recents_foros.data);
                self.controller.set('foro_reciente', recents_foros.data)
                $('#loading').fadeOut('slow');
                */
            },
            change_foro: function change_foro(comunidad_k, foro_k) {
                Ember.$('#foro_recientes').fadeIn('slow').removeAttr('hidden');
                Ember.$('#foro_muro').fadeOut('slow');
                this._super.apply(this, arguments);
                var self = this;
                Ember.$.ajax({
                    url: constant.APIURL + 'foro/listarPreguntasRecientes',
                    type: 'POST',
                    xhrFields: {
                        withCredentials: true
                    },
                    contentType: 'application/x-www-form-urlencoded',
                    data: 'params={"comunidad_k":"' + comunidad_k + '"}',
                    success: function success(r) {
                        r = JSON.parse(r);
                        Object.keys(r.data).forEach(function (idx) {
                            var date_time = r.data[idx].timestamp_creacion.split(' ');
                            var date = date_time[0].split('-');
                            var month = date[1];
                            r.data[idx].dayFormath = date[2];
                            r.data[idx].yearFormath = date[0];
                            var secondColor = Math.floor(Math.random() * 6);
                            if (r.data[idx] != 'foto') {
                                var nombre = r.data[idx].full_name.split('');
                                var img = nombre[0].toUpperCase();
                                r.data[idx].foto = "https://dummyimage.com/600x400/" + self.arrayColor[secondColor] + "/fff.jpg&text=" + img;
                            }
                        });
                        self.controller.set('foro_reciente', r.data);
                    },
                    error: function error(e) {
                        console.log(e);
                    }
                });
            }
        }
    });
});