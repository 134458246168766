define('comunidades-nafin/components/admin-components/administracion-cursos-descripcion-curso', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        /*
         * didReceiveAttrs - Runs after init, and it also runs on subsequent re-renders, which is useful for logic that is the same on all renders.
         *
         *
         * Se sobe-escribe el metodo didReceiveAttrs, para implementa recepción y guardado de propiedades enviadas al instanciar el compomnente. y 
         * ejecutar metodos de cargado de Store.
         *
         *
         * @author  [YRM] Maurilio Cruz Morales
         *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
         *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
         *              Tel. celular (55) 6702 6252
         *
         * @date    2018-10-04
         * @param   void
         * @return  void
         */

        didReceiveAttrs: function didReceiveAttrs() {

            this._super.apply(this, arguments);
            this.set('storeCurso', this.storeCurso);
        }

    });
});