define('comunidades-nafin/components/component-chat', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        usuario_k: 0,
        last_mensaje_k: 0,
        data_conversation: {},
        global_token_chat: '',
        chatsocket: {},
        user_reciver: 0,
        lastdata: null,
        token_general: 0,
        iniciar_socket: function iniciar_socket() {

            var data_conversation = this.get('data_conversation');
            this.socket.socket.nuevo = false;

            if (data_conversation.token) {
                this.socket.socket.emit('switchRoom', data_conversation.token);
            }

            this.socket.socket.on('updateLeido', function (data) {});

            var self = this;

            this.socket.socket.on('updateChat', function (data) {
                var html = '';
                data = JSON.parse(data);
                var usuario_k = self.session.userData.usuario_k;

                Ember.$('#chatInputBox').val('');

                if (usuario_k == data.usuario_emisor_k) {

                    html = '<div class="card text-white bg-primary mb-3 dialog_user_chat">';
                    html += '<div class="card-body">';
                    html += '<p class="card-text">';
                    html += data.mensaje;
                    html += '</p>';
                    html += '</div></div>';
                } else {

                    html = '<div class="card bg-light mb-3 dialog_chat">';
                    html += '<div class="card-body">';
                    html += '<p class="card-text">';
                    html += data.mensaje;
                    html += '</p>';
                    html += '</div></div>';
                }

                Ember.$('#scrollChat').append(html);

                if (!self.get('outChat')) {

                    var element = document.getElementById('scrollChat');
                    element.scrollTop = element.scrollHeight - element.clientHeight;
                } else {

                    self.send('seeConvertation', data);

                    setTimeout(function () {
                        var element = document.getElementById('scrollChat');
                        element.scrollTop = element.scrollHeight - element.clientHeight;
                    }, 1000);
                }
            });

            this.socket.socket.on('updateChatTbar', function (data) {
                console.log(JSON.parse(data));
            });

            this.socket.socket.on('updateRecibido', function (data) {

                try {
                    self.set('token', data.token_conversacion);
                    if (Ember.$('#chat_box_component').is(":hidden")) {
                        Ember.$('#notificacionChat').show();

                        var user = data.nombre;
                        Ember.$('.card.bg_light_notification.margin_ud_10px:contains(' + user + ')').css("background-color", window.constant.COLOR);
                    } else {
                        var user = data.nombre;
                        Ember.$('.card.bg_light_notification.margin_ud_10px:contains(' + user + ')').css("background-color", window.constant.COLOR);
                    }
                } catch (e) {
                    console.log(e);
                }
            });
        },

        init: function init() {
            this._super.apply(this, arguments);
            var self = this;
        },
        didInsertElement: function didInsertElement() {

            this._super.apply(this, arguments);

            var self = this;
            this.set('outChat', true);

            if (Ember.$(window).width() > 991) {
                self.set('isDesktop', true);
                self.set('sizeMovile', false);
            } else {
                self.set('isDesktop', false);
                self.set('sizeMovile', true);
            }

            Ember.$.ajax({
                url: constant.APIURL + 'conversaciones/listarConversaciones',
                type: 'GET',
                contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                xhrFields: {
                    withCredentials: true
                },
                success: function success(response) {

                    var r = JSON.parse(response);
                    var data = r.data;

                    Object.keys(data).forEach(function (idx) {

                        if (data[idx].foto.indexOf('http') !== 0) {
                            data[idx].foto = constant.BASEURL + data[idx].foto;
                        }
                    });

                    self.set('conversaciones', data);
                    self.set('data_conversation', {});

                    if (self.socket.socket.nuevo) {
                        self.iniciar_socket();
                    }
                },
                error: function error(e) {
                    console.error(e);
                }
            });
        },


        actions: {
            seeConvertation: function seeConvertation(user) {
                var self = this;
                this.user = user;

                if (this.socket.socket.chat_room != user.token) {

                    this.socket.socket.chat_room = user.token;
                    this.socket.socket.emit('switchRoom', user.token);
                }

                event.stopPropagation(); // don't close the drop down when someone is on click
                this.set('outChat', false);
                this.set('usuario_k', user.usuario_k);
                this.set('data_conversation', user);
                Ember.$('#chatInputBox').focus();

                Ember.$.ajax({
                    url: constant.APIURL + 'conversaciones/agregarConversacionUsuario',
                    type: 'POST',
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    xhrFields: {
                        withCredentials: true
                    },
                    async: false,
                    data: 'usuario_k=' + self.session.userData.usuario_k,
                    success: function success(response) {

                        var data = JSON.parse(response).data[0];
                        var token = data.token;
                        self.set('token', token);

                        Ember.$.ajax({
                            url: constant.APIURL + 'conversaciones/listarConversacionesMensajes',
                            type: 'POST',
                            async: false,
                            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                            xhrFields: {
                                withCredentials: true
                            },
                            data: 'conversacion_k=' + user.conversacion_k,
                            success: function success(r) {
                                var response = JSON.parse(r).data.reverse();
                                Object.keys(response).forEach(function (idx) {
                                    //Is my comento only ...
                                    if (response[idx].usuario_emisor_k == self.get('usuario_k')) {
                                        response[idx].myComment = true;
                                        self.set('user_reciver', response[idx].usuario_receptor_k);
                                    } else {
                                        response[idx].myComment = false;
                                    }
                                    self.set('last_mensaje_k', response[idx].mensaje_k);
                                });
                                self.set('conversation', response);
                                Ember.run.schedule('afterRender', function () {
                                    var userfoto = user.foto;
                                    if (user.foto.indexOf('http') != 0) {
                                        userfoto = constant.BASEURL + user.foto;
                                    }
                                    Ember.$('#chatPictureUser').attr('src', String(userfoto));
                                    Ember.$('#chatUserName').html(user.destinatario);
                                    var element = document.getElementById('scrollChat');
                                    element.scrollTop = element.scrollHeight - element.clientHeight;
                                });
                            },
                            error: function error(e) {
                                console.error(e);
                            }
                        });
                    },
                    error: function error(e) {
                        console.error('NT: ', e);
                    }
                });
            },
            sendComment: function sendComment() {

                var self = this;
                var msg = Ember.$('#chatInputBox').val();

                if (msg === '') {
                    return;
                }

                event.stopPropagation();
                var conversation = this.get('data_conversation');
                var sendChat = {
                    'tipoInterfaz': "grouptabs",
                    'generoMujer': 1,
                    'conversacion_k': conversation.conversacion_k,
                    'usuario_emisor_k': self.get('usuario_k'),
                    'usuario_creacion': self.get('usuario_k'),
                    'usuario_receptor_k': self.get('user_reciver'),
                    'mensaje': msg,
                    'token': self.get('token'),
                    'estatus': 1,
                    'token_conversacion': self.get('data_conversation').token,
                    'foto': self.session.userData.foto,
                    "nombre": self.session.userData.nombre,
                    "mensaje_k": self.last_mensaje_k += 1
                };

                var sendRecibido = {
                    'tipoInterfaz': "grouptabs",
                    'generoMujer': 1,
                    'conversacion_k': '' + conversation.conversacion_k + '',
                    'usuario_emisor_k': self.get('usuario_k'),
                    'usuario_creacion': self.get('usuario_k'),
                    'usuario_receptor_k': self.get('user_reciver'),
                    'mensaje': msg,
                    'token': self.get('token'),
                    'estatus': 1,
                    'token_conversacion': self.get('data_conversation').token,
                    'foto': self.session.userData.foto,
                    "nombre": self.session.userData.nombre,
                    "mensaje_k": parseInt(self.last_mensaje_k += 1)
                };

                var user = this.user;

                if (event.keyCode === 13) {

                    this.socket.socket.emit('sendChat', JSON.stringify(sendChat));
                    this.socket.socket.emit("sendRecibido", sendRecibido);

                    return;
                }
                //Click buttom
                this.socket.socket.emit('sendChat', JSON.stringify(sendChat));
                this.socket.socket.emit("sendRecibido", sendRecibido);
            },


            /**
             *
             */

            enterKeyPress: function enterKeyPress() {
                if (event.keyCode === 13) {
                    this.send('sendComment');
                }
            },


            /**
             *
             */

            exitConvertation: function exitConvertation() {
                event.stopPropagation();
                this.set('outChat', true);
            },


            /**
             *
             */

            search: function search(e) {

                var keyWord = Ember.$(e.target).val();

                if (keyWord.length > 3) {

                    Ember.$('.card.bg_light_notification.margin_ud_10px').hide();
                    Ember.$('.card.bg_light_notification.margin_ud_10px:contains(' + keyWord + ')').show();
                } else {

                    Ember.$('.card.bg_light_notification.margin_ud_10px').show();
                }
            },


            /**
             *
             */

            close: function close() {
                Ember.$('#chat_box_component').hide();
            }
        }
    });
});