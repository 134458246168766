define('comunidades-nafin/components/communities-cards', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        verMasComunidadesAutoinscribibles: true,
        didInsertElement: function didInsertElement() {

            var self = this._super.apply(this, arguments);
            sessionStorage.setItem('seccion-anterior', Ember.$(".titulo-seccion").text());

            Ember.$.ajax({
                url: constant.APIURL + 'comunidad/listarMisComunidades',
                type: 'GET',
                contentType: 'application/x-www-form-urlencoded',
                data: '',
                xhrFields: {
                    withCredentials: true
                },
                success: function success(r) {
                    var respons = JSON.parse(r);
                    Object.keys(respons.data).forEach(function (idx) {
                        if (respons.data[idx].img.indexOf('http') != -1) {
                            respons.data[idx].img = respons.data[idx].img;
                        } else {
                            respons.data[idx].img = constant.BASEURL + respons.data[idx].img;
                        }
                        if (respons.data[idx].descripcion.split(' ').length > 22) {
                            respons.data[idx].descripcion = respons.data[idx].descripcion.split(' ').slice(0, 22).join(' ') + '...';
                        }
                    });

                    var firstCourses = [];
                    for (var i = 0; i < 4; i++) {
                        if (respons.data[i]) {
                            firstCourses.push(respons.data[i]);
                        }
                    }
                    self.setComunidades(firstCourses);
                    Ember.$('#seeMoreCommunities').click(function () {
                        self.setComunidades(respons.data);
                        Ember.$('#seeMoreCommunities').remove();
                    });

                    setTimeout(function () {
                        Ember.$('#loading').fadeOut('slow');
                        lazyLoad.update();
                    }, 3000);

                    Ember.$.ajax({
                        url: constant.APIURL + 'usuario/datospersonales/',
                        type: 'POST',
                        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                        xhrFields: { withCredentials: true },
                        success: function success(r) {
                            var data = JSON.parse(r);
                            /** Redes sociales Comunidad **/
                            self.set("comunidades_facebook", true);
                            if (data.configuracion.general_compatir_comunidad_en_facebook_habilitar == undefined || data.configuracion.general_compatir_comunidad_en_facebook_habilitar == false) {
                                self.set("comunidades_facebook", false);
                            }
                            self.set("comunidades_twitter", true);
                            if (data.configuracion.general_compatir_comunidad_en_twitter_habilitar == undefined || data.configuracion.general_compatir_comunidad_en_twitter_habilitar == false) {
                                self.set("comunidades_twitter", false);
                            }
                            self.set("comunidades_linkedin", true);
                            if (data.configuracion.general_compatir_comunidad_en_linkedin_habilitar == undefined || data.configuracion.general_compatir_comunidad_en_linkedin_habilitar == false) {
                                self.set("comunidades_linkedin", false);
                            }
                        }
                    });
                },
                error: function error(e) {
                    console.log(e);
                }
            });

            /* $.ajax({
                 url:constant.APIURL+'comunidad/listarOtrasComunidades',
                 type:'GET',
                 contentType:'application/x-www-form-urlencoded',
                 data:'',
                 xhrFields: {
                   withCredentials: true
                 },
                 success(r){
                     var respons = JSON.parse(r);
                     Object.keys(respons.data).forEach(function(idx){
                         if (respons.data[idx].img.indexOf('http')!=-1) {
                             respons.data[idx].img = respons.data[idx].img;
                          }else{
                             respons.data[idx].img = constant.BASEURL + respons.data[idx].img;
                         }
                     });
                                   
                     var firstCourses = [];
                     for (var i = 0; i < 2; i++) {
                         if(respons.data[i]){
                             firstCourses.push(respons.data[i]);
                         }
                     }    
                     //if(firstCourses.length <= 2){
                       //  $('#seeMoreOtherCommunities').remove();
                     //}
                    
                     $('#seeMoreOtherCommunities').click(function() {
                         alert("OK");
                         self.setOtherComunidades(respons.data);
                         $('#seeMoreOtherCommunities').remove();
                     });  
                      self.setOtherComunidades(firstCourses);               
                 },
                 error(e){
                     console.log(e);
                 }
             });  */

            self.send("getComunidadesAuto", false);
        },
        didRender: function didRender() {
            var self = this._super.apply(this, arguments);
        },
        setComunidades: function setComunidades(r) {
            this.set('comunidades-cards', r);
        },
        setOtherComunidades: function setOtherComunidades(r) {
            this.set('other-comunidades-cards', r);
        },

        actions: {
            getComunidadesAuto: function getComunidadesAuto(all) {

                var self = this;
                Ember.$.ajax({
                    url: constant.APIURL + 'comunidad/listarOtrasComunidades',
                    type: 'GET',
                    contentType: 'application/x-www-form-urlencoded',
                    data: '',
                    xhrFields: {
                        withCredentials: true
                    },
                    success: function success(r) {
                        var respons = JSON.parse(r);
                        Object.keys(respons.data).forEach(function (idx) {
                            if (respons.data[idx].img.indexOf('http') != -1) {
                                respons.data[idx].img = respons.data[idx].img;
                            } else {
                                respons.data[idx].img = constant.BASEURL + respons.data[idx].img;
                            }
                        });

                        var firstCourses = [];
                        var to = all ? respons.data.length : 2;
                        for (var i = 0; i < to; i++) {
                            if (respons.data[i]) {
                                firstCourses.push(respons.data[i]);
                            }
                        }
                        if (all || firstCourses.length <= 2) {
                            // $('#seeMoreOtherCommunities').remove();
                            self.set("verMasComunidadesAutoinscribibles", false);
                        }

                        Ember.$('#seeMoreOtherCommunities').click(function () {
                            self.setOtherComunidades(respons.data);
                            Ember.$('#seeMoreOtherCommunities').remove();
                        });
                        self.setOtherComunidades(firstCourses);
                    },
                    error: function error(e) {
                        console.log(e);
                    }
                });
            },
            suscribe2other: function suscribe2other(comunidad_k) {
                Ember.$.ajax({
                    url: constant.APIURL + 'comunidad/inscribirUsuarioAComunidad',
                    type: 'POST',
                    contentType: 'application/x-www-form-urlencoded',
                    data: 'comunidad_k=' + comunidad_k,
                    xhrFields: {
                        withCredentials: true
                    },
                    success: function success(r) {

                        document.location.href = "/comunidades/" + comunidad_k;
                    },
                    error: function error(e) {
                        console.log(e);
                    }
                });
            },
            facebook: function facebook(e) {
                this._super();
                var self = this;

                var aTagCurso = Ember.$(e.target).parent().parent().parent().parent().parent().children()[0];
                console.log(Ember.$(aTagCurso).data('href'));
                var arrayComunidad = Ember.$(aTagCurso).data('href').split('/');

                shareFB(constant.SHAREURL + 'comunidad/' + arrayComunidad[arrayComunidad.length - 1]);
                //shareFB()

                //Agrega elemento en LOGROS
                window.add_user_action(parseInt(self.session.userData.usuario_k), 4, self.session.userData.nombre);
            },
            twitter: function twitter(e) {
                this._super();
                var self = this;

                // Selector a card del elemento dom
                var elementoPadreDom = Ember.$(e.target).parent().parent().parent().parent().parent();
                // Elementos hijos de elemento padre del dom
                var arrayChildParents = elementoPadreDom.children();
                var elementoContenido = arrayChildParents[0];
                //  selector a elemeto que contiene imagen, titulo, numero de integrantes de la comunidad
                var arrayElementoPadreContenido = Ember.$(elementoContenido).children().children();
                var elementoImagenComunidad = arrayElementoPadreContenido[0];
                //Recuperación Imagen fondo de comunidad
                var imagenSrc = Ember.$(elementoImagenComunidad).data('src');

                var elementoCardImgOverlay = arrayElementoPadreContenido[1];

                var arrayChildsCardImgOverlay = Ember.$(elementoCardImgOverlay).children();

                var elementoComunidadTextDom = arrayChildsCardImgOverlay[1];
                var elementoComunidadParticipantes = arrayChildsCardImgOverlay[3];

                var numeroDeParticipatesEnComunidad = Ember.$(elementoComunidadParticipantes).text().trim();
                var nombreDeLaComunidad = Ember.$(elementoComunidadTextDom).text();

                var twitterMsm = 'Forma parte de más de los ' + numeroDeParticipatesEnComunidad + ' que son parte de la comunidad ' + nombreDeLaComunidad + ' en NAFIN te capacita';
                var aTagCurso = Ember.$(e.target).parent().parent().parent().parent().parent().children()[0];
                var arrayComunidad = Ember.$(aTagCurso).data('href').split('/');
                var url = constant.SHAREURL + 'comunidad/' + arrayComunidad[arrayComunidad.length - 1];

                var width = 575,
                    height = 400,
                    left = (Ember.$(window).width() - width) / 2,
                    top = (Ember.$(window).height() - height) / 2,
                    url = 'https://twitter.com/share?url=' + url + '&text=' + twitterMsm,
                    opts = 'status=1' + ',width=' + width + ',height=' + height + ',top=' + top + ',left=' + left;
                window.open(url, 'twitter', opts);

                //Agrega elemento en LOGROS
                window.add_user_action(parseInt(self.session.userData.usuario_k), 4, self.session.userData.nombre);
            },
            linkedin: function linkedin(e) {
                this._super();
                var self = this;

                var aTagCurso = Ember.$(e.target).parent().parent().parent().parent().parent().children()[0];
                console.log(Ember.$(aTagCurso).data('href'));
                var arrayComunidad = Ember.$(aTagCurso).data('href').split('/');

                shareLI(constant.SHAREURL + 'comunidad/' + arrayComunidad[arrayComunidad.length - 1]);

                //Agrega elemento en LOGROS
                window.add_user_action(parseInt(self.session.userData.usuario_k), 4, self.session.userData.nombre);
            }
        }
    });
});