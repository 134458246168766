define('comunidades-nafin/components/course-evaluation', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		didInsertElement: function didInsertElement() {
			var self = this;
			setInterval(function () {
				self.LoadData();
			}, 10000);
		},
		setEvaluacion: function setEvaluacion(r, self) {
			self.set('evaluacion', r);
		},
		LoadData: function LoadData() {
			var url_page = window.location.pathname;
			var id = url_page.substring(url_page.lastIndexOf('/') + 1);
			var self = this;
			window.isHabilitarCalifRa = false;
			Ember.$.ajax({
				url: constant.APIURL + 'lms/alumno/listarrutas/listarmisras',
				type: 'POST',
				xhrFields: {
					withCredentials: true
				},
				contentType: 'application/x-www-form-urlencoded',
				data: 'params={"ra_k":' + id + '}',
				success: function success(r) {
					var response = JSON.parse(r);
					var ra;
					Object.keys(response.data).forEach(function (idx) {
						ra = response.data[idx].intento_ra;
					});
					Ember.$.ajax({
						url: constant.APIURL + 'usuario/datospersonales/',
						type: 'POST',
						xhrFields: {
							withCredentials: true
						},
						contentType: 'application/x-www-form-urlencoded',
						success: function success(r) {
							var usuario = JSON.parse(r);
							Ember.$.ajax({
								url: constant.APIURL + 'lms/raprendizaje/listarProgreso',
								type: 'POST',
								xhrFields: {
									withCredentials: true
								},
								contentType: 'application/x-www-form-urlencoded',
								data: 'usuario_k=' + usuario.usuario_k + '&llave_propietario_k=' + id + '&intento_ra=' + ra + '&cat=is null',
								success: function success(r) {
									var respons = JSON.parse(r);
									Object.keys(respons.data).forEach(function (idx) {
										if (respons.data[idx].numero_intentos === null) {
											respons.data[idx].numero_intentos = "0";
										}

										console.log(respons.data[idx]);
										if (respons.data[idx].habilitar_calificar_ra == 1) {
											console.log('hola mundo!!!');
											window.datosScoredUser = { "id_user": respons.ra.usuario_k, "id_course": respons.ra.ra_k, "evaluation": idx };
										} else {
											window.datosScoredUser = { "id_user": -2, "id_course": -2, "evaluation": idx };
										}

										//Label de los botones
										if (respons.data[idx].categoria_objeto_aprendizaje === 'Autoevaluación' || respons.data[idx].categoria_objeto_aprendizaje === 'Evaluaciones' || respons.data[idx].categoria_objeto_aprendizaje === 'Finales') {
											respons.data[idx].categoria_objeto_aprendizaje += ' / Evaluación';
											respons.data[idx].boton_label = "APLICAR";
										} else if (respons.data[idx].categoria_objeto_aprendizaje === 'Nafin') {
											respons.data[idx].categoria_objeto_aprendizaje += ' / Curso Online';
											respons.data[idx].boton_label = "INGRESAR";
										} else if (respons.data[idx].categoria_objeto_aprendizaje === 'Nafin' && respons.data[idx].estatus === "8") {
											respons.data[idx].categoria_objeto_aprendizaje += ' / Curso Online';
											respons.data[idx].boton_label = "VISUALIZAR";
										} else if (respons.data[idx].categoria_objeto_aprendizaje === 'Encuestas') {
											respons.data[idx].categoria_objeto_aprendizaje += ' / Encuesta';
											respons.data[idx].boton_label = "APLICAR";
										} else {
											respons.data[idx].categoria_objeto_aprendizaje += ' / Actividades';
											respons.data[idx].boton_label = "APLICAR";
										}

										//fix 	
										if (respons.data[idx].numero_intentos.indexOf('/') != -1) {
											respons.data[idx].numero_intentos = respons.data[idx].numero_intentos.split('/')[0];
										}

										//Labels con base en código THT
										if (respons.data[idx].tabla_recurso == "lms_cursos_presencial" && respons.data[idx].estatus == 7) {
											respons.data[idx].boton_label = "VER DETALLES";
										}
										if (respons.data[idx].tabla_recurso == "lms_evaluaciones" || respons.data[idx].tabla_recurso == "lms_encuestas") {
											if (respons.data[idx].estatus == 1 || respons.data[idx].estatus == 3 || respons.data[idx].estatus == 9 && parseInt(respons.data[idx].numero_intentos) > 0) {
												respons.data[idx].boton_label = "APLICAR";
											}
										}
										if (respons.data[idx].tabla_recurso == "lms_cursos_online") {
											if (respons.data[idx].estatus == 1 || respons.data[idx].estatus == 3 || respons.data[idx].estatus == 9 && parseInt(respons.data[idx].numero_intentos) > 0) {
												respons.data[idx].boton_label = "INGRESAR";
											} else if (respons.data[idx].estatus == 8 && respons.data[idx].habilitar_calificar_ra == 1) {
												console.log('curso finalizado');
												console.log('course-evaluation');
												respons.data[idx].boton_label = "VISUALIZAR";
												respons.data[idx].habilitar_calificar = "1";
												respons.data[idx].habilitar_calificar_ra = respons.data[idx].habilitar_calificar;
												/*$('#modal_grade').modal({
            	backdrop: 'static',
            	keyboard: false
            });
            $('#modal_grade').on('hidden.bs.modal', function () {
            	document.location.reload();
            });
            $('#modal_grade').modal();*/
											}
										}

										respons.data[idx].visible = true;

										//Estatis
										if (respons.data[idx].estatus === "1") {
											respons.data[idx].estatus_evaluacion = "En curso";
										} else if (respons.data[idx].estatus === "2") {
											respons.data[idx].estatus_evaluacion = "Cancelado";
										} else if (respons.data[idx].estatus === "3") {
											respons.data[idx].estatus_evaluacion = "Reabierto";
										} else if (respons.data[idx].estatus === "4") {
											respons.data[idx].estatus_evaluacion = "Completo";
											respons.data[idx].visible = false;
										} else if (respons.data[idx].estatus === "5") {
											respons.data[idx].estatus_evaluacion = "Incompleto";
										} else if (respons.data[idx].estatus === "6") {
											respons.data[idx].estatus_evaluacion = "Preinscripción";
										} else if (respons.data[idx].estatus === "7") {
											respons.data[idx].estatus_evaluacion = "Inscrito";
										} else if (respons.data[idx].estatus === "8") {

											respons.data[idx].estatus_evaluacion = "Aprobado";
											respons.data[idx].boton_label = "VISUALIZAR";

											if (respons.data[idx].tabla_recurso == 'lms_cursos_online') {
												respons.data[idx].visible = true;
											} else {
												respons.data[idx].visible = false;
											}
										} else if (respons.data[idx].estatus === '9') {
											respons.data[idx].estatus_evaluacion = "Reprobado";
										} else if (respons.data[idx].estatus === '10') {
											respons.data[idx].estatus_evaluacion = "No atendido";
										} else if (respons.data[idx].estatus === '11') {
											respons.data[idx].estatus_evaluacion = "Visitado";
										} else if (respons.data[idx].estatus === '12') {
											respons.data[idx].estatus_evaluacion = "No asistió";
										} else {
											respons.data[idx].estatus_evaluacion = "En espera";
										}

										if (parseInt(respons.data[idx].numero_intentos) > parseInt(respons.data[idx].limite_intentos)) {
											respons.data[idx].lock = true;
											respons.data[idx].leyend = 'Número de intentos alcanzado';
										} else {
											Object.keys(respons.data).forEach(function (idx2) {
												if (respons.data[idx].dependencias != null) {
													if (respons.data[idx2].propietario_recurso_k == respons.data[idx].dependencias) {
														if (respons.data[idx2].propietario_recurso_k.estatus == '8' || respons.data[idx2].propietario_recurso_k.estatus == '4') {

															respons.data[idx].lock = false;
															respons.data[idx].leyend = '';
														} else {
															respons.data[idx].lock = true;
															respons.data[idx].leyend = 'Este objeto tiene dependencia de ' + respons.data[idx2].nombre;
															if (respons.data[idx].estatus == '1' || respons.data[idx].estatus == '8' || respons.data[idx].estatus == '4' || respons.data[idx].estatus == '9') {

																respons.data[idx].lock = false;
																respons.data[idx].leyend = '';
															}
														}
													}
												}
											});
										}

										//ICONos
										if (respons.data[idx].tabla_recurso == 'lms_evaluaciones' && respons.data[idx].evaluacion_autodiagnostica == "1") {
											respons.data[idx].icon = '/img/iconos_cursos/evaluacion.png';
										} else if (respons.data[idx].tabla_recurso == 'lms_cursos_online') {
											respons.data[idx].icon = '/img/iconos_cursos/scorm.png';
										} else if (respons.data[idx].tabla_recurso == 'lms_encuestas') {
											respons.data[idx].icon = '/img/iconos_cursos/encuesta.png';
										} else if (respons.data[idx].tabla_recurso == 'lms_evaluaciones') {
											respons.data[idx].icon = '/img/iconos_cursos/evaluacion.png';
										} else if (respons.data[idx].tabla_recurso == 'lms_actividad') {
											respons.data[idx].icon = '/img/iconos_cursos/actividad.png';
										} else {
											respons.data[idx].icon = '/img/iconos_cursos/presencial.png';
										}

										setTimeout(function () {
											Ember.$('#loading').fadeOut('slow');
										}, 3000);
									});
									//console.log(respons.data);
									self.setEvaluacion(respons.data, self);
								},
								error: function error(e) {
									console.log(e);
								}
							});
						},
						error: function error(e) {
							console.error(e);
						}
					});
				},
				error: function error(e) {
					console.log(e);
				}
			});
		},
		didRender: function didRender() {
			var self = this;
			Ember.$('button[data-lock="false"]').removeAttr('disabled');
			/*
   	$('.1').removeAttr('disabled');
   	$('.8').attr('hidden', 'hidden');
   	$('.8').removeAttr('disabled');
   	$('.9').removeAttr('disabled');
   	$('#lms_cursos_online .8').removeAttr('hidden');
   */
		},

		actions: {
			validaciones_pregunta_inicial: function validaciones_pregunta_inicial() {
				console.log('validaciones_pregunta');

				if (Ember.$('.carousel-item.active').data('obligatorio') == 1) {
					Ember.$('#btn_nxt').prop("disabled", true);
					setTimeout(function () {
						if (Ember.$('.carousel-item.active input:checked').prop('checked') === true) {
							console.log('dentro de if prop checked');
							Ember.$('#btn_nxt').prop("disabled", false);
						} else {
							Ember.$('#btn_nxt').prop("disabled", true);
						}
					}, 800);
				}

				setTimeout(function () {

					Ember.$('.carousel-item.active textarea').each(function () {
						Ember.$(this).on('keyup mouseup change', function () {
							console.log('textarea: keyup mouseup change');
							if (Ember.$(this).val() !== '' && Ember.$(this).val() != 0) {
								Ember.$('#btn_nxt').prop("disabled", false);
							} else {
								Ember.$('#btn_nxt').prop("disabled", true);
							}
						});
					});
					Ember.$('.carousel-item.active input[type=number]').each(function () {
						Ember.$(this).on('keyup mouseup change', function () {
							console.log('type=number: keyup mouseup');
							var pass = 0;
							Ember.$('.carousel-item.active input[type=number]').each(function () {
								if (Ember.$(this).val() !== '' && Ember.$(this).val() != 0 && parseInt(Ember.$(this).val()) <= parseInt(Ember.$(this).attr('max')) && parseInt(Ember.$(this).val()) >= parseInt(Ember.$(this).attr('min'))) {
									pass++;
								}
							});
							if (pass == Ember.$('.carousel-item.active input[type=number]').length) {
								Ember.$('#btn_nxt').prop("disabled", false);
							} else {
								Ember.$('#btn_nxt').prop("disabled", true);
							}
						});
					});
					Ember.$('.carousel-item.active select').each(function () {
						Ember.$(this).change(function () {
							if (Ember.$(this).val() !== '') {
								Ember.$('#btn_nxt').prop("disabled", false);
							}
						});
					});
				}, 800);
			},
			evaluacion: function evaluacion(event) {
				var self = this;

				var propietario_recurso_k = Ember.$(event.target).attr('id');
				var noIntentos = Ember.$(event.target).data('intetos');
				var noIntentosActual = noIntentos;
				var evaluacion = this.get('evaluacion');
				var idxobjeto = Ember.$(event.target).data('idx');
				var objeto = evaluacion[idxobjeto];
				var url_page = window.location.pathname;
				var id = url_page.substring(url_page.lastIndexOf('/') + 1);

				if (idxobjeto == datosScoredUser.evaluation) {
					window.isHabilitarCalifRa = true;
				} else {
					window.isHabilitarCalifRa = false;
				}
				//window.isHabilitarCalifRa = true;

				console.log('EVALUACIón');
				console.log(idxobjeto);

				//window open post
				if (objeto.tabla_recurso == 'lms_cursos_online') {
					var url = constant.BASEURL + 'index.php/scorm/lanzarscorm';

					if (objeto.estatus == '8' || objeto.estatus == '4') {
						var form = Ember.$('<form action="' + url + '" method="post" target="_blank">' + '<input type="hidden" name="lms_scorm_k" value="' + objeto.scos + '" onclick="test()"/>' + '<input type="hidden" name="scorm_test" value="1" />' + '<input type="hidden" name="propietario_recurso_k" value="' + objeto.propietario_recurso_k + '" />' +
						//'<input type="hidden" name="propietario_recurso_k" value="'+objeto.noIntentosActual+'" />'+
						'</form>');
						Ember.$('body').append(form);
						form.submit();
					} else {
						var form = Ember.$('<form action="' + url + '" method="post" target="_blank">' + '<input type="hidden" name="lms_scorm_k" value="' + objeto.scos + '" />' + '<input type="hidden" name="scorm_test" value="0" />' + '<input type="hidden" name="propietario_recurso_k" value="' + objeto.propietario_recurso_k + '" />' +
						//'<input type="hidden" name="propietario_recurso_k" value="'+objeto.noIntentosActual+'" />'+
						'</form>');
						Ember.$('body').append(form);
						form.submit();
					}
				} else {
					window.buttoIngresar = Ember.$(event.target).attr('id');
					var data = 'propietario_recurso_k=' + objeto.propietario_recurso_k + '&intento_ra=' + objeto.intento_ra + '&grupo_k=' + id + '&usuario_k=' + '&numero_intentos=';

					// propietario_recurso_k: 151
					// intento_ra: 1
					// grupo_k: 12
					// usuario_k: 
					// numero_intentos: 

					var segmento = 'evaluacion';
					if (objeto.tabla_recurso == 'lms_encuestas') {
						segmento = 'encuesta';
					}

					Ember.$.ajax({
						url: constant.APIURL + 'lms/' + segmento + '/validaraplicar',
						type: 'POST',
						contentType: 'application/x-www-form-urlencoded',
						data: data,
						xhrFields: {
							withCredentials: true
						},
						success: function success(response) {
							var r = JSON.parse(response);

							Ember.$('#btn_nxt').attr('hidden', false);
							Ember.$('#btn_prv').attr('hidden', true);
							Ember.$('#btn_close').attr('hidden', true);

							sessionStorage.setItem('recurso_alumno_resultado_k', r.recurso_alumno_resultado_k);
							var preguntas = r.data.preguntas;
							Ember.$('#contadorPreguntas').html('Pregunta 1 de ' + preguntas.length);
							var html = '';
							var findOne = 0;
							if (preguntas.length == 1) {
								Ember.$('#btn_nxt').attr('hidden', true);
								Ember.$('#btn_end').removeAttr('hidden');
							}
							//$('#contadorPreguntas').html('Pregunta 10 de 10')

							Object.keys(preguntas).forEach(function (idx) {

								/*var contenedor = [];
        Object.keys(preguntas[idx].respuestas).forEach(function (idx_respuesta) {
        	contenedor.push(preguntas[idx].respuestas[idx_respuesta].correcta)
        });*/

								// contenedor.indexOf("1") < 0
								/*if (true) {
        	//findOne = 1;
        	//console.log(preguntas[idx]);
        	//console.log('IDX: ',idx+1);
        	//console.log('Preguntas: ',preguntas.length - 1);								
        }*/

								/*if ((preguntas.length - 1) == idx) {
        	$('#btn_end').attr('hidden', false);
        	$('#btn_nxt').attr('hidden', true);
        	$('#btn_prv').attr('hidden', false);
        	$('#contadorPreguntas').html('Pregunta ' + preguntas.length + ' de ' + preguntas.length);
        }*/

								html += '<div class="carousel-item" data-tabla="' + objeto.tabla_recurso + '" data-obligatorio=' + preguntas[idx].obligatoria + '>';
								html += '<div class="row">';
								html += '<div class="col-12">';
								html += '<h2 data-usuario="' + objeto.usuario_k + '" data-propietario="' + propietario_recurso_k + '" data-tipo="' + preguntas[idx].tipo_pregunta_k + '">' + preguntas[idx].pregunta + '</h2>';
								html += '</div>';
								html += '</div>';
								// Tipo de variables traidas directamente del back
								//'LMS_PREGUNTAS_TIPO_RESPUESTA_SI_NO',              1 
								//'LMS_PREGUNTAS_TIPO_RESPUESTA_UNICA',              2 
								//'LMS_PREGUNTAS_TIPO_RESPUESTA_MULTIPLE',           3 
								//'LMS_PREGUNTAS_TIPO_RESPUESTA_LISTA_DESPLEGABLE',  4 
								//'LMS_PREGUNTAS_TIPO_RESPUESTA_RELACIONAR_COLUMNAS',5 
								//'LMS_PREGUNTAS_TIPO_RESPUESTA_PORCENTAJE',         6 
								//'LMS_PREGUNTAS_TIPO_RESPUESTA_PUNTUACION',         7 
								//'LMS_PREGUNTAS_TIPO_RESPUESTA_ABIERTA',            8 
								//'LMS_PREGUNTAS_TIPO_RESPUESTA_ORDENAR_RESPUESTA',  9 
								//'LMS_PREGUNTAS_TIPO_RESPUESTA_CALIFICAR_NIVELES',  10

								//console.log('esta pregunta no la haz contestado: ', contenedor);

								if (preguntas[idx].obligatoria == '1') {
									Ember.$('#btn_nxt').prop("disabled", true);
								}

								if (preguntas[idx].tipo_pregunta_k == 1) {
									Object.keys(preguntas[idx].respuestas).forEach(function (idx_respuesta) {
										html += '<div class="form-check">';
										html += '<input class="form-check-input" type="radio" name="_' + idx + '" id="' + preguntas[idx].respuestas[idx_respuesta].respuesta_k + '" value="' + preguntas[idx].respuestas[idx_respuesta].puntuacion + '" data-correcta="' + preguntas[idx].respuestas[idx_respuesta].correcta + '" data-orden="' + preguntas[idx].respuestas[idx_respuesta].orden + '" data-quest="' + preguntas[idx].pregunta_k + '"/>';
										html += '<label class="form-check-label" for="' + preguntas[idx].respuestas[idx_respuesta].respuesta_k + '">';
										html += preguntas[idx].respuestas[idx_respuesta].descripcion;
										html += '</label>';
										html += '</div>';
										//console.log('Hola mundo:  -> ', preguntas[idx].respuestas[idx_respuesta]);
									});
								} else if (preguntas[idx].tipo_pregunta_k == 2) {
									Object.keys(preguntas[idx].respuestas).forEach(function (idx2) {
										html += '<div class="form-check">';
										html += '<input class="form-check-input" type="radio" name="_' + idx + '" id="' + preguntas[idx].respuestas[idx2].respuesta_k + '" value="' + preguntas[idx].respuestas[idx2].puntuacion + '" data-correcta="' + preguntas[idx].respuestas[idx2].correcta + '" data-orden="' + preguntas[idx].respuestas[idx2].orden + '" data-quest="' + preguntas[idx].pregunta_k + '"/>';
										html += '<label class="form-check-label" for="' + preguntas[idx].respuestas[idx2].respuesta_k + '">';
										html += preguntas[idx].respuestas[idx2].descripcion;
										html += '</label>';
										html += '</div>';
										//console.log('Hola mundo:  -> ', preguntas[idx].respuestas[idx2]);
									});
									//console.log('Contenedor: ', contenedor);
								} else if (preguntas[idx].tipo_pregunta_k == 3) {
									Object.keys(preguntas[idx].respuestas).forEach(function (idx2) {
										html += '<div class="form-check">';
										html += '<input class="form-check-input" type="checkbox" name="_' + idx + '" id="' + preguntas[idx].respuestas[idx2].respuesta_k + '" value="' + preguntas[idx].respuestas[idx2].puntuacion + '" data-correcta="' + preguntas[idx].respuestas[idx2].correcta + '" data-orden="' + preguntas[idx].respuestas[idx2].orden + '" data-quest="' + preguntas[idx].pregunta_k + '"/>';
										html += '<label class="form-check-label" for="' + preguntas[idx].respuestas[idx2].respuesta_k + '">';
										html += preguntas[idx].respuestas[idx2].descripcion;
										html += '</label>';
										html += '</div>';
										//console.log('Hola mundo:  -> ', preguntas[idx].respuestas[idx2]);
									});
								} else if (preguntas[idx].tipo_pregunta_k == 4) {
									html += '<div class="form-group">';
									html += '<select class="form-control" data-quest="' + preguntas[idx].pregunta_k + '">';
									//html += '<select class="form-control" data-quest="'+preguntas[idx].pregunta_k+'">'
									html += '<option class="form-check-input" data-orden=""></option>';
									Object.keys(preguntas[idx].respuestas).forEach(function (idx2) {
										html += '<option class="form-check-input" name="_' + idx + '" id="' + preguntas[idx].respuestas[idx2].respuesta_k + '" value="' + preguntas[idx].respuestas[idx2].puntuacion + '" data-correcta="' + preguntas[idx].respuestas[idx2].correcta + '" data-orden="' + preguntas[idx].respuestas[idx2].orden + '">' + preguntas[idx].respuestas[idx2].descripcion + '</option>';
										//console.log('Hola mundo:  -> ', preguntas[idx].respuestas[idx2]);
									});

									html += '</select>';
									html += '</div>';
								} else if (preguntas[idx].tipo_pregunta_k == 5) {
									var left = [];
									var right = [];
									Object.keys(preguntas[idx].respuestas).forEach(function (idx2) {
										if (parseInt(preguntas[idx].respuestas[idx2].orden, 10) % 2) {
											left.push(preguntas[idx].respuestas[idx2]);
										} else {
											right.push(preguntas[idx].respuestas[idx2]);
										}
									});
									//console.log('PREGUNTAS', left, right);
									html += '<div class="row">';
									html += '<div class="col-6">';
									html += '<ul data-quest="' + preguntas[idx].pregunta_k + '">';
									for (var i = 0; i < left.length; i++) {
										html += '<li id="' + left[i].respuesta_k + '" data-correcta="' + left[i].correcta + '" data-orden="' + left[i].orden + '"><i>' + (i + 1) + '</i><span>' + left[i].descripcion + '</span></li>';
									}
									html += '</ul>';
									html += '</div>';
									var letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
									html += '<div class="col-6">';
									html += '<ul>';
									for (var i = 0; i < right.length; i++) {
										html += '<li>';
										html += '<i>' + letters[i] + '</i>' + ' ' + '<span>' + right[i].descripcion + '</span>';
										html += '<select class="form-control select2" id="' + right[i].respuesta_k + '" data-orden=' + right[i].orden + '>';
										html += '<option class="form-check-input" type="checkbox" id="" value="0" data-correcta="" data-orden=""></option>';
										for (var j = 0; j < left.length; j++) {
											html += '<option value="' + left[j].respuesta_k + '">';
											html += '<span>' + left[j].descripcion + '</span>';
											html += '</option>';
										}

										html += '</select>';
										html += '</li>';
									}
									html += '</ul>';
									html += '</div>';
									html += '</div>';
								} else if (preguntas[idx].tipo_pregunta_k == 6) {
									Object.keys(preguntas[idx].respuestas).forEach(function (idx2) {
										html += '<div class="form-check">';
										html += '<input max="100" min="0" class="form-check-input" type="number" name="_' + idx + '" id="' + preguntas[idx].respuestas[idx2].respuesta_k + '" value="' + preguntas[idx].respuestas[idx2].correcta + '" data-orden="' + preguntas[idx].respuestas[idx2].orden + '" data-quest="' + preguntas[idx].pregunta_k + '"/>';
										html += '<label class="form-check-label" for="' + preguntas[idx].respuestas[idx2].respuesta_k + '">';
										html += preguntas[idx].respuestas[idx2].descripcion;
										html += '</label>';
										html += '</div>';
									});
								} else if (preguntas[idx].tipo_pregunta_k == 7) {
									Object.keys(preguntas[idx].respuestas).forEach(function (idx_respuesta) {
										html += '<div class="form-check">';
										html += '<label class="form-check-label margin_ud_10px" for="' + preguntas[idx].respuestas[idx_respuesta].respuesta_k + '">';
										html += preguntas[idx].respuestas[idx_respuesta].descripcion;
										html += '<input class="form-control" max="' + preguntas[idx].respuestas[idx_respuesta].respuesta_correcta_k + '" min="1" type="number" name="_' + idx + '" id="' + preguntas[idx].respuestas[idx_respuesta].respuesta_k + '" data-correcta="' + preguntas[idx].respuestas[idx_respuesta].correcta + '" value="' + preguntas[idx].respuestas[idx_respuesta].puntuacion * 10 + '" data-rc="' + preguntas[idx].respuestas[idx_respuesta].respuesta_correcta + '" data-orden="' + preguntas[idx].respuestas[idx_respuesta].orden + '"  data-quest="' + preguntas[idx].pregunta_k + '"/>';
										html += '</label>';
										html += '</div>';
									});
									/*Object.keys(preguntas[idx].respuestas).forEach(function(idx2){
         	html +=	'<div class="form-check">'
         	html += '<label class="form-check-label" for="'+preguntas[idx].respuestas[idx2].respuesta_k+'">'
         	html +=	preguntas[idx].respuestas[idx2].descripcion
         	html +=	'<input class="form-control" max="100" min="0" type="number" name="_'+idx+'" id="'+preguntas[idx].respuestas[idx2].respuesta_k+'" data-correcta="'+preguntas[idx].respuestas[idx2].correcta+'" value="'+(preguntas[idx].respuestas[idx2].puntuacion*10)+'" data-orden="'+preguntas[idx].respuestas[idx2].orden+'"  data-quest="'+preguntas[idx].pregunta_k+'"/>'
         	html +=	'</label>'
         	html +=	'</div>'
         });*/
								} else if (preguntas[idx].tipo_pregunta_k == 8) {
									html += '<div class="form-check">';
									html += '<textarea class="form-control" name="_' + idx + '" id="' + preguntas[idx].respuestas[0].respuesta_k + '" value="' + preguntas[idx].respuestas[0].descripcion + '" data-quest="' + preguntas[idx].pregunta_k + '"></textarea>';
									html += '<label class="form-check-label">';
									html += preguntas[idx].respuestas[0].descripcion;
									html += '</label>';
									html += '</div>';
								} else if (preguntas[idx].tipo_pregunta_k == 9) {
									Object.keys(preguntas[idx].respuestas).forEach(function (idx_respuesta) {
										var contador = 1;
										html += '<div class="row">';
										html += '<div class="col-12 col-lg-8 margin_ud_10px">';
										html += '<label class="form-check-label" for="customCheck1" data-respuesta="' + preguntas[idx].respuestas[idx_respuesta].respuesta_k + '" data-orden="' + preguntas[idx].respuestas[idx_respuesta].orden + '">';
										html += preguntas[idx].respuestas[idx_respuesta].descripcion;
										html += '</label>';
										html += '</div>';
										html += '<div class="col-12 col-lg-4 margin_ud_10px">';
										html += '<select class="form-control" data-quest="' + preguntas[idx].pregunta_k + '">';
										html += '<option class="form-check-input value="0" data-correcta="" data-rc="" data-orden="">Seleccione una opción</option>';
										Object.keys(preguntas[idx].respuestas).forEach(function (idx_respuesta_ordenar) {
											html += '<option class="form-check-input" id="' + preguntas[idx].respuestas[idx_respuesta_ordenar].respuesta_k + '" value="' + preguntas[idx].respuestas[idx_respuesta_ordenar].puntuacion + '" data-correcta="' + preguntas[idx].respuestas[idx_respuesta_ordenar].correcta + '" data-rc="' + preguntas[idx].respuestas[idx_respuesta].respuesta_correcta + '" data-orden="' + preguntas[idx].respuestas[idx_respuesta_ordenar].orden + '">' + contador++ + '</option>';
										});
										html += '</select>';
										html += '</div>';
										html += '</div>';
									});
								} else {
									html += 'Tipo de pregunta no implementado ' + preguntas[idx].tipo_pregunta_k;
								}
								html += '</div>';
							});
							var estadoPregunta = false;

							Ember.$('.bd-evaluacion-modal-lg').modal({
								backdrop: 'static',
								keyboard: false
							});
							Ember.$('#carouselQuestions').html(html);
							Ember.$('.carousel-item:first-child').addClass('active');
							var d = new Date();
							Ember.$('#date_time_start').html("Inicio: " + d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear() + " " + d.getHours() + ":" + d.getMinutes());
							Ember.$('.form-check-input').click(function () {
								Ember.$('#btn_nxt').prop("disabled", false);
							});

							Ember.$('select.select2').select2({
								placeholder: 'Seleccione una opción',
								allowClear: true,
								width: '80%',
								selectOnClose: true,
								closeOnSelect: true
							});

							self.send('validaciones_pregunta_inicial');
						},
						error: function error(e) {
							console.error(e);
							Ember.$('#carouselQuestions').html('Estamos teniendo problemas tecnicos');
						}
					});
				}
			}
		}
	});
});