define('comunidades-nafin/routes/preguntas-diagnostico', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		arrPreguntasPrev: [],
		selectoptions: {},

		/**
   * beforeModel - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis sapien.
   *
   * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
   * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
   * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
   * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
   * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
   *
   *
   * @author
   *
   * @date
   * @param   void
   * @return  promise
   */

		beforeModel: function beforeModel() {

			var promise = new Ember.RSVP.Promise(function (resolve) {
				Ember.run.later(this, function () {
					resolve("ok");
				}, 1000);
			});

			return promise;
		},


		/**
   * setupController - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis sapien.
   *
   * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
   * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
   * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
   * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
   * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
   *
   *
   * @author
   *
   * @date
   * @param   controller controller
   * @return  void
   */

		setupController: function setupController(controller) {
			var self = this;

			var url_page = new URLSearchParams(window.location.search);
			var scope_setup_controler = this;
			sessionStorage.setItem('tipoDiagnostico', url_page.get('tipo'));
			sessionStorage.setItem('urlPrevia', document.referrer);

			sessionStorage.setItem('guardarPregunta', []);
			window.lastArray = [];

			Ember.$.ajax({

				url: constant.APIURL + 'usuario/datospersonales/',
				type: 'POST',
				xhrFields: { withCredentials: true },
				contentType: 'application/x-www-form-urlencoded',
				success: function success(r) {

					var usuario = JSON.parse(r);

					Ember.$.ajax({
						url: constant.APIURL + '/evaluacionesdinamicas/evaluaciondinamica/inscribirusuarioenevaluaciondinamica',
						type: 'POST',
						xhrFields: { withCredentials: true },
						contentType: 'application/x-www-form-urlencoded',
						data: 'evaluacion_dinamica_k=' + url_page.get('evaluacion') + '&usuario_k=' + usuario.usuario_k,
						success: function success(response) {

							Ember.$("#seccionAnterior").text(sessionStorage.getItem('seccion-anterior') != null ? sessionStorage.getItem('seccion-anterior') : " sección anterior");
							sessionStorage.setItem('seccion-anterior', "");
							var res = JSON.parse(response);
							var data_eval = res.data;

							if (res.error) {

								Ember.$('.btn.btn-secondary').on("click", function () {
									Ember.$('.btn.btn-secondary').unbind();
									document.location.href = "/diagnostico-empresarial/0?tipo=misDiagnosticos";
								});

								Ember.$('#loading').attr('hidden', true);
								Ember.$('#modal-avisos .modal-header').text('Aviso');
								Ember.$('#modal-avisos .modal-body').text(res.error);
								Ember.$('#modal-avisos').modal('show');
							} else {

								Ember.$.ajax({

									url: constant.APIURL + '/evaluacionesdinamicas/evaluaciondinamica/listarevaluacionusuario',
									type: 'POST',
									xhrFields: { withCredentials: true },
									contentType: 'application/x-www-form-urlencoded',
									data: 'evaluacion_usuario_k=' + data_eval.evaluacion_usuario_k + '&usario_k=' + usuario.usuario_k,
									success: function success(response) {

										var r = JSON.parse(response);
										var preguntas = r.data.preguntas;
										var html = '';
										var date = new Date();
										var indext_lastResponse = 0;

										window.evaluacionDinamica = {
											usuario_k: usuario.usuario_k,
											evaluacion_usuario_k: data_eval.evaluacion_usuario_k,
											preguntas: r.data.preguntas,
											pregunta_ultima_respondida_k: r.pregunta_ultima_respondida_k
										};

										Ember.$('#contadorPreguntas').html('Pregunta ' + (parseInt(indext_lastResponse) + 1) + ' de ' + preguntas.length);

										sessionStorage.setItem('evaluacion_usuario_k', r.evaluacion_usuario_k);

										Object.keys(preguntas).forEach(function (idx) {

											html += '<div class="carousel-item" id="' + idx + '" data-psc="' + preguntas[idx].pregunta_siguiente_correcta_k + '" data-psi="' + preguntas[idx].pregunta_siguiente_incorrecta_k + '" data-pregunta="' + preguntas[idx].pregunta_k + '">';
											html += '<div class="row">';
											html += '<div class="col-12">';
											html += '<h2 data-tipo="' + preguntas[idx].tipo_pregunta_k + '">' + preguntas[idx].pregunta + '</h2>';
											html += '</div>';
											html += '</div>';

											if (preguntas[idx].tipo_pregunta_k == constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_SI_NO) {

												Object.keys(preguntas[idx].respuestas).forEach(function (idx_respuesta) {

													var is_checked_string = preguntas[idx].respuestas[idx_respuesta].correcta == '1' ? ' checked ' : ' ';

													html += '<div class="form-check">';
													html += '<input	class="form-check-input" ' + 'type="radio" ' + 'name="_' + idx + '" ' + 'id="' + preguntas[idx].respuestas[idx_respuesta].respuesta_k + '" ' + 'value="' + preguntas[idx].respuestas[idx_respuesta].puntuacion + '" ' + 'data-correcta="' + preguntas[idx].respuestas[idx_respuesta].correcta + '" ' + 'data-rc="' + preguntas[idx].respuestas[idx_respuesta].respuesta_correcta + '" ' + 'data-orden="' + preguntas[idx].respuestas[idx_respuesta].orden + '" ' + 'data-quest="' + preguntas[idx].pregunta_k + '" ' + 'data-pregunta_siguiente_k="' + preguntas[idx].respuestas[idx_respuesta].pregunta_siguiente_k + '" ' + is_checked_string + '/>';
													html += '<label class="form-check-label" for="' + preguntas[idx].respuestas[idx_respuesta].respuesta_k + '">';
													html += preguntas[idx].respuestas[idx_respuesta].descripcion;
													html += '</label>';
													html += '</div>';
												});
											} else if (preguntas[idx].tipo_pregunta_k == constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_UNICA) {

												Object.keys(preguntas[idx].respuestas).forEach(function (idx_respuesta) {

													var is_checked_string = preguntas[idx].respuestas[idx_respuesta].correcta == '1' ? ' checked ' : ' ';

													html += '<div class="form-check">';
													html += '<input class="form-check-input" ' + 'type="radio" ' + 'name="_' + idx + '" ' + 'id="' + preguntas[idx].respuestas[idx_respuesta].respuesta_k + '" ' + 'value="' + preguntas[idx].respuestas[idx_respuesta].puntuacion + '" ' + 'data-correcta="' + preguntas[idx].respuestas[idx_respuesta].correcta + '" ' + 'data-rc="' + preguntas[idx].respuestas[idx_respuesta].respuesta_correcta + '" ' + 'data-orden="' + preguntas[idx].respuestas[idx_respuesta].orden + '" ' + 'data-quest="' + preguntas[idx].pregunta_k + '" ' + 'data-pregunta_siguiente_k="' + preguntas[idx].respuestas[idx_respuesta].pregunta_siguiente_k + '"' + is_checked_string + '/>';
													html += '<label class="form-check-label" for="' + preguntas[idx].respuestas[idx_respuesta].respuesta_k + '">';
													html += preguntas[idx].respuestas[idx_respuesta].descripcion;
													html += '</label>';
													html += '</div>';
												});
											} else if (preguntas[idx].tipo_pregunta_k == constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_MULTIPLE) {

												Object.keys(preguntas[idx].respuestas).forEach(function (idx_respuesta) {

													var is_checked_string = preguntas[idx].respuestas[idx_respuesta].correcta == '1' ? ' checked ' : ' ';

													html += '<div 	class="form-check">';
													html += '<input class="form-check-input check-event" ' + 'type="checkbox" ' + 'name="_' + idx + '" ' + 'id="' + preguntas[idx].respuestas[idx_respuesta].respuesta_k + '" ' + 'value="' + preguntas[idx].respuestas[idx_respuesta].puntuacion + '" ' + 'data-correcta="' + preguntas[idx].respuestas[idx_respuesta].correcta + '" ' + 'data-rc="' + preguntas[idx].respuestas[idx_respuesta].respuesta_correcta + '" ' + 'data-orden="' + preguntas[idx].respuestas[idx_respuesta].orden + '" ' + 'data-aplicaseleccion="' + preguntas[idx].respuestas[idx_respuesta].aplica_seleccion + '" ' + 'data-aplicadeseleccion="' + preguntas[idx].respuestas[idx_respuesta].aplica_deseleccion + '" ' + 'data-seleccionatodos="' + preguntas[idx].respuestas[idx_respuesta].selecciona_todos + '" ' + 'data-deseleccionatodos="' + preguntas[idx].respuestas[idx_respuesta].deselecciona_todos + '" ' + 'data-quest="' + preguntas[idx].pregunta_k + '"' + is_checked_string + '/>';
													html += '<label class="form-check-label" for="' + preguntas[idx].respuestas[idx_respuesta].respuesta_k + '">';
													html += preguntas[idx].respuestas[idx_respuesta].descripcion;
													html += '</label>';
													html += '</div>';
												});
											} else if (preguntas[idx].tipo_pregunta_k == constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_LISTA_DESPLEGABLE) {

												html += '<div class="form-group">';
												html += '<select class="form-control" data-quest="' + preguntas[idx].pregunta_k + '">';
												html += '<option class="form-check-input" value="">Selecciona una opción</option>';
												Object.keys(preguntas[idx].respuestas).forEach(function (idx_respuesta) {
													html += '<option class="form-check-input" id="' + preguntas[idx].respuestas[idx_respuesta].respuesta_k + '" value="' + preguntas[idx].respuestas[idx_respuesta].puntuacion + '" data-correcta="' + preguntas[idx].respuestas[idx_respuesta].correcta + '" data-rc="' + preguntas[idx].respuestas[idx_respuesta].respuesta_correcta + '" data-orden="' + preguntas[idx].respuestas[idx_respuesta].orden + '">' + preguntas[idx].respuestas[idx_respuesta].descripcion + '</option>';
												});
												html += '</select>';
												html += '</div>';
											} else if (preguntas[idx].tipo_pregunta_k == constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_RELACIONAR_COLUMNAS) {

												var left = [];
												var right = [];

												Object.keys(preguntas[idx].respuestas).forEach(function (idx2) {
													if (parseInt(preguntas[idx].respuestas[idx2].orden, 10) % 2) {
														left.push(preguntas[idx].respuestas[idx2]);
													} else {
														right.push(preguntas[idx].respuestas[idx2]);
													}
												});

												html += '<div class="row">';
												html += '<div class="col-6">';
												html += '<ul data-quest="' + preguntas[idx].pregunta_k + '">';
												for (var i = 0; i < left.length; i++) {
													html += '<li id="' + left[i].respuesta_k + '" data-correcta="' + left[i].correcta + '" data-orden="' + left[i].orden + '"><i>' + (i + 1) + '</i><span>' + left[i].descripcion + '</span></li>';
												}
												html += '</ul>';
												html += '</div>';
												var letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
												html += '<div class="col-6">';
												html += '<ul>';
												for (var i = 0; i < right.length; i++) {
													html += '<li>';
													html += '<i>' + letters[i] + '</i>' + ' ' + '<span>' + right[i].descripcion + '</span>';
													html += '<select class="form-control" id="' + right[i].respuesta_k + '" data-orden="' + right[i].orden + '" data-rc="' + left[i].respuesta_correcta + '" >';
													html += '<option class="form-check-input" value="0" data-correcta=""></option>';
													for (var j = 0; j < left.length; j++) {
														html += '<option value="' + left[j].respuesta_k + '">';
														html += '<span>' + left[j].descripcion + '</span>';
														html += '</option>';
													}
													html += '</select>';
													html += '</li>';
												}
												html += '</ul>';
												html += '</div>';
												html += '</div>';
											} else if (preguntas[idx].tipo_pregunta_k == constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_PORCENTAJE) {

												Object.keys(preguntas[idx].respuestas).forEach(function (idx_respuesta) {
													html += '<div class="form-check">';
													html += '<input max="100" min="0" class="form-check-input" type="number" name="_' + idx + '" id="' + preguntas[idx].respuestas[idx_respuesta].respuesta_k + '" value="' + preguntas[idx].respuestas[idx_respuesta].correcta + '" data-orden="' + preguntas[idx].respuestas[idx_respuesta].orden + '" data-quest="' + preguntas[idx].pregunta_k + '"/>';
													html += '<label class="form-check-label" for="' + preguntas[idx].respuestas[idx_respuesta].respuesta_k + '">';
													html += preguntas[idx].respuestas[idx_respuesta].descripcion;
													html += '</label>';
													html += '</div>';
												});
											} else if (preguntas[idx].tipo_pregunta_k == constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_PUNTUACION) {

												Object.keys(preguntas[idx].respuestas).forEach(function (idx_respuesta) {

													var select_html = '';
													select_html += '<select class="form-control not-select" ' + 'name="_' + idx + '" ' + 'id="' + preguntas[idx].respuestas[idx_respuesta].respuesta_k + '" ' + 'data-correcta="' + preguntas[idx].respuestas[idx_respuesta].correcta + '" ' + 'value="' + preguntas[idx].respuestas[idx_respuesta].puntuacion * 10 + '" ' + 'data-rc="' + preguntas[idx].respuestas[idx_respuesta].respuesta_correcta + '" ' + 'data-orden="' + preguntas[idx].respuestas[idx_respuesta].orden + '" ' + 'data-quest="' + preguntas[idx].pregunta_k + '"' + '>' + '<option class="form-check-input" value="0" data-correcta="" data-rc="" data-orden="">Seleccione una opción</option>';

													for (var contador_iteracion_int = 1; contador_iteracion_int <= preguntas[idx].respuestas[idx_respuesta].respuesta_correcta_k; contador_iteracion_int++) {

														var selected_string = contador_iteracion_int == preguntas[idx].respuestas[idx_respuesta].correcta ? ' selected ' : '';
														select_html += '<option class="form-check-input" ' + 'id="' + contador_iteracion_int + '" ' + 'value="' + contador_iteracion_int + '" ' + selected_string + '>' + contador_iteracion_int + '</option>';
													}
													select_html += '</select>';

													html += '<div class="col-12 col-lg-8 margin_ud_10px">';
													html += '<label class="form-check-label" for="customCheck1" data-respuesta="' + preguntas[idx].respuestas[idx_respuesta].respuesta_k + '" data-orden="' + preguntas[idx].respuestas[idx_respuesta].orden + '">';
													html += preguntas[idx].respuestas[idx_respuesta].descripcion;
													html += '</label>';
													html += '</div>';
													html += '<div class="col-12 col-lg-4 margin_ud_10px">';
													html += '<input type="hidden" value="' + preguntas[idx].respuestas[idx_respuesta].descripcion + '"/>';
													html += select_html;
													html += '</div>';
												});
											} else if (preguntas[idx].tipo_pregunta_k == constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_ABIERTA) {

												Object.keys(preguntas[idx].respuestas).forEach(function (idx_respuesta) {

													var descripcion_html = preguntas[idx].respuestas[idx_respuesta].correcta == 'null' || preguntas[idx].respuestas[idx_respuesta].correcta == null ? '' : preguntas[idx].respuestas[idx_respuesta].correcta;

													html += '<div class="form-check">';
													html += '<textarea	class="form-control" ' + 'name="_' + idx + '" ' + 'id="' + preguntas[idx].respuestas[idx_respuesta].respuesta_k + '" ' + 'data-quest="' + preguntas[idx].pregunta_k + '" ' + 'data-orden="' + preguntas[idx].respuestas[idx_respuesta].orden + '" ' + '>' + descripcion_html + '</textarea>';
													html += '<label class="form-check-label">';
													html += preguntas[idx].respuestas[0].descripcion;
													html += '</label>';
													html += '</div>';
												});
											} else if (preguntas[idx].tipo_pregunta_k == constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_ORDENAR_RESPUESTA) {

												Object.keys(preguntas[idx].respuestas).forEach(function (idx_respuesta) {

													var contador = 1;

													html += '<div class="row">';
													html += '<div class="col-12 col-lg-8 margin_ud_10px">';
													html += '<label class="form-check-label" for="customCheck1" data-respuesta="' + preguntas[idx].respuestas[idx_respuesta].respuesta_k + '" data-orden="' + preguntas[idx].respuestas[idx_respuesta].orden + '">';
													html += preguntas[idx].respuestas[idx_respuesta].descripcion;
													html += '</label>';
													html += '</div>';
													html += '<div class="col-12 col-lg-4 margin_ud_10px">';
													html += '<select class="form-control" data-quest="' + preguntas[idx].pregunta_k + '">';
													html += '<option class="form-check-input selecciona" value="0" data-correcta="" data-rc="" data-orden="">Seleccione una opción</option>';

													Object.keys(preguntas[idx].respuestas).forEach(function (idx_respuesta_ordenar) {

														var is_checked_string = preguntas[idx].respuestas[idx_respuesta].correcta == contador ? ' selected ' : ' ';
														var is_hidden = preguntas[idx].respuestas[idx_respuesta].correcta == contador ? '  ' : 'hidden=""';
														html += '<option	class="form-check-input" ' + 'id="' + preguntas[idx].respuestas[idx_respuesta_ordenar].respuesta_k + '" ' + 'value="' + preguntas[idx].respuestas[idx_respuesta_ordenar].puntuacion + '" ' + 'data-correcta="' + preguntas[idx].respuestas[idx_respuesta_ordenar].correcta + '" ' + 'data-rc="' + preguntas[idx].respuestas[idx_respuesta].respuesta_correcta + '" ' + 'data-orden="' + preguntas[idx].respuestas[idx_respuesta_ordenar].orden + '"' + is_checked_string + is_hidden + '>' + contador++ + '</option>';
													});

													html += '</select>';
													html += '</div>';
													html += '</div>';
												});
											} else {

												//Se asume constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_CALIFICAR_NIVELES */

												Object.keys(preguntas[idx].respuestas).forEach(function (idx_respuesta) {

													var is_checked_string = preguntas[idx].respuestas[idx_respuesta].correcta == '1' ? ' checked ' : ' ';

													html += '<div 	class="form-check">';
													html += '<input	class="form-check-input" ' + 'type="radio"' + 'name="_' + idx + '" ' + 'id="' + preguntas[idx].respuestas[idx_respuesta].respuesta_k + '" ' + 'value="' + preguntas[idx].respuestas[idx_respuesta].puntuacion + '" ' + 'data-correcta="' + preguntas[idx].respuestas[idx_respuesta].correcta + '" ' + 'data-rc="' + preguntas[idx].respuestas[idx_respuesta].respuesta_correcta + '" ' + 'data-orden="' + preguntas[idx].respuestas[idx_respuesta].orden + '" ' + 'data-quest="' + preguntas[idx].pregunta_k + '" ' + 'data-pregunta_siguiente_k="' + preguntas[idx].respuestas[idx_respuesta].pregunta_siguiente_k + '"' + is_checked_string + '/>';
													html += '<label class="form-check-label" for="' + preguntas[idx].respuestas[idx_respuesta].respuesta_k + '">';
													html += preguntas[idx].respuestas[idx_respuesta].descripcion;
													html += '</label>';
													html += '</div>';
												});
											}

											html += '</div>';

											/*
            * Evaluamos que si la pregunta es obligatoria y que no haya nunguna respuesta previa.
            */

											if (preguntas[idx].pregunta_requerida == "1") {

												//$('#btn_nxt').attr('disabled',true);

											}
										}); /* End Object.keys(preguntas).forEach */

										var month = scope_setup_controler.getNameMonth(date.getMonth() + 1);
										var hours = date.getHours();
										var minutes = date.getMinutes();

										if (hours < 10) {
											hours = "0" + hours;
										}
										if (minutes < 10) {
											minutes = "0" + minutes;
										}

										Ember.$('#date_time_start').html("Inicio: " + date.getDate() + " de " + month + " de " + date.getFullYear() + " " + hours + ":" + minutes);
										Ember.$('#carouselQuestionsDiagnostic').html(html);
										Ember.$('.carousel-item:first-child').addClass('active');
										Ember.$('input:radio').change(function () {
											Ember.$('#btn_nxt').removeAttr('disabled');
										});
										Ember.$('input:checkbox').change(function () {
											Ember.$('#btn_nxt').removeAttr('disabled');
										});
										Ember.$('#loading').attr('hidden', true);

										//Habilitamos evento para seleccionar o deseleccionar todo
										Ember.$(".check-event").click(function () {

											// Si chequeamos opcion "todos"
											if (parseInt(Ember.$(this).data("seleccionatodos")) == 1) {
												var thisCheck = Ember.$(this);
												var contenedorRespuestas = thisCheck.closest(".carousel-item");
												var inputs = contenedorRespuestas.find(".check-event");
												inputs.each(function () {
													if (thisCheck.is(":checked") && thisCheck != Ember.$(this)) {
														if (parseInt(Ember.$(this).data("aplicaseleccion")) == 1) {
															Ember.$(this).prop("checked", true);
														} else {
															Ember.$(this).prop("checked", false);
														}
													} else {
														Ember.$(this).prop("checked", false);
													}
												});
											}
											// Si chequeamos opcion "todos"
											else if (parseInt(Ember.$(this).data("deseleccionatodos")) == 1) {

													var _thisCheck = Ember.$(this);
													var _contenedorRespuestas = _thisCheck.closest(".carousel-item");
													var _inputs = _contenedorRespuestas.find(".check-event");
													_inputs.each(function () {
														if (_thisCheck.is(":checked") && _thisCheck.attr("id") != Ember.$(this).attr("id")) {
															if (parseInt(Ember.$(this).data("aplicadeseleccion")) == 1) Ember.$(this).prop("checked", false);
														}
													});
												}
												//Cualquier otro caso
												else {
														var _thisCheck2 = Ember.$(this);
														var _contenedorRespuestas2 = _thisCheck2.closest(".carousel-item");
														var _inputs2 = _contenedorRespuestas2.find(".check-event");
														var contadorTodos = 0;
														var contadorChecks = 0;
														_inputs2.each(function () {
															if (parseInt(Ember.$(this).data("seleccionatodos")) == 1 || parseInt(Ember.$(this).data("deseleccionatodos")) == 1) {
																Ember.$(this).prop("checked", false);
															} else {
																contadorTodos++;
																if (Ember.$(this).is(":checked")) contadorChecks++;
															}
														});
														// Si se chequean todos los inputs excepto ninguno, chequeamos la opcion todos
														if (contadorTodos == contadorChecks) {
															_inputs2.each(function () {
																if (parseInt(Ember.$(this).data("seleccionatodos")) == 1) Ember.$(this).prop("checked", true);
															});
														}
													}
										});
										//var selectoptions = {};

										Ember.$('.carousel-item select:not(.not-select)').on('change', function (e) {

											var selectedText = Ember.$(this).find('option:selected').text().trim();
											var selectedOption = Ember.$(this).find('option:selected');
											var cbquest = Ember.$(selectedOption).parent();

											if (!self.selectoptions[Ember.$('.carousel-item.active').attr('id')]) {
												self.selectoptions[Ember.$('.carousel-item.active').attr('id')] = [];
											}

											self.selectoptions[Ember.$('.carousel-item.active').attr('id')][Ember.$(".carousel-item.active select").index(cbquest)] = selectedText;
											Ember.$(".carousel-item.active select option").prop('hidden', false);
											Object.keys(self.selectoptions[Ember.$('.carousel-item.active').attr('id')]).forEach(function (key) {

												Ember.$(".carousel-item.active select option:contains('" + self.selectoptions[Ember.$('.carousel-item.active').attr('id')][key] + "')").prop('hidden', true);
											});
										}); /* $('.carousel-item select:not(.not-select)').on('change' */

										Ember.$('#btn_rst').attr('hidden', false);

										var indexOfPositionLastResponse = scope_setup_controler.loadQuestionsSequence();

										Ember.$('#carouselQuestionsDiagnostic').carousel(indexOfPositionLastResponse);
										Ember.$('#contadorPreguntas').html('Pregunta ' + (indexOfPositionLastResponse + 1) + ' de ' + window.evaluacionDinamica.preguntas.length);

										/*
           * Evaluación si es la primer pregunta
           */

										if (indexOfPositionLastResponse == 0) {
											Ember.$('#btn_prv').attr('hidden', true);
										} else {
											Ember.$('#btn_prv').attr('hidden', false);
										}

										/*
           * Evaluación si es la última pregunta
           */

										if (indexOfPositionLastResponse + 1 == window.evaluacionDinamica.preguntas.length) {

											Ember.$('#btn_nxt').attr('hidden', true);
											Ember.$('#btn_end').removeAttr('hidden');
										} else {

											Ember.$('#btn_nxt').removeAttr('hidden');
											Ember.$('#btn_nxt').attr('disabled', false);
											Ember.$('#btn_end').attr('hidden', true);
										}
									},
									/* End success */

									error: function error(e) {

										Ember.$('#loading').attr('hidden', true);
										Ember.$('#carouselQuestionsDiagnostic').html('Estámos teniendo problemas técnicos.');
									} /* End error */

								});
							}
						},
						/* End success */

						error: function error(e) {

							console.log(e);
						}
					});
				},
				error: function error(e) {

					console.log(e);
				}
			}); /* End $.ajax */
		},

		/*
   *
   *
   *
   */

		actions: {

			/**
    * goBack - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis sapien.
    *
    * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
    * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
    * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
    * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
    * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
    *
    *
    * @author
    *
    * @date
    * @param   void
    * @return  void
    */

			goBack: function goBack() {
				history.back();
			},


			/**
    * prev_qs - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis sapien.
    *
    * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
    * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
    * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
    * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
    * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
    *
    *
    * @author
    *
    * @date
    * @param   void
    * @return  void
    */

			prev_qs: function prev_qs() {

				/*
     * Tomar elementos del DOM
     */

				var array = Ember.$('#contadorPreguntas').text().split(' ');
				var currentQuestion = array[1];
				var total = array[3];
				var prevQuestion = parseInt(currentQuestion) - 1;
				var self = this;
				/* Ocultamos el botón de finalizar evaluación */
				Ember.$('#btn_end').attr('hidden', true);

				if (window.lastArray.length > 1) {
					/* Sí hay al menos una pregunta en la pila */
					Ember.$('#btn_prv').removeAttr('hidden');
				} else {
					/*Solo existe la última pregunta en la pila*/
					Ember.$('#btn_prv').attr('hidden', true);
				}

				/* Desplazar carrusel y actualizar info */

				var record_poped = this.arrPreguntasPrev.pop();
				var last_array_record_poped = JSON.parse(window.lastArray.pop());

				/*
     *	TODO:	Trabajar en 'borrar las respuestas' de la pregunta actual. antes de 'hacer el brinco'a la pregunta
     *			anterior.
     */

				this.resetQuestion();

				/*
     *	Teniendo el registro correspondiente a la anterior pregunta, procedemos a buscar su ubicación
     *	en el arreglo de preguntas y 'hacer un brico' a esta ubicación
     */

				var prev_slide_c = parseInt(Ember.$('.carousel-item[data-pregunta="' + last_array_record_poped.pregunta_k + '"]').attr('id'));
				Ember.$('#carouselQuestionsDiagnostic').carousel(prev_slide_c);
				Ember.$('#contadorPreguntas').html('Pregunta ' + (prev_slide_c + 1) + ' de ' + total);

				/*
     *  Depeniendo de la pregunta siguiente (ahora pregunta actual) tiene una respuesta seleccionada, se habilita
     *  o deshabilita el botón de pŕegunra sigueinte.
     */

				if (parseInt(window.evaluacionDinamica.preguntas[prev_slide_c].pregunta_requerida) == 1) {

					Ember.$('#btn_nxt').removeAttr('hidden');

					if (this.hasAnswerTheQuestion()) {
						Ember.$('#btn_nxt').attr('disabled', true);
					} else {
						Ember.$('#btn_nxt').attr('disabled', false);
					}
				} else {

					Ember.$('#btn_nxt').attr('disabled', false);
				}

				self.send('changePrevCuestion', self);
			},


			/**
    * continuar - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis sapien.
    *
    * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
    * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
    * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
    * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
    * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
    *
    *
    * @author
    *
    * @date
    * @param   void
    * @return  void
    */

			continuar: function continuar() {

				Ember.$('#btn_nxt').removeAttr('hidden');
				Ember.$('#btn_nxt').removeAttr('disabled');
				Ember.$('#btn_end').attr('hidden', true);
				Ember.$('#modal_finalizar').modal('hide');

				window.lastArray.pop();
			},


			/**
    * next_qs - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis sapien.
    *
    * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
    * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
    * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
    * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
    * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
    *
    *
    * @author
    *
    * @date
    * @param   void
    * @return  void
    */

			next_qs: function next_qs() {

				/*  Tomar elementos del DOM */
				var array = Ember.$('#contadorPreguntas').text().split(' '); // Separar cadena en Strings
				var currentQuestion = array[1];
				var total = array[3];
				var nextQuestion = parseInt(currentQuestion) + 1;

				var tipo_pregunta_k = Ember.$('.carousel-item.active h2').data('tipo');
				var self = this;
				switch (tipo_pregunta_k) {

					case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_SI_NO:
						this.pregunta_respuesta_si_no(true, false);
						break;

					case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_UNICA:
						this.pregunta_unica(true, false);
						break;

					case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_MULTIPLE:
						this.pregunta_multiple(true, false);
						break;

					case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_LISTA_DESPLEGABLE:
						if (Ember.$('.carousel-item.active select option:selected').val() == '') {
							return;
						}
						this.pregunta_lista_desplegable(true, false);
						break;

					case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_RELACIONAR_COLUMNAS:
						this.pregunta_relacion(true, false);
						break;

					case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_PORCENTAJE:
						this.pregunta_porcentaje(true, false);
						break;

					case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_PUNTUACION:
						this.pregunta_puntuacion(true, false);
						break;

					case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_ABIERTA:
						this.pregunta_abierta(true, false);
						break;

					case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_ORDENAR_RESPUESTA:
						var valor = [];
						var errorRespuesta = false;

						Ember.$('.carousel-item.active select option:selected').each(function () {
							if (valor.indexOf(Ember.$(this).text().trim()) != -1 || Ember.$(this).val() == 0) {
								errorRespuesta = true;
							}
							valor.push(Ember.$(this).text().trim());
						});

						if (errorRespuesta) {
							var paramModal = { titulo: "Error", mensaje: "Por favor seleccione una respuesta de cada elemento para continuar" };
							window.showModal(paramModal);
							return;
						}
						this.pregunta_ordenar_respuesta(true, false);
						break;

					case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_CALIFICAR_NIVELES:
						this.pregunta_calificar_niveles(true, false);
						break;

					default:
						/* Se solicita un nuevo tipo diferente de pregunta que no podemos gestioanr adecuadamente */
						break;

				}

				//Cambiar controles
				Ember.$('#btn_prv').removeAttr('hidden');

				if (nextQuestion == total) {

					Ember.$('#btn_nxt').attr('hidden', true);
					Ember.$('#btn_end').removeAttr('hidden');
				} else {

					Ember.$('#btn_nxt').removeAttr('hidden');
					Ember.$('#btn_nxt').attr('disabled', true);
					Ember.$('#btn_end').attr('hidden', true);
				}

				Ember.$('input:radio').change(function () {
					Ember.$('#btn_nxt').removeAttr('disabled');
				});
				Ember.$('input:checkbox').change(function () {
					Ember.$('#btn_nxt').removeAttr('disabled');
				});

				Ember.$('select').each(function () {

					Ember.$(this).change(function () {
						if (Ember.$(this).val() !== '') {
							Ember.$('#btn_nxt').prop("disabled", false);
						}
					});
				}); /* $('select').each */

				Ember.$('textarea').each(function () {

					Ember.$(this).on('keyup mouseup change', function () {
						if (Ember.$(this).val() !== '' && Ember.$(this).val() != 0) {
							Ember.$('#btn_nxt').prop("disabled", false);
						} else {
							Ember.$('#btn_nxt').prop("disabled", true);
						}
					});
				}); /* End $('textarea').each */

				Ember.$('input[type=number]').each(function () {

					Ember.$(this).on('keyup mouseup change', function () {

						var pass = 0;

						Ember.$('input[type=number]').each(function () {
							if (Ember.$(this).val() !== '' && Ember.$(this).val() != 0 && parseInt(Ember.$(this).val()) <= parseInt(Ember.$(this).attr('max')) && parseInt(Ember.$(this).val()) >= parseInt(Ember.$(this).attr('min'))) {
								pass++;
							}
						});

						if (pass == Ember.$('input[type=number]').length) {
							Ember.$('#btn_nxt').prop("disabled", false);
						} else {
							Ember.$('#btn_nxt').prop("disabled", true);
						}
					});
				}); /* End $('input[type=number]').each */

				var psc_k = Ember.$('.carousel-item.active').data('psc');
				var psi_k = Ember.$('.carousel-item.active').data('psi');
				var respuesta_correcta = Ember.$('.carousel-item.active input:checked').data('rc');

				this.arrPreguntasPrev.push(Ember.$('.carousel-item.active').attr('id'));

				// Desplazar carrusel y actualizar info
				if (psc_k != null || psi_k != null) {

					if (tipo_pregunta_k == 1) {

						var _pregunta_siguiente_k = Ember.$('.carousel-item.active input:checked').data('pregunta_siguiente_k');

						/*
       *	Aqui Validar si el -1 para Finalizar
       */

						if (_pregunta_siguiente_k == '-1') {

							Ember.$('#modal_finalizar').modal({
								backdrop: 'static',
								keyboard: false
							});

							Ember.$('#btn_prv').prop("disabled", true);
						} else {

							var next_slide_c = parseInt(Ember.$('.carousel-item[data-pregunta="' + _pregunta_siguiente_k + '"]').attr('id'));

							Ember.$('#contadorPreguntas').html('Pregunta ' + (next_slide_c + 1) + ' de ' + total);

							this.arrPreguntasPrev.push(Ember.$('.carousel-item.active').attr('id'));

							Ember.$('#carouselQuestionsDiagnostic').carousel(next_slide_c);
						}
					} else if (tipo_pregunta_k == 2) {

						var _pregunta_siguiente_k2 = Ember.$('.carousel-item.active input:checked').data('pregunta_siguiente_k');

						//Aqui Validar si el -1 para Finalizar
						//************************************

						if (_pregunta_siguiente_k2 == '-1') {

							Ember.$('#modal_finalizar').modal({
								backdrop: 'static',
								keyboard: false
							});
							Ember.$('#btn_prv').prop("disabled", true);
						} else {

							var next_slide_c = parseInt(Ember.$('.carousel-item[data-pregunta="' + _pregunta_siguiente_k2 + '"]').attr('id'));
							Ember.$('#contadorPreguntas').html('Pregunta ' + (next_slide_c + 1) + ' de ' + total);
							this.arrPreguntasPrev.push(Ember.$('.carousel-item.active').attr('id'));
							Ember.$('#carouselQuestionsDiagnostic').carousel(next_slide_c);
						}
					} else if (tipo_pregunta_k == 3) {

						var respuesta_correcta = Ember.$('.carousel-item.active input:checked').data('rc'); //$('.carousel-item.active input[name=_' + (parseInt(currentQuestion) - 1) + ']:checked').data('rc')
						if (respuesta_correcta == "1") {
							var pregunta_siguiente_k = psc_k;
							if (pregunta_siguiente_k == '-1') {
								Ember.$('#modal_finalizar').modal({
									backdrop: 'static',
									keyboard: false
								});
								//$('#btn_prv').prop("disabled", true);
							} else {
								var next_slide_c = parseInt(Ember.$('.carousel-item[data-pregunta="' + psc_k + '"]').attr('id'));
								Ember.$('#contadorPreguntas').html('Pregunta ' + (next_slide_c + 1) + ' de ' + total);
								this.arrPreguntasPrev.push(Ember.$('.carousel-item.active').attr('id'));
								Ember.$('#carouselQuestionsDiagnostic').carousel(next_slide_c);
							}
						} else {

							if (pregunta_siguiente_k == '-1') {
								Ember.$('#modal_finalizar').modal({
									backdrop: 'static',
									keyboard: false
								});
								//$('#btn_prv').prop("disabled", true);
							} else {
								var next_slide_i = parseInt(Ember.$('.carousel-item[data-pregunta="' + psi_k + '"]').attr('id'));
								Ember.$('#contadorPreguntas').html('Pregunta ' + (next_slide_i + 1) + ' de ' + total);
								this.arrPreguntasPrev.push(Ember.$('.carousel-item.active').attr('id'));
								Ember.$('#carouselQuestionsDiagnostic').carousel(next_slide_i);
							}
						}
					} else if (tipo_pregunta_k == 4) {

						var respuesta_correcta = Ember.$('.carousel-item.active select option:selected').data('rc');

						if (respuesta_correcta == "1") {

							pregunta_siguiente_k = psc_k;

							if (pregunta_siguiente_k == '-1') {
								Ember.$('#modal_finalizar').modal({
									backdrop: 'static',
									keyboard: false
								});
							} else {
								var next_slide_c = parseInt(Ember.$('.carousel-item[data-pregunta="' + psc_k + '"]').attr('id'));
								Ember.$('#contadorPreguntas').html('Pregunta ' + (next_slide_c + 1) + ' de ' + total);
								this.arrPreguntasPrev.push(Ember.$('.carousel-item.active').attr('id'));
								Ember.$('#carouselQuestionsDiagnostic').carousel(next_slide_c);
							}
						} else {

							pregunta_siguiente_k = psi_k;

							if (pregunta_siguiente_k == '-1') {

								Ember.$('#modal_finalizar').modal({
									backdrop: 'static',
									keyboard: false
								});
							} else {

								var next_slide_i = parseInt(Ember.$('.carousel-item[data-pregunta="' + psi_k + '"]').attr('id'));
								Ember.$('#contadorPreguntas').html('Pregunta ' + (next_slide_i + 1) + ' de ' + total);
								this.arrPreguntasPrev.push(Ember.$('.carousel-item.active').attr('id'));
								Ember.$('#carouselQuestionsDiagnostic').carousel(next_slide_i);
							}
						}
					} else if (tipo_pregunta_k == 5) {
						var _respuesta_correcta = '1';
						Ember.$('.carousel-item.active select').each(function () {
							if (Ember.$(this).data('rc') != Ember.$(this).find('option:selected').val()) {
								pass = '0';
							}
						});
						if (_respuesta_correcta == '1') {
							pregunta_siguiente_k = psc_k;
							if (pregunta_siguiente_k == '-1') {
								if (pregunta_siguiente_k == '-1') {
									Ember.$('#modal_finalizar').modal({
										backdrop: 'static',
										keyboard: false
									});
									//$('#btn_prv').prop("disabled", true);
								} else {
									Ember.$('#btn_nxt').removeAttr('disabled');
									return;
								}
							} else {
								var next_slide_c = parseInt(Ember.$('.carousel-item[data-pregunta="' + psc_k + '"]').attr('id'));
								Ember.$('#contadorPreguntas').html('Pregunta ' + (next_slide_c + 1) + ' de ' + total);
								this.arrPreguntasPrev.push(Ember.$('.carousel-item.active').attr('id'));
								Ember.$('#carouselQuestionsDiagnostic').carousel(next_slide_c);
							}
						} else {

							pregunta_siguiente_k = psi_k;

							if (pregunta_siguiente_k == '-1') {
								Ember.$('#modal_finalizar').modal({
									backdrop: 'static',
									keyboard: false
								});
							} else {
								var next_slide_i = parseInt(Ember.$('.carousel-item[data-pregunta="' + psi_k + '"]').attr('id'));
								Ember.$('#contadorPreguntas').html('Pregunta ' + (next_slide_i + 1) + ' de ' + total);
								this.arrPreguntasPrev.push(Ember.$('.carousel-item.active').attr('id'));
								Ember.$('#carouselQuestionsDiagnostic').carousel(next_slide_i);
							}
						}
					} else if (tipo_pregunta_k == 9) {

						var respuesta_correcta = Ember.$('.carousel-item.active input:checked').data('rc'); //$('.carousel-item.active input[name=_' + (parseInt(currentQuestion) - 1) + ']:checked').data('rc')
						if (respuesta_correcta == "1") {
							pregunta_siguiente_k = psc_k;
							if (pregunta_siguiente_k == '-1') {
								Ember.$('#modal_finalizar').modal({
									backdrop: 'static',
									keyboard: false
								});
								//$('#btn_prv').prop("disabled", true);
							} else {
								var next_slide_c = parseInt(Ember.$('.carousel-item[data-pregunta="' + psc_k + '"]').attr('id'));
								Ember.$('#contadorPreguntas').html('Pregunta ' + (next_slide_c + 1) + ' de ' + total);
								this.arrPreguntasPrev.push(Ember.$('.carousel-item.active').attr('id'));
								Ember.$('#carouselQuestionsDiagnostic').carousel(next_slide_c);
							}
						} else {
							pregunta_siguiente_k = psi_k;
							if (pregunta_siguiente_k == '-1') {
								Ember.$('#modal_finalizar').modal({
									backdrop: 'static',
									keyboard: false
								});
								//$('#btn_prv').prop("disabled", true);
							} else {
								var next_slide_i = parseInt(Ember.$('.carousel-item[data-pregunta="' + psi_k + '"]').attr('id'));
								Ember.$('#contadorPreguntas').html('Pregunta ' + (next_slide_i + 1) + ' de ' + total);
								this.arrPreguntasPrev.push(Ember.$('.carousel-item.active').attr('id'));
								Ember.$('#carouselQuestionsDiagnostic').carousel(next_slide_i);
							}
						}
					} else {

						//Se asume que es 10
						var _pregunta_siguiente_k3 = Ember.$('.carousel-item.active input:checked').data('pregunta_siguiente_k');

						if (_pregunta_siguiente_k3 == '-1') {

							Ember.$('#modal_finalizar').modal({
								backdrop: 'static',
								keyboard: false
							});
						} else {

							var next_slide_c = parseInt(Ember.$('.carousel-item[data-pregunta="' + _pregunta_siguiente_k3 + '"]').attr('id'));
							Ember.$('#contadorPreguntas').html('Pregunta ' + (next_slide_c + 1) + ' de ' + total);
							this.arrPreguntasPrev.push(Ember.$('.carousel-item.active').attr('id'));
							Ember.$('#carouselQuestionsDiagnostic').carousel(next_slide_c);
						}
					}
				} else {

					Ember.$('#contadorPreguntas').html('Pregunta ' + nextQuestion + ' de ' + total);
					Ember.$('#carouselQuestionsDiagnostic').carousel('next');
					self.send('changeNextCuestion', self);
				}
			},
			changeNextCuestion: function changeNextCuestion(self) {

				var next_tipo_pregunta_k = Ember.$('.carousel-item.active h2').data('tipo');

				//Detectamos el cambio de slide y obtenemos el tipo pregunta para validaciones
				switch (next_tipo_pregunta_k) {
					case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_ORDENAR_RESPUESTA:
						Ember.$('.carousel-item.active select').each(function () {
							var options = Ember.$(this).find("option");
							self.selectoptions[Ember.$('.carousel-item.active').attr('id')] = [];
							Ember.$.each(options, function () {
								Ember.$(this).show().removeAttr("hidden");
							});
						});
						break;
				}
			},
			changePrevCuestion: function changePrevCuestion(self) {

				var next_tipo_pregunta_k = Ember.$('.carousel-item.active h2').data('tipo');

				//Detectamos el cambio de slide y obtenemos el tipo pregunta para validaciones
				switch (next_tipo_pregunta_k) {
					case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_ORDENAR_RESPUESTA:
						Ember.$('.carousel-item.active select').each(function () {
							var options = Ember.$(this).find("option");
							if (!self.selectoptions[Ember.$('.carousel-item.active').attr('id')]) {
								self.selectoptions[Ember.$('.carousel-item.active').attr('id')] = [];
							}

							Ember.$.each(options, function () {
								if (!Ember.$(this).is(":selected")) {
									if (Ember.$(this).hasClass("selecciona")) Ember.$(this).removeAttr("hidden");else Ember.$(this).attr("hidden", true);
								} else {

									var selectedOption = Ember.$(this);
									var selectedText = Ember.$(this).text().trim();
									var cbquest = Ember.$(selectedOption).parent();
									self.selectoptions[Ember.$('.carousel-item.active').attr('id')][Ember.$(".carousel-item.active select").index(cbquest)] = selectedText;
								}
							});
						});
						break;
				}
			},


			/**
    * callback - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis sapien.
    *
    * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
    * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
    * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
    * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
    * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
    *
    *
    * @author
    *
    * @date
    * @param   void
    * @return  void
    */

			callback: function callback(evaluacion_usuario_k, usuario_k) {

				Ember.$.ajax({
					url: constant.APIURL + 'usuario/datospersonales/',
					type: 'POST',
					xhrFields: {
						withCredentials: true
					},
					contentType: 'application/x-www-form-urlencoded',
					success: function success(r) {

						var usuario = JSON.parse(r);

						Ember.$('#loading').attr('hidden', false);
						Ember.$('#loading').fadeOut('slow');

						Ember.$.ajax({
							url: constant.APIURL + 'evaluacionesdinamicas/evaluaciondinamica/finalizaraplicar',
							type: 'POST',
							xhrFields: {
								withCredentials: true
							},
							contentType: 'application/x-www-form-urlencoded',
							data: 'evaluacion_usuario_k=' + evaluacion_usuario_k + '&usuario_k=' + usuario_k + '&preguntas=[' + window.lastArray + ']',
							success: function success(response) {
								window.lastArray = [];
							},
							error: function error(e) {
								console.error(e);
								Ember.$('#carouselQuestionsDiagnostic').html('<center><h2>Oops los sentimos tenemos algunos problemas</h2></center>');
							}
						});
					},
					error: function error(e) {
						console.log(e);
					}
				});

				Ember.$('#btn_end').attr('hidden', true);
				Ember.$('#btn_prv').attr('hidden', true);
			},


			/**
    * finalizar - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis sapien.
    *
    * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
    * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
    * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
    * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
    * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
    *
    *
    * @author
    *
    * @date
    * @param   void
    * @return  void
    */

			finalizar: function finalizar(op) {

				/* Tomare elementos del DOM */
				var evaluacion_usuario_k = sessionStorage.getItem('evaluacion_usuario_k');

				if (!op) {

					var tipo_pregunta_k = Ember.$('.carousel-item.active h2').data('tipo');

					switch (tipo_pregunta_k) {

						case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_SI_NO:
							this.pregunta_respuesta_si_no(true, true);
							break;

						case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_UNICA:
							this.pregunta_unica(true, true);
							break;

						case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_MULTIPLE:
							this.pregunta_multiple(true, true);
							break;

						case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_LISTA_DESPLEGABLE:
							if (Ember.$('.carousel-item.active select option:selected').val() == '') {
								return;
							}
							this.pregunta_lista_desplegable(true, true);
							break;

						case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_RELACIONAR_COLUMNAS:
							this.pregunta_relacion(true, true);
							break;

						case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_PORCENTAJE:
							this.pregunta_porcentaje(true, true);
							break;

						case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_PUNTUACION:
							this.pregunta_puntuacion(true, true);
							break;

						case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_ABIERTA:
							this.pregunta_abierta(true, true);
							break;

						case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_ORDENAR_RESPUESTA:
							var valor = [];
							var errorRespuesta = false;

							Ember.$('.carousel-item.active select option:selected').each(function () {

								if (valor.indexOf(Ember.$(this).text().trim()) != -1 || Ember.$(this).val() == 0) {
									errorRespuesta = true;
								}

								valor.push(Ember.$(this).text().trim());
							});

							if (errorRespuesta) {
								var paramModal = { titulo: "Error", mensaje: "Por favor seleccione una respuesta de cada elemento para continuar" };
								window.showModal(paramModal);
								return;
							};
							this.pregunta_ordenar_respuesta(true, true);
							break;

						case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_CALIFICAR_NIVELES:
							this.pregunta_calificar_niveles(true, true);
							break;

						default:
							break;
					}
				} else {

					var usuario_k = this.session.userData.usuario_k;

					Ember.$.ajax({

						url: constant.APIURL + 'evaluacionesdinamicas/evaluaciondinamica/finalizaraplicar',
						type: 'POST',
						xhrFields: {
							withCredentials: true
						},
						contentType: 'application/x-www-form-urlencoded',
						data: 'evaluacion_usuario_k=' + evaluacion_usuario_k + '&usuario_k=' + usuario_k + '&preguntas=[' + window.lastArray + ']',
						success: function success(response) {

							window.lastArray = [];
						},
						error: function error(e) {
							console.error(e);
							//$('#carouselQuestionsDiagnostic').html('<center><h2>Oops los sentimos tenemos algunos problemas</h2></center>');
						}
					});

					var self = this;

					setTimeout(function () {
						self.send('regresar');
					}, 0);
				}
			},
			/* End finalizar */

			/**
    * regresar - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis sapien.
    *
    * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
    * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
    * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
    * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
    * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
    *
    *
    * @author
    *
    * @date
    * @param   void
    * @return  void
    */

			regresar: function regresar() {
				var url_page = new URLSearchParams(window.location.search);
				var descripcion = Ember.$('#titulo_evaluacion').text().trim();
				document.location.href = '/historico/' + url_page.get('evaluacion') + '?evaluacion=' + url_page.get('evaluacion') + '&descripcion=' + url_page.get("descripcion");
			}
		}, /* End actions */

		/**
   * pregunta_respuesta_si_no - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis sapien.
   *
   * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
   * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
   * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
   * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
   * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
   *
   *
   * @author
   *
   * @date
   * @param   void
   * @return  void
   */

		pregunta_respuesta_si_no: function pregunta_respuesta_si_no(apilar_pregunta, finalizar) {

			var array = Ember.$('#contadorPreguntas').text().split(' ');
			var currentQuestion = array[1];
			var evaluacion_usuario_k = sessionStorage.getItem('evaluacion_usuario_k');
			var pregunta_k = Ember.$('input[name=_' + (parseInt(currentQuestion) - 1) + ']').data('quest');
			array = [];
			var self = this;

			Ember.$('.carousel-item.active input').each(function () {

				var correcta = false;
				var respuesta = Ember.$(Ember.$(this).parent().children()[1]).text();
				var respuesta_k = Ember.$(this).attr('id');

				if (Ember.$(this).prop('checked') === true) {
					correcta = true;
					respuesta = Ember.$(Ember.$(this).parent().children()[1]).text();
				}

				array.push({
					'correcta': correcta,
					'orden': Ember.$(this).data('orden'),
					'respuesta': respuesta,
					'respuesta_k': parseInt(respuesta_k)
				});
			});

			var data = {
				'pregunta_k': String(pregunta_k),
				'tipo_pregunta_k': "1",
				'respuestas': array
			};

			Ember.$.ajax({
				url: constant.APIURL + 'usuario/datospersonales/',
				type: 'POST',
				xhrFields: { withCredentials: true },
				contentType: 'application/x-www-form-urlencoded',
				success: function success(r) {

					var usuario = JSON.parse(r);

					Ember.$.ajax({
						url: constant.APIURL + 'evaluacionesdinamicas/evaluaciondinamica/guardarPregunta',
						type: 'POST',
						xhrFields: {
							withCredentials: true
						},
						contentType: 'application/x-www-form-urlencoded',
						data: 'evaluacion_usuario_k=' + evaluacion_usuario_k + '&usuario_k=' + usuario.usuario_k + '&pregunta_k=' + pregunta_k + '&pregunta=' + JSON.stringify(data),
						success: function success(response) {

							if (apilar_pregunta) {
								window.lastArray.push(JSON.stringify(data));
							}

							if (finalizar) {
								self.send('callback', evaluacion_usuario_k, usuario.usuario_k, pregunta_k);
							}
						},
						error: function error(e) {
							console.error(e);
						}
					});
				},
				error: function error(e) {
					console.log(e);
				}
			});
		},


		/**
   * pregunta_unica - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis sapien.
   *
   * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
   * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
   * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
   * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
   * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
   *
   *
   * @author
   *
   * @date
   * @param   void
   * @return  void
   */

		pregunta_unica: function pregunta_unica(apilar_pregunta, finalizar) {

			var currentQuestion = this.getPositionCurrentQuestion();
			var self = this;

			/* Obtener y enviar pregunta actual */

			var evaluacion_usuario_k = sessionStorage.getItem('evaluacion_usuario_k');
			var pregunta_k = Ember.$('input[name=_' + (parseInt(currentQuestion) - 1) + ']', '.carousel-item').data('quest');
			var array = [];

			Ember.$('.carousel-item.active input').each(function () {
				var correcta = false;
				var respuesta = Ember.$(Ember.$(this).parent().children()[1]).text();
				var respuesta_k = Ember.$(this).attr('id');

				if (Ember.$(this).prop('checked') === true) {
					correcta = true;
					respuesta = Ember.$(Ember.$(this).parent().children()[1]).text();
				}

				array.push({ 'correcta': correcta, 'orden': Ember.$(this).data('orden'), 'respuesta': respuesta, 'respuesta_k': parseInt(respuesta_k) });
			});

			var data = {
				'pregunta_k': String(pregunta_k),
				'tipo_pregunta_k': "2",
				'respuestas': array
			};

			Ember.$.ajax({
				url: constant.APIURL + 'usuario/datospersonales/',
				type: 'POST',
				xhrFields: {
					withCredentials: true
				},
				contentType: 'application/x-www-form-urlencoded',
				success: function success(r) {

					var usuario = JSON.parse(r);

					Ember.$.ajax({
						url: constant.APIURL + 'evaluacionesdinamicas/evaluaciondinamica/guardarPregunta',
						type: 'POST',
						xhrFields: {
							withCredentials: true
						},
						contentType: 'application/x-www-form-urlencoded',
						data: 'evaluacion_usuario_k=' + evaluacion_usuario_k + '&usuario_k=' + usuario.usuario_k + '&pregunta_k=' + pregunta_k + '&pregunta=' + JSON.stringify(data),
						success: function success(response) {

							if (apilar_pregunta) {

								window.lastArray.push(JSON.stringify(data));
							}

							if (finalizar) {

								self.send('callback', evaluacion_usuario_k, usuario.usuario_k, pregunta_k);
							}
						},
						error: function error(e) {

							console.error(e);
						}
					});
				},
				error: function error(e) {

					console.error(e);
				}
			});
		},


		/**
   * pregunta_multiple - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis sapien.
   *
   * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
   * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
   * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
   * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
   * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
   *
   *
   * @author
   *
   * @date
   * @param   void
   * @return  void
   */

		pregunta_multiple: function pregunta_multiple(apilar_pregunta, finalizar) {

			/* Obtener elementos del DOM */
			var array = Ember.$('#contadorPreguntas').text().split(' ');
			var self = this;

			/* Obtener y enviar pregunta actual */
			var evaluacion_usuario_k = sessionStorage.getItem('evaluacion_usuario_k');
			var pregunta_k = Ember.$('.carousel-item.active input').first().data('quest');

			array = [];

			Ember.$('.carousel-item.active input').each(function () {

				var correcta = false;
				var respuesta = Ember.$(Ember.$(this).parent().children()[1]).text();
				var respuesta_k = Ember.$(this).attr('id');

				if (Ember.$(this).is(':checked')) {
					correcta = true;
					respuesta = Ember.$(Ember.$(this).parent().children()[1]).text();
				}

				array.push({ 'correcta': correcta, 'orden': Ember.$(this).data('orden'), 'respuesta': respuesta, 'respuesta_k': parseInt(respuesta_k) });
			});

			var data = {
				'pregunta_k': String(pregunta_k),
				'tipo_pregunta_k': "3",
				'respuestas': array
			};

			Ember.$.ajax({
				url: constant.APIURL + 'usuario/datospersonales/',
				type: 'POST',
				xhrFields: {
					withCredentials: true
				},
				contentType: 'application/x-www-form-urlencoded',
				success: function success(r) {
					var usuario = JSON.parse(r);
					Ember.$.ajax({
						url: constant.APIURL + 'evaluacionesdinamicas/evaluaciondinamica/guardarPregunta',
						type: 'POST',
						xhrFields: {
							withCredentials: true
						},
						contentType: 'application/x-www-form-urlencoded',
						data: 'evaluacion_usuario_k=' + evaluacion_usuario_k + '&usuario_k=' + usuario.usuario_k + '&pregunta_k=' + pregunta_k + '&pregunta=' + JSON.stringify(data),
						success: function success(response) {

							if (apilar_pregunta) {
								window.lastArray.push(JSON.stringify(data));
							}

							if (finalizar) {
								self.send('callback', evaluacion_usuario_k, usuario.usuario_k, pregunta_k);
							}
						},
						error: function error(e) {
							console.error(e);
						}
					});
				},
				error: function error(e) {
					console.error(e);
				}
			});
		},


		/**
   * pregunta_lista_desplegable - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis sapien.
   *
   * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
   * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
   * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
   * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
   * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
   *
   *
   * @author
   *
   * @date
   * @param   void
   * @return  void
   */

		pregunta_lista_desplegable: function pregunta_lista_desplegable(apilar_pregunta, finalizar) {

			var array = Ember.$('#contadorPreguntas').text().split(' ');
			var self = this;

			var evaluacion_usuario_k = sessionStorage.getItem('evaluacion_usuario_k');
			var pregunta_k = Ember.$('.carousel-item.active select').data('quest');

			array = [];

			Ember.$('.carousel-item.active select option').each(function () {

				if (Ember.$(this).val() != '') {

					var correcta = false;
					var respuesta = Ember.$(this).text();
					var respuesta_k = Ember.$(this).attr('id');

					if (Ember.$(this).is(':selected')) {
						correcta = true;
						respuesta = Ember.$(this).text();
					}

					array.push({
						'correcta': correcta,
						'orden': Ember.$(this).data('orden'),
						'respuesta': respuesta,
						'respuesta_k': parseInt(respuesta_k)
					});
				}
			});

			var data = {
				'pregunta_k': String(pregunta_k),
				'tipo_pregunta_k': "4",
				'respuestas': array
			};

			Ember.$.ajax({
				url: constant.APIURL + 'usuario/datospersonales/',
				type: 'POST',
				xhrFields: {
					withCredentials: true
				},
				contentType: 'application/x-www-form-urlencoded',
				success: function success(r) {
					var usuario = JSON.parse(r);
					Ember.$.ajax({
						url: constant.APIURL + 'evaluacionesdinamicas/evaluaciondinamica/guardarPregunta',
						type: 'POST',
						xhrFields: {
							withCredentials: true
						},
						contentType: 'application/x-www-form-urlencoded',
						data: 'evaluacion_usuario_k=' + evaluacion_usuario_k + '&usuario_k=' + usuario.usuario_k + '&pregunta_k=' + pregunta_k + '&pregunta=' + JSON.stringify(data),
						success: function success(response) {

							if (apilar_pregunta) {
								window.lastArray.push(JSON.stringify(data));
							}

							if (finalizar) {
								self.send('callback', evaluacion_usuario_k, usuario.usuario_k, pregunta_k);
							}
						},
						error: function error(e) {
							console.error(e);
						}
					});
				},
				error: function error(e) {
					console.error(e);
				}
			});
		},


		/**
   * pregunta_relacion - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis sapien.
   *
   * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
   * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
   * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
   * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
   * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
   *
   *
   * @author
   *
   * @date
   * @param   void
   * @return  void
   */

		pregunta_relacion: function pregunta_relacion(apilar_pregunta, finalizar) {

			var array = Ember.$('#contadorPreguntas').text().split(' ');
			var currentQuestion = array[1];
			var total = array[3];
			var nextQuestion = parseInt(currentQuestion) + 1;
			var self = this;

			var recurso_alumno_resultado_k = sessionStorage.getItem('recurso_alumno_resultado_k');
			var pregunta_k = Ember.$('.carousel-item.active ul').first().data('quest');
			array = [];

			Ember.$('.carousel-item.active select').each(function () {
				array.push({
					'correcta': Ember.$(this).attr('id'),
					'orden': Ember.$(this).data('orden'),
					'respuesta': Ember.$(this).find('option:selected').text(),
					'respuesta_k': Ember.$(this).val()
				});
			});

			var data = {
				'pregunta_k': pregunta_k,
				'tipo_pregunta_k': "5",
				'respuestas': array
			};

			var evaluacion_usuario_k = sessionStorage.getItem('evaluacion_usuario_k');

			Ember.$.ajax({
				url: constant.APIURL + 'usuario/datospersonales/',
				type: 'POST',
				xhrFields: {
					withCredentials: true
				},
				contentType: 'application/x-www-form-urlencoded',
				success: function success(r) {

					var usuario = JSON.parse(r);

					Ember.$.ajax({

						url: constant.APIURL + 'evaluacionesdinamicas/evaluaciondinamica/guardarPregunta',
						type: 'POST',
						xhrFields: {
							withCredentials: true
						},
						contentType: 'application/x-www-form-urlencoded',
						data: 'evaluacion_usuario_k=' + evaluacion_usuario_k + ' &usuario_k=' + usuario.usuario_k + ' &pregunta_k=' + pregunta_k + '&pregunta=' + JSON.stringify(data),
						success: function success(response) {

							if (apilar_pregunta) {
								window.lastArray.push(JSON.stringify(data));
							}

							if (finalizar) {
								self.send('callback', evaluacion_usuario_k, usuario.usuario_k, pregunta_k);
							}
						},
						error: function error(e) {
							console.error(e);
						}
					});
				},
				error: function error(e) {
					console.error(e);
				}
			});
		},


		/**
   * pregunta_porcentaje - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis sapien.
   *
   * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
   * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
   * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
   * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
   * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
   *
   *
   * @author
   *
   * @date
   * @param   void
   * @return  void
   */

		pregunta_porcentaje: function pregunta_porcentaje(apilar_pregunta, finalizar) {

			var array = Ember.$('#contadorPreguntas').text().split(' ');
			var currentQuestion = array[1];
			var self = this;

			/*
    * Obtener y enviar pregunta actual
    */

			var respuesta = c.val(); // Obtener el valor del checkbox selecionado
			var respuesta_k = Ember.$('input[name=_' + (parseInt(currentQuestion) - 1) + ']', '.carousel-item').attr('id'); // Obtener el ID del checkbox selecionado
			var correcto = respuesta;
			var evaluacion_usuario_k = sessionStorage.getItem('evaluacion_usuario_k');

			/*
    * Obtener llave de respuesta almacenado en data-quest del DOM
    */

			var pregunta_k = Ember.$('input[name=_' + (parseInt(currentQuestion) - 1) + ']', '.carousel-item').data('quest');
			var jsonRespuestas = { 'correcta': correcto, orden: parseInt(currentQuestion), 'respuesta': respuesta, 'respuesta_k': parseInt(respuesta_k) };
			array = [];

			/*
    * Almacenar repuesta en la casilla corespondiente al orden que deben de ser mandados
    */

			array.push(jsonRespuestas);

			var data = {
				'pregunta_k': String(pregunta_k),
				'tipo_pregunta_k': "6",
				'respuestas': array
			};

			Ember.$.ajax({
				url: constant.APIURL + 'usuario/datospersonales/',
				type: 'POST',
				xhrFields: {
					withCredentials: true
				},
				contentType: 'application/x-www-form-urlencoded',
				success: function success(r) {

					var usuario = JSON.parse(r);

					Ember.$.ajax({
						url: constant.APIURL + 'evaluacionesdinamicas/evaluaciondinamica/guardarPregunta',
						type: 'POST',
						xhrFields: {
							withCredentials: true
						},
						contentType: 'application/x-www-form-urlencoded',
						data: 'evaluacion_usuario_k=' + evaluacion_usuario_k + ' &usuario_k=' + usuario.usuario_k + '&pregunta_k=' + pregunta_k + '&pregunta=' + JSON.stringify(data),
						success: function success(response) {

							if (apilar_pregunta) {
								window.lastArray.push(JSON.stringify(data));
							}

							if (finalizar) {
								self.send('callback', evaluacion_usuario_k, usuario.usuario_k, pregunta_k);
							}
						},
						error: function error(e) {
							console.error(e);
						}
					});
				},
				error: function error(e) {
					console.error(e);
				}
			});
		},


		/**
   * pregunta_puntuacion - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis sapien.
   *
   * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
   * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
   * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
   * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
   * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
   *
   *
   * @author
   *
   * @date
   * @param   void
   * @return  void
   */

		pregunta_puntuacion: function pregunta_puntuacion(apilar_pregunta, finalizar) {

			/*
    * Obtener elementos del DOM
    */

			var array = Ember.$('#contadorPreguntas').text().split(' ');
			var currentQuestion = array[1];
			var self = this;
			array = [];
			var evaluacion_usuario_k = sessionStorage.getItem('evaluacion_usuario_k');
			var pregunta_k = Ember.$('.carousel-item.active select').data('quest');

			/*
    * Obtener y enviar pregunta actual
    */

			Ember.$('.carousel-item.active select').each(function () {
				array.push({
					'respuesta_k': parseInt(Ember.$(this).attr('id')),
					'correcta': Ember.$(this).val(),
					orden: Ember.$(this).data('orden'),
					'respuesta': Ember.$(this).parent().find('input[type="hidden"]').val()
				});
			});

			var data = { 'pregunta_k': String(pregunta_k), 'tipo_pregunta_k': "7", 'respuestas': array }; // Generar JSON para mandar en petición AJAX

			Ember.$.ajax({
				url: constant.APIURL + 'usuario/datospersonales/',
				type: 'POST',
				xhrFields: {
					withCredentials: true
				},
				contentType: 'application/x-www-form-urlencoded',
				success: function success(r) {
					var usuario = JSON.parse(r);
					Ember.$.ajax({
						url: constant.APIURL + 'evaluacionesdinamicas/evaluaciondinamica/guardarPregunta',
						type: 'POST',
						xhrFields: {
							withCredentials: true
						},
						contentType: 'application/x-www-form-urlencoded',
						data: 'evaluacion_usuario_k=' + evaluacion_usuario_k + '&usuario_k=' + usuario.usuario_k + '&pregunta_k=' + pregunta_k + '&pregunta=' + JSON.stringify(data),
						success: function success(response) {

							if (apilar_pregunta) {
								window.lastArray.push(JSON.stringify(data));
							}

							if (finalizar) {
								self.send('callback', evaluacion_usuario_k, usuario.usuario_k, pregunta_k);
							}
						},
						error: function error(e) {
							console.error(e);
						}
					});
				},
				error: function error(e) {
					console.error(e);
				}
			});
		},


		/**
   * pregunta_abierta - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis sapien.
   *
   * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
   * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
   * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
   * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
   * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
   *
   *
   * @author
   *
   * @date
   * @param   void
   * @return  void
   */

		pregunta_abierta: function pregunta_abierta(apilar_pregunta, finalizar) {

			/*
    * Recuperar parametros del DOM
    * Recuperar parametros del DOM
    */
			var array = Ember.$('#contadorPreguntas').text().split(' ');
			var currentQuestion = array[1];
			var self = this;

			/*
    * Obtener y enviar pregunta actual
    */
			var respuesta = 'abierta';
			var respuesta_k = Ember.$('textarea[name=_' + (parseInt(currentQuestion) - 1) + ']').attr('id'); // Obtener el ID del textarea
			var correcto = Ember.$('textarea[name=_' + (parseInt(currentQuestion) - 1) + ']').val();
			var orden = Ember.$('textarea[name=_' + (parseInt(currentQuestion) - 1) + ']').data('orden');
			var evaluacion_usuario_k = sessionStorage.getItem('evaluacion_usuario_k');

			/*
    * Obtener llave de respuesta almacenado en data-quest del DOM
    */
			var pregunta_k = Ember.$('textarea[name=_' + (parseInt(currentQuestion) - 1) + ']').data('quest');

			/*
    * generar JSON con la estructura para mandar al back end.
    */

			array = [];
			var jsonRespuestas = { 'correcta': correcto, orden: String(orden), 'respuesta': respuesta, 'respuesta_k': respuesta_k };
			array.push(jsonRespuestas); // Almacenar repuesta en la casilla corespondiente al orden que deben de ser mandados
			var data = { 'pregunta_k': String(pregunta_k), 'tipo_pregunta_k': "8", 'respuestas': array }; // Generar JSON para mandar en petición AJAX

			Ember.$.ajax({
				url: constant.APIURL + 'usuario/datospersonales/',
				type: 'POST',
				xhrFields: {
					withCredentials: true
				},
				contentType: 'application/x-www-form-urlencoded',
				success: function success(r) {
					var usuario = JSON.parse(r);
					Ember.$.ajax({
						url: constant.APIURL + 'evaluacionesdinamicas/evaluaciondinamica/guardarPregunta',
						type: 'POST',
						xhrFields: {
							withCredentials: true
						},
						contentType: 'application/x-www-form-urlencoded',
						data: 'evaluacion_usuario_k=' + evaluacion_usuario_k + '&usuario_k=' + usuario.usuario_k + '&pregunta_k=' + pregunta_k + '&pregunta=' + JSON.stringify(data),
						success: function success(response) {

							if (apilar_pregunta) {
								window.lastArray.push(JSON.stringify(data));
							}

							if (finalizar) {
								self.send('callback', evaluacion_usuario_k, usuario.usuario_k, pregunta_k);
							}
						},
						error: function error(e) {
							console.error(e);
						}
					});
				},
				error: function error(e) {
					console.error(e);
				}
			});
		},


		/**
   * pregunta_ordenar_respuesta - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis sapien.
   *
   * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
   * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
   * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
   * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
   * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
   *
   *
   * @author
   *
   * @date
   * @param   void
   * @return  void
   */

		pregunta_ordenar_respuesta: function pregunta_ordenar_respuesta(apilar_pregunta, finalizar) {

			var self = this;

			/* Obtener y enviar pregunta actual*/
			var evaluacion_usuario_k = sessionStorage.getItem('evaluacion_usuario_k');
			var pregunta_k = Ember.$('.carousel-item.active select').data('quest');
			var array = [];
			var arrObjetos = [];

			Ember.$('.carousel-item.active select option:selected').each(function () {
				arrObjetos.push({ valor: Ember.$(this).text().trim() });
			});

			Ember.$('.carousel-item.active label').each(function (idx) {
				arrObjetos[idx].orden = Ember.$(this).data('orden');
				arrObjetos[idx].respuesta_seleccionada = Ember.$(this).text().trim();
				arrObjetos[idx].respuesta_key = Ember.$(this).data('respuesta');
				array.push({
					'correcta': parseInt(arrObjetos[idx].valor),
					'orden': arrObjetos[idx].orden,
					'respuesta': arrObjetos[idx].respuesta_seleccionada,
					'respuesta_k': parseInt(arrObjetos[idx].respuesta_key)
				});
			});

			var data = {
				'pregunta_k': String(pregunta_k),
				'tipo_pregunta_k': String(constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_ORDENAR_RESPUESTA),
				'respuestas': array
			};

			Ember.$.ajax({
				url: constant.APIURL + 'usuario/datospersonales/',
				type: 'POST',
				xhrFields: {
					withCredentials: true
				},
				contentType: 'application/x-www-form-urlencoded',
				success: function success(r) {
					var usuario = JSON.parse(r);
					Ember.$.ajax({
						url: constant.APIURL + 'evaluacionesdinamicas/evaluaciondinamica/guardarPregunta',
						type: 'POST',
						xhrFields: { withCredentials: true },
						contentType: 'application/x-www-form-urlencoded',
						data: 'evaluacion_usuario_k=' + evaluacion_usuario_k + '&usuario_k=' + usuario.usuario_k + '&pregunta_k=' + pregunta_k + '&pregunta=' + JSON.stringify(data),
						success: function success(response) {

							if (apilar_pregunta) {
								window.lastArray.push(JSON.stringify(data));
							}

							if (finalizar) {
								self.send('callback', evaluacion_usuario_k, usuario.usuario_k, pregunta_k);
							}
						},
						error: function error(e) {
							console.error(e);
						}
					});
				},
				error: function error(e) {
					console.error(e);
				}
			});
		},


		/**
   * pregunta_calificar_niveles - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis sapien.
   *
   * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
   * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
   * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
   * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
   * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
   *
   *
   * @author
   *
   * @date
   * @param   void
   * @return  void
   */

		pregunta_calificar_niveles: function pregunta_calificar_niveles(apilar_pregunta, finalizar) {

			var currentQuestion = this.getPositionCurrentQuestion();
			var self = this;

			/* Obtener y enviar pregunta actual */
			var evaluacion_usuario_k = sessionStorage.getItem('evaluacion_usuario_k');
			var pregunta_k = Ember.$('input[name=_' + (parseInt(currentQuestion) - 1) + ']', '.carousel-item').data('quest');
			var array = [];

			Ember.$('.carousel-item.active input').each(function () {

				var correcta = false;
				var respuesta = Ember.$(Ember.$(this).parent().children()[1]).text();
				var respuesta_k = Ember.$(this).attr('id');

				if (Ember.$(this).is(':checked')) {
					correcta = true;
					respuesta = Ember.$(Ember.$(this).parent().children()[1]).text();
				}

				array.push({ 'correcta': correcta,
					'orden': Ember.$(this).data('orden'),
					'respuesta': respuesta,
					'respuesta_k': parseInt(respuesta_k)
				});
			});

			var data = {
				'pregunta_k': String(pregunta_k),
				'tipo_pregunta_k': "10",
				'respuestas': array
			};

			Ember.$.ajax({
				url: constant.APIURL + 'usuario/datospersonales/',
				type: 'POST',
				xhrFields: {
					withCredentials: true
				},
				contentType: 'application/x-www-form-urlencoded',
				success: function success(r) {

					var usuario = JSON.parse(r);

					Ember.$.ajax({

						url: constant.APIURL + 'evaluacionesdinamicas/evaluaciondinamica/guardarPregunta',
						type: 'POST',
						xhrFields: {
							withCredentials: true
						},
						contentType: 'application/x-www-form-urlencoded',
						data: 'evaluacion_usuario_k=' + evaluacion_usuario_k + '&usuario_k=' + usuario.usuario_k + '&pregunta_k=' + pregunta_k + '&pregunta=' + JSON.stringify(data),
						success: function success(response) {

							if (apilar_pregunta) {
								window.lastArray.push(JSON.stringify(data));
							}

							if (finalizar) {

								self.send('callback', evaluacion_usuario_k, usuario.usuario_k, pregunta_k);
							}
						},
						error: function error(e) {
							console.error(e);
						}
					});
				},
				error: function error(e) {
					console.error(e);
				}
			});
		},


		/**
   * getNameMonth - Función utilitaria. A partir de un nuermo de mes , retorna el nombre correspondiente.
   *
   * Función utilitaria. A partir de un nuermo de mes , retorna el nombre correspondiente.
   *
   *
   * @author  [YRM] Maurilio Cruz Morales
   *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *              Tel. celular (55) 6702 6252
   *
   * @date    2019-06-14
   * @param   int number_month Valor numerico entre 1 y 12, que indica la posición de un mes.
   * @return  string Nombre del mes indicado
   */

		resetQuestion: function resetQuestion() {

			var tipo_pregunta_pregunta_actual = this.getQuestionTypeFromCurrentQuestion();

			switch (tipo_pregunta_pregunta_actual) {

				/* [X] Checked */
				case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_SI_NO:
					this.resetQuestionTipoRespuestaSiNo();
					break;

				/* [X] Checked */
				case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_UNICA:
					this.resetQuestionTipoRespuestaUnica();
					break;

				/* [X] Checked */
				case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_MULTIPLE:
					this.resetQuestionTipoRespuestaMultiple();
					break;

				case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_LISTA_DESPLEGABLE:
					this.resetQuestionTipoRespuestaListaDesplegable();
					break;

				case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_RELACIONAR_COLUMNAS:
					this.resetQuestionTipoRespuestaRelacionarColumnas();
					break;

				case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_PORCENTAJE:
					this.resetQuestionTipoRespuestaPorcentaje();
					break;

				/* [X] Checked */
				case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_PUNTUACION:
					this.resetQuestionTipoRespuestaPuntuacion();
					break;

				/* [X] Checked */
				case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_ABIERTA:
					this.resetQuestionTipoRespuestaAbierta();
					break;

				/* [-] @todo:	Cuando se resetea la pregunta,
     *				aun se requiere que se los valores de los combos se restauren
     */
				case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_ORDENAR_RESPUESTA:
					this.resetQuestionTipoRespuestaOrdenarRespuesta();
					break;

				/* [X] Checked */
				case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_CALIFICAR_NIVELES:
					this.resetQuestionTipoRespuestaCalificarniveles();
					break;

				default:
					break;

			}
		},

		/**
   * hasAnswerTheQuestion - Evalua si la pregunta actual tiene o no una respuesta seleccionada por parte del usuario.
   *
   * Evalua si la pregunta actual tiene o no una respuesta seleccionada por parte del usuario.
   *
   *
   * @author  [YRM] Maurilio Cruz Morales
   *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *              Tel. celular (55) 6702 6252
   *
   * @date    2019-06-14
   * @param   void
   * @return  void
   */

		hasAnswerTheQuestion: function hasAnswerTheQuestion() {

			var tipo_pregunta_pregunta_actual = this.getQuestionTypeFromCurrentQuestion();

			switch (tipo_pregunta_pregunta_actual) {

				case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_SI_NO:
					this.hasAnswerTipoRespuestaSiNo();
					break;

				case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_UNICA:
					this.hasAnswerTipoRespuestaUnica();
					break;

				case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_MULTIPLE:
					this.hasAnswerTipoRespuestaMultiple();
					break;

				case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_LISTA_DESPLEGABLE:
					this.hasAnswerTipoListaDesplegable();
					break;

				case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_RELACIONAR_COLUMNAS:
					this.hasAnswerTipoRespuestaRelacionarColumnas();
					break;

				case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_PORCENTAJE:
					this.hasAnswerTipoRespuestaPorcentaje();
					break;

				case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_PUNTUACION:
					this.hasAnswerTipoRespuestaPuntuacion();
					break;

				case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_ABIERTA:
					this.hasAnswerTipoRespuestaAbierta();
					break;

				case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_ORDENAR_RESPUESTA:
					this.hasAnswerTipoRespuestaOrdenarRespuesta();
					break;

				case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_CALIFICAR_NIVELES:
					this.hasAnswerTipoRespuestaCalificarNiveles();
					break;

				default:
					break;

			}
		},

		/**
   * hasAnswerTipoRespuestaSiNo - Evalua si la pregunta de tipo PREGUNTAS_TIPO_RESPUESTA_SI_NO actual tiene una respuesta de usaurio
   *
   * Evalua si la pregunta de tipo PREGUNTAS_TIPO_RESPUESTA_SI_NO actual tiene una respuesta de usaurio
   *
   *
   * @author  [YRM] Maurilio Cruz Morales
   *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *              Tel. celular (55) 6702 6252
   *
   * @date    2019-06-14
   * @param   void
   * @return  void
   */

		hasAnswerTipoRespuestaSiNo: function hasAnswerTipoRespuestaSiNo() {
			return Ember.$('.carousel-item.active input:checked').length != 0 ? true : false;
		},

		/**
   * hasAnswerTipoRespuestaUnica - Evalua si la pregunta de tipo PREGUNTAS_TIPO_RESPUESTA_UNICA actual tiene una respuesta de usaurio
   *
   * Evalua si la pregunta de tipo PREGUNTAS_TIPO_RESPUESTA_UNICA actual tiene una respuesta de usaurio
   *
   *
   * @author  [YRM] Maurilio Cruz Morales
   *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *              Tel. celular (55) 6702 6252
   *
   * @date    2019-06-14
   * @param   void
   * @return  void
   */

		hasAnswerTipoRespuestaUnica: function hasAnswerTipoRespuestaUnica() {
			return Ember.$('.carousel-item.active input:checked').length != 0 ? true : false;
		},

		/**
   * hasAnswerTipoRespuestaMultiple - Evalua si la pregunta de tipo PREGUNTAS_TIPO_RESPUESTA_MULTIPLE actual tiene una respuesta de usaurio
   *
   * Evalua si la pregunta de tipo PREGUNTAS_TIPO_RESPUESTA_MULTIPLE actual tiene una respuesta de usaurio
   *
   *
   * @author  [YRM] Maurilio Cruz Morales
   *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *              Tel. celular (55) 6702 6252
   *
   * @date    2019-06-14
   * @param   void
   * @return  void
   */

		hasAnswerTipoRespuestaMultiple: function hasAnswerTipoRespuestaMultiple() {
			return Ember.$('.carousel-item.active input:checked').length != 0 ? true : false;
		},

		/**
   * hasAnswerTipoRespuestaListaDesplegable - Evalua si la pregunta de tipo PREGUNTAS_TIPO_RESPUESTA_LISTA_DESPLEGABLE actual tiene una respuesta de usaurio
   *
   * Evalua si la pregunta de tipo PREGUNTAS_TIPO_RESPUESTA_LISTA_DESPLEGABLE actual tiene una respuesta de usaurio
   *
   *
   * @author  [YRM] Maurilio Cruz Morales
   *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *              Tel. celular (55) 6702 6252
   *
   * @date    2019-06-14
   * @param   void
   * @return  void
   */

		hasAnswerTipoRespuestaListaDesplegable: function hasAnswerTipoRespuestaListaDesplegable() {
			return false;
		},

		/**
   * hasAnswerTipoRespuestaRelacionarColumnas - Evalua si la pregunta de tipo PREGUNTAS_TIPO_RESPUESTA_RELACIONAR_COLUMNAS actual tiene una respuesta de usaurio
   *
   * Evalua si la pregunta de tipo PREGUNTAS_TIPO_RESPUESTA_RELACIONAR_COLUMNAS actual tiene una respuesta de usaurio
   *
   *
   * @author  [YRM] Maurilio Cruz Morales
   *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *              Tel. celular (55) 6702 6252
   *
   * @date    2019-06-14
   * @param   void
   * @return  void
   *
   * @todo:	Integrar esta validación
   */

		hasAnswerTipoRespuestaRelacionarColumnas: function hasAnswerTipoRespuestaRelacionarColumnas() {
			return false;
		},

		/**
   * hasAnswerTipoRespuestaPorcentaje - Evalua si la pregunta de tipo PREGUNTAS_TIPO_RESPUESTA_PORCENTAJE actual tiene una respuesta de usaurio
   *
   * Evalua si la pregunta de tipo PREGUNTAS_TIPO_RESPUESTA_PORCENTAJE actual tiene una respuesta de usaurio
   *
   *
   * @author  [YRM] Maurilio Cruz Morales
   *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *              Tel. celular (55) 6702 6252
   *
   * @date    2019-06-14
   * @param   void
   * @return  void
   *
   */

		hasAnswerTipoRespuestaPorcentaje: function hasAnswerTipoRespuestaPorcentaje() {
			return false;
		},

		/**
   * hasAnswerTipoRespuestaPuntuacion - Evalua si la pregunta de tipo PREGUNTAS_TIPO_RESPUESTA_PUNTUACION actual tiene una respuesta de usaurio
   *
   * Evalua si la pregunta de tipo PREGUNTAS_TIPO_RESPUESTA_PUNTUACION actual tiene una respuesta de usaurio
   *
   *
   * @author  [YRM] Maurilio Cruz Morales
   *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *              Tel. celular (55) 6702 6252
   *
   * @date    2019-06-14
   * @param   void
   * @return  void
   *
   */

		hasAnswerTipoRespuestaPuntuacion: function hasAnswerTipoRespuestaPuntuacion() {

			var has_answer = true;

			if (Ember.$('.carousel-item.active select option:selected').length == 0) {
				has_answer = false;
				return has_answer;
			}

			Ember.$('.carousel-item.active select option:selected').each(function () {

				if (Ember.$(this).data('orden') == '') {
					has_answer = false;
				}
			});

			return has_answer;
		},

		/**
   * hasAnswerTipoRespuestaAbierta - Evalua si la pregunta de tipo PREGUNTAS_TIPO_RESPUESTA_ABIERTA actual tiene una respuesta de usaurio
   *
   * Evalua si la pregunta de tipo PREGUNTAS_TIPO_RESPUESTA_ABIERTA actual tiene una respuesta de usaurio
   *
   *
   * @author  [YRM] Maurilio Cruz Morales
   *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *              Tel. celular (55) 6702 6252
   *
   * @date    2019-06-14
   * @param   void
   * @return  void
   *
   */

		hasAnswerTipoRespuestaAbierta: function hasAnswerTipoRespuestaAbierta() {
			return Ember.$('.carousel-item.active textarea').val().trim() == '' ? false : true;
		},

		/**
   * hasAnswerTipoRespuestaOrdenarRespuesta - Evalua si la pregunta de tipo PREGUNTAS_TIPO_RESPUESTA_ORDENAR_RESPUESTA actual tiene una respuesta de usaurio
   *
   * Evalua si la pregunta de tipo PREGUNTAS_TIPO_RESPUESTA_ORDENAR_RESPUESTA actual tiene una respuesta de usaurio
   *
   *
   * @author  [YRM] Maurilio Cruz Morales
   *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *              Tel. celular (55) 6702 6252
   *
   * @date    2019-06-14
   * @param   void
   * @return  void
   *
   */

		hasAnswerTipoRespuestaOrdenarRespuesta: function hasAnswerTipoRespuestaOrdenarRespuesta() {

			var has_answer = true;

			if (Ember.$('.carousel-item.active select option:selected').length == 0) {
				has_answer = false;
				return has_answer;
			}

			Ember.$('.carousel-item.active select option:selected').each(function () {

				if (Ember.$(this).data('orden') == '') {
					has_answer = false;
				}
			});

			return has_answer;
		},

		/**
   * hasAnswerTipoRespuestaCalificarNiveles - Evalua si la pregunta de tipo PREGUNTAS_TIPO_RESPUESTA_CALIFICAR_NIVELES actual tiene una respuesta de usaurio
   *
   * Evalua si la pregunta de tipo PREGUNTAS_TIPO_RESPUESTA_CALIFICAR_NIVELES actual tiene una respuesta de usaurio
   *
   *
   * @author  [YRM] Maurilio Cruz Morales
   *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *              Tel. celular (55) 6702 6252
   *
   * @date    2019-06-14
   * @param   void
   * @return  void
   *
   */

		hasAnswerTipoRespuestaCalificarNiveles: function hasAnswerTipoRespuestaCalificarNiveles() {
			return Ember.$('.carousel-item.active input:checked').length != 0 ? true : false;
		},

		/**
   * resetQuestionTipoRespuestaSiNo - Resetea la pregunta actual que sea de tipo PREGUNTAS_TIPO_RESPUESTA_SI_NO
   *
   * Resetea la pregunta actual que sea de tipo PREGUNTAS_TIPO_RESPUESTA_SI_NO
   *
   *
   * @author  [YRM] Maurilio Cruz Morales
   *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *              Tel. celular (55) 6702 6252
   *
   * @date    2019-06-14
   * @param   void
   * @return  void
   */

		resetQuestionTipoRespuestaSiNo: function resetQuestionTipoRespuestaSiNo() {

			Ember.$('.carousel-item.active input').each(function () {
				Ember.$('.carousel-item.active input').prop('checked', false);
			});

			this.pregunta_respuesta_si_no(false, false);
		},

		/**
   * resetQuestionTipoRespuestaUnica - Resetea la pregunta actual que sea de tipo PREGUNTAS_TIPO_RESPUESTA_UNICA
   *
   * Resetea la pregunta actual que sea de tipo PREGUNTAS_TIPO_RESPUESTA_UNICA
   *
   *
   * @author  [YRM] Maurilio Cruz Morales
   *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *              Tel. celular (55) 6702 6252
   *
   * @date    2019-06-24
   * @param   void
   * @return  void
   */

		resetQuestionTipoRespuestaUnica: function resetQuestionTipoRespuestaUnica() {

			Ember.$('.carousel-item.active input').each(function () {
				Ember.$(this).prop('checked', false);
			});

			this.pregunta_unica(false, false);
		},

		/**
   * resetQuestionTipoRespuestaMultiple - Resetea la pregunta actual que sea de tipo PREGUNTAS_TIPO_RESPUESTA_MULTIPLE
   *
   * Resetea la pregunta actual que sea de tipo PREGUNTAS_TIPO_RESPUESTA_MULTIPLE
   *
   *
   * @author  [YRM] Maurilio Cruz Morales
   *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *              Tel. celular (55) 6702 6252
   *
   * @date    2019-06-24
   * @param   void
   * @return  void
   */

		resetQuestionTipoRespuestaMultiple: function resetQuestionTipoRespuestaMultiple() {

			Ember.$('.carousel-item.active input').each(function () {
				Ember.$(this).prop('checked', false);
			});

			this.pregunta_multiple(false, false);
		},

		/**
   * resetQuestionTipoRespuestaListaDesplegable - Resetea la pregunta actual que sea de tipo PREGUNTAS_TIPO_RESPUESTA_LISTA_DESPLEGABLE
   *
   * Resetea la pregunta actual que sea de tipo PREGUNTAS_TIPO_RESPUESTA_LISTA_DESPLEGABLE
   *
   *
   * @author  [YRM] Maurilio Cruz Morales
   *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *              Tel. celular (55) 6702 6252
   *
   * @date    2019-06-24
   * @param   void
   * @return  void
   */

		resetQuestionTipoRespuestaListaDesplegable: function resetQuestionTipoRespuestaListaDesplegable() {

			Ember.$('.carousel-item.active select option').each(function () {
				Ember.$(this).prop('selected', false);
			});

			this.pregunta_lista_desplegable(false, false);
		},

		/**
   * resetQuestionTipoRespuestaRelacionarColumnas - Resetea la pregunta actual que sea de tipo PREGUNTAS_TIPO_RESPUESTA_RELACIONAR_COLUMNAS
   *
   * Resetea la pregunta actual que sea de tipo PREGUNTAS_TIPO_RESPUESTA_RELACIONAR_COLUMNAS
   *
   *
   * @author  [YRM] Maurilio Cruz Morales
   *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *              Tel. celular (55) 6702 6252
   *
   * @date    2019-06-24
   * @param   void
   * @return  void
   */

		resetQuestionTipoRespuestaRelacionarColumnas: function resetQuestionTipoRespuestaRelacionarColumnas() {

			Ember.$('.carousel-item.active select').each(function () {
				Ember.$(this).prop('selected', false);
			});

			this.pregunta_relacion(false, false);
		},

		/**
   * resetQuestionTipoRespuestaPorcentaje - Resetea la pregunta actual que sea de tipo PREGUNTAS_TIPO_RESPUESTA_PORCENTAJE
   *
   * Resetea la pregunta actual que sea de tipo PREGUNTAS_TIPO_RESPUESTA_PORCENTAJE
   *
   *
   * @author  [YRM] Maurilio Cruz Morales
   *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *              Tel. celular (55) 6702 6252
   *
   * @todo:	Finalizar el reset de este tipo de pregunta.
   * @date    2019-06-24
   * @param   void
   * @return  void
   */

		resetQuestionTipoRespuestaPorcentaje: function resetQuestionTipoRespuestaPorcentaje() {

			var currentQuestion = getPositionCurrentQuestion();
			Ember.$('input[name=_' + (parseInt(currentQuestion) - 1) + ']', '.carousel-item');

			this.pregunta_porcentaje(false, false);
		},

		/**
   * resetQuestionTipoRespuestaPuntuacion - Resetea la pregunta actual que sea de tipo PREGUNTAS_TIPO_RESPUESTA_PUNTUACION
   *
   * Resetea la pregunta actual que sea de tipo PREGUNTAS_TIPO_RESPUESTA_PUNTUACION
   *
   *
   * @author  [YRM] Maurilio Cruz Morales
   *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *              Tel. celular (55) 6702 6252
   *
   * @date    2019-06-24
   * @param   void
   * @return  void
   */

		resetQuestionTipoRespuestaPuntuacion: function resetQuestionTipoRespuestaPuntuacion() {

			Ember.$('.carousel-item.active select').each(function () {
				Ember.$(this).prop('value', 0);
			});

			this.pregunta_puntuacion(false, false);
		},

		/**
   * resetQuestionTipoRespuestaAbierta - Resetea la pregunta actual que sea de tipo PREGUNTAS_TIPO_RESPUESTA_ABIERTA
   *
   * Resetea la pregunta actual que sea de tipo PREGUNTAS_TIPO_RESPUESTA_ABIERTA
   *
   *
   * @author  [YRM] Maurilio Cruz Morales
   *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *              Tel. celular (55) 6702 6252
   *
   * @date    2019-06-24
   * @param   void
   * @return  void
   */

		resetQuestionTipoRespuestaAbierta: function resetQuestionTipoRespuestaAbierta() {

			var currentQuestion = this.getPositionCurrentQuestion();
			var correcto = Ember.$('textarea[name=_' + (parseInt(currentQuestion) - 1) + ']').val('');

			this.pregunta_abierta(false, false);
		},

		/**
   * resetQuestionTipoRespuestaOrdenarRespuesta - Resetea la pregunta actual que sea de tipo PREGUNTAS_TIPO_RESPUESTA_ORDENAR_RESPUESTA
   *
   * Resetea la pregunta actual que sea de tipo PREGUNTAS_TIPO_RESPUESTA_ORDENAR_RESPUESTA
   *
   *
   * @author  [YRM] Maurilio Cruz Morales
   *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *              Tel. celular (55) 6702 6252
   *
   * @date    2019-06-24
   * @param   void
   * @return  void
   */

		resetQuestionTipoRespuestaOrdenarRespuesta: function resetQuestionTipoRespuestaOrdenarRespuesta() {

			Ember.$('.carousel-item.active select option:selected').each(function () {
				Ember.$(this).prop('selected', false);
			});

			this.pregunta_ordenar_respuesta(false, false);
		},

		/**
   * resetQuestionTipoRespuestaCalificarniveles - Resetea la pregunta actual que sea de tipo PREGUNTAS_TIPO_RESPUESTA_CALIFICAR_NIVELES
   *
   * Resetea la pregunta actual que sea de tipo PREGUNTAS_TIPO_RESPUESTA_CALIFICAR_NIVELES
   *
   *
   * @author  [YRM] Maurilio Cruz Morales
   *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *              Tel. celular (55) 6702 6252
   *
   * @date    2019-06-24
   * @param   void
   * @return  void
   */

		resetQuestionTipoRespuestaCalificarniveles: function resetQuestionTipoRespuestaCalificarniveles() {

			Ember.$('.carousel-item.active input').each(function () {
				Ember.$(this).prop('checked', false);
			});

			this.pregunta_calificar_niveles(false, false);
		},

		/**
   * getNameMonth - Función utilitaria. A partir de un nuermo de mes , retorna el nombre correspondiente.
   *
   * Función utilitaria. A partir de un nuermo de mes , retorna el nombre correspondiente.
   *
   *
   * @author  [YRM] Maurilio Cruz Morales
   *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *              Tel. celular (55) 6702 6252
   *
   * @date    2019-06-14
   * @param   int number_month Valor numerico entre 1 y 12, que indica la posición de un mes.
   * @return  string Nombre del mes indicado
   */

		getNameMonth: function getNameMonth(number_month) {

			if (number_month < 1 || number_month > 12) {
				return '';
			}

			var months_array = ['', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

			return months_array[number_month];
		},

		/**
   * getNameMonth - Función utilitaria. Devuelve la posición numerica de la pregunta actual.
   *
   * Función utilitaria. Devuelve la posición numerica de la pregunta actual.
   *
   *
   * @author  [YRM] Maurilio Cruz Morales
   *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *              Tel. celular (55) 6702 6252
   *
   * @date    2019-06-21
   * @param   void
   * @return  int Posición numerica de la pregunta actual
   */

		getPositionCurrentQuestion: function getPositionCurrentQuestion() {

			/*
    * Comentario al margen: La forma de obtener la posición actual es solo un workaround.
    *
    * @todo: modificar la implementación de la secuenciación de preguntas. Se requiere conservar un valor numerico
    *        a nivel del controlador ( Ejem. controler.set( 'posicionActual', valorNumerico ) ) conforme se navegue
    *        la evaluación.
    */

			var arrayString = Ember.$('#contadorPreguntas').text().split(' ');
			return arrayString[1];
		},

		/**
   * getQuestionTypeFromCurrentQuestion - Función utilitaria. Devuelve el valor numerico de tipo de pregunta.
   *
   * Función utilitaria. Devuelve el valor numerico de tipo de pregunta.
   *
   *
   * @author  [YRM] Maurilio Cruz Morales
   *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *              Tel. celular (55) 6702 6252
   *
   * @date    2019-06-21
   * @param   void
   * @return  int Valor numerico de tipo de pregunta para la pregunta actual.
   */

		getQuestionTypeFromCurrentQuestion: function getQuestionTypeFromCurrentQuestion() {

			/*
          * Comentario al margen: La forma de obtener la posición actual es solo un workaround.
          *
          * @todo: modificar la implementación de la secuenciación de preguntas. Se requiere conservar un valor numerico
          *        a nivel del controlador ( Ejem. controler.set( 'posicionActual', valorNumerico ) ) conforme se navegue
          *        la evaluación.
          */

			return Ember.$('.carousel-item.active h2').data('tipo');
		},

		/**
   * loadQuestionsSequence - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis sapien.
   *
   * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
   * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
   * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
   * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
   * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
   *
   *
   * @author
   *
   * @date
   * @param   void
   * @return  void
   */

		loadQuestionsSequence: function loadQuestionsSequence() {

			var isloadQuestionsSequencefinished = false;
			var actualQuestionIndex = 0;

			var continuar_iteracion_de_proceso = true;
			var iteracion_seguridad = 500;

			var pregunta_ultima_respondida_k = null;

			if (!(window.evaluacionDinamica.pregunta_ultima_respondida_k == null || window.evaluacionDinamica.pregunta_ultima_respondida_k == 'null')) {
				pregunta_ultima_respondida_k = parseInt(window.evaluacionDinamica.pregunta_ultima_respondida_k);
			}

			if (pregunta_ultima_respondida_k === null) {
				window.lastArray = [];
				return actualQuestionIndex;
			}

			do {

				var preguntaActualRecord = window.evaluacionDinamica.preguntas[actualQuestionIndex];
				var evaluacionPreguntaActual = this.determinarSiguientePregunta(window.evaluacionDinamica.preguntas[actualQuestionIndex]);

				/*
     * En caso de la respuesta actual no tenga respuesta de usaurio, nos situamos en la pregunta inicial.
     */

				if (this.sonEquivalentes(preguntaActualRecord.pregunta_k, pregunta_ultima_respondida_k)) {

					continuar_iteracion_de_proceso = false;
				} else {

					window.lastArray.push(JSON.stringify(preguntaActualRecord));

					if (evaluacionPreguntaActual.pregunta_siguiente_k == null) {

						/*
                      * Sí no esta definida una próxima pregunta siguiente condicionada y llegamos al final de la evaluación,
                      * nos situamos en la posición actual.
                      */

						if (actualQuestionIndex + 1 >= window.evaluacionDinamica.preguntas.length) {

							continuar_iteracion_de_proceso = false;
						} else {

							/* Nos desplazamos a la pregunta inmediata próxima */
							actualQuestionIndex++;
						}
					} else {

						/*
                      * Buscamos en el arreglo de preguntas la posición de la siguiente pregunta condicionada.
                      */

						actualQuestionIndex = this.getQuestionIndexFromPreguntaK(evaluacionPreguntaActual.pregunta_siguiente_k);
					}
				}

				iteracion_seguridad--;
			} while (continuar_iteracion_de_proceso && iteracion_seguridad > 0);

			return actualQuestionIndex;
		},

		/**
   * determinarPreguntaSiguiente - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis sapien.
   *
   * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
   * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
   * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
   * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
   * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
   *
   *
   * @author
   *
   * @date
   * @param   void
   * @return  void
   */

		determinarSiguientePregunta: function determinarSiguientePregunta(preguntaRecord) {

			var pregunta_condicional = {};

			var tipo_pregunta_k = parseInt(preguntaRecord.tipo_pregunta_k);

			switch (tipo_pregunta_k) {

				case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_SI_NO:
					pregunta_condicional = this.determinarSiguientePreguntaTipoRespuestaSiNo(preguntaRecord);
					break;

				case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_UNICA:
					pregunta_condicional = this.determinarSiguientePreguntaTipoRespuestaUnica(preguntaRecord);
					break;

				case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_MULTIPLE:
					pregunta_condicional = this.determinarSiguientePreguntaTipoRespuestaMultiple(preguntaRecord);
					break;

				case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_LISTA_DESPLEGABLE:
					pregunta_condicional = this.determinarSiguientePreguntaTipoListaDesplegable(preguntaRecord);
					break;

				case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_RELACIONAR_COLUMNAS:
					pregunta_condicional = this.determinarSiguientePreguntaTipoRespuestaRelacionarColumnas(preguntaRecord);
					break;

				case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_PORCENTAJE:
					pregunta_condicional = this.determinarSiguientePreguntaTipoRespuestaPorcentaje(preguntaRecord);
					break;

				case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_PUNTUACION:
					pregunta_condicional = this.determinarSiguientePreguntaTipoRespuestaPuntuacion(preguntaRecord);
					break;

				case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_ABIERTA:
					pregunta_condicional = this.determinarSiguientePreguntaTipoRespuestaAbierta(preguntaRecord);
					break;

				case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_ORDENAR_RESPUESTA:
					pregunta_condicional = this.determinarSiguientePreguntaTipoRespuestaOrdenarRespuesta(preguntaRecord);
					break;

				case constant.EVALUACIONES_DINAMICAS.CONSTANTES.PREGUNTAS_TIPO_RESPUESTA_CALIFICAR_NIVELES:
					pregunta_condicional = this.determinarSiguientePreguntaTipoRespuestaCalificarNiveles(preguntaRecord);
					break;

				default:
					break;

			}

			return pregunta_condicional;
		},

		/**
   * determinarSiguientePreguntaTipoRespuestaSiNo - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad.
   *
   * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
   * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
   * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
   * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
   * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
   *
   *
   * @author
   *
   * @date
   * @param   void
   * @return  void
   */

		determinarSiguientePreguntaTipoRespuestaSiNo: function determinarSiguientePreguntaTipoRespuestaSiNo(preguntaRecord) {

			var tiene_respuesta = false;
			var respuestaUno = preguntaRecord.respuestas[0];
			var respuestaDos = preguntaRecord.respuestas[1];
			var pregunta_siguiente_k = null;

			if (this.sonEquivalentes(respuestaUno.correcta, 1) || this.sonEquivalentes(respuestaDos.correcta, 1)) {

				tiene_respuesta = true;

				if (this.sonEquivalentes(respuestaUno.correcta, 1) && this.sonEquivalentes(respuestaUno.respuesta_correcta, 1)) {

					pregunta_siguiente_k = respuestaUno.pregunta_siguiente_k;
				} else if (this.sonEquivalentes(respuestaDos.correcta, 1) && this.sonEquivalentes(respuestaDos.respuesta_correcta, 1)) {

					pregunta_siguiente_k = respuestaDos.pregunta_siguiente_k;
				}
			}

			return {
				tiene_respuesta_de_usuario: tiene_respuesta,
				pregunta_siguiente_k: pregunta_siguiente_k
			};
		},

		/**
   * determinarSiguientePreguntaTipoRespuestaUnica - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad.
   *
   * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
   * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
   * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
   * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
   * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
   *
   *
   * @author
   *
   * @date
   * @param   void
   * @return  void
   */

		determinarSiguientePreguntaTipoRespuestaUnica: function determinarSiguientePreguntaTipoRespuestaUnica(preguntaRecord) {

			var self = this;
			var es_respuesta_correcta = false;
			var tiene_respuesta_de_usuario = false;
			var pregunta_siguiente_k = null;

			preguntaRecord.respuestas.forEach(function (respuestaRecord) {

				if (self.sonEquivalentes(respuestaRecord.correcta, 1)) {

					tiene_respuesta_de_usuario = true;

					if (self.sonEquivalentes(respuestaRecord.correcta, 1) && self.sonEquivalentes(respuestaRecord.respuesta_correcta, 1)) {

						es_respuesta_correcta = true;
						pregunta_siguiente_k = respuestaRecord.pregunta_siguiente_k;
					}
				}
			});

			return {
				tiene_respuesta_de_usuario: tiene_respuesta_de_usuario,
				pregunta_siguiente_k: pregunta_siguiente_k
			};
		},

		/**
   * determinarSiguientePreguntaTipoRespuestaMultiple - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad.
   *
   * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
   * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
   * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
   * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
   * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
   *
   *
   * @author
   *
   * @date
   * @param   void
   * @return  void
   */

		determinarSiguientePreguntaTipoRespuestaMultiple: function determinarSiguientePreguntaTipoRespuestaMultiple(preguntaRecord) {

			var self = this;
			var es_respuesta_correcta = true;
			var pregunta_siguiente_k = null;
			var tiene_respuesta_de_usuario = false;
			var numero_respuestas_de_usuario = 0;

			preguntaRecord.respuestas.forEach(function (respuestaRecord) {

				/*
     *	Sí alguna de la respuesta es incorrecta, y es seleccionada por el usuario,
     *	Toda la pregunta se considera erronea.
     */

				if (self.sonEquivalentes(respuestaRecord.correcta, 1)) {

					numero_respuestas_de_usuario++;
					tiene_respuesta_de_usuario = true;

					if (self.sonEquivalentes(respuestaRecord.correcta, 1) && self.sonEquivalentes(respuestaRecord.respuesta_correcta, 0)) {
						es_respuesta_correcta = false;
					}
				}

				if (numero_respuestas_de_usuario == 0) {
					es_respuesta_correcta = false;
				}
			});

			return {
				tiene_respuesta_de_usuario: tiene_respuesta_de_usuario,
				pregunta_siguiente_k: pregunta_siguiente_k
			};
		},

		/**
   * determinarSiguientePreguntaTipoListaDesplegable - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad.
   *
   * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
   * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
   * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
   * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
   * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
   *
   *
   * @author
   *
   * @date
   * @param   void
   * @return  void
   */

		determinarSiguientePreguntaTipoListaDesplegable: function determinarSiguientePreguntaTipoListaDesplegable(preguntaRecord) {

			var self = this;
			var es_respuesta_correcta = true;
			var pregunta_siguiente_k = null;
			var tiene_respuesta_de_usuario = false;

			return {
				tiene_respuesta_de_usuario: tiene_respuesta_de_usuario,
				pregunta_siguiente_k: pregunta_siguiente_k
			};
		},

		/**
   * determinarSiguientePreguntaTipoRespuestaRelacionarColumnas - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad.
   *
   * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
   * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
   * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
   * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
   * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
   *
   *
   * @author
   *
   * @date
   * @param   void
   * @return  void
   */

		determinarSiguientePreguntaTipoRespuestaRelacionarColumnas: function determinarSiguientePreguntaTipoRespuestaRelacionarColumnas(preguntaRecord) {

			var self = this;
			var es_respuesta_correcta = true;
			var pregunta_siguiente_k = null;
			var tiene_respuesta_de_usuario = false;
			var numero_respuestas_de_usuario = 0;

			return {
				tiene_respuesta_de_usuario: tiene_respuesta_de_usuario,
				pregunta_siguiente_k: pregunta_siguiente_k
			};
		},

		/**
   * determinarSiguientePreguntaTipoRespuestaPorcentaje - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad.
   *
   * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
   * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
   * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
   * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
   * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
   *
   *
   * @author
   *
   * @date
   * @param   void
   * @return  void
   */

		determinarSiguientePreguntaTipoRespuestaPorcentaje: function determinarSiguientePreguntaTipoRespuestaPorcentaje(preguntaRecord) {

			var self = this;
			var es_respuesta_correcta = true;
			var pregunta_siguiente_k = null;
			var tiene_respuesta_de_usuario = false;
			var numero_respuestas_de_usuario = 0;

			return {
				tiene_respuesta_de_usuario: tiene_respuesta_de_usuario,
				pregunta_siguiente_k: pregunta_siguiente_k
			};
		},

		/**
   * determinarSiguientePreguntaTipoRespuestaPuntuacion - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad.
   *
   * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
   * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
   * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
   * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
   * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
   *
   *
   * @author
   *
   * @date
   * @param   void
   * @return  void
   */

		determinarSiguientePreguntaTipoRespuestaPuntuacion: function determinarSiguientePreguntaTipoRespuestaPuntuacion(preguntaRecord) {

			var self = this;
			var es_respuesta_correcta = false;
			var pregunta_siguiente_k = null;
			var tiene_respuesta_de_usuario = false;

			preguntaRecord.respuestas.forEach(function (respuestaRecord) {

				if (parseInt(respuestaRecord.correcta) > 0) {
					es_respuesta_correcta = true;
					tiene_respuesta_de_usuario = true;
				}
			});

			pregunta_siguiente_k = es_respuesta_correcta ? preguntaRecord.pregunta_siguiente_correcta_k : preguntaRecord.pregunta_siguiente_incorrecta_k;

			return {
				tiene_respuesta_de_usuario: tiene_respuesta_de_usuario,
				pregunta_siguiente_k: pregunta_siguiente_k
			};
		},

		/**
   * determinarSiguientePreguntaTipoRespuestaAbierta - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad.
   *
   * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
   * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
   * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
   * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
   * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
   *
   *
   * @author
   *
   * @date
   * @param   void
   * @return  void
   */

		determinarSiguientePreguntaTipoRespuestaAbierta: function determinarSiguientePreguntaTipoRespuestaAbierta(preguntaRecord) {

			var self = this;
			var es_respuesta_correcta = false;
			var pregunta_siguiente_k = null;
			var tiene_respuesta_de_usuario = false;

			var respuesta_de_usuario = String(preguntaRecord.respuestas[0].correcta);

			if (respuesta_de_usuario != 'null' && respuesta_de_usuario.length > 0) {
				es_respuesta_correcta = true;
				tiene_respuesta_de_usuario = true;
			}

			pregunta_siguiente_k = es_respuesta_correcta ? preguntaRecord.pregunta_siguiente_correcta_k : preguntaRecord.pregunta_siguiente_incorrecta_k;

			return {
				tiene_respuesta_de_usuario: tiene_respuesta_de_usuario,
				pregunta_siguiente_k: pregunta_siguiente_k
			};
		},

		/**
   * determinarSiguientePreguntaTipoRespuestaOrdenarRespuesta - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad.
   *
   * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
   * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
   * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
   * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
   * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
   *
   *
   * @author
   *
   * @date
   * @param   void
   * @return  void
   */

		determinarSiguientePreguntaTipoRespuestaOrdenarRespuesta: function determinarSiguientePreguntaTipoRespuestaOrdenarRespuesta(preguntaRecord) {

			var self = this;
			var es_respuesta_correcta = true;
			var pregunta_siguiente_k = null;

			var tiene_respuesta_de_usuario = false;
			var numero_respuestas_de_usuario = 0;

			preguntaRecord.respuestas.forEach(function (respuestaRecord) {

				if (respuestaRecord.correcta == respuestaRecord.respuesta_correcta_k) {

					numero_respuestas_de_usuario++;
					tiene_respuesta_de_usuario = true;
				}

				if (respuestaRecord.correcta != respuestaRecord.respuesta_correcta_k) {

					es_respuesta_correcta = false;
				}
			});

			if (numero_respuestas_de_usuario == 0) {
				es_respuesta_correcta = false;
			}

			pregunta_siguiente_k = es_respuesta_correcta ? preguntaRecord.pregunta_siguiente_correcta_k : preguntaRecord.pregunta_siguiente_incorrecta_k;

			return {
				tiene_respuesta_de_usuario: tiene_respuesta_de_usuario,
				pregunta_siguiente_k: pregunta_siguiente_k
			};
		},

		/**
   * determinarSiguientePreguntaTipoRespuestaCalificarNiveles - Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad.
   *
   * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
   * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
   * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
   * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
   * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
   *
   *
   * @author
   *
   * @date
   * @param   void
   * @return  void
   */

		determinarSiguientePreguntaTipoRespuestaCalificarNiveles: function determinarSiguientePreguntaTipoRespuestaCalificarNiveles(preguntaRecord) {

			var self = this;
			var es_respuesta_correcta = false;
			var pregunta_siguiente_k = null;
			var tiene_respuesta_de_usuario = false;

			preguntaRecord.respuestas.forEach(function (respuestaRecord) {

				if (self.sonEquivalentes(respuestaRecord.correcta, 1) && self.sonEquivalentes(respuestaRecord.respuesta_correcta, 1)) {

					es_respuesta_correcta = true;
					tiene_respuesta_de_usuario = true;
					pregunta_siguiente_k = respuestaRecord.pregunta_siguiente_k;
				}
			});

			return {
				tiene_respuesta_de_usuario: tiene_respuesta_de_usuario,
				pregunta_siguiente_k: pregunta_siguiente_k
			};
		},

		/**
   * getQuestionIndexFromPreguntaK - Lorem ipsum dolor sit amet consectetur adipiscing elit adipiscing elit, luctus ad.
   *
   * Lorem ipsum dolor sit amet consectetur adipiscing elit, luctus ad curabitur iaculis scelerisque sapien gravida
   * elementum, dictum porta inceptos tincidunt malesuada quis. Facilisi habitasse sem auctor morbi libero placerat
   * vulputate potenti a, dapibus est ultricies eu imperdiet pharetra velit feugiat laoreet lobortis, rutrum mollis
   * condimentum nostra vestibulum tempus justo suspendisse. Inceptos nostra placerat et at platea posuere suspendisse,
   * erat gravida odio ligula molestie magnis orci, mauris varius massa semper eget aptent.
   *
   *
   * @author
   *
   * @date
   * @param   void
   * @return  void
   */

		getQuestionIndexFromPreguntaK: function getQuestionIndexFromPreguntaK(pregunta_k) {

			var indexPreguntaCoincidente = -1;

			window.evaluacionDinamica.preguntas.forEach(function (recordPregunta, index) {

				if (recordPregunta.pregunta_k == pregunta_k) {
					indexPreguntaCoincidente = index;
				}
			});

			return indexPreguntaCoincidente;
		},

		/**
   * sonEquivalentes - Funcion utilitaria. Valida que dos valores sean dados sean iguales en terminos de valor numerico.
   *
   *
   * Funcion utilitaria. Valida que dos valores sean dados sean iguales en terminos de valor numerico.
   *
   *
   * @author
   *
   * @date
   * @param   void
   * @return  void
   */

		sonEquivalentes: function sonEquivalentes(variable, valor) {

			return variable === valor || parseInt(variable) === valor ? true : false;
		}

	});
});