define('comunidades-nafin/components/webinars-cards', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		init: function init() {
			this._super.apply(this, arguments);
			var self = this;

			moment.updateLocale('es', { months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'] });

			$.ajax({
				url: constant.FIREBASE_WEBIN,
				type: 'POST',
				data: {
					method: 'get_webinars_open'
				},
				success: function success(r) {
					if (r.status) {
						var open_webinars = r.data.conferences;
						$.ajax({
							url: constant.FIREBASE_WEBIN,
							type: 'POST',
							data: {
								method: 'get_webinars_user',
								data: {
									id: self.session.userData.usuario_k
								}
							},
							success: function success(r) {
								var suscriber_webinars = r.data;
								if (suscriber_webinars) {
									Object.keys(open_webinars).forEach(function (key) {
										if (suscriber_webinars[open_webinars[key].id]) {
											open_webinars[key].suscribed = true;
										}
									});
								}
								self.set('next_webinars', open_webinars);
							}
						});
					}
				}
			});
			$.ajax({
				url: constant.FIREBASE_WEBIN,
				type: 'POST',
				data: {
					method: 'get_webinars_close'
				},
				success: function success(r) {
					if (r.status) {
						self.set('previous_webinars', r.data.conferences);
					}
					$('#loading').fadeOut('slow');
				}
			});
		},
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			this.set("webinar_state", true);
		},
		actions: {
			new_webinar: function new_webinar() {
				this.set("webinar_state", true);
			},
			old_webinar: function old_webinar() {
				this.set("webinar_state", false);
			},
			gotowebinar: function gotowebinar(id) {
				$('#loading').fadeIn('slow');
				$.ajax({
					url: constant.FIREBASE_WEBIN,
					type: 'POST',
					data: {
						method: 'enter_webinar',
						data: {
							webinar: {
								id: id
							},
							user: this.session.userData
						}
					},
					success: function success(r) {
						$('#loading').fadeOut('slow');
						var a = document.createElement('a');
						a.href = r.data.enter_uri;
						a.target = '_blank';
						document.body.appendChild(a);
						a.click();
						document.body.removeChild(a);
					}
				});
			},
			getwebinarvideo: function getwebinarvideo(id) {

				$.ajax({
					url: constant.FIREBASE_WEBIN,
					type: 'POST',
					data: {
						method: 'get_webinars_detail',
						data: {
							webinar: {
								id: id
							}
						}
					},
					success: function success(r) {

						$('#loading').fadeOut('slow');

						if (r.status && r.data) {
							var webinar = r.data.conference;
							//$('#webinar-'+webinar.id).parent().parent().addClass('text-center');
							var html = '<div class="col-12 text-center">' + webinar.recording_iframe + '</div>';
							$('#webinar-' + webinar.id).append(html);
							$('#webinar-' + webinar.id).find('.no-video').parent().parent().remove();
						}
					}
				});
			},
			registerwebinar: function registerwebinar(id) {
				$('#loading').fadeIn('slow');
				$.ajax({
					url: constant.FIREBASE_WEBIN,
					type: 'POST',
					data: {
						method: 'suscribe_webinar',
						data: {
							webinar: {
								id: id
							},
							user: this.session.userData
						}
					},
					success: function success(r) {
						$('#loading').fadeOut('slow');
					}
				});
			},

			unregisterwebinar: function unregisterwebinar(id) {
				$('#loading').fadeIn('slow');
				$.ajax({
					url: constant.FIREBASE_WEBIN,
					type: 'POST',
					data: {
						method: 'unsuscribe_webinar',
						data: {
							webinar: {
								id: id
							},
							user: this.session.userData
						}
					},
					success: function success(r) {
						$('#loading').fadeOut('slow');
					}
				});
			},
			changesuscription: function changesuscription(id) {
				var status = $('#webinar-' + id).find('input[type="checkbox"]').is(':checked');
				if (status) {
					this.send('registerwebinar', id);
				} else {
					this.send('unregisterwebinar', id);
				}
			},

			funcionalidadNoIntegrada: function funcionalidadNoIntegrada() {
				alert("funcionalidad aun no desarrollada");
			}
		}
	});
});