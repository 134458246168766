define('comunidades-nafin/components/media-box', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        didInsertElement: function didInsertElement() {

            this._super.apply(this, arguments);

            var self = this;
            var id = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
            var params_string = 'params=';
            params_string += JSON.stringify({ comunidad_k: id });

            Ember.$.ajax({

                url: constant.APIURL + 'comunidad/listarInformacionReciente',
                data: params_string,
                type: 'POST',
                contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                xhrFields: { withCredentials: true },
                success: function success(r) {

                    var data = JSON.parse(r);
                    self.formatter(data.data, self);

                    data.data.forEach(function (record_object, index_int, vector_array) {
                        console.log(record_object);
                        self.fileType(record_object);
                    });
                    console.log(data.data);
                    self.set('mediateca_reciente', data.data);

                    if (data.data.length == 0) {
                        Ember.$('.reciente-mediateca').hide();
                    }
                },
                error: function error(e) {
                    console.log(e);
                }
            });
        },
        didRender: function didRender() {
            Ember.$('.carousel-item:first-child').addClass('active');
            Ember.$('.carousel').carousel({ interval: 5000 });
        },
        fileType: function fileType(element) {

            var arrayFileLink = element.ruta.split('.');
            var documentType = arrayFileLink[arrayFileLink.length - 1];

            if (documentType === 'docx' || documentType === 'doc') {
                element.icon = '<i class="fas fa-file-word" style="color: #379be9;"></i>';
            } else if (documentType === 'xlsx' || document === 'xls') {
                element.icon = '<i class="fas fa-file-excel" style="color: #008000;"></i>';
            } else if (documentType === 'pdf') {
                element.icon = '<i class="fas fa-file-pdf" style="color: #e70000;"></i>';
            } else if (documentType === 'ppt' || documentType === 'pptx' || documentType === 'pps' || documentType === 'ppsx') {
                element.icon = '<i class="fas fa-file-powerpoint" style="color: #fe3f0d;"></i>';
            } else if (documentType === 'jpg' || documentType === 'png' || documentType === 'gif') {
                element.icon = '<i class="fas fa-file-image" style="color: #fff;"></i>';
            } else if (documentType === 'mp3' || documentType === 'wma') {
                element.icon = '<i class="fas fa-file-audio" style="color: #fff;"></i>';
            } else if (documentType === 'flv') {
                element.icon = '<i class="fas fa-file-video" style="color: #fff;"></i>';
            } else if (documentType === 'rar' || documentType === 'zip') {
                element.icon === '<i class="fas fa-file-archive" style="color: #fff;"></i>';
            } else {
                element.icon === '<i class="fas fa-file" style="color: #fff;"></i>';
            }
        },


        //Dar formato deseado a mes y fechas
        formatter: function formatter(data, self) {
            Object.keys(data).forEach(function (idx) {
                var date = data[idx].fecha_creacion.split('-');
                var month = date[1];
                data[idx].weekDay = self.timeStamp(date);
                data[idx].monthFormath = self.monthFormatter(month);
                data[idx].dayFormath = date[2];
                data[idx].yearFormath = date[0];
                //data[idx].formatt_hora = data[idx].hora.substr(0,5);
                //data[idx].formatt_hora_fin = data[idx].hora_fin.substr(0,5);
            });
            self.setMonth(data, self);
        },


        // Asignar el més en el que nos encontramos
        monthFormatter: function monthFormatter(month) {
            if (month === "01") {
                return "Enero";
            } else if (month === "02") {
                return "Febrero";
            } else if (month === "03") {
                return "Marzo";
            } else if (month === "04") {
                return "Abril";
            } else if (month === "05") {
                return "Mayo";
            } else if (month === "06") {
                return "Junio";
            } else if (month === "07") {
                return "Julio";
            } else if (month === "08") {
                return "Agosto";
            } else if (month === "09") {
                return "Septiembre";
            } else if (month === "10") {
                return "Octubre";
            } else if (month === "11") {
                return "Noviembre";
            } else {
                return "Diciembre";
            }
        },
        timeStamp: function timeStamp(date) {
            var array = date;
            var weeks = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];
            var newDate = array[1] + "/" + array[2] + "/" + array[0];
            var temp = new Date(newDate);
            return weeks[temp.getDay()];
        },
        setMonth: function setMonth(data, self) {
            self.set('event', data);
        },


        actions: {

            downloadFile: function downloadFile(documentoObject) {

                window.open(solveResourceURL(Ember.get(documentoObject, 'ruta')), '_blank');
            },
            prevSlide: function prevSlide() {
                Ember.$('.carousel-inner.media-cards').carousel('prev');
            },
            nextSlide: function nextSlide() {
                Ember.$('.carousel-inner.media-cards').carousel('next');
            }
        }

    });
});