define('comunidades-nafin/components/course-detail', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		progressiveUpdate: function progressiveUpdate(id, usuario, score_courses_resume) {
			var self = this;
			setTimeout(function () {
				lazyLoad.update();
			}, 1000);
			setInterval(function () {
				if (document.location.href.indexOf('cursos') != -1) {
					if (document.location.href.split('cursos/').length == 2) {
						self.updateData(id, usuario, score_courses_resume);
					}
				}
			}, 10000);
			setTimeout(function () {
				Ember.$('#loading').fadeOut('slow');
			}, 5000);
		},
		updateData: function updateData(id, usuario, score_courses_resume) {
			var self = this;
			Ember.$.ajax({
				url: constant.APIURL + 'lms/alumno/listarrutas/listarmisras',
				type: 'POST',
				xhrFields: {
					withCredentials: true
				},
				data: 'params={"ra_k":' + id + '}',
				success: function success(r) {
					var response = JSON.parse(r);
					var ra;
					Object.keys(response.data).forEach(function (idx) {
						ra = response.data[idx].intento_ra;
					});

					Ember.$.ajax({
						url: constant.APIURL + 'lms/raprendizaje/listarProgreso',
						type: 'POST',
						xhrFields: {
							withCredentials: true
						},
						contentType: 'application/x-www-form-urlencoded',
						data: 'usuario_k=' + usuario.usuario_k + '&llave_propietario_k=' + id + '&intento_ra=' + ra + '&cat=is null',
						success: function success(r) {
							//console.log('course-detail');
							var respons = JSON.parse(r);
							respons.ra.formattAvance = parseInt(respons.ra.porcentaje_avance);
							respons.ra.porcentaje_avance_css = Ember.String.htmlSafe('width:' + respons.ra.porcentaje_avance + '%');
							respons.ra.avance = {
								datasets: [{
									data: [respons.ra.formattAvance, 100 - respons.ra.formattAvance],
									backgroundColor: ['#38bc62', '#dddddd'],
									borderWidth: 0
								}],
								labels: [respons.ra.formattAvance + '%', parseInt(100 - respons.ra.formattAvance) + '%']
							};

							if (score_courses_resume.data) {
								respons.ra.ranking_raprendizaje = score_courses_resume.data[respons.ra.ra_k];
								if (respons.ra.ranking_raprendizaje) {
									respons.ra.ranking_raprendizaje_score = Math.round(respons.ra.ranking_raprendizaje.rate);
								} else {
									respons.ra.ranking_raprendizaje_score = 0;
								}
							} else {
								respons.ra.ranking_raprendizaje_score = 0;
							}

							respons.ra.estatus_string = constant.LMS.CURSOS.ESTATUS[parseInt(respons.ra.estatus)];
							respons.ra.imagen_raprendizaje = solveImageURL(respons.ra.imagen_raprendizaje);
							respons.ra.is_certificado_imprimible = parseInt(respons.ra.certificado_imprimible) === 1 ? true : false;

							self.setCursos(respons.ra, self);
							console.log(respons.ra);
							console.log(respons.ra.usuario_k);
							window.notScored = true;

							Object.keys(respons.data).forEach(function (idx) {

								console.log(respons.data[idx].estatus == 8 && respons.data[idx].habilitar_calificar_ra == 1);
								if (respons.data[idx].estatus == 8 && respons.data[idx].habilitar_calificar_ra == 1 && notScored) {

									Ember.$.ajax({
										url: constant.FIREBASE_SCORE,
										type: 'POST',
										contentType: 'application/x-www-form-urlencoded',
										data: {
											method: "user_already_score",
											data: {
												"id_user": respons.ra.usuario_k,
												"id_course": respons.ra.ra_k
											}
										},
										success: function success(r) {
											if (!r.alredyScored) {
												console.log(r);
												Ember.$('#modal_grade').modal({
													backdrop: 'static',
													keyboard: false
												});
												Ember.$('#modal_grade').on('hidden.bs.modal', function () {
													document.location.reload();
												});
												Ember.$('#modal_grade').modal();
											}{
												notScored = false;
											}
										},
										error: function error(err) {
											console.error(err);
										}
									});
								}
							});
						},
						error: function error(e) {
							console.log(e);
						}
					});
				},
				error: function error(e) {
					console.error(e);
				}
			});
		},
		init: function init() {
			this._super.apply(this, arguments);
			this.set('chartype', 'doughnut');
			this.set('chartoptions', {
				legend: {
					display: false
				}
			});
		},
		didInsertElement: function didInsertElement() {
			/*$('#modal_grade').modal({
   	backdrop: 'static',
   	keyboard: false
   });*/
			console.trace = function () {};
			var self = this._super.apply(this, arguments);
			var url_page = window.location.pathname;
			var id = url_page.substring(url_page.lastIndexOf('/') + 1);

			Ember.$.ajax({
				url: constant.APIURL + 'usuario/datospersonales/',
				type: 'POST',
				xhrFields: {
					withCredentials: true
				},
				contentType: 'application/x-www-form-urlencoded',
				success: function success(r) {
					var usuario = JSON.parse(r);
					if (usuario.configuracion.general_compatir_cursos_en_facebook_habilitar == "false") {
						Ember.$("#option1").hide();
					} else {
						Ember.$("#option1").show();
					}
					if (usuario.configuracion.general_compatir_cursos_en_twitter_habilitar == "false") {
						Ember.$("#option2").hide();
					} else {
						Ember.$("#option2").show();
					}
					if (usuario.configuracion.general_compatir_cursos_en_linkedin_habilitar == "false") {
						Ember.$("#option3").hide();
					} else {
						Ember.$("#option3").show();
					}
					if (constant.FIREBASE_SCORE != '') {
						Ember.$.ajax({
							url: constant.FIREBASE_SCORE,
							type: 'POST',
							data: {
								"method": "score_courses_resume"
							},
							success: function success(score_courses_resume) {

								self.updateData(id, usuario, score_courses_resume);
								self.progressiveUpdate(id, usuario, score_courses_resume);
							}
						});
					} else {
						self.updateData(id, usuario, []);
						self.progressiveUpdate(id, usuario, []);
					}
				},
				error: function error(e) {
					console.log(e);
				}
			});
		},
		setCursos: function setCursos(r, self) {

			if (r.estatus == 1) {
				r.status = true;
			} else {
				r.status = false;
			}

			self.set('curso', r);
		},

		numOfTimes: Ember.computed('times', function () {
			return [1, 2, 3, 4, 5];
		}),
		actions: {

			/**
    * printCertificate - Construye la URL para generar el certificado correspondiente a la RA.
   *
   *
   * Construye la URL para generar el certificado correspondiente a la RA.
   *
   *
    * @author  	[YRM] Maurilio Cruz Morales
   *	              maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *  	            Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *      	        Tel. celular (55) 6702 625
   *	@action		printCertificate
   ** @date   	2018-08-27
    *  @param   	void
    *  @return  	void
   *
   */
			printCertificate: function printCertificate() {

				var _curso = this.get('curso');
				location.href = constant.APIURL + 'lms/curso/certificado_pdf/' + _curso.certificado_k + '/' + _curso.fecha_fin;

				/*
           *  @todo:		Agregar fomateo de la variable _curso.fecha_fin, para garantizar que la fecha enviada al imprimir
              *  			el certificado, contenga el formato  'Y-m-d', considerando la siguiente línea de origen:
              *
              * location.href	= constant.APIURL + 'lms/curso/certificado_pdf/' + _curso.certificado_k + '/' + Ext.util.Format.date( _curso.fecha_fin, 'Y-m-d' );
              */
			},

			/**
    * goFacebook - goFacebook.
   *
   *
   * goFacebook.
   *
    *
    */

			goFacebook: function goFacebook() {
				this._super();
				var self = this;

				var url = window.location.href;
				var arrayUrl = url.split('/');
				shareFB(constant.SHAREURL + 'curso/' + arrayUrl[arrayUrl.length - 1]);

				//Agrega elemento en LOGROS
				window.add_user_action(parseInt(self.session.userData.usuario_k), 4, self.session.userData.nombre);
			},
			goTwitter: function goTwitter(e) {
				this._super();
				var self = this;
				//document.location.href = 'https://twitter.com/gobmx';
				console.log(Ember.$(e.target).parent().parent().parent().parent().parent().parent().parent().parent().parent().parent());
				console.log(Ember.$(e.target).parent().parent().parent().parent().parent().parent());
				var elementoPadreDom = Ember.$(e.target).parent().parent().parent().parent().parent().parent().children()[1];
				var elementoPadreEmberComponente = Ember.$(e.target).parent().parent().parent().parent().parent().parent().parent().parent().parent().parent().children()[3];

				//console.log($(elementoPadreDom).children().text());
				var twitterMsm = 'Se parte del curso ' + Ember.$(elementoPadreEmberComponente).children().children().text();

				var url = window.location.href;
				var arrayUrl = url.split('/');
				url = constant.SHAREURL + 'curso/' + arrayUrl[arrayUrl.length - 1];

				var width = 575,
				    height = 400,
				    left = (Ember.$(window).width() - width) / 2,
				    top = (Ember.$(window).height() - height) / 2,
				    url = 'https://twitter.com/share?url=' + url + '&text=' + twitterMsm,
				    opts = 'status=1' + ',width=' + width + ',height=' + height + ',top=' + top + ',left=' + left;
				window.open(url, 'twitter', opts);

				//Agrega elemento en LOGROS
				window.add_user_action(parseInt(self.session.userData.usuario_k), 4, self.session.userData.nombre);
			},
			linkedin: function linkedin() {
				this._super();
				var self = this;

				console.log('linkedIn');
				var url = window.location.href;
				var arrayUrl = url.split('/');
				shareLI(constant.SHAREURL + 'curso/' + arrayUrl[arrayUrl.length - 1]);

				//Agrega elemento en LOGROS
				window.add_user_action(parseInt(self.session.userData.usuario_k), 4, self.session.userData.nombre);
			},
			grade: function grade(event) {
				var numberStart = Ember.$(event.target).parent().attr('id');

				if (numberStart === 'uno_estrellas') {
					Ember.$('#uno_estrellas').html('<i class="fas fa-star"></i>');
					Ember.$('#dos_estrellas').html('<i class="far fa-star"></i>');
					Ember.$('#tres_estrellas').html('<i class="far fa-star"></i>');
					Ember.$('#cuatro_estrellas').html('<i class="far fa-star"></i>');
					Ember.$('#cinco_estrellas').html('<i class="far fa-star"></i>');
					//console.log('1 estrella');
				} else if (numberStart === 'dos_estrellas') {
					Ember.$('#uno_estrellas').html('<i class="fas fa-star"></i>');
					Ember.$('#dos_estrellas').html('<i class="fas fa-star"></i>');
					Ember.$('#tres_estrellas').html('<i class="far fa-star"></i>');
					Ember.$('#cuatro_estrellas').html('<i class="far fa-star"></i>');
					Ember.$('#cinco_estrellas').html('<i class="far fa-star"></i>');
					//console.log('2 estrellas');
				} else if (numberStart === 'tres_estrellas') {
					Ember.$('#uno_estrellas').html('<i class="fas fa-star"></i>');
					Ember.$('#dos_estrellas').html('<i class="fas fa-star"></i>');
					Ember.$('#tres_estrellas').html('<i class="fas fa-star"></i>');
					Ember.$('#cuatro_estrellas').html('<i class="far fa-star"></i>');
					Ember.$('#cinco_estrellas').html('<i class="far fa-star"></i>');
					//console.log('3 estrellas');
				} else if (numberStart === 'cuatro_estrellas') {
					Ember.$('#uno_estrellas').html('<i class="fas fa-star"></i>');
					Ember.$('#dos_estrellas').html('<i class="fas fa-star"></i>');
					Ember.$('#tres_estrellas').html('<i class="fas fa-star"></i>');
					Ember.$('#cuatro_estrellas').html('<i class="fas fa-star"></i>');
					Ember.$('#cinco_estrellas').html('<i class="far fa-star"></i>');
					//console.log('4 estrellas');
				} else if (numberStart === 'cinco_estrellas') {
					Ember.$('#uno_estrellas').html('<i class="fas fa-star"></i>');
					Ember.$('#dos_estrellas').html('<i class="fas fa-star"></i>');
					Ember.$('#tres_estrellas').html('<i class="fas fa-star"></i>');
					Ember.$('#cuatro_estrellas').html('<i class="fas fa-star"></i>');
					Ember.$('#cinco_estrellas').html('<i class="fas fa-star"></i>');
					//console.log('5 estrellas');
				} else {
					console.warn(numberStart);
				}
			},
			grade_platform: function grade_platform(event) {
				//console.log($(event.target).parent().attr('id'));
				var numberStart = Ember.$(event.target).parent().attr('id');
				if (numberStart === 'uno_estrellas_pltf') {
					Ember.$('#uno_estrellas_pltf').html('<i class="fas fa-star"></i>');
					Ember.$('#dos_estrellas_pltf').html('<i class="far fa-star"></i>');
					Ember.$('#tres_estrellas_pltf').html('<i class="far fa-star"></i>');
					Ember.$('#cuatro_estrellas_pltf').html('<i class="far fa-star"></i>');
					Ember.$('#cinco_estrellas_pltf').html('<i class="far fa-star"></i>');
					//$('#modal_grade').modal();
				} else if (numberStart === 'dos_estrellas_pltf') {
					Ember.$('#uno_estrellas_pltf').html('<i class="fas fa-star"></i>');
					Ember.$('#dos_estrellas_pltf').html('<i class="fas fa-star"></i>');
					Ember.$('#tres_estrellas_pltf').html('<i class="far fa-star"></i>');
					Ember.$('#cuatro_estrellas_pltf').html('<i class="far fa-star"></i>');
					Ember.$('#cinco_estrellas_pltf').html('<i class="far fa-star"></i>');
					//$('#modal_grade').modal();
				} else if (numberStart === 'tres_estrellas_pltf') {
					Ember.$('#uno_estrellas_pltf').html('<i class="fas fa-star"></i>');
					Ember.$('#dos_estrellas_pltf').html('<i class="fas fa-star"></i>');
					Ember.$('#tres_estrellas_pltf').html('<i class="fas fa-star"></i>');
					Ember.$('#cuatro_estrellas_pltf').html('<i class="far fa-star"></i>');
					Ember.$('#cinco_estrellas_pltf').html('<i class="far fa-star"></i>');
					//$('#modal_grade').modal();
				} else if (numberStart === 'cuatro_estrellas_pltf') {
					Ember.$('#uno_estrellas_pltf').html('<i class="fas fa-star"></i>');
					Ember.$('#dos_estrellas_pltf').html('<i class="fas fa-star"></i>');
					Ember.$('#tres_estrellas_pltf').html('<i class="fas fa-star"></i>');
					Ember.$('#cuatro_estrellas_pltf').html('<i class="fas fa-star"></i>');
					Ember.$('#cinco_estrellas_pltf').html('<i class="far fa-star"></i>');
					//$('#modal_grade').modal();
				} else if (numberStart === 'cinco_estrellas_pltf') {
					Ember.$('#uno_estrellas_pltf').html('<i class="fas fa-star"></i>');
					Ember.$('#dos_estrellas_pltf').html('<i class="fas fa-star"></i>');
					Ember.$('#tres_estrellas_pltf').html('<i class="fas fa-star"></i>');
					Ember.$('#cuatro_estrellas_pltf').html('<i class="fas fa-star"></i>');
					Ember.$('#cinco_estrellas_pltf').html('<i class="fas fa-star"></i>');
					//$('#modal_grade').modal();
				} else {
					console.warn(numberStart);
				}
			},
			send_grade: function send_grade() {
				var url_page = window.location.pathname;
				var id = url_page.substring(url_page.lastIndexOf('/') + 1);
				var self = this;

				var score_course = Ember.$('#modal_grade .modal-body').find('center').first().find('.fas').length;
				var score_platform = Ember.$('#modal_grade .modal-body').find('center').last().find('.fas').length;

				if (score_platform == 0 || score_course == 0) {
					Ember.$('#modal-avisos .modal-header').text('Aviso');
					Ember.$('#modal-avisos .modal-body').text('Porfavor valora el curso y la plataforma, nos ayudará a mejorar tu experiencia.');
					Ember.$('#modal-avisos').modal('show');
				} else {

					if (constant.FIREBASE_SCORE != '') {
						Ember.$.ajax({
							url: constant.FIREBASE_SCORE,
							type: 'POST',
							crossDomain: true,
							data: {
								method: "score_course",
								data: {
									id_course: parseInt(id),
									id_user: parseInt(self.session.userData.usuario_k),
									score: parseInt(score_course)
								}
							},
							success: function success(response) {
								Ember.$.ajax({
									url: constant.FIREBASE_SCORE,
									type: 'POST',
									crossDomain: true,
									data: {
										method: "score_platform",
										data: {
											id_user: parseInt(self.session.userData.usuario_k),
											score: parseInt(score_platform)
										}
									},
									success: function success(r) {
										Ember.$('#modal_grade').modal('hide');
										Ember.$.ajax({
											url: constant.APIURL + 'usuario/datospersonales/',
											type: 'POST',
											xhrFields: {
												withCredentials: true
											},
											contentType: 'application/x-www-form-urlencoded',
											success: function success(r) {
												var usuario = JSON.parse(r);
												Ember.$.ajax({
													url: constant.FIREBASE_SCORE,
													type: 'POST',
													data: {
														"method": "score_courses_resume"
													},
													success: function success(score_courses_resume) {

														self.updateData(id, usuario, score_courses_resume);
														self.progressiveUpdate(id, usuario, score_courses_resume);
													}
												});
											}
										});
									}
								});
							}
						});
					}
				}
			}
		}
	});
});