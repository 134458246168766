define('comunidades-nafin/components/admin-components/boton-admin-noticias', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            editarNoticias: function editarNoticias() {
                document.location.href = '/noticias';
            }
        }
    });
});