define('comunidades-nafin/routes/eventos', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var promise = new Ember.RSVP.Promise(function (resolve) {
        Ember.run.later(this, function () {
          resolve("ok");
        }, 1000);
      });
      return promise;
    },


    didReceiveAttrs: function didReceiveAttrs() {

      this._super.apply(this, arguments);
      this.resetNewEvent(this);
    },

    setupController: function setupController(controller) {

      controller.set('isAdmin', this.session.get('isAdmin'));

      Ember.$.ajax({
        url: constant.APIURL + 'calendario/listarProximosEventos',
        type: 'GET',
        xhrFields: { withCredentials: true },
        contentType: 'application/x-www-form-urlencoded',
        success: function success(response) {

          var data = JSON.parse(response);
          controller.set('proximosEventos', data.data);
        },
        error: function error(e) {
          console.log(e);
        }
      });
      Ember.$.ajax({
        url: constant.APIURL + 'usuario/datospersonales/',
        type: 'POST',
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: { withCredentials: true },
        success: function success(r) {

          var data = JSON.parse(r);
          //Redes sociales

          if (data.configuracion.general_compatir_eventos_en_facebook_habilitar == "false") {
            Ember.$("#facebook_share_actual").hide();
          } else {
            Ember.$("#facebook_share_actual").show();
          }
          if (data.configuracion.general_compatir_eventos_en_twitter_habilitar == "false") {
            Ember.$("#twitter_share_actual").hide();
          } else {
            Ember.$("#twitter_share_actual").show();
          }
          if (data.configuracion.general_compatir_eventos_en_linkedin_habilitar == "false") {
            Ember.$("#linkedin_share_actual").hide();
          } else {
            Ember.$("#linkedin_share_actual").show();
          }
        },
        error: function error(e) {

          console.log(e);
        }
      });
      setTimeout(function () {
        var _this = this;

        Ember.$('#facebook_share_actual').unbind("click");
        Ember.$('#facebook_share_actual').click(function () {
          // FB.ui({
          // 	method: 'share',
          // 	href: constant.APIURL + 'publico/sharing/evento/',
          // }, function (response) { });
          shareFB(constant.SHAREURL + 'evento/' + Ember.$(this).data('event'));
          //Agrega elemento en LOGROS
          window.add_user_action(parseInt(controller.session.userData.usuario_k), 4, controller.session.userData.nombre);
        });

        Ember.$('#twitter_share_actual').click(function (e) {

          // Selector al card del evento
          var elementoPadreDom = Ember.$(e.target).parent().parent().parent().parent().parent().parent().children();

          // Arreglo con los elementos titulo, fecha, hora, lugar del card
          var detalleEventoDom = elementoPadreDom[1];

          // Selector a tag ul de los elementos en detalle evento
          var dataEventoDom = Ember.$(detalleEventoDom).children()[0];

          // Arreglo con elementos li con datos de titulo, fecha, hora, lugar del card
          var arregloDatosEventoDetalle = Ember.$(dataEventoDom).children();

          var titulo = arregloDatosEventoDetalle[0];
          var fecha = arregloDatosEventoDetalle[1];
          var hora = arregloDatosEventoDetalle[2];
          var lugar = arregloDatosEventoDetalle[3];

          // obtenemos los strings generados en cada elemento
          var strTitulo = Ember.$(titulo).text();
          var strFecha = Ember.$(fecha).text();
          var strHora = Ember.$(hora).text();
          var strLugar = Ember.$(lugar).text();

          var twitterMsm = strTitulo + ' del ' + strFecha + ' de ' + strHora + ' en ' + strLugar;
          var url = constant.SHAREURL + 'evento/' + Ember.$('#titleCurrentEvent').data('id');

          var width = 575,
              height = 400,
              left = (Ember.$(window).width() - width) / 2,
              top = (Ember.$(window).height() - height) / 2,
              url = 'https://twitter.com/share?url=' + url + '&text=' + twitterMsm,
              opts = 'status=1' + ',width=' + width + ',height=' + height + ',top=' + top + ',left=' + left;
          window.open(url, 'twitter', opts);

          //Agrega elemento en LOGROS
          window.add_user_action(parseInt(controller.session.userData.usuario_k), 4, controller.session.userData.nombre);
        });

        //
        Ember.$('#linkedin_share_actual').click(function (e) {
          /// PORFAVOR ARREGLAR COMPARTIR TWITTER, LINKEDIN, FB

          shareLI(constant.SHAREURL + 'evento/' + Ember.$(_this).data('event'));
          window.add_user_action(parseInt(self.session.userData.usuario_k), 4, self.session.userData.nombre);
        });
      }, 100);
    },


    /*
    * setEditedEvent - función utilitaria.
    *
    *
    */

    setEditedEvent: function setEditedEvent(_evento_record, _scope) {

      if (!_scope) {
        _scope = this;
      }

      var new_event = {
        calendario_k: _evento_record.calendario_k,
        nombre: _evento_record.nombre,
        lugar: _evento_record.lugar,
        fecha: _evento_record.fecha,
        hora: _evento_record.hora,
        fecha_fin: _evento_record.fecha_fin,
        hora_fin: _evento_record.hora_fin,
        descripcion: _evento_record.descripcion,
        anfitrion_k: _evento_record.anfitrion_k
      };

      _scope.set('store_new_event', new_event);
    },

    /*
     * resetNewEvent - función utilitaria.
     *
     *
     */

    resetNewEvent: function resetNewEvent(_scope) {

      if (!_scope) {
        _scope = this;
      }

      var new_event = {
        calendario_k: '',
        nombre: '',
        lugar: '',
        fecha: '',
        hora: '',
        fecha_fin: '',
        hora_fin: '',
        descripcion: '',
        anfitrion_k: 0
      };

      _scope.set('store_new_event', new_event);
    },

    /*
     *
     */

    actions: {

      /*
       * showModalNewEvent - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
       *
       *
       * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
       *
       *
       * @author  [YRM] Maurilio Cruz Morales
       *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *              Tel. celular (55) 6702 6252
       *
       * @date    2018-10-04
       * @param   void
       * @return  void
       */

      showModalNewEvent: function showModalNewEvent() {

        this.resetNewEvent(this.controller);
        Ember.$('#modalFormNewEvent').modal('show');
      },

      /*
       * showModalEditEvent - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
       *
       *
       * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
       *
       *
       * @author  [YRM] Maurilio Cruz Morales
       *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *              Tel. celular (55) 6702 6252
       *
       * @date    2018-10-04
       * @param   void
       * @return  void
       */

      showModalEditEvent: function showModalEditEvent(_evento) {

        this.setEditedEvent(_evento, this.controller);
        Ember.$('#modalFormNewEvent').modal('show');
      },

      /*
       * showModalDeleteEvent - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
       *
       *
       * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
       *
       *
       * @author  [YRM] Maurilio Cruz Morales
       *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *              Tel. celular (55) 6702 6252
       *
       * @date    2018-10-04
       * @param   void
       * @return  void
       */

      showModalDeleteEvent: function showModalDeleteEvent(_evento) {

        this.setEditedEvent(_evento, this.controller);
        Ember.$('#modalConfirmDeleteEvent').modal('show');
      },

      /*
       * afterCreateEvent - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
       *
       *
       * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
       *
       *
       * @author  [YRM] Maurilio Cruz Morales
       *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *              Tel. celular (55) 6702 6252
       *
       * @date    2018-10-04
       * @param   void
       * @return  void
       */

      afterCreateEvent: function afterCreateEvent(data_object) {

        /*
         * Les indicamos a los componentes hijos que propaguen el evento afterDeleteEvent por medio de propagationAfterDeleteEvent.
         */

        if (this.eventCardCalendarScope) {

          if (isFunction(this.eventCardCalendarScope.propagationAfterCreateEvent)) {

            this.eventCardCalendarScope.propagationAfterCreateEvent(data_object);
          }
        }

        if (this.eventCalendarScope) {

          if (isFunction(this.eventCalendarScope.propagationAfterCreateEvent)) {

            this.eventCalendarScope.propagationAfterCreateEvent(data_object);
          }
        }

        /*
        * Solo si existe un callback_function válido, se ejecuta.
        */
        if (isFunction(data_object.callbackFunction)) {
          data_object.callbackFunction();
        }
      },

      /*
       * afterUpdateEvent - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
       *
       *
       * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
       *
       *
       * @author  [YRM] Maurilio Cruz Morales
       *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *              Tel. celular (55) 6702 6252
       *
       * @date    2018-10-04
       * @param   void
       * @return  void
       */

      afterUpdateEvent: function afterUpdateEvent(data_object) {

        /*
         * Les indicamos a los componentes hijos que propaguen el evento afterDeleteEvent por medio de propagationAfterDeleteEvent.
         */

        if (this.eventCardCalendarScope) {

          if (isFunction(this.eventCardCalendarScope.propagationAfterUpdateEvent)) {

            this.eventCardCalendarScope.propagationAfterUpdateEvent(data_object);
          }
        }

        if (this.eventCalendarScope) {

          if (isFunction(this.eventCalendarScope.propagationAfterUpdateEvent)) {

            this.eventCalendarScope.propagationAfterUpdateEvent(data_object);
          }
        }

        /*
        * Solo si existe un callback_function válido, se ejecuta.
        */

        if (isFunction(data_object.callbackFunction)) {
          data_object.callbackFunction();
        }
      },

      /*
       * afterDeleteEvent - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
       *
       *
       * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
       *
       *
       * @author  [YRM] Maurilio Cruz Morales
       *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *              Tel. celular (55) 6702 6252
       *
       * @date    2018-10-04
       * @param   void
       * @return  void
       */

      afterDeleteEvent: function afterDeleteEvent(data_object) {

        /*
         * Les indicamos a los componentes hijos que propaguen el evento afterDeleteEvent por medio de propagationAfterDeleteEvent.
         */

        if (this.eventCardCalendarScope) {

          if (isFunction(this.eventCardCalendarScope.propagationAfterDeleteEvent)) {

            this.eventCardCalendarScope.propagationAfterDeleteEvent(data_object);
          }
        }

        if (this.eventCalendarScope) {

          if (isFunction(this.eventCalendarScope.propagationAfterDeleteEvent)) {

            this.eventCalendarScope.propagationAfterDeleteEvent(data_object);
          }
        }

        /*
        * Solo si existe un callback_function válido, se ejecuta.
        */
        if (isFunction(data_object.callbackFunction)) {
          data_object.callbackFunction();
        }
      },

      /*
             * bindScopeToEventCardCalendarComponent - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
             *
             *
             * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
             *
             *
             * @author  [YRM] Maurilio Cruz Morales
             *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
             *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
             *              Tel. celular (55) 6702 6252
             *
             * @date    2018-10-04
             * @param   void
             * @return  void
             */

      bindScopeToEventCardCalendarComponent: function bindScopeToEventCardCalendarComponent(scope_event_card_calendar) {

        this.eventCardCalendarScope = scope_event_card_calendar;
      },

      /*
               * bindScopeToEventCardCalendarComponent - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
               *
               *
               * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
               *
               *
               * @author  [YRM] Maurilio Cruz Morales
               *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
               *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
               *              Tel. celular (55) 6702 6252
               *
               * @date    2018-10-04
               * @param   void
               * @return  void
               */

      bindScopeToEventCalendarComponent: function bindScopeToEventCalendarComponent(scope_event_calendar) {

        this.eventCalendarScope = scope_event_calendar;
      }

    }

  });
});