define('comunidades-nafin/components/modal-evaluation', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        didInsertElement: function didInsertElement() {
            var self = this;
            var url_page = window.location.pathname;
            var id = url_page.substring(url_page.lastIndexOf('/') + 1);
            Ember.$('#carouselQuestions').carousel();

            sessionStorage.setItem('guardarCambios', []);
            //window.array = [];
            window.lastArray = [];

            Ember.$.ajax({
                url: constant.APIURL + 'lms/alumno/listarrutas/listarmisras',
                type: 'POST',
                xhrFields: {
                    withCredentials: true
                },
                data: 'params={"ra_k":' + id + '}',
                success: function success(r) {
                    var response = JSON.parse(r);
                    var ra;
                    Object.keys(response.data).forEach(function (idx) {
                        ra = response.data[idx].intento_ra;
                    });
                    Ember.$.ajax({
                        url: constant.APIURL + 'usuario/datospersonales/',
                        type: 'POST',
                        xhrFields: {
                            withCredentials: true
                        },
                        contentType: 'application/x-www-form-urlencoded',
                        success: function success(r) {
                            var usuario = JSON.parse(r);
                            Ember.$.ajax({
                                url: constant.APIURL + 'lms/raprendizaje/listarProgreso',
                                type: 'POST',
                                xhrFields: {
                                    withCredentials: true
                                },
                                contentType: 'application/x-www-form-urlencoded',
                                data: 'usuario_k=' + usuario.usuario_k + '&llave_propietario_k=' + id + '&intento_ra=' + ra + '&cat=is null',
                                success: function success(response) {
                                    var r = JSON.parse(response);
                                    // console.log(r.ra.estatus)
                                    //var datum = r.data;
                                    //Elementos necesarios para finalizar evaluación 
                                    window.grupo_k = r.ra.grupo_k;
                                    window.intento_ra = r.ra.intento_ra;
                                },
                                error: function error(e) {
                                    console.log(e);
                                }
                            });
                        },
                        error: function error(e) {
                            console.error(e);
                        }
                    });
                },
                error: function error(e) {
                    console.error(e);
                }
            });

            var estadoPregunta = false;
            Ember.$('.carousel-item.active [type=number], carousel-item.active textarea:selected').each(function () {
                Ember.$(this).keyup(function () {
                    if (Ember.$(this).val() !== '') {
                        estadoPregunta = true;
                        console.log(estadoPregunta);
                        Ember.$('#btn_nxt').prop("disabled", false);
                    }
                });
            });
        },
        setEvaluacion: function setEvaluacion(r, self) {
            self.set('evaluacion', r);
        },
        setPreguntas: function setPreguntas(r, self) {
            self.set('preguntas', r);
        },

        actions: {
            prev_qs: function prev_qs() {
                var array = Ember.$('#contadorPreguntas').text().split(' ');
                var currentQuestion = array[1];
                var total = array[3];
                var prevQuestion = parseInt(currentQuestion) - 1;
                console.log(array, currentQuestion, prevQuestion, total);

                //Obtener y enviar pregunta actual


                //Cambiar controles
                Ember.$('#btn_end').attr('hidden', true);
                Ember.$('#btn_nxt').removeAttr('hidden');
                if (prevQuestion == 1) {
                    Ember.$('#btn_prv').attr('hidden', true);
                } else {
                    Ember.$('#btn_prv').removeAttr('hidden');
                }
                //Desplazar carrusel y actualizar unfo
                Ember.$('#contadorPreguntas').html('Pregunta ' + prevQuestion + ' de ' + total);
                Ember.$('#carouselQuestions').carousel('prev');
                Ember.$('#btn_nxt').prop("disabled", false);
            },
            validaciones_pregunta: function validaciones_pregunta() {
                if (Ember.$('.carousel-item.active').data('obligatorio') == 1) {
                    Ember.$('#btn_nxt').prop("disabled", true);
                    setTimeout(function () {
                        if (Ember.$('.carousel-item.active input:checked').prop('checked') === true) {
                            console.log('dentro de if prop checked');
                            Ember.$('#btn_nxt').prop("disabled", false);
                        } else {
                            Ember.$('#btn_nxt').prop("disabled", true);
                        }
                    }, 800);
                }

                setTimeout(function () {

                    Ember.$('.carousel-item.active textarea').each(function () {
                        Ember.$(this).on('keyup mouseup change', function () {
                            console.log('textarea: keyup mouseup change');
                            if (Ember.$(this).val() !== '' && Ember.$(this).val() != 0) {
                                Ember.$('#btn_nxt').prop("disabled", false);
                            } else {
                                Ember.$('#btn_nxt').prop("disabled", true);
                            }
                        });
                    });
                    Ember.$('.carousel-item.active input[type=number]').each(function () {
                        Ember.$(this).on('keyup mouseup change', function () {
                            console.log('type=number: keyup mouseup');
                            var pass = 0;
                            Ember.$('.carousel-item.active input[type=number]').each(function () {
                                if (Ember.$(this).val() !== '' && Ember.$(this).val() != 0 && parseInt(Ember.$(this).val()) <= parseInt(Ember.$(this).attr('max')) && parseInt(Ember.$(this).val()) >= parseInt(Ember.$(this).attr('min'))) {
                                    pass++;
                                }
                            });
                            if (pass == Ember.$('.carousel-item.active input[type=number]').length) {
                                Ember.$('#btn_nxt').prop("disabled", false);
                            } else {
                                Ember.$('#btn_nxt').prop("disabled", true);
                            }
                        });
                    });
                    Ember.$('.carousel-item.active select').each(function () {
                        Ember.$(this).change(function () {
                            if (Ember.$(this).val() !== '') {
                                Ember.$('#btn_nxt').prop("disabled", false);
                            }
                        });
                    });
                }, 800);
            },
            next_qs: function next_qs() {
                var array = Ember.$('#contadorPreguntas').text().split(' ');
                var currentQuestion = array[1];
                var total = array[3];
                var nextQuestion = parseInt(currentQuestion) + 1;
                //$('#btn_nxt').prop( "disabled",true);
                // comando para console.trace console.trace = function() {}
                //'LMS_PREGUNTAS_TIPO_RESPUESTA_SI_NO',              1 
                //'LMS_PREGUNTAS_TIPO_RESPUESTA_UNICA',              2 
                //'LMS_PREGUNTAS_TIPO_RESPUESTA_MULTIPLE',           3 
                //'LMS_PREGUNTAS_TIPO_RESPUESTA_LISTA_DESPLEGABLE',  4 
                //'LMS_PREGUNTAS_TIPO_RESPUESTA_RELACIONAR_COLUMNAS',5 
                //'LMS_PREGUNTAS_TIPO_RESPUESTA_PORCENTAJE',         6 
                //'LMS_PREGUNTAS_TIPO_RESPUESTA_PUNTUACION',         7 
                //'LMS_PREGUNTAS_TIPO_RESPUESTA_ABIERTA',            8 
                //'LMS_PREGUNTAS_TIPO_RESPUESTA_ORDENAR_RESPUESTA',  9 
                //'LMS_PREGUNTAS_TIPO_RESPUESTA_CALIFICAR_NIVELES',  10

                /*if (('.carousel-item.active').data('obligatorio') == 1) {
                    $('#btn_nxt').prop( "disabled",true);
                }*/

                var tipo_pregunta_k = Ember.$('.carousel-item.active h2').data('tipo');
                if (tipo_pregunta_k == 1) {
                    console.log('respuesta si no');
                    this.lms_pregunta_sino();
                } else if (tipo_pregunta_k == 2) {
                    this.lms_pregunta_unica();
                } else if (tipo_pregunta_k == 3) {
                    this.lms_pregunta_multiple();
                } else if (tipo_pregunta_k == 4) {
                    if (Ember.$('.carousel-item.active select option:selected').val() == '') {
                        return;
                    }
                    this.lms_pregunta_seleccion();
                } else if (tipo_pregunta_k == 5) {
                    this.lms_pregunta_relacion();
                } else if (tipo_pregunta_k == 6) {
                    this.lms_pregunta_porcentaje();
                } else if (tipo_pregunta_k == 7) {
                    this.lms_pregunta_puntuacion();
                } else if (tipo_pregunta_k == 8) {
                    this.lms_pregunta_abierta();
                } else if (tipo_pregunta_k == 9) {
                    var valor = [];
                    var repetida = false;

                    Ember.$('.carousel-item.active select option:selected').each(function () {
                        if (valor.indexOf(Ember.$(this).text().trim()) != -1) {
                            repetida = true;
                        }
                        valor.push(Ember.$(this).text().trim());
                    });

                    if (repetida) return;
                    this.pregunta_ordenar_respuesta();
                }

                //Cambiar controles
                Ember.$('#btn_prv').removeAttr('hidden');
                if (nextQuestion == total) {
                    Ember.$('#btn_nxt').attr('hidden', true);
                    Ember.$('#btn_end').removeAttr('hidden');
                } else {
                    Ember.$('#btn_nxt').removeAttr('hidden');
                    Ember.$('#btn_end').attr('hidden', true);
                }
                //Desplazar carrusel y actualizar unfo
                Ember.$('#contadorPreguntas').html('Pregunta ' + nextQuestion + ' de ' + total);
                Ember.$('#carouselQuestions').carousel('next');
                var estadoPregunta = false;

                this.send('validaciones_pregunta');
            },
            cerrar: function cerrar() {
                this._super();
                var self = this;
                Ember.$('.bd-evaluacion-modal-lg').modal('hide');
                var url_page = window.location.pathname;
                var id = url_page.substring(url_page.lastIndexOf('/') + 1);

                Ember.$.ajax({
                    url: constant.APIURL + 'usuario/datospersonales/',
                    type: 'POST',
                    xhrFields: {
                        withCredentials: true
                    },
                    contentType: 'application/x-www-form-urlencoded',
                    success: function success(r) {
                        //console.log(r);
                        var usuario = JSON.parse(r);
                        console.log(usuario);

                        Ember.$.ajax({
                            url: constant.APIURL + 'lms/alumno/listarrutas/listarmisras',
                            type: 'POST',
                            xhrFields: {
                                withCredentials: true
                            },
                            data: 'params={"ra_k":' + id + '}',
                            success: function success(r) {
                                var response = JSON.parse(r);
                                var ra;
                                Object.keys(response.data).forEach(function (idx) {
                                    ra = response.data[idx].intento_ra;
                                });

                                Ember.$.ajax({
                                    url: constant.APIURL + 'lms/raprendizaje/listarProgreso',
                                    type: 'POST',
                                    xhrFields: {
                                        withCredentials: true
                                    },
                                    contentType: 'application/x-www-form-urlencoded',
                                    data: 'usuario_k=' + usuario.usuario_k + '&llave_propietario_k=' + id + '&intento_ra=' + ra + '&cat=is null',
                                    success: function success(response) {
                                        var r = JSON.parse(response);

                                        //r.data[idx].habilitar_calificar_ra = "1";
                                        //r.ra.estatus == '8' || (r.ra.estatus == '9' &&  (parseInt(r.ra.numero_intentos)>=parseInt(r.ra.limite_intentos)) ) && r.data[idx].habilitar_calificar_ra == "0"
                                        /*if (r.ra.estatus == '8' && r.data[idx].habilitar_calificar_ra == "1") {
                                            //Finalizado
                                            console.log('moda-evaluation');
                                            $('#modal_grade').modal({
                                                backdrop: 'static',
                                                keyboard: false
                                            });
                                            $('#modal_grade').on('hidden.bs.modal', function () {
                                                document.location.reload();
                                            });
                                             //Contar accion para LOGROS
                                            window.add_user_action(parseInt(self.session.userData.usuario_k),2,self.session.userData.nombre); 
                                        }*/

                                        //r.data[idx].habilitar_calificar_ra = "1";
                                    },
                                    error: function error(e) {
                                        console.log(e);
                                    }
                                });
                            },
                            error: function error(e) {}
                        });
                    },
                    error: function error(e) {
                        console.error(e);
                    }
                });
            },
            finalizar: function finalizar() {
                //$('#' + window.buttoIngresar).prop('disabled', true);
                var array = Ember.$('#contadorPreguntas').text().split(' '); // Separar cadena en Strings
                // Tomare elementos del DOM
                var currentQuestion = array[1];
                var recurso_alumno_resultado_k = sessionStorage.getItem('recurso_alumno_resultado_k');
                var respuesta_k = Ember.$('input[name=_' + (parseInt(currentQuestion) - 1) + ']', '.carousel-item').attr('id');
                console.log(recurso_alumno_resultado_k);
                console.log(respuesta_k);
                console.log(currentQuestion);

                var usuario_k = void 0; //$('.carousel-item.active h2').data('usuario');
                var propietario_recurso_k = Ember.$('.carousel-item.active h2').data('propietario');
                var tipo_pregunta_k = Ember.$('.carousel-item.active h2').data('tipo');

                if (tipo_pregunta_k == 1) {
                    this.lms_pregunta_sino();
                } else if (tipo_pregunta_k == 2) {
                    this.lms_pregunta_unica();
                } else if (tipo_pregunta_k == 3) {
                    this.lms_pregunta_multiple();
                } else if (tipo_pregunta_k == 4) {
                    if (Ember.$('.carousel-item.active select option:selected').val() == '') {
                        return;
                    }
                    this.lms_pregunta_seleccion();
                } else if (tipo_pregunta_k == 5) {
                    this.lms_pregunta_relacion();
                } else if (tipo_pregunta_k == 6) {
                    this.lms_pregunta_porcentaje();
                } else if (tipo_pregunta_k == 7) {
                    this.lms_pregunta_puntuacion();
                } else if (tipo_pregunta_k == 8) {
                    this.lms_pregunta_abierta();
                }

                setTimeout(function () {
                    Ember.$.ajax({
                        url: constant.APIURL + 'lms/evaluacion/finalizaraplicar',
                        type: 'POST',
                        xhrFields: {
                            withCredentials: true
                        },
                        contentType: 'application/x-www-form-urlencoded',
                        data: 'recurso_alumno_resultado_k=' + recurso_alumno_resultado_k + ' & propietario_recurso_k=' + propietario_recurso_k + ' & intento_ra=' + window.intento_ra + ' & grupo_k=' + window.grupo_k + ' & usuario_k=&preguntas=' + '[' + window.lastArray + ']',
                        success: function success(response) {
                            console.log(response);
                            Ember.$.ajax({
                                url: constant.APIURL + 'lms/encuesta/obtenerresultados',
                                type: 'POST',
                                xhrFields: {
                                    withCredentials: true
                                },
                                contentType: 'application/x-www-form-urlencoded',
                                data: '_dc= & recurso_alumno_resultado_k=' + recurso_alumno_resultado_k + ' & usuario_k= ',
                                success: function success(response) {
                                    var r = JSON.parse(response);
                                    var contador = 0;
                                    var evaluacion_autodiagnostica = '0';
                                    console.log(r.data);
                                    var html = '';
                                    Object.keys(r.data).forEach(function (idx) {
                                        evaluacion_autodiagnostica = r.data[idx].evaluacion_autodiagnostica;

                                        if (r.data[idx].correcta === '1') {
                                            contador++;
                                            html += '<center><p>' + r.data[idx].pregunta + ' <i class="fas fa-check"></i> </p></center>';
                                        } else {
                                            html += '<center><p>' + r.data[idx].pregunta + ' <i class="fas fa-times"></i> </p></center>';
                                        }
                                        console.log('*****************');
                                        console.log(r.data[idx]);
                                        console.log('*****************');

                                        var ajaxDatosPersonales = Ember.$.ajax({
                                            url: constant.APIURL + 'usuario/datospersonales/',
                                            type: 'POST',
                                            xhrFields: {
                                                withCredentials: true
                                            },
                                            success: function success(r) {
                                                //console.log(JSON.parse(r));
                                                return JSON.parse(r);
                                            },
                                            error: function error(err) {
                                                console.error(err);
                                            }
                                        });

                                        Ember.$.when(ajaxDatosPersonales).done(function (datosPersonalesText) {
                                            console.log('await ajax');

                                            var datosPersonales = JSON.parse(datosPersonalesText);
                                            var user_id = datosPersonales.usuario_k;
                                            var course_id = parseInt(document.location.href.split('/')[document.location.href.split('/').length - 1]);

                                            console.log(datosPersonales);
                                        });

                                        if ((r.data[idx].estatus == 'Aprobado' || r.data[idx].estatus == '8') && window.isHabilitarCalifRa) {

                                            Ember.$.ajax({
                                                url: constant.FIREBASE_SCORE,
                                                type: 'POST',
                                                contentType: 'application/x-www-form-urlencoded',
                                                data: {
                                                    method: "user_already_score",
                                                    data: {
                                                        "id_user": window.datosScoredUser.id_user,
                                                        "id_course": window.datosScoredUser.id_course
                                                    }
                                                },
                                                success: function success(r) {
                                                    if (!r.alredyScored) {

                                                        console.log('**** modal-evaluation ****');
                                                        Ember.$('#modal_grade').modal({
                                                            backdrop: 'static',
                                                            keyboard: false
                                                        });
                                                        Ember.$('#modal_grade').on('hidden.bs.modal', function () {
                                                            document.location.reload();
                                                        });
                                                    } else {
                                                        notScored = false;
                                                    }
                                                },
                                                error: function error(err) {
                                                    console.error(err);
                                                }
                                            });
                                        }
                                    });
                                    var grade = 100 / r.data.length * contador;
                                    console.log(grade);
                                    Ember.$('#carouselQuestions').html('<center><h2>Tu evaluación ha Finalizado.</h2></center>');
                                    Ember.$('#carouselQuestions').append('<center><h2>Tu resultado fue de: ' + parseInt(grade) + '</h2></center>');
                                    Ember.$('#carouselQuestions').append(html);

                                    /*if(evaluacion_autodiagnostica=='1'){
                                        $('#carouselQuestions').html('<center><h2>Tu evaluación ha Finalizado.</h2></center>');
                                    }
                                    else{
                                        $('#carouselQuestions').html('<center><h2>Tu resultado fue de: ' + grade + '</h2></center>');
                                    }*/
                                    window.lastArray = [];
                                },
                                error: function error(e) {
                                    console.error(e);
                                    Ember.$('#carouselQuestions').html('<center><h2>Oops los sentimos tenemos alguno problemas</h2></center>');
                                }
                            });
                        },
                        error: function error(e) {
                            console.error(e);
                        }
                    });
                }, 500);

                Ember.$('#btn_end').attr('hidden', true);
                Ember.$('#btn_prv').attr('hidden', true);
                Ember.$('#btn_close').removeAttr('hidden');
            }
        },

        // recurso_alumno_resultado_k: 8973757
        // propietario_recurso_k: 313
        // intento_ra: 2
        // grupo_k: 44
        // usuario_k: 
        // preguntas: []

        lms_pregunta_relacion: function lms_pregunta_relacion() {
            var array = Ember.$('#contadorPreguntas').text().split(' ');
            var currentQuestion = array[1];
            var total = array[3];
            var nextQuestion = parseInt(currentQuestion) + 1;
            var propietario_recurso_k = Ember.$('.carousel-item.active h2').data('propietario');

            //Obtener y enviar pregunta actual
            var recurso_alumno_resultado_k = sessionStorage.getItem('recurso_alumno_resultado_k');
            var pregunta_k = Ember.$('.carousel-item.active ul').first().data('quest');
            array = [];

            var segmento = 'evaluacion';
            if (Ember.$('.carousel-item.active').data('tabla') == 'lms_encuestas') {
                segmento = 'encuesta';
            }

            Ember.$('.carousel-item.active select').each(function () {
                array.push({
                    'correcta': Ember.$(this).attr('id'),
                    'orden': Ember.$(this).data('orden'),
                    'respuesta': Ember.$(this).find('option:selected').text(),
                    'respuesta_k': Ember.$(this).val()
                });
                console.log(Ember.$(this, 'option:selected').text());
            });
            var data = {
                'pregunta_k': pregunta_k,
                'tipo_pregunta_k': "5",
                'respuestas': array
            }; // Generar JSON para mandar en petición AJAX

            window.lastArray.push(JSON.stringify(data));
            Ember.$.ajax({
                url: constant.APIURL + 'lms/' + segmento + '/guardarcambios',
                type: 'POST',
                xhrFields: {
                    withCredentials: true
                },
                contentType: 'application/x-www-form-urlencoded',
                data: 'recurso_alumno_resultado_k=' + recurso_alumno_resultado_k + '&propietario_recurso_k=' + propietario_recurso_k + '&intento_ra=' + window.intento_ra + '&grupo_k=' + window.grupo_k + '&usuario_k=&preguntas=[' + window.lastArray + ']',
                success: function success(response) {
                    console.log(response);
                },
                error: function error(e) {
                    console.error(e);
                    window.lastArray.pop();
                }
            });
        },
        lms_pregunta_sino: function lms_pregunta_sino() {
            var array = Ember.$('#contadorPreguntas').text().split(' ');
            var currentQuestion = array[1];

            //Obtener y enviar pregunta actual
            //let evaluacion_usuario_k = sessionStorage.getItem('evaluacion_usuario_k');
            var pregunta_k = Ember.$('input[name=_' + (parseInt(currentQuestion) - 1) + ']:checked').data('quest');
            var propietario_recurso_k = Ember.$('.carousel-item.active h2').data('propietario');
            var recurso_alumno_resultado_k = sessionStorage.getItem('recurso_alumno_resultado_k');
            array = [];

            var segmento = 'evaluacion';
            if (Ember.$('.carousel-item.active').data('tabla') == 'lms_encuestas') {
                segmento = 'encuesta';
            }

            Ember.$('.carousel-item.active input').each(function () {
                var correcta = false;
                var respuesta = 'No';
                var respuesta_k = Ember.$(this).attr('id');

                if (Ember.$(this).prop('checked') === true) {
                    correcta = true;
                    respuesta = Ember.$(Ember.$(this).parent().children()[1]).text();
                    //console.log($($(this).parent().children()[1]).text());
                } else {
                    correcta = false;
                    respuesta = Ember.$(Ember.$(this).parent().children()[1]).text();
                }
                array.push({
                    'correcta': correcta,
                    'orden': Ember.$(this).data('orden'),
                    'respuesta': respuesta,
                    'respuesta_k': respuesta_k
                });
            });
            var data = {
                'pregunta_k': pregunta_k,
                'tipo_pregunta_k': "1",
                'respuestas': array
            }; // Generar JSON para mandar en petición AJAX

            window.lastArray.push(JSON.stringify(data));
            Ember.$.ajax({
                url: constant.APIURL + 'lms/' + segmento + '/guardarcambios',
                type: 'POST',
                xhrFields: {
                    withCredentials: true
                },
                contentType: 'application/x-www-form-urlencoded',
                data: 'recurso_alumno_resultado_k=' + recurso_alumno_resultado_k + '&propietario_recurso_k=' + propietario_recurso_k + '&intento_ra=' + window.intento_ra + '&grupo_k=' + window.grupo_k + '&usuario_k=&preguntas=[' + window.lastArray + ']',
                success: function success(response) {
                    console.log(response);
                },
                error: function error(e) {
                    console.error(e);
                    window.lastArray.pop();
                }
            });
            /*$.ajax({
                url: constant.APIURL + 'lms/evaluacion/guardarcambios',
                type: 'POST',
                xhrFields: {
                    withCredentials: true
                },
                contentType: 'application/x-www-form-urlencoded',
                data: 'propietario_recurso_k='+propietario_recurso_k+'&evaluacion_usuario_k=' + evaluacion_usuario_k + ' & usuario_k=& pregunta_k=' + pregunta_k + '&pregunta=' + JSON.stringify(data),
                success(response) {
                    console.log(response);
                    window.lastArray.push(JSON.stringify(data));
                },
                error(e) {
                    console.error(e);
                }
            });*/
        },
        lms_pregunta_unica: function lms_pregunta_unica() {
            var array = Ember.$('#contadorPreguntas').text().split(' ');
            var currentQuestion = array[1];
            var total = array[3];
            var nextQuestion = parseInt(currentQuestion) + 1;

            //Obtener y enviar pregunta actual
            var recurso_alumno_resultado_k = sessionStorage.getItem('recurso_alumno_resultado_k');
            var pregunta_k = Ember.$('input[name=_' + (parseInt(currentQuestion) - 1) + ']:checked').data('quest');
            var propietario_recurso_k = Ember.$('.carousel-item.active h2').data('propietario');
            array = [];

            var segmento = 'evaluacion';
            if (Ember.$('.carousel-item.active').data('tabla') == 'lms_encuestas') {
                segmento = 'encuesta';
            }
            Ember.$('.carousel-item.active input').each(function () {
                var correcta = false;
                var respuesta = 'Falso';
                var respuesta_k = Ember.$(this).attr('id');
                //console.log($(this).prop('checked'));
                if (Ember.$(this).prop('checked') === true) {
                    correcta = true;
                    respuesta = Ember.$(Ember.$(this).parent().children()[1]).text();
                    //console.log($($(this).parent().children()[1]).text());
                } else {
                    correcta = false;
                    respuesta = Ember.$(Ember.$(this).parent().children()[1]).text();
                }
                //console.log($(this));
                array.push({
                    'correcta': correcta,
                    'orden': Ember.$(this).data('orden'),
                    'respuesta': respuesta,
                    'respuesta_k': respuesta_k
                });
            });
            var data = {
                'pregunta_k': pregunta_k,
                'tipo_pregunta_k': "2",
                'respuestas': array
            }; // Generar JSON para mandar en petición AJAX

            window.lastArray.push(JSON.stringify(data));
            Ember.$.ajax({
                url: constant.APIURL + 'lms/' + segmento + '/guardarcambios',
                type: 'POST',
                xhrFields: {
                    withCredentials: true
                },
                contentType: 'application/x-www-form-urlencoded',
                data: 'recurso_alumno_resultado_k=' + recurso_alumno_resultado_k + '&propietario_recurso_k=' + propietario_recurso_k + '&intento_ra=' + window.intento_ra + '&grupo_k=' + window.grupo_k + '&usuario_k=&preguntas=[' + window.lastArray + ']',
                success: function success(response) {
                    console.log(response);
                },
                error: function error(e) {
                    console.error(e);
                    window.lastArray.pop();
                }
            });
            /*$.ajax({
                url: constant.APIURL + 'lms/evaluacion/guardarcambios',
                type: 'POST',
                xhrFields: {
                    withCredentials: true
                },
                contentType: 'application/x-www-form-urlencoded',
                data: 'intento_ra=' + window.intento_ra + '&grupo_k=' + window.grupo_k + '&usuario_k=' + '&recurso_alumno_resultado_k=' + recurso_alumno_resultado_k + '&preguntas=[' + JSON.stringify(data) + ']',
                success(response) {
                    console.log(response);
                    console.log(data);
                    window.lastArray.push(JSON.stringify(data));
                },
                error(e) {
                    console.error(e);
                }
            });*/
        },
        lms_pregunta_multiple: function lms_pregunta_multiple() {
            var array = Ember.$('#contadorPreguntas').text().split(' ');
            var currentQuestion = array[1];
            var total = array[3];
            var nextQuestion = parseInt(currentQuestion) + 1;
            //console.log('respuesta multiple');
            //Obtener y enviar pregunta actual
            var recurso_alumno_resultado_k = sessionStorage.getItem('recurso_alumno_resultado_k');
            var pregunta_k = Ember.$('.carousel-item.active input').first().data('quest');
            var propietario_recurso_k = Ember.$('.carousel-item.active h2').data('propietario');
            array = [];

            var segmento = 'evaluacion';
            if (Ember.$('.carousel-item.active').data('tabla') == 'lms_encuestas') {
                segmento = 'encuesta';
            }
            Ember.$('.carousel-item.active input').each(function () {
                var correcta = false;
                var respuesta = 'incorrecta';
                var respuesta_k = Ember.$(this).attr('id');
                if (Ember.$(this).prop('checked') === true) {
                    correcta = true;
                    respuesta = Ember.$(Ember.$(this).parent().children()[1]).text();
                } else {
                    correcta = false;
                    respuesta = Ember.$(Ember.$(this).parent().children()[1]).text();
                }
                array.push({
                    'correcta': correcta,
                    'orden': Ember.$(this).data('orden'),
                    'respuesta': respuesta,
                    'respuesta_k': respuesta_k
                });
            });
            var data = {
                'pregunta_k': pregunta_k,
                'tipo_pregunta_k': "3",
                'respuestas': array
            }; // Generar JSON para mandar en petición AJAX

            window.lastArray.push(JSON.stringify(data));
            Ember.$.ajax({
                url: constant.APIURL + 'lms/' + segmento + '/guardarcambios',
                type: 'POST',
                xhrFields: {
                    withCredentials: true
                },
                contentType: 'application/x-www-form-urlencoded',
                data: 'recurso_alumno_resultado_k=' + recurso_alumno_resultado_k + '&propietario_recurso_k=' + propietario_recurso_k + '&intento_ra=' + window.intento_ra + '&grupo_k=' + window.grupo_k + '&usuario_k=&preguntas=[' + window.lastArray + ']',
                success: function success(response) {
                    console.log(response);
                },
                error: function error(e) {
                    console.error(e);
                    window.lastArray.pop();
                }
            });

            /*$.ajax({
                url: constant.APIURL + 'lms/evaluacion/guardarcambios',
                type: 'POST',
                xhrFields: {
                    withCredentials: true
                },
                contentType: 'application/x-www-form-urlencoded',
                data: 'usuario_k=&recurso_alumno_resultado_k=' + recurso_alumno_resultado_k + '&preguntas=[' + JSON.stringify(data) + ']',
                success(response) {
                    console.log(response);
                    window.lastArray.push(JSON.stringify(data));
                },
                error(e) {
                    console.error(e);
                }
            });*/
        },
        lms_pregunta_puntuacion: function lms_pregunta_puntuacion() {
            var array = Ember.$('#contadorPreguntas').text().split(' ');
            var currentQuestion = array[1];
            var total = array[3];
            var nextQuestion = parseInt(currentQuestion) + 1;
            var propietario_recurso_k = Ember.$('.carousel-item.active h2').data('propietario');
            array = [];
            var recurso_alumno_resultado_k = sessionStorage.getItem('recurso_alumno_resultado_k');
            var pregunta_k = Ember.$('input[name=_' + (parseInt(currentQuestion) - 1) + ']:checked').data('quest');
            //Obtener y enviar pregunta actual


            var segmento = 'evaluacion';
            if (Ember.$('.carousel-item.active').data('tabla') == 'lms_encuestas') {
                segmento = 'encuesta';
            }
            Ember.$('.carousel-item.active input').each(function () {
                array.push({
                    'respuesta_k': Ember.$(this).attr('id'),
                    'correcta': Ember.$(this).val(),
                    orden: Ember.$(this).data('orden'),
                    'respuesta': Ember.$(this).parent().text().trim()
                });
            });

            var data = {
                'pregunta_k': pregunta_k,
                'tipo_pregunta_k': "7",
                'respuestas': array
            }; // Generar JSON para mandar en petición AJAX

            window.lastArray.push(JSON.stringify(data));
            Ember.$.ajax({
                url: constant.APIURL + 'lms/' + segmento + '/guardarcambios',
                type: 'POST',
                xhrFields: {
                    withCredentials: true
                },
                contentType: 'application/x-www-form-urlencoded',
                data: 'recurso_alumno_resultado_k=' + recurso_alumno_resultado_k + '&propietario_recurso_k=' + propietario_recurso_k + '&intento_ra=' + window.intento_ra + '&grupo_k=' + window.grupo_k + '&usuario_k=&preguntas=[' + window.lastArray + ']',
                success: function success(response) {
                    console.log(response);
                },
                error: function error(e) {
                    console.error(e);
                    window.lastArray.pop();
                }
            });

            /*$.ajax({
                url: constant.APIURL + 'lms/evaluacion/guardarcambios',
                type: 'POST',
                xhrFields: {
                    withCredentials: true
                },
                contentType: 'application/x-www-form-urlencoded',
                data: 'usuario_k=&recurso_alumno_resultado_k=' + recurso_alumno_resultado_k + '&preguntas=[' + JSON.stringify(data) + ']',
                success(response) {
                    console.log(response);
                    window.lastArray.push(JSON.stringify(data));
                },
                error(e) {
                    console.error(e);
                }
            });*/
        },
        lms_pregunta_seleccion: function lms_pregunta_seleccion() {
            var array = Ember.$('#contadorPreguntas').text().split(' ');
            var currentQuestion = array[1];
            var total = array[3];
            var nextQuestion = parseInt(currentQuestion) + 1;

            //Obtener y enviar pregunta actual
            var recurso_alumno_resultado_k = sessionStorage.getItem('recurso_alumno_resultado_k');
            var pregunta_k = Ember.$('.carousel-item.active select').data('quest');
            var propietario_recurso_k = Ember.$('.carousel-item.active h2').data('propietario');
            array = [];

            var segmento = 'evaluacion';
            if (Ember.$('.carousel-item.active').data('tabla') == 'lms_encuestas') {
                segmento = 'encuesta';
            }
            Ember.$('.carousel-item.active select option').each(function () {
                var correcta = false;
                var respuesta = 'incorrecta';
                var respuesta_k = Ember.$(this).attr('id');
                if (Ember.$(this).is(':selected')) {
                    correcta = true;
                    respuesta = Ember.$(this).text();
                }
                array.push({
                    'correcta': correcta,
                    'orden': Ember.$(this).data('orden'),
                    'respuesta': respuesta,
                    'respuesta_k': respuesta_k
                });
            });
            var data = {
                'pregunta_k': String(pregunta_k),
                'tipo_pregunta_k': "4",
                'respuestas': array
            }; // Generar JSON para mandar en petición AJAX


            window.lastArray.push(JSON.stringify(data));
            Ember.$.ajax({
                url: constant.APIURL + 'lms/' + segmento + '/guardarcambios',
                type: 'POST',
                xhrFields: {
                    withCredentials: true
                },
                contentType: 'application/x-www-form-urlencoded',
                data: 'recurso_alumno_resultado_k=' + recurso_alumno_resultado_k + '&propietario_recurso_k=' + propietario_recurso_k + '&intento_ra=' + window.intento_ra + '&grupo_k=' + window.grupo_k + '&usuario_k=&preguntas=[' + window.lastArray + ']',
                success: function success(response) {
                    console.log(response);
                },
                error: function error(e) {
                    console.error(e);
                    window.lastArray.pop();
                }
            });

            /*$.ajax({
                url: constant.APIURL + 'lms/evaluacion/guardarcambios',
                type: 'POST',
                xhrFields: {
                    withCredentials: true
                },
                contentType: 'application/x-www-form-urlencoded',
                data: 'usuario_k=&recurso_alumno_resultado_k=' + recurso_alumno_resultado_k + '&preguntas=[' + JSON.stringify(data) + ']',
                success(response) {
                    console.log(response);
                    window.lastArray.push(JSON.stringify(data));
                },
                error(e) {
                    console.error(e);
                }
            });*/
        },
        lms_pregunta_porcentaje: function lms_pregunta_porcentaje() {
            var array = Ember.$('#contadorPreguntas').text().split(' ');
            var currentQuestion = array[1];
            var total = array[3];
            var nextQuestion = parseInt(currentQuestion) + 1;

            //Obtener y enviar pregunta actual
            var respuesta = Ember.$('input[name=_' + (parseInt(currentQuestion) - 1) + ']', '.carousel-item').val(); // Obtener el valor del checkbox selecionado
            var respuesta_k = Ember.$('input[name=_' + (parseInt(currentQuestion) - 1) + ']', '.carousel-item').attr('id'); // Obtener el ID del checkbox selecionado
            var correcto = respuesta;;
            var recurso_alumno_resultado_k = sessionStorage.getItem('recurso_alumno_resultado_k');
            var propietario_recurso_k = Ember.$('.carousel-item.active h2').data('propietario');

            // Obtener llave de respuesta almacenado en data-quest del DOM
            var pregunta_k = Ember.$('input[name=_' + (parseInt(currentQuestion) - 1) + ']').data('quest');
            var jsonRespuestas = {
                'correcta': correcto,
                orden: parseInt(currentQuestion),
                'respuesta': respuesta,
                'respuesta_k': respuesta_k
            };
            array = [];
            array.push(jsonRespuestas); // Almacenar repuesta en la casilla corespondiente al orden que deben de ser mandados
            var data = {
                'pregunta_k': pregunta_k,
                'tipo_pregunta_k': "6",
                'respuestas': array
            }; // Generar JSON para mandar en petición AJAX


            var segmento = 'evaluacion';
            if (Ember.$('.carousel-item.active').data('tabla') == 'lms_encuestas') {
                segmento = 'encuesta';
            }

            window.lastArray.push(JSON.stringify(data));
            Ember.$.ajax({
                url: constant.APIURL + 'lms/' + segmento + '/guardarcambios',
                type: 'POST',
                xhrFields: {
                    withCredentials: true
                },
                contentType: 'application/x-www-form-urlencoded',
                data: 'recurso_alumno_resultado_k=' + recurso_alumno_resultado_k + '&propietario_recurso_k=' + propietario_recurso_k + '&intento_ra=' + window.intento_ra + '&grupo_k=' + window.grupo_k + '&usuario_k=&preguntas=[' + window.lastArray + ']',
                success: function success(response) {
                    console.log(response);
                },
                error: function error(e) {
                    console.error(e);
                    window.lastArray.pop();
                }
            });

            /*$.ajax({
                url: constant.APIURL + 'lms/evaluacion/guardarcambios',
                type: 'POST',
                xhrFields: {
                    withCredentials: true
                },
                contentType: 'application/x-www-form-urlencoded',
                data: 'usuario_k=&recurso_alumno_resultado_k=' + recurso_alumno_resultado_k + '&preguntas=[' + JSON.stringify(data) + ']',
                success(response) {
                    console.log(response);
                    window.lastArray.push(JSON.stringify(data));
                },
                error(e) {
                    console.error(e);
                }
            });*/
        },
        lms_pregunta_abierta: function lms_pregunta_abierta() {
            var array = Ember.$('#contadorPreguntas').text().split(' ');
            var currentQuestion = array[1];
            var total = array[3];
            var nextQuestion = parseInt(currentQuestion) + 1;
            var propietario_recurso_k = Ember.$('.carousel-item.active h2').data('propietario');

            //Obtener y enviar pregunta actual
            var respuesta = Ember.$('textarea[name=_' + (parseInt(currentQuestion) - 1) + ']').val(); // Obtener el valor del checkbox selecionado
            var respuesta_k = Ember.$('textarea[name=_' + (parseInt(currentQuestion) - 1) + ']').attr('id'); // Obtener el ID del checkbox selecionado
            var respuesta_incorrecta = Ember.$('textarea[name=_' + (parseInt(currentQuestion) - 1) + ']', '.carousel-item').val();
            var respuesta_incorrecta_k = Ember.$('textarea[name=_' + (parseInt(currentQuestion) - 1) + ']', '.carousel-item').attr('id');
            var correcto = Ember.$('textarea[name=_' + (parseInt(currentQuestion) - 1) + ']').val();
            var incorrecto = false;
            var recurso_alumno_resultado_k = sessionStorage.getItem('recurso_alumno_resultado_k');

            // Obtener llave de respuesta almacenado en data-quest del DOM
            var pregunta_k = Ember.$('textarea[name=_' + (parseInt(currentQuestion) - 1) + ']:checked').data('quest');

            // generar JSON con la estructura para mandar al back end.
            var jsonRespuestas = {
                'correcta': correcto,
                orden: parseInt(currentQuestion),
                'respuesta': respuesta,
                'respuesta_k': respuesta_k
            };
            var jsonRespuesta_incorrecta = {
                'respuesta_k': respuesta_incorrecta_k,
                'correcta': incorrecto,
                orden: parseInt(currentQuestion),
                'respuesta': respuesta_incorrecta
            };
            array = [];
            array.push(jsonRespuestas); // Almacenar repuesta en la casilla corespondiente al orden que deben de ser mandados
            array.push(jsonRespuesta_incorrecta);
            var data = {
                'pregunta_k': String(pregunta_k),
                'tipo_pregunta_k': "8",
                'respuestas': array
            }; // Generar JSON para mandar en petición AJAX


            var segmento = 'evaluacion';
            if (Ember.$('.carousel-item.active').data('tabla') == 'lms_encuestas') {
                segmento = 'encuesta';
            }

            window.lastArray.push(JSON.stringify(data));
            Ember.$.ajax({
                url: constant.APIURL + 'lms/' + segmento + '/guardarcambios',
                type: 'POST',
                xhrFields: {
                    withCredentials: true
                },
                contentType: 'application/x-www-form-urlencoded',
                data: 'recurso_alumno_resultado_k=' + recurso_alumno_resultado_k + '&propietario_recurso_k=' + propietario_recurso_k + '&intento_ra=' + window.intento_ra + '&grupo_k=' + window.grupo_k + '&usuario_k=&preguntas=[' + window.lastArray + ']',
                success: function success(response) {
                    console.log(response);
                },
                error: function error(e) {
                    console.error(e);
                    window.lastArray.pop();
                }
            });

            /*$.ajax({
                url: constant.APIURL + 'lms/evaluacion/guardarcambios',
                type: 'POST',
                xhrFields: {
                    withCredentials: true
                },
                contentType: 'application/x-www-form-urlencoded',
                data: 'usuario_k=&recurso_alumno_resultado_k=' + recurso_alumno_resultado_k + '&preguntas=[' + JSON.stringify(data) + ']',
                success(response) {
                    console.log(response);
                    window.lastArray.push(JSON.stringify(data));
                },
                error(e) {
                    console.error(e);
                }
            });*/
        },
        pregunta_ordenar_respuesta: function pregunta_ordenar_respuesta() {
            var array = Ember.$('#contadorPreguntas').text().split(' ');

            var segmento = 'evaluacion';
            if (Ember.$('.carousel-item.active').data('tabla') == 'lms_encuestas') {
                segmento = 'encuesta';
            }

            //Obtener y enviar pregunta actual
            var evaluacion_usuario_k = sessionStorage.getItem('evaluacion_usuario_k');
            var pregunta_k = Ember.$('.carousel-item.active select').data('quest');

            array = [];
            var arrObjetos = [];
            Ember.$('.carousel-item.active select option:selected').each(function () {
                arrObjetos.push({ valor: Ember.$(this).text().trim() });
            });

            Ember.$('.carousel-item.active label').each(function (idx) {
                arrObjetos[idx].orden = Ember.$(this).data('orden');
                arrObjetos[idx].respuesta_seleccionada = Ember.$(this).text().trim();
                arrObjetos[idx].respuesta_key = Ember.$(this).data('respuesta');
                array.push({ 'correcta': parseInt(arrObjetos[idx].valor), 'orden': arrObjetos[idx].orden, 'respuesta': arrObjetos[idx].respuesta_seleccionada, 'respuesta_k': parseInt(arrObjetos[idx].respuesta_key) });
            });

            var data = { 'pregunta_k': String(pregunta_k), 'tipo_pregunta_k': "9", 'respuestas': array }; // Generar JSON para mandar en petición AJAX

            Ember.$.ajax({
                url: constant.APIURL + 'usuario/datospersonales/',
                type: 'POST',
                xhrFields: {
                    withCredentials: true
                },
                contentType: 'application/x-www-form-urlencoded',
                success: function success(r) {
                    var usuario = JSON.parse(r);
                    Ember.$.ajax({
                        url: constant.APIURL + 'lms/' + segmento + '/guardarcambios',
                        type: 'POST',
                        xhrFields: {
                            withCredentials: true
                        },
                        contentType: 'application/x-www-form-urlencoded',
                        data: 'evaluacion_usuario_k=' + evaluacion_usuario_k + '&usuario_k=' + usuario.usuario_k + '&pregunta_k=' + pregunta_k + '&pregunta=' + JSON.stringify(data),
                        success: function success(response) {
                            console.log(response);
                            window.lastArray.push(JSON.stringify(data));
                        },
                        error: function error(e) {
                            console.error(e);
                        }
                    });
                },
                error: function error(e) {
                    console.error(e);
                }
            });
        }
    });
});