define('comunidades-nafin/routes/administracion-logros', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		beforeModel: function beforeModel() {
			var promise = new Ember.RSVP.Promise(function (resolve) {
				Ember.run.later(this, function () {
					resolve("ok");
				}, 1000);
			});

			return promise;
		},
		loadGeneralData: function loadGeneralData(controller) {
			var self = this;
			if (constant.FIREBASE_LOGRO != '') {
				Ember.$.ajax({
					url: constant.FIREBASE_LOGRO,
					type: 'POST',
					data: {
						'method': 'get_catalog'
					},
					success: function success(data) {
						controller.set('general', data);
					}
				});
			} else {
				controller.set('general', []);
			}
		},
		loadRankingData: function loadRankingData(controller) {
			var self = this;
			controller.set('usuario', self.session.userData.usuario_k);

			if (constant.FIREBASE_LOGRO != '') {
				Ember.$.ajax({
					url: constant.FIREBASE_LOGRO,
					type: 'POST',
					data: {
						'method': 'get_user_ranking',
						data: {
							id_usuario: parseInt(self.session.userData.usuario_k)
						}
					},
					success: function success(data) {
						//console.log(data);
						controller.set('ranking', data);
						setTimeout(function () {
							Ember.$('.selected').parent().addClass('selected');
						}, 1000);
						Ember.$('#loading').fadeOut('slow');
					},
					error: function error() {
						Ember.$('#loading').fadeOut('slow');
					}
				});
			} else {
				controller.set('ranking', []);
				setTimeout(function () {
					Ember.$('#loading').fadeOut('slow');
				}, 1000);
			}
		},

		setupController: function setupController(controller) {
			this.loadGeneralData(controller);
			this.loadRankingData(controller);
			controller.set("user_photo", this.session.userData.foto);
		},
		validarInsignia: function validarInsignia() {
			var imgInsignia = Ember.$("#preview-img-insignia").attr("src");
			var nameInsignia = Ember.$("#insignia-name").val();
			var actionInsignia = Ember.$("#insignia-action").val();
			//const periodInsignia  = $("#insignia-period").val();
			var actionsRequired = Ember.$("#insignia-action-required").val();

			if ( //imgInsignia == "/img/ico-upload.png" || 
			nameInsignia.trim() == "" || actionInsignia.trim() == "" ||
			//periodInsignia.trim()  == "" ||
			actionsRequired.trim() == "") {
				return false;
			}

			return true;
		},
		validarLevel: function validarLevel() {
			var imgLevel = Ember.$("#preview-img-level").attr("src");
			var nameLevel = Ember.$("#level-name").val();
			var pointsLevel = Ember.$("#points-level").val();

			if ( //imgLevel == "/img/ico-upload.png" || 
			nameLevel.trim() == "" || pointsLevel.trim() == "") {
				return false;
			}

			return true;
		},
		actions: {
			updatePoints: function updatePoints(idx) {

				var points = Ember.$("#action-point-" + idx).val();

				if (constant.FIREBASE_LOGRO != '') {
					Ember.$.ajax({
						url: constant.FIREBASE_LOGRO,
						type: 'POST',
						data: {
							'method': 'update_points',
							'data': {
								'puntos': points,
								'id_accion': idx
							}
						},
						success: function success(data) {
							if (data.status) {

								Ember.$("#msg-points").show();

								setTimeout(function () {
									Ember.$("#msg-points").hide();
									self.loadGeneralData(controller);
								}, 1500);
							}
						}
					});
				}
			},
			showModalPuntos: function showModalPuntos() {
				Ember.$('#modalPuntos').modal();
			},
			showModalRanking: function showModalRanking() {
				Ember.$('#modalRanking').modal();
			},
			showConfirmDelete: function showConfirmDelete(idLevel, level) {

				var controller = this.controller;

				Ember.$('#modalConfirmDelete').modal();

				controller.set('msgConfirmDelete', "Al eliminar el nivel, " + level.users_in_level + " usuarios cambiarán a " + level.lower_level + ". ¿Estás seguro de eliminar este nivel?");
				controller.set('idLevelDelete', idLevel);
				controller.set('levelDelete', level);
			},
			showConfirmDeleteInsignia: function showConfirmDeleteInsignia(idInsignia, insignia) {

				var controller = this.controller;

				Ember.$('#modalConfirmDeleteInsignia').modal();

				controller.set('idInsigniaDelete', idInsignia);
				controller.set('insigniaDelete', insignia);
			},
			showModalFormLevel: function showModalFormLevel(idLevel, level) {
				var imgTrigger = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;


				var controller = this.controller;
				Ember.$('#modalFormLevel').modal();

				controller.set('msgErrorLevel', "");
				controller.set('msgSuccessLevel', "");

				if (idLevel && level) {

					controller.set('addEditLevel', "Editar");
					controller.set('idLevelEdit', idLevel);
					controller.set('levelEdit', level);
					controller.set('imgPreviewLevel', level.img_nivel);
				} else {

					controller.set('addEditLevel', "Agregar");
					controller.set('idLevelEdit', '');
					controller.set('levelEdit', []);
					controller.set('imgPreviewLevel', '/img/ico-upload.png');
					Ember.$("#preview-img-level").attr("src", '/img/ico-upload.png');
				}

				Ember.$('#img-level').change(function () {

					var input = this;
					if (input.files && input.files[0]) {
						var reader = new FileReader();
						reader.onload = function (e) {
							Ember.$('#preview-img-level').attr('src', e.target.result);
						};

						reader.readAsDataURL(input.files[0]);
					}
				});

				if (imgTrigger) {
					setTimeout(function () {
						Ember.$("#img-level").trigger("click");
					}, 500);
				}
			},
			closeModalLogros: function closeModalLogros() {
				Ember.$('#modalLogros').fadeOut('slow');
				Ember.$('#modalLogrosContent').html('');

				this.checkForModalAlerts(this.controller);
			},
			deleteLevel: function deleteLevel(idLevel) {

				var controller = this.controller;
				var self = this;

				if (constant.FIREBASE_LOGRO != '') {
					Ember.$.ajax({
						url: constant.FIREBASE_LOGRO,
						type: 'POST',
						data: {
							'method': 'delete_level',
							'data': {
								'id_nivel': idLevel
							}
						},
						success: function success(data) {
							self.loadGeneralData(controller);
						}
					});
				}
			},
			addEditLevel: function addEditLevel() {
				var idLevel = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;


				var controller = this.controller;
				var self = this;

				var imgLevel = "https://firebasestorage.googleapis.com/v0/b/thehuman-nafin.appspot.com/o/niveles%2F1.png?alt=media&token=0795a9dd-93d3-4d03-912f-bd4e01f45538";
				var nameLevel = Ember.$("#level-name").val();
				var pointsLevel = Ember.$("#points-level").val();

				if (this.validarLevel()) {

					if (constant.FIREBASE_LOGRO != '') {
						Ember.$.ajax({
							url: constant.FIREBASE_LOGRO,
							type: 'POST',
							data: {
								'method': 'add_level',
								'data': {
									'img_nivel': imgLevel,
									'nombre_nivel': nameLevel,
									'puntos_necesarios': pointsLevel,
									'id_nivel': idLevel
								}
							},
							success: function success(data) {
								if (data.status) {
									if (idLevel == 0) {
										controller.set('msgSuccessLevel', "Nivel Agregado");
									} else {
										controller.set('msgSuccessLevel', "Nivel Editado");
									}

									controller.set('msgErrorLevel', "");
									setTimeout(function () {
										Ember.$('#modalFormLevel').modal('hide');
										self.loadGeneralData(controller);
									}, 1000);
								}
							}
						});
					}
				} else {

					controller.set('msgErrorLevel', "Todos los campos son requeridos");
					controller.set('msgSuccessLevel', "");
				}
			},
			addEditInsignia: function addEditInsignia() {
				var idInsignia = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;


				var controller = this.controller;
				var self = this;

				var imgInsignia = "https://firebasestorage.googleapis.com/v0/b/thehuman-nafin.appspot.com/o/insignias%2Fcursos.png?alt=media&token=16c13150-d38e-402c-84e1-50dd9bbf702c";
				var nameInsignia = Ember.$("#insignia-name").val();
				var actionInsignia = Ember.$("#insignia-action").val();
				var periodInsignia = Ember.$("#insignia-period").val();
				var actionsRequired = Ember.$("#insignia-action-required").val();

				if (this.validarInsignia()) {

					if (constant.FIREBASE_LOGRO != '') {
						Ember.$.ajax({
							url: constant.FIREBASE_LOGRO,
							type: 'POST',
							data: {
								'method': 'add_insignia',
								'data': {
									'img_insignia': imgInsignia,
									'nombre_insignia': nameInsignia,
									'accion': actionInsignia,
									'periodo': periodInsignia,
									'acciones_requeridas': actionsRequired,
									'id_insignia': idInsignia
								}
							},
							success: function success(data) {

								if (data.status) {

									if (idInsignia == 0) {
										controller.set('msgSuccessInsignia', "Insignia Agregada");
									} else {
										controller.set('msgSuccessInsignia', "Insignia Editada");
									}

									controller.set('msgErrorInsignia', "");
									setTimeout(function () {
										Ember.$('#modalFormInsignias').modal('hide');
										self.loadGeneralData(controller);
									}, 1000);
								}
							}
						});
					}
				} else {

					controller.set('msgErrorInsignia', "Todos los campos son requeridos");
					controller.set('msgSuccessInsignia', "");
				}
			},
			deleteInsignia: function deleteInsignia(idInsignia) {

				var controller = this.controller;
				var self = this;

				if (constant.FIREBASE_LOGRO != '') {
					Ember.$.ajax({
						url: constant.FIREBASE_LOGRO,
						type: 'POST',
						data: {
							'method': 'delete_insignia',
							'data': {
								'id_insignia': idInsignia
							}
						},
						success: function success(data) {
							self.loadGeneralData(controller);
						}
					});
				}
			},
			showModalFormInsignias: function showModalFormInsignias(idInsignia, insignia) {

				var controller = this.controller;
				Ember.$('#modalFormInsignias').modal();

				controller.set('msgErrorInsignia', "");
				controller.set('msgSuccessInsignia', "");

				if (idInsignia && insignia) {

					controller.set('addEditInsignia', "Editar");
					controller.set('idInsigniaEdit', idInsignia);
					controller.set('insigniaEdit', insignia);
					controller.set('imgPreviewInsignia', insignia.img_insignia);
					Ember.$("#insignia-action").val(insignia.accion_id);
					Ember.$("#insignia-period").val(insignia.periodo);
				} else {

					controller.set('addEditInsignia', "Agregar");
					controller.set('idInsigniaEdit', '');
					controller.set('insigniaEdit', []);
					controller.set('imgPreviewInsignia', '/img/ico-upload.png');
					Ember.$("#preview-img-insignia").attr("src", '/img/ico-upload.png');
					Ember.$("#insignia-action").val("");
					Ember.$("#insignia-period").val("");
				}

				Ember.$('#img-insignia').change(function () {

					var input = this;
					if (input.files && input.files[0]) {
						var reader = new FileReader();

						reader.onload = function (e) {
							Ember.$('#preview-img-insignia').attr('src', e.target.result);
						};

						reader.readAsDataURL(input.files[0]);
					}
				});
			}
		}

	});
});