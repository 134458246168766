define('comunidades-nafin/routes/administracion-usuarios', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		start_int: 0,
		model: function model() {
			var self = this;
			var promise = new Ember.RSVP.Promise(function (resolve, reject) {
				Ember.run.later(this, function () {
					if (self.session.isAdmin === false) {
						reject('No admin');
					} else {
						resolve("ok");
					}
				}, 1000);
			});
			return promise;
		},
		getUsers: function getUsers(params) {

			$('#button-search-users').attr("disabled", "disabled");

			var controller = this.controller;
			var paramsData = typeof params == "undefined" ? "" : params;
			var start_int = this.get("start_int");

			//GET USERS LIST
			$.ajax({
				url: constant.APIURL + 'usuario/listar',
				type: 'POST',
				contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
				xhrFields: {
					withCredentials: true
				},
				data: 'start=' + start_int + '&limit=' + constant.PAGESIZE + paramsData,
				success: function success(r) {

					$('#button-search-users').attr("disabled", false);

					var usuarioslist = JSON.parse(r);

					if (usuarioslist.data.length >= 1) {
						controller.set("isEmpty", false);
						controller.set("usuarioslist", usuarioslist.data);
						controller.set("totalUsers", usuarioslist.numFilas);
						controller.set("showLoad", false);
					} else {
						controller.set("showLoad", false);
						controller.set("isEmpty", true);
						return false;
					}

					window.scrollTo(0, 0);
				},
				error: function error(e) {
					console.log(e);
				}
			}); //END GET USERS LIST
		},

		setupController: function setupController(controller) {

			this.getUsers();

			var tiposContacto = Ember.A([{ "title": "Emprendedor", "id": 1 }, { "title": "Empresario", "id": 2 /*,
                                                                                                      { "title": "Tutor", "id": 3 },
                                                                                                      { "title": "Administrador", "id": 4 }*/ }]);

			var escolaridades = Ember.A([{ "title": "Básico", "id": 1 }, { "title": "Bachillerato", "id": 2 }, { "title": "Profesional", "id": 3 }, { "title": "Posgrado", "id": 4 }]);

			controller.set("tiposContacto", tiposContacto);
			controller.set("escolaridades", escolaridades);
			controller.set("showLoad", true);
			controller.set("isEmpty", false);

			//GET FEDERAL ENTITIES
			$.ajax({
				url: constant.APIURL + 'general/listarEstado',
				type: 'POST',
				xhrFields: {
					withCredentials: true
				},
				contentType: 'application/x-www-form-urlencoded',
				success: function success(r) {
					var estadosLista = JSON.parse(r);
					if (estadosLista.data.length >= 1) {
						controller.set("estadosLista", estadosLista.data);
					} else {
						return false;
					}
				},
				error: function error(e) {
					console.log(e);
				}
			}); //END GET FEDERAL ENTITIES
			setTimeout(function () {
				$('#loading').attr('hidden', true);
			}, 0);
		},
		actions: {
			error: function error(reason) {
				console.log(reason);
				this.transitionTo('inicio');
			},

			nuevousuario: function nuevousuario() {
				$('.add-user').show();
				$('.edit-user').hide();
				$('#idusuario').val('');
				$("#showModalNuevoUsuario").modal("show");
			}, //nuevousuario

			cambioEstado: function cambioEstado(selection) {
				var controller = this.controller;
				controller.set("estadoSeleccionado", selection.d_estado);
				$("#buestado").children("a").text(selection.d_estado);
				var params = { "c_estado": parseInt(selection.c_estado) };
				//GET TOWNS
				$.ajax({
					url: constant.APIURL + 'general/listarMunicipio',
					type: 'POST',
					xhrFields: {
						withCredentials: true
					},
					contentType: 'application/x-www-form-urlencoded',
					data: 'limit=200&params=' + JSON.stringify(params),
					success: function success(r) {
						var municipiosLista = JSON.parse(r);
						if (municipiosLista.data.length >= 1) {
							controller.set("municipiosLista", municipiosLista.data.uniqBy('D_mnpio'));
							$('#bumunicipio').prop('disabled', false);
						} else {
							return false;
						}
					},
					error: function error(e) {
						console.log(e);
					}
				}); //END GET TOWNS
			}, //cambioestado

			cambioMunicipio: function cambioMunicipio(selection) {
				var controller = this.controller;
				controller.set("municipioSeleccionado", selection.D_mnpio);
				//$("#bumunicipio").children("a").text(selection.D_mnpio);
			}, //cambioMunicipio

			cambioContacto: function cambioContacto(selection) {
				var controller = this.controller;
				controller.set("tipoContactoSeleccionado", selection.id);
				//$("#butipocontacto").children("a").text(selection.title);
			}, //cambioMunicipio

			cambioEscolaridad: function cambioEscolaridad(selection) {
				var controller = this.controller;
				controller.set("escolaridadSeleccionado", selection.id);
				//$("#buescolaridad").children("a").text(selection.title);
			}, //cambioEscolaridad

			clearForm: function clearForm() {
				var controller = this.controller;

				$("#bunombre").val("");
				$("#bucorreo").val("");
				/*$("#buescolaridad").children("a").text("Selecciona una opción");
    $("#butipocontacto").children("a").text("Selecciona una opción");
    $("#buestado").children("a").text("Selecciona una opción");
    $("#bumunicipio").children("a").text("Selecciona una opción");*/

				controller.set("escolaridadSeleccionado", "");
				controller.set("municipioSeleccionado", "");
				controller.set("tipoContactoSeleccionado", "");
				controller.set("estadoSeleccionado", "");
			}, //clearForm

			buscarusuario: function buscarusuario() {

				var controller = this.controller;
				var params = "";
				var usuarionombre = $("#bunombre").val();
				var usuariocorreo = $("#bucorreo").val();
				var usuarioescolaridad = controller.get("escolaridadSeleccionado");
				var usuariotipocontacto = controller.get("tipoContactoSeleccionado");
				var usuarioestado = controller.get("estadoSeleccionado");
				var usuariomunicipio = controller.get("municipioSeleccionado");
				var usuarioq = {};

				if (usuarionombre != "") {
					usuarioq.nombre = usuarionombre;
				}
				if (usuariocorreo != "") {
					usuarioq.email = usuariocorreo;
				}
				if (usuarioescolaridad) {
					usuarioq.grado_estudios = usuarioescolaridad;
				}
				if (usuariotipocontacto) {
					usuarioq.tipo_contacto = usuariotipocontacto;
				}
				if (usuarioestado) {
					usuarioq.estado = usuarioestado;
				}
				if (usuariomunicipio) {
					usuarioq.municipio = usuariomunicipio;
				}

				if (Object.keys(usuarioq).length > 0) {
					params = '&params=' + JSON.stringify(usuarioq);
				}

				controller.set("showLoad", true);

				this.getUsers(params);
			}, //buscarusuario

			next: function next() {

				var start_int = this.get("start_int");
				start_int = start_int + constant.PAGESIZE;

				this.set("start_int", start_int);

				this.send('buscarusuario');
			}, //next

			prev: function prev() {

				var start_int = this.get("start_int");
				start_int = start_int - constant.PAGESIZE;
				start_int = start_int < 0 ? 0 : start_int;

				this.set("start_int", start_int);

				this.send('buscarusuario');
			}, //prev

			getUser: function getUser(idUser) {

				//GET USER
				$.ajax({
					url: constant.APIURL + 'usuario/form',
					type: 'POST',
					contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
					xhrFields: {
						withCredentials: true
					},
					data: 'i=' + idUser,
					success: function success(r) {

						var usuario = JSON.parse(r);

						$('.add-user').hide();
						$('.edit-user').show();

						var puesto = usuario.data.cb_puesto ? usuario.data.cb_puesto : 0;
						var departamento = usuario.data.cb_departamento ? usuario.data.cb_departamento : 0;
						var oficina = usuario.data.cb_oficina ? usuario.data.cb_oficina : 0;
						var rol = usuario.data.cb_rol ? usuario.data.cb_rol : 0;

						$('#nuevonomina').val(usuario.data.txt_nomina);
						$('#nuevousername').val(usuario.data.username);
						$('#nuevonombre').val(usuario.data.txt_nombre);
						$('#nuevoapellido').val(usuario.data.txt_apaterno);
						$('#nuevomaterno').val(usuario.data.txt_amaterno);
						$('#nuevopuesto').val(puesto);
						$('#nuevodepartamento').val(departamento);
						$('#nuevooficina').val(oficina);
						$('#nuevorol').val(rol);
						$('#nuevocorreo').val(usuario.data.txt_email);
						$('#idusuario').val(idUser);

						$("#showModalNuevoUsuario").modal("show");
					},
					error: function error(e) {
						console.log(e);
					}
				}); //END GET USER
			}, //getUser

			cambiarSesionUsuario: function cambiarSesionUsuario(usuarioRecordObject) {

				var usuario_record_string = JSON.stringify(usuarioRecordObject);

				$('#loading').attr('hidden', false);

				$.ajax({
					url: constant.APIURL + 'usuario/cambiasesion',
					type: 'POST',
					contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
					xhrFields: { withCredentials: true },
					data: 'usuario=' + usuario_record_string,
					success: function success(r) {
						mask();
						unmask();
						document.location.reload();
						document.location.href = '/inicio';
					},
					error: function error(e) {
						console.log(e);
					}
				});
			}, //cambiarSesionUsuario

			modalCambiarSesion: function modalCambiarSesion(usuarioRecordObject) {

				$('#modal_cambiar_sesion').modal({
					keyboard: false,
					backdrop: 'static'
				});

				this.controller.set('cambiarSesionUsuario', usuarioRecordObject);
			} //modalCambiarSesion

		} //actions
	});
});