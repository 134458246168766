define('comunidades-nafin/components/evaluations-cards', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        didInsertElement: function didInsertElement() {

            var self = this._super.apply(this, arguments);
        },
        setEvaluaciones: function setEvaluaciones(r) {
            this.set('evaluaciones-cards', r);
        },
        didRender: function didRender() {
            var self = this._super.apply(this, arguments);
        },

        actions: {
            goPreviewEvaluation: function goPreviewEvaluation(enc_ev_k) {
                Ember.$.ajax({
                    url: constant.APIURL + 'lms/evaluacion/preview',
                    type: 'POST',
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    data: 'enc_ev_k=' + enc_ev_k,
                    xhrFields: {
                        withCredentials: true
                    },
                    success: function success(r) {
                        var data = JSON.parse(r);
                        console.log(data);
                        // self.set('avatar', data.foto);
                        // self.loadConversations();
                    },
                    error: function error(e) {
                        console.log(e);
                    }
                });
            }
        }
    });
});