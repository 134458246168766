define('comunidades-nafin/components/video-tutorial', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		didInsertElement: function didInsertElement() {
			$.ajax({
				url: constant.APIURL + 'usuario/p',
				type: 'POST',
				xhrFields: {
					withCredentials: true
				},
				data: 'cl=mis_comunidades_general',
				success: function success(r) {
					r = JSON.parse(r);
					if (r.p.tutorial && r.p.tutorial != '') {
						console.log('Se mostrará Video', r);
						$("#tutorialModalCenterBody").html('<iframe width="100%" height="300" src="' + constant.BASEURL + r.p.tutorial + '" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowfullscreen></iframe>');
						$('#tutorialModalCenter').modal('show');
					}
				}
			});
			$('.close').click(function (e) {
				e.preventDefault();
				$('#tutorialModalCenterBody').remove();
			});
		},
		actions: {
			ocultar: function ocultar() {
				$.ajax({
					url: constant.APIURL + 'usuario/ocultartutorialporusuario',
					type: 'POST',
					xhrFields: {
						withCredentials: true
					},
					data: 'submodulo_k=mis_comunidades_general',
					success: function success(r) {
						console.log(r);
						$('#tutorialModalCenter').modal('hide');
						$('#tutorialModalCenterBody').remove();
					}
				});
			},
			cerrar: function cerrar() {
				$('#tutorialModalCenterBody').remove();
			}

		}
	});
});