define('comunidades-nafin/routes/administracion-diccionario', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		beforeModel: function beforeModel() {
			var promise = new Ember.RSVP.Promise(function (resolve) {
				Ember.run.later(this, function () {
					resolve("ok");
				}, 1000);
			});
			return promise;
		},
		setupController: function setupController(controller) {
			this._super.apply(this, arguments);
			this.reloadwords();
		},

		reloadwords: function reloadwords() {
			var self = this;
			Ember.$.ajax({
				url: constant.FIREBASE_DICCI,
				type: 'POST',
				data: {
					method: 'get_dictionary'
				},
				success: function success(response) {
					window.diccionario = [];
					Object.keys(response.data).forEach(function (key) {
						window.diccionario.push(key);
					});
					self.controller.set('diccionario', response.data);
					console.log(response);
				},
				error: function error(_error) {
					console.log(_error);
					Ember.$('#add_restricted_word').modal('hide');
				}
			});
		},
		actions: {
			add_restricted_word: function add_restricted_word() {
				Ember.$('#add_restricted_word').modal('show');
			},
			addRestrictedWord: function addRestrictedWord(word) {
				var self = this;
				var word = Ember.$('#restricted-word').val().trim();
				if (word) {
					Ember.$.ajax({
						url: constant.FIREBASE_DICCI,
						type: 'POST',
						data: {
							method: 'add_dictionary_word',
							data: {
								word: word
							}
						},
						success: function success(response) {
							console.log(response);
							Ember.$('#add_restricted_word').modal('hide');
							self.reloadwords();
						},
						error: function error(_error2) {
							console.log(_error2);
							Ember.$('#add_restricted_word').modal('hide');
						}
					});
				}
			},
			deleteword: function deleteword(word) {
				var self = this;
				if (word) {
					Ember.$.ajax({
						url: constant.FIREBASE_DICCI,
						type: 'POST',
						data: {
							method: 'remove_dictionary_word',
							data: {
								word: word
							}
						},
						success: function success(response) {
							self.reloadwords();
						},
						error: function error(_error3) {
							console.log(_error3);
						}
					});
				}
			}
		}
	});
});