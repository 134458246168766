define('comunidades-nafin/components/examples-components/react-imported-example', ['exports', 'comunidades-nafin/react-component', 'comunidades-nafin/components/examples-components/react-imported-example/demo-modal'], function (exports, _reactComponent, _demoModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _reactComponent.default.extend({

    title: 'An awesome demo',

    onTextChange: function onTextChange(text) {
      Ember.set(this, 'title', text);
      this.renderModal();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.renderModal();
    },
    renderModal: function renderModal() {
      var _this = this;

      this.reactRender(React.createElement(_demoModal.default, {
        title: Ember.get(this, 'title'),
        onTextChange: function onTextChange(text) {
          return _this.onTextChange(text);
        }
      }));
    }
  });
});