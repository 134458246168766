define('comunidades-nafin/components/admin-components/boton-admin-cursos', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            editarCursos: function editarCursos() {
                document.location.href = '/administracion-cursos';
            }
        }
    });
});