define('comunidades-nafin/components/banner-sider', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			this.send('loadBanners');
		},

		actions: {
			loadBanners: function loadBanners() {
				var self = this;
				Ember.$.ajax({
					// url: constant.APIURL + 'banner/listar',
					// type: 'POST',
					// xhrFields: {
					// 	withCredentials: true
					// },
					// contentType: 'application/x-www-form-urlencoded',
					// data: 'params[status]=1',
					url: constant.APIURL + 'noticia/noticiasRecientes',
					type: 'POST',
					xhrFields: {
						withCredentials: true
					},
					contentType: 'application/x-www-form-urlencoded',
					data: 'params= {"comunidad_k":"-2"}',
					success: function success(response) {

						var banners = JSON.parse(response).data;
						Object.keys(banners).forEach(function (key) {
							if (banners[key].imagen.indexOf('http') != 0) {
								banners[key].imagen = constant.BASEURL + banners[key].imagen;
							}
						});
						self.set('banne-img', banners);
						setTimeout(function () {
							lazyLoad.update();
							Ember.$('#loading').fadeOut('slow');
							Ember.$('#home_slider .carousel-item:first-child').addClass('active');
							Ember.$('#home_slider').carousel({
								interval: 5000
							});
						}, 2500);
					},
					error: function error(e) {
						console.log(e);
					}
				});
			}
		}
	});
});