define('comunidades-nafin/routes/buscador', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		beforeModel: function beforeModel() {
			var promise = new Ember.RSVP.Promise(function (resolve) {
				Ember.run.later(this, function () {
					resolve("ok");
				}, 1000);
			});
			return promise;
		},
		setupController: function setupController(controller) {

			this._super.apply(this, arguments);

			var url_page = new URLSearchParams(window.location.search);
			var criterio_busqueda = url_page.get('search');
			controller.set('criterio_busqueda', criterio_busqueda);

			this.loadStoreResultadosBusquedas(controller);
			controller.set('isEventosProximosHabilitado', true);
		},


		/**
   * numOfTimes - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
   *
   *
   * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
   *
   *
   * @author  [Artur] Arturo Amador Paulino
   *				amadorassassin@gmail.com
   *
   * @date    2018-08-18
   * @param	void
   * @return  array
   */

		numOfTimes: Ember.computed('times', function () {
			return [1, 2, 3, 4, 5];
		}),

		prepareDataToStore: function prepareDataToStore(controlle, data_array) {

			var self = this;
			var resultados_busqueda_array = {
				'comunidades': [],
				'noticias': [],
				'preguntasForos': [],
				'documentosCursosYComunidades': [],
				'eventosProximos': [],
				'documentos': [],
				'evaluacionesDinamicas': []
			};

			data_array.forEach(function (_resultado_object, _index_int, _resultados_array) {

				var date_time = _resultado_object.fecha_hora_creacion.split(' ');
				var date = date_time[0].split('-');
				var month = date[1];

				_resultado_object.monthFormath = self.getNameMonth(parseInt(month, 10));

				_resultado_object.dayFormath = date[2];
				_resultado_object.yearFormath = date[0];
				_resultado_object.formatt_hora = date_time[1].substr(0, 5);

				_resultado_object.params = JSON.parse(_resultado_object.params);
				/*
     * Aseguramos que el path/URL de la imagen tenga un formato de URL Absoluta.
     */
				if (_resultado_object.params) {
					if (_resultado_object.params.imagen) {
						_resultado_object.params.imagen = solveImageURL(_resultado_object.params.imagen);
					}
				}

				/*
      * Aseguramos que el path/URL de la imagen tenga un formato de URL Absooluta.
      */
				if (_resultado_object.params) {
					if (_resultado_object.params.img) {
						_resultado_object.params.img = solveImageURL(_resultado_object.params.img);
					}
				}

				/*
      * Dependiendo del tipo de elemento, lo apilamos al arreglo de datos correspondiente.
      */

				switch (_resultado_object.tipo_elemento) {

					case constant.BUSQUEDAS.CONSTANTES.BUSQUEDA_COMUNIDADES_TIPO_ELEMENTO:

						if (_resultado_object.params.descripcion) {
							if (_resultado_object.params.descripcion.length > 240) {
								_resultado_object.params.descripcion = _resultado_object.params.descripcion.substring(0, 235) + '...';
							}
						}

						resultados_busqueda_array.comunidades.push(_resultado_object);
						break;

					case constant.BUSQUEDAS.CONSTANTES.BUSQUEDA_NOTICIAS_TIPO_ELEMENTO:
						self.dateFormatter(_resultado_object.params);
						var nombre_comunidad = self.communtyName(_resultado_object.params.comunidad_k);

						if (_resultado_object.params.subtitulo.length > 150) {
							_resultado_object.params.subtitulo = _resultado_object.params.subtitulo.substring(0, 150) + "...";
						}
						if (nombre_comunidad != undefined) {
							_resultado_object.params.nombre_comunidad = nombre_comunidad;
						}
						_resultado_object.params.imagen = solveImageURL(_resultado_object.params.imagen_crop);

						resultados_busqueda_array.noticias.push(_resultado_object);
						break;

					case constant.BUSQUEDAS.CONSTANTES.BUSQUEDA_FOROS_PREGUNTAS_TIPO_ELEMENTO:

						/*
       * Aseguramos que el path/URL de la imagen tenga un formato de URL Absooluta.
       */

						if (_resultado_object.params) {
							if (_resultado_object.params.fotoUsuario) {
								_resultado_object.params.fotoUsuario = solveImageURL(_resultado_object.params.fotoUsuario);
							}
						}

						self.dateFormatter(_resultado_object.params);

						resultados_busqueda_array.preguntasForos.push(_resultado_object);
						break;

					case constant.BUSQUEDAS.CONSTANTES.BUSQUEDA_ELEMENTOS_ASOCIADOS_TIPO_ELEMENTO:

						if (_resultado_object.params) {
							_resultado_object.params.imagen = solveImageURL(_resultado_object.params.imagen);
							if (!_resultado_object.params.imagen || _resultado_object.params.imagen === null || _resultado_object.params.imagen.toLowerCase() === 'null' || _resultado_object.params.imagen.toLowerCase().trim() === '') {
								_resultado_object.params.imagen = constant.LMS.CURSOS.VALORES_DEFAULT.PATH_IMAGEN_RUTA_APRENDIZAJE;
							}
						}

						if (_resultado_object.params) {
							if (_resultado_object.params.imagen) {
								_resultado_object.params.imagen = solveImageURL(_resultado_object.params.imagen);
							}
						}

						resultados_busqueda_array.documentosCursosYComunidades.push(_resultado_object);
						break;

					case constant.BUSQUEDAS.CONSTANTES.BUSQUEDA_CALENDARIO_TIPO_ELEMENTO:
						resultados_busqueda_array.eventosProximos.push(_resultado_object);
						break;

					case constant.BUSQUEDAS.CONSTANTES.BUSQUEDA_DOCUMENTOS_TIPO_ELEMENTO:
						self.dateFormatter(_resultado_object.params);
						self.fivarype(_resultado_object.params);
						resultados_busqueda_array.documentos.push(_resultado_object);
						break;

					case constant.BUSQUEDAS.CONSTANTES.BUSQUEDA_EVALUACIONES_DINAMICAS_TIPO_ELEMENTO:
						self.dateFormatter(_resultado_object.params);
						self.isEnrolled(_resultado_object.params);

						if (_resultado_object.params.ultimo_estatus == "1" || _resultado_object.params.ultimo_estatus == "60") {
							_resultado_object.params.cursoIniciado = true;
							if (_resultado_object.params.ultimo_estatus == "1") {
								_resultado_object.params.evaluacionPendiente = true;
							} else {
								_resultado_object.params.evaluacionPendiente = false;
							}
						} else {
							_resultado_object.params.cursoIniciado = false;
						}

						_resultado_object.params.ruta_imagen = solveImageURL(_resultado_object.params.ruta_imagen);
						resultados_busqueda_array.evaluacionesDinamicas.push(_resultado_object);
						break;

					default:
						break;

				}
			});
			this.eventDateFormatter(resultados_busqueda_array.eventosProximos);
			this.eventTimeFormatter(resultados_busqueda_array.eventosProximos);

			return resultados_busqueda_array;
		},
		isEnrolled: function isEnrolled(element) {
			if (element.intentos === "0") {
				element.isEnrolled = false;
			} else {
				element.isEnrolled = true;
			}
		},
		communtyName: function communtyName(_resultado_object) {

			if (_resultado_object == 'NULL') {} else {

				var comunidad = null;

				Ember.$.ajax({
					url: constant.APIURL + 'comunidad/listarMisComunidades',
					async: false,
					type: 'POST',
					contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
					data: 'params=%7B%22comunidad_k%22%3A%22' + _resultado_object + '%22%7D',
					xhrFields: { withCredentials: true },
					success: function success(r) {
						comunidad = JSON.parse(r);
					},
					error: function error(e) {
						console.log(e);
					}
				});

				if (comunidad.data.length > 0) {
					return comunidad.data[0].nombre;
				}

				return "";
			}
		},


		/**
   * fivarype
   *
   *
   * @param element
   */

		fivarype: function fivarype(element) {
			var arrayFileLink = element.ruta.split('.');
			var documentType = arrayFileLink[arrayFileLink.length - 1];

			if (documentType === 'docx' || documentType === 'doc') {
				element.icon = '<i class="fas fa-file-word" style="color: #379be9;"></i>';
			} else if (documentType === 'xlsx' || document === 'xls') {
				element.icon = '<i class="fas fa-file-excel" style="color: #008000;"></i>';
			} else if (documentType === 'pdf') {
				element.icon = '<i class="fas fa-file-pdf" style="color: #e70000;"></i>';
			} else if (documentType === 'ppt' || documentType === 'pptx' || documentType === 'pps' || documentType === 'ppsx') {
				element.icon = '<i class="fas fa-file-powerpoint" style="color: #fe3f0d;"></i>';
			} else if (documentType === 'jpg' || documentType === 'png' || documentType === 'gif') {
				element.icon = '<i class="fas fa-file-image" style="color: #fff;"></i>';
			} else if (documentType === 'mp3' || documentType === 'wma') {
				element.icon = '<i class="fas fa-file-audio" style="color: #fff;"></i>';
			} else if (documentType === 'flv') {
				element.icon = '<i class="fas fa-file-video" style="color: #fff;"></i>';
			} else if (documentType === 'rar' || documentType === 'zip') {
				element.icon === '<i class="fas fa-file-archive" style="color: #fff;"></i>';
			} else {
				element.icon === '<i class="fas fa-file" style="color: #fff;"></i>';
			}
		},
		dateFormatter: function dateFormatter(date_time_forum) {
			var date = null;
			if (date_time_forum.fecha_hora_creacion === undefined) {
				date = date_time_forum.fecha_creacion.split('-');
			} else {
				var date_time = date_time_forum.fecha_hora_creacion.split(' ');
				date = date_time[0].split('-');
			}

			var month = date[1];
			date_time_forum.dayFormat = date[2];
			date_time_forum.yearFormat = date[0];

			date_time_forum.monthFormat = this.getNameMonth(parseInt(month, 10));
		},
		eventTimeFormatter: function eventTimeFormatter(eventsArray) {

			var self = this;

			Object.keys(eventsArray).forEach(function (idx) {
				eventsArray[idx].params.hora_format = self.pmFormatter(eventsArray[idx].params.hora);
				eventsArray[idx].params.hora_fin_format = self.pmFormatter(eventsArray[idx].params.hora_fin);
			});
		},
		pmFormatter: function pmFormatter(time24) {
			var ts = time24;
			var H = +ts.substr(0, 2);
			var h = H % 12 || 12;
			h = h < 10 ? "0" + h : h; // leading 0 at the left for 1 digit hours
			var ampm = H < 12 ? " AM" : " PM";
			ts = h + ts.substr(2, 3) + ampm;
			return ts;
		},
		eventDateFormatter: function eventDateFormatter(eventsArray) {
			var self = this;
			var dateArray;
			Object.keys(eventsArray).forEach(function (idx) {
				dateArray = eventsArray[idx].params.start.split('-');
				eventsArray[idx].dayFormat = dateArray[2];
				eventsArray[idx].monthFormat = self.monthFormatter(dateArray[1]);
				eventsArray[idx].yearFormat = dateArray[0];

				dateArray = eventsArray[idx].params.end.split('-');
				eventsArray[idx].dayFormatEnd = dateArray[2];
				eventsArray[idx].monthFormatEnd = self.monthFormatter(dateArray[1]);
				eventsArray[idx].yearFormatEnd = dateArray[0];
			});
		},
		monthFormatter: function monthFormatter(k) {

			return this.getNameMonth(parseInt(k, 10));
		},


		loadStoreResultadosBusquedas: function loadStoreResultadosBusquedas(controller) {

			var criterio_busqueda = controller.get('criterio_busqueda');
			controller.set('resultadosBusquedas', []);
			var self = this;

			Ember.$.ajax({
				url: constant.APIURL + '/comunidad/generarBusqueda',
				type: 'POST',
				xhrFields: { withCredentials: true },
				contentType: 'application/x-www-form-urlencoded',
				data: 'criterio=' + criterio_busqueda,
				success: function success(response_text) {

					var responseObject = JSON.parse(response_text);

					if (responseObject.success != true) {
						return;
					}

					var busqueda_k = responseObject.data.busqueda_k;

					Ember.$.ajax({
						url: constant.APIURL + '/comunidad/listarResultadosBusqueda',
						type: 'POST',
						xhrFields: { withCredentials: true },
						contentType: 'application/x-www-form-urlencoded',
						data: 'limit=250&busqueda_k=' + busqueda_k,
						success: function success(resultados_response_text) {

							var resultados_response_object = JSON.parse(resultados_response_text);

							var resultados_busqueda_array = self.prepareDataToStore(controller, resultados_response_object.data);
							controller.set('resultadosBusquedas', resultados_busqueda_array);
							Ember.$('#loading').fadeOut('slow');
							sessionStorage.setItem('seccion-anterior', Ember.$(".titulo-seccion").text());
						},
						error: function error(e) {
							console.error(e);
						}
					});
				},
				error: function error(e) {
					console.error(e);
				}
			});
		},

		/**
   * getNameMonth - Función utilitaria. A partir de un nuermo de mes , retorna el nombre correspondiente.
   *
   * Función utilitaria. A partir de un nuermo de mes , retorna el nombre correspondiente.
   *
   *
   * @author  [YRM] Maurilio Cruz Morales
   *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *              Tel. celular (55) 6702 6252
   *
   * @date    2019-08-14
   * @param   int number_month Valor numerico entre 1 y 12, que indica la posición de un mes.
   * @return  string Nombre del mes indicado
   */

		getNameMonth: function getNameMonth(number_month) {

			if (number_month < 1 || number_month > 12) {
				return '';
			}

			var months_array = ['', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

			return months_array[number_month];
		},

		/**
   * actions
   */

		actions: {

			facebook: function facebook() {},

			twitter: function twitter() {},

			linkedin: function linkedin() {},

			redirectTo: function redirectTo(elementRecord) {

				var _redirect_path = '';

				/*
      * Dependiendo del tipo de elemento, lo apilamos al arreglo de datos correspondiente.
      */

				switch (elementRecord.tipo_elemento) {

					case constant.BUSQUEDAS.CONSTANTES.BUSQUEDA_COMUNIDADES_TIPO_ELEMENTO:

						_redirect_path = '/comunidades/' + elementRecord.propietario_k;
						break;

					case constant.BUSQUEDAS.CONSTANTES.BUSQUEDA_NOTICIAS_TIPO_ELEMENTO:
						_redirect_path = '/noticias?noticia_k=' + elementRecord.propietario_k;
						break;

					case constant.BUSQUEDAS.CONSTANTES.BUSQUEDA_FOROS_PREGUNTAS_TIPO_ELEMENTO:

						_redirect_path = '/foros/' + elementRecord.params.foro_k + '?foro_pregunta_k=' + elementRecord.params.foro_pregunta_k;

						if (elementRecord.params.foro_respuesta_k) {
							_redirect_path += '&foro_respuesta_k=' + elementRecord.params.foro_respuesta_k;
						}

						break;

					case constant.BUSQUEDAS.CONSTANTES.BUSQUEDA_ELEMENTOS_ASOCIADOS_TIPO_ELEMENTO:
						_redirect_path = '/cursos/' + elementRecord.params.ra_k;
						break;

					case constant.BUSQUEDAS.CONSTANTES.BUSQUEDA_CALENDARIO_TIPO_ELEMENTO:
						_redirect_path = '/eventos?evento_k=' + elementRecord.params.calendario_k;
						break;

					case constant.BUSQUEDAS.CONSTANTES.BUSQUEDA_DOCUMENTOS_TIPO_ELEMENTO:
						window.open(solveResourceURL(elementRecord.params.ruta), "_blank");
						break;

					case constant.BUSQUEDAS.CONSTANTES.BUSQUEDA_EVALUACIONES_DINAMICAS_TIPO_ELEMENTO:

						if (elementRecord.params.evaluacionPendiente) _redirect_path += "/preguntas-diagnostico/" + elementRecord.params.evaluacion_dinamica_k;else _redirect_path += "/historico/" + elementRecord.params.evaluacion_dinamica_k;

						_redirect_path += "?evaluacion=" + elementRecord.params.evaluacion_dinamica_k;
						_redirect_path += "&descripcion=" + elementRecord.params.nombre;

						break;

					default:
						break;

				}

				if (_redirect_path != '') {
					document.location.href = _redirect_path;
				}
			}

		}

	});
});