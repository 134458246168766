define('comunidades-nafin/initializers/grammar', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.initialize = initialize;
	function initialize(application) {

		Ember.$.ajax({
			url: constant.FIREBASE_DICCI,
			type: 'POST',
			data: {
				method: 'get_dictionary'
			},
			success: function success(response) {
				window.diccionario = [];
				Object.keys(response.data).forEach(function (key) {
					window.diccionario.push(key.toLowerCase());
				});
			},
			error: function error(_error) {
				console.log(_error);
			}
		});
	}
	exports.default = {
		initialize: initialize
	};
});