define('comunidades-nafin/components/sub-footer', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		lastScrollTop: 0,
		didScroll: false,
		delta: 5,
		navbarHeight: 0,
		hasScrolled: function hasScrolled() {
			this._super();
			var st = $(document).scrollTop();
			//console.log('st',st);
			//console.log('abs',Math.abs(this.lastScrollTop - st));

			// Make sure they scroll more than delta
			if (Math.abs(this.lastScrollTop - st) <= this.delta) return;
			// If they scrolled down and are past the navbar, add class .nav-up.
			// This is necessary so you never see what is "behind" the navbar.
			//console.log('lastScrollTop',this.lastScrollTop);
			//console.log('navbarHeight',this.navbarHeight);
			if (st > this.lastScrollTop && st > this.navbarHeight) {
				// Scroll Down
				$('footer').removeClass('nav-down').addClass('nav-up');
			} else {
				// Scroll Up
				if (st + $(window).height() < $(document).height()) {
					$('footer').removeClass('nav-up').addClass('nav-down');
				}
			}
			this.lastScrollTop = st;
		},
		didInsertElement: function didInsertElement() {

			this._super.apply(this, arguments);
			var self = this;

			this.navbarHeight = $('footer').outerHeight();

			$(window).scroll(function (event) {
				self.didScroll = true;
			});

			setInterval(function () {
				if (self.didScroll) {
					self.hasScrolled();
					self.didScroll = false;
				}
			}, 250);
			var self = this._super.apply(this, arguments);
			$.ajax({
				url: constant.APIURL + 'usuario/datospersonales/',
				type: 'POST',
				contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
				xhrFields: { withCredentials: true },
				success: function success(r) {

					var data = JSON.parse(r);
					//Redes sociales
					self.set('general_navegacion_link_pagina_facebook', false);
					if (data.configuracion.general_navegacion_link_pagina_facebook != false) self.set('general_navegacion_link_pagina_facebook', data.configuracion.general_navegacion_link_pagina_facebook);

					self.set('general_navegacion_link_pagina_twitter', false);
					if (data.configuracion.general_navegacion_link_pagina_twitter != false) self.set('general_navegacion_link_pagina_twitter', data.configuracion.general_navegacion_link_pagina_twitter);
				},
				error: function error(e) {

					console.log(e);
				}
			});
		}
	});
});