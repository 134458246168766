define('comunidades-nafin/routes/perfil', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		beforeModel: function beforeModel() {
			var promise = new Ember.RSVP.Promise(function (resolve) {
				Ember.run.later(this, function () {
					resolve("ok");
				}, 1000);
			});
			return promise;
		},


		setupController: function setupController(controller) {

			var self = this;

			Ember.$.ajax({

				url: constant.APIURL + 'usuario/datospersonales/',
				type: 'POST',
				contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
				xhrFields: { withCredentials: true },
				success: function success(r) {

					var data = JSON.parse(r);
					if (data.foto.split('/')[0] === 'uploads') {
						data.foto = constant.BASEURL + data.foto;
					}

					controller.set('avatar', data.foto);
				},
				error: function error(e) {
					console.log(e);
				}
			});

			Ember.$.ajax({
				url: constant.APIURL + 'usuario/getdatosperfil/',
				type: 'POST',
				xhrFields: {
					withCredentials: true
				},
				contentType: 'application/x-www-form-urlencoded',
				success: function success(response) {
					var r = JSON.parse(response);
					controller.set('usuario', r);
					self.visualizar(r);
					self.acciones(r);
				},
				error: function error(e) {
					console.log(e);
				}
			});

			this.loadgiros();
			this.loadpaises();
			this.loadestadosusa();
		},

		changeInputEdition: function changeInputEdition(input_disabled_boolean) {

			if (input_disabled_boolean) {

				Ember.$('button#btn-guardar-perfil').addClass("d-none");
				Ember.$('button#btn-editar-perfil').removeClass("d-none");

				Ember.$('button#btn-cambiar-imagen-usuario-perfil').addClass("d-none");
				Ember.$('button#btn-eliminar-imagen-usuario-perfil').addClass("d-none");
				Ember.$('#size_pic').addClass("d-none");
			} else {

				Ember.$('button#btn-guardar-perfil').removeClass("d-none");
				Ember.$('button#btn-editar-perfil').addClass("d-none");

				Ember.$('button#btn-cambiar-imagen-usuario-perfil').removeClass("d-none");
				Ember.$('button#btn-eliminar-imagen-usuario-perfil').removeClass("d-none");
				Ember.$('#size_pic').removeClass("d-none");
			}
			Ember.$("input").each(function () {
				if (!Ember.$(this).hasClass("no-enabled")) Ember.$(this).prop('disabled', input_disabled_boolean);
			});

			Ember.$("select").each(function () {
				if (!Ember.$(this).hasClass("no-enabled")) Ember.$(this).prop('disabled', input_disabled_boolean);
			});

			Ember.$("radio").each(function () {
				if (!Ember.$(this).hasClass("no-enabled")) Ember.$(this).prop('disabled', input_disabled_boolean);
			});

			Ember.$("input#searchMenu").prop('disabled', false);
		},

		acciones: function acciones() {

			var self = this;

			Ember.$('.btn_save_profile#btn-guardar-perfil').click(function () {
				self.saveuser();
			});

			Ember.$('#usuario_codigopostal').keyup(function () {
				Ember.$('#usuario_colonia').empty();
				self.vaciarCampos();

				if (Ember.$(this).val().length === 5) {

					var cp = Ember.$(this).val();

					Ember.$.ajax({
						url: constant.APIURL + 'publico/usuariopublicctr/listardireccion',
						type: 'POST',
						xhrFields: { withCredentials: true },
						contentType: 'application/x-www-form-urlencoded',
						data: 'tipo=d_asenta&depende=D_mnpio&cp=' + cp,
						success: function success(response) {
							response = JSON.parse(response);
							var address = response.data;

							if (response.numFilas == 0) {
								self.vaciarCampos();
								Ember.$('#modal-avisos h4').text('Error');
								Ember.$('#modal-avisos div.modal-body').html('El código postal no existe.');
								Ember.$('#modal-avisos').modal('show');
								Ember.$('#btn-guardar-perfil').prop('disabled', true);
								return;
							}

							Ember.$('#btn-guardar-perfil').prop('disabled', false);
							Ember.$('#usuario_estado').val(address[0].d_estado);
							Ember.$('#usuario_estado').prop('disabled', true);
							Ember.$('#usuario_municipio').val(address[0].d_ciudad ? address[0].d_ciudad : address[0].D_mnpio);
							Ember.$('#usuario_municipio').prop('disabled', true);
							//$('#usuario_colonia option').remove();
							Object.keys(address).forEach(function (item) {
								//console.log(item,address[item]);
								Ember.$('#usuario_colonia').append('<option value="' + address[item].d_asenta + '">' + address[item].d_asenta + '</option>');
							});
							Ember.$('#usuario_calle').val('');
							Ember.$('#usuario_numero').val('');
						},
						error: function error(e) {
							console.log(e);
						}
					});
				} else {
					Ember.$('#usuario_codigopostal').on('blur', function (e) {

						if (Ember.$('#usuario_codigopostal').val().length < 5) {
							self.vaciarCampos();

							if (Ember.$('#usuario_codigopostal').val().length == 5) {
								return true;
							}

							Ember.$('#btn-guardar-perfil').prop('disabled', true);
							Ember.$('#modal-avisos h4').text('Error');
							Ember.$('#modal-avisos div.modal-body').html('El código postal no es válido, debe contener 5 digitos.');
							Ember.$('#modal-avisos').modal('show');
							return false;
						}
					});
				}
			});

			Ember.$('#seccion_persona input[name="opt_pais"]').change(function () {
				self.vaciarCampos();
				Ember.$('#usuario_codigopostal').val('');
				Ember.$('#btn-guardar-perfil').prop('disabled', false);
				if (Ember.$(this).val() == '1') {
					Ember.$('#campos_mexico').show();
					Ember.$('#campos_usa').hide();
					Ember.$('#campos_otropais').hide();
				} else if (Ember.$(this).val() == '2') {
					Ember.$('#campos_mexico').hide();
					Ember.$('#campos_usa').hide();
					Ember.$('#campos_otropais').show();
				} else {
					Ember.$('#campos_mexico').hide();
					Ember.$('#campos_usa').show();
					Ember.$('#campos_otropais').hide();
				}
			});
			Ember.$('input[name="opt_type"]').change(function () {
				if (Ember.$(this).val() == '1') {
					Ember.$('#seccion_empresario').hide();
				} else {
					Ember.$('#seccion_empresario').show();
				}
			});

			Ember.$('#empresa_telefono').attr('maxlength', 10);

			//Empresa
			Ember.$('#empresa_codigopostal').keyup(function () {
				Ember.$('#empresa_colonia').empty();
				self.vaciarCamposEmpresa();
				if (Ember.$(this).val().length === 5) {

					var cp = Ember.$(this).val();
					Ember.$.ajax({
						url: constant.APIURL + 'publico/usuariopublicctr/listardireccion',
						type: 'POST',
						xhrFields: {
							withCredentials: true
						},
						contentType: 'application/x-www-form-urlencoded',
						data: 'tipo=d_asenta&depende=D_mnpio&cp=' + cp,
						success: function success(response) {
							response = JSON.parse(response);

							var address = response.data;

							if (response.numFilas == 0) {
								self.vaciarCamposEmpresa();
								Ember.$('#modal-avisos h4').text('Error');
								Ember.$('#modal-avisos div.modal-body').html('El código postal no existe.');
								Ember.$('#modal-avisos').modal('show');
								Ember.$('#btn-guardar-perfil').prop('disabled', true);
								return;
							}

							Ember.$('#empresa_estado').val(address[0].d_estado);
							Ember.$('#empresa_estado').prop('disabled', true);
							Ember.$('#empresa_municipio').val(address[0].d_ciudad ? address[0].d_ciudad : address[0].D_mnpio);
							Ember.$('#empresa_municipio').prop('disabled', true);
							Ember.$('#empresa_colonia option').remove();
							address.forEach(function (item) {
								Ember.$('#empresa_colonia').append('<option value="' + item.d_asenta + '">' + item.d_asenta + '</option>');
							});
							Ember.$('#empresa_calle').val('');
							Ember.$('#empresa_numero').val('');
							//$('#usuario_colonia"]').val(address.d_asenta);
						},
						error: function error(e) {
							console.log(e);
						}
					});
				} else {
					Ember.$('#empresa_codigopostal').on('blur', function (e) {

						if (Ember.$('#empresa_codigopostal').val().length < 5) {
							self.vaciarCamposEmpresa();

							if (Ember.$('#empresa_codigopostal').val().length == 5) {
								return true;
							}

							Ember.$('#btn-guardar-perfil').prop('disabled', true);
							Ember.$('#modal-avisos h4').text('Error');
							Ember.$('#modal-avisos div.modal-body').html('El código postal no es válido, debe contener 5 digitos.');
							Ember.$('#modal-avisos').modal('show');
							return false;
						}
					});
				}
			});
			Ember.$('#seccion_empresario input[name="opt_pais"]').change(function () {
				self.vaciarCamposEmpresa();
				Ember.$('#empresa_codigopostal').val('');
				Ember.$('#btn-guardar-perfil').prop('disabled', false);
				if (Ember.$(this).val() == '1') {
					Ember.$('#empresa_campos_mexico').show();
					Ember.$('#empresa_campos_usa').hide();
					Ember.$('#empresa_campos_otropais').hide();
				} else if (Ember.$(this).val() == '2') {
					Ember.$('#empresa_campos_mexico').hide();
					Ember.$('#empresa_campos_usa').hide();
					Ember.$('#empresa_campos_otropais').show();
				} else {
					Ember.$('#empresa_campos_mexico').hide();
					Ember.$('#empresa_campos_usa').show();
					Ember.$('#empresa_campos_otropais').hide();
				}
			});
		},
		isValidDate: function isValidDate(yy, mm, dd) {

			var d = new Date(yy, mm - 1, dd);
			return d && d.getMonth() + 1 == mm;
		},

		vaciarCampos: function vaciarCampos() {
			Ember.$('#usuario_colonia').val('');
			Ember.$('#usuario_municipio').val('');
			Ember.$('#usuario_estado').val('');
			Ember.$('#usuario_numero').val('');
			Ember.$('#usuario_calle').val('');
		},
		vaciarCamposEmpresa: function vaciarCamposEmpresa() {
			Ember.$('#empresa_colonia').val('');
			Ember.$('#empresa_municipio').val('');
			Ember.$('#empresa_estado').val('');
			Ember.$('#empresa_numero').val('');
			Ember.$('#empresa_calle').val('');
		},
		visualizar: function visualizar(usuario) {
			var self = this;
			usuario.telefonos.data.forEach(function (telefono) {
				if (telefono.tipo == '1') {
					Ember.$('#usuario_celular').attr('maxlength', 10);
					Ember.$('#usuario_celular').val(telefono.numero);
				}
				if (telefono.tipo == '0') {
					Ember.$('#usuario_telefono').attr('maxlength', 10);
					Ember.$('#usuario_telefono').val(telefono.numero);
				}
			});

			Ember.$('#inputGrade option').each(function () {
				if (Ember.$(this).val() == usuario.grado_estudios) {
					Ember.$(this).prop('selected', true);
				}
			});

			Ember.$('#inputCivilStatus option').each(function () {
				if (Ember.$(this).val() == usuario.extras.data.estado_civil) {
					Ember.$(this).prop('selected', true);
				}
			});

			var birth = usuario.fecha_nacimiento.split('-');

			Ember.$('#usuario_dia').attr('maxlength', 2);
			Ember.$('#usuario_dia').val(birth[2]);
			Ember.$('#usuario_mes').val(parseInt(birth[1]));
			Ember.$('#usuario_anio').attr('maxlength', 4);
			Ember.$('#usuario_anio').val(birth[0]);

			Ember.$('#usuario_anio, #usuario_dia, #usuario_mes').change(function () {
				var dia = ("0" + Ember.$('#usuario_dia').val()).slice(-2);
				Ember.$('#usuario_dia').val(dia);
				var mes = ("0" + Ember.$('#usuario_mes').val()).slice(-2);
				var anio = Ember.$('#usuario_anio').val();
				var fecha_nacimiento = new Date(anio, mes, dia);
				var hoy = new Date();
				if (parseInt(dia) != 0 && mes != null && parseInt(anio) != 0 && dia != "" && anio != "") if (fecha_nacimiento.getTime() > hoy.getTime() || parseInt(dia) == 0 || parseInt(mes) == 0 || parseInt(anio) < 1900 || parseInt(anio) >= hoy.getFullYear() || !self.isValidDate(anio, mes, dia)) {
					Ember.$('#modal-avisos .modal-header').text('Error');
					Ember.$('#modal-avisos .modal-body').html("La fecha de nacimiento ingresada no existe, favor de ingresarla nuevamente");
					Ember.$('#modal-avisos').modal('show');
					Ember.$('#usuario_dia').val('');
					Ember.$('#usuario_mes option:first').prop('selected', true);
					Ember.$('#usuario_anio').val('');
				}
			});

			Ember.$('#usuario_rfc, #empresa_rfc').keydown(function (e) {
				var k = e.which;
				//console.log(e);
				var ok = k >= 65 && k <= 90 || // A-Z
				k >= 96 && k <= 122 || // 0 numeric pad, a-z
				k >= 48 && k <= 57 || // 0-9
				k == 241 || k == 209 || // ñ Ñ
				k == 45 || k == 39 || // - ' 
				k == 8 || k == 9 || k == 32;
				if (!ok) {
					e.preventDefault();
				} else if (e.shiftKey && k >= 48 && k <= 57) {
					e.preventDefault();
				} else if (e.altKey) {
					e.preventDefault();
				}
			});

			function rfcValido(rfc, aceptarGenerico) {
				var re = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
				var validado = rfc.match(re);

				if (!validado) //Coincide con el formato general del regex?
					return false;

				//Separar el dígito verificador del resto del RFC
				var digitoVerificador = validado.pop(),
				    rfcSinDigito = validado.slice(1).join(''),
				    len = rfcSinDigito.length,


				//Obtener el digito esperado
				diccionario = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ",
				    indice = len + 1;
				var suma, digitoEsperado;

				if (len == 12) suma = 0;else suma = 481; //Ajuste para persona moral

				for (var i = 0; i < len; i++) {
					suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
				}digitoEsperado = 11 - suma % 11;
				if (digitoEsperado == 11) digitoEsperado = 0;else if (digitoEsperado == 10) digitoEsperado = "A";

				//El dígito verificador coincide con el esperado?
				// o es un RFC Genérico (ventas a público general)?
				if (digitoVerificador != digitoEsperado && (!aceptarGenerico || rfcSinDigito + digitoVerificador != "XAXX010101000")) return false;else if (!aceptarGenerico && rfcSinDigito + digitoVerificador == "XEXX010101000") return false;
				return rfcSinDigito + digitoVerificador;
			}

			Ember.$('#usuario_rfc').change(function () {
				if (Ember.$('#usuario_rfc').val() != '') {
					if (!rfcValido(Ember.$('#usuario_rfc').val(), true) || !(Ember.$('#usuario_rfc').val().length == 13)) {
						Ember.$('#modal-avisos h4').text('Error');
						Ember.$('#modal-avisos div.modal-body').html('El RFC proporcionado no es válido, debe cumplir con la estructura correcta para persona física. <br><br> 4 letras seguidas por 6 dígitos y 3 caracteres alfanuméricos = 13 dígitos ');
						Ember.$('#modal-avisos').modal('show');
						Ember.$('#usuario_rfc').val('');
						//$('input[name="rfc"]').focus();
						return false;
					}
				}
			});

			Ember.$('#empresa_rfc').change(function () {
				if (Ember.$('#empresa_rfc').val() != '') {
					if (!rfcValido(Ember.$('#empresa_rfc').val(), true) || !(Ember.$('#empresa_rfc').val().length == 12)) {
						Ember.$('#modal-avisos h4').text('Error');
						Ember.$('#modal-avisos div.modal-body').html('El RFC proporcionado no es válido, debe cumplir con la estructura correcta para persona moral. <br><br> 3 letras seguidas por 6 dígitos y 3 caracteres alfanumericos = 12 dígitos.  ');
						Ember.$('#modal-avisos').modal('show');
						Ember.$('#empresa_rfc').val('');
						//$('input[name="empresario_rfc"]').focus();
						return false;
					}
				}
			});

			Ember.$('input[name="opt_gender"]').each(function () {
				if (Ember.$(this).val() == usuario.genero) {
					Ember.$(this).prop('checked', true);
				}
			});

			if (usuario.extras.data && usuario.extras.data.perfil_publico && usuario.extras.data.perfil_publico == '1') {
				Ember.$('input[name="opt_profile"][value="1"]').prop('checked', true);
			} else {
				Ember.$('input[name="opt_profile"][value="0"]').prop('checked', true);
			}

			if (usuario.extras.data && usuario.extras.data.tipo_usuario && usuario.extras.data.tipo_usuario == '1') {
				Ember.$('input[name="opt_type"][value="1"]').prop('checked', true);
				Ember.$('#seccion_empresario').hide();
			} else {
				Ember.$('input[name="opt_type"][value="2"]').prop('checked', true);
				Ember.$('#seccion_empresario').show();
			}

			if (usuario.extras.data && usuario.extras.data.dedonde && usuario.extras.data.dedonde == '1') {
				Ember.$('input[name="opt_origin"][value="1"]').prop('checked', true);
			} else if (usuario.extras.data && usuario.extras.data.dedonde && usuario.extras.data.dedonde == '2') {
				Ember.$('input[name="opt_origin"][value="2"]').prop('checked', true);
			} else {
				Ember.$('input[name="opt_origin"][value="3"]').prop('checked', true);
			}

			if (usuario.extras.data && usuario.extras.data.ubicacion && usuario.extras.data.ubicacion == '1') {
				Ember.$('#seccion_persona input[name="opt_pais"][value="1"]').prop('checked', true);
				Ember.$('#campos_mexico').show();
				Ember.$('#campos_usa').hide();
				Ember.$('#campos_otropais').hide();
			} else if (usuario.extras.data && usuario.extras.data.ubicacion && usuario.extras.data.ubicacion == '2') {
				Ember.$('#seccion_persona input[name="opt_pais"][value="2"]').prop('checked', true);
				Ember.$('#campos_mexico').hide();
				Ember.$('#campos_usa').hide();
				setTimeout(function () {
					Ember.$('#campos_otropais').show();
					Ember.$('select[name="otros_paises"] option:contains("' + usuario.extras.data.pais + '")').prop('selected', true);
				}, 2500);
			} else {
				Ember.$('#seccion_persona input[name="opt_pais"][value="3"]').prop('checked', true);
				Ember.$('#campos_mexico').hide();
				Ember.$('#campos_otropais').hide();
				setTimeout(function () {
					Ember.$('#campos_usa').show();
					if (usuario.direccion.data.length) {
						Ember.$('select[name="estados_usa"] option:contains("' + usuario.direccion.data[0].estado + '")').prop('selected', true);
					}
				}, 2500);
			}

			if (usuario.direccion && usuario.direccion.data && usuario.direccion.data.length > 0) {
				var address = usuario.direccion.data[0];
				Ember.$('#usuario_codigopostal').val(address.cp);
				Ember.$('#usuario_estado').val(address.estado);
				Ember.$('#usuario_municipio').val(address.ciudad);

				Ember.$('#usuario_colonia').html('<option value="' + address.colonia + '">' + address.colonia + '</option>');
				Ember.$('#usuario_colonia').val(address.colonia);

				Ember.$('#usuario_calle').val(address.direccion);
				Ember.$('#usuario_numero').val(address.numero);
			}
			//Bloqueo de caracteres
			Ember.$('#usuario_nombre').keypress(function (e) {
				var keC1 = e.which;
				if (!(keC1 >= 65 && keC1 <= 90 || keC1 >= 97 && keC1 <= 122 || keC1 >= 192 && keC1 <= 214 || keC1 >= 217 && keC1 <= 220 || keC1 >= 223 && keC1 <= 246 || keC1 >= 249 && keC1 <= 253) && keC1 != 180 && keC1 != 8 && keC1 != 32 && keC1 != 45 && keC1 != 39 || keC1 == 44 || keC1 == 96 || keC1 == 43 || keC1 == 209) {
					e.preventDefault();
				}
			});

			Ember.$('#usuario_apellidopaterno').keypress(function (e) {
				var keC1 = e.which;
				if (!(keC1 >= 65 && keC1 <= 90 || keC1 >= 97 && keC1 <= 122 || keC1 >= 192 && keC1 <= 214 || keC1 >= 217 && keC1 <= 220 || keC1 >= 223 && keC1 <= 246 || keC1 >= 249 && keC1 <= 253) && keC1 != 180 && keC1 != 8 && keC1 != 32 && keC1 != 45 && keC1 != 39 || keC1 == 44 || keC1 == 96 || keC1 == 43 || keC1 == 209) {
					e.preventDefault();
				}
			});

			Ember.$('#usuario_apellidomaterno').keypress(function (e) {
				var keC1 = e.which;
				if (!(keC1 >= 65 && keC1 <= 90 || keC1 >= 97 && keC1 <= 122 || keC1 >= 192 && keC1 <= 214 || keC1 >= 217 && keC1 <= 220 || keC1 >= 223 && keC1 <= 246 || keC1 >= 249 && keC1 <= 253) && keC1 != 180 && keC1 != 8 && keC1 != 32 && keC1 != 45 && keC1 != 39 || keC1 == 44 || keC1 == 96 || keC1 == 43 || keC1 == 209) {
					e.preventDefault();
				}
			});

			Ember.$('#usuario_dia').keypress(function (e) {
				var keC1 = e.which;
				if (!(keC1 >= 48 && keC1 <= 57)) {
					e.preventDefault();
				}
			});

			Ember.$('#usuario_anio').keypress(function (e) {
				var keC1 = e.which;
				if (!(keC1 >= 48 && keC1 <= 57)) {
					e.preventDefault();
				}
			});

			Ember.$('#usuario_telefono').keypress(function (e) {
				var keC1 = e.which;
				if (!(keC1 >= 48 && keC1 <= 57)) {
					e.preventDefault();
				}
			});

			Ember.$('#usuario_celular').keypress(function (e) {
				var keC1 = e.which;
				if (!(keC1 >= 48 && keC1 <= 57)) {
					e.preventDefault();
				}
			});

			Ember.$('#usuario_codigopostal').keypress(function (e) {
				var keC1 = e.which;
				if (!(keC1 >= 48 && keC1 <= 57)) {
					e.preventDefault();
				}
			});

			//Empresa
			if (usuario.extras.data && usuario.extras.data.tipo_usuario && usuario.extras.data.tipo_usuario == '2') {
				Ember.$('#empresa_razonsocial').val(usuario.extras.data.razon_social);
				Ember.$('#empresa_rfc').val(usuario.extras.data.empresa_rfc);
				Ember.$('#empresa_numeroempleados').val(usuario.extras.data.tamano_empresa);
				Ember.$('#empresa_pagina').val(usuario.extras.data.web);
				Ember.$('#empresa_redes').val(usuario.extras.data.redes_sociales);
				Ember.$('#empresa_anio').val(usuario.extras.data.antiguedad);

				Ember.$('input[name="opt_yn"]').each(function () {
					if (usuario.extras.data.empresa_familiar !== undefined && Ember.$(this).val() == usuario.extras.data.empresa_familiar.toLocaleLowerCase()) {
						Ember.$(this).prop('checked', true);
					}
				});
				Ember.$('input[name="opt_sector"]').each(function () {
					if (Ember.$(this).val() == usuario.extras.data.sector) {
						Ember.$(this).prop('checked', true);
					}
				});

				setTimeout(function () {
					Ember.$('#empresa_giro option').remove();
					Object.keys(giros).forEach(function (key) {
						if (giros[key].sector_k == usuario.extras.data.sector) {
							Ember.$('#empresa_giro').append('<option value="' + giros[key].giro_k + '">' + giros[key].giro + '</option>');
						}
					});
					Ember.$('#empresa_giro').val(usuario.extras.data.giro);
				}, 1000);

				//Domicilio
				if (usuario.direccion_empresa.data[0] !== null && usuario.direccion_empresa.data[0] !== undefined) {
					Ember.$('#empresa_codigopostal').val(usuario.direccion_empresa.data[0].cp);
					Ember.$('#empresa_estado').val(usuario.direccion_empresa.data[0].estado);
					Ember.$('#empresa_municipio').val(usuario.direccion_empresa.data[0].ciudad);

					Ember.$('#empresa_colonia').html('<option value="' + usuario.direccion_empresa.data[0].colonia + '">' + usuario.direccion_empresa.data[0].colonia + '</option>');
					Ember.$('#empresa_colonia').val(usuario.direccion_empresa.data[0].colonia);

					Ember.$('#empresa_calle').val(usuario.direccion_empresa.data[0].direccion);
					Ember.$('#empresa_numero').val(usuario.direccion_empresa.data[0].numero);
				}
				if (usuario.extras.data && usuario.extras.data.empresa_ubicacion && usuario.extras.data.empresa_ubicacion == '1') {
					Ember.$('#seccion_empresario input[name="opt_pais"][value="1"]').prop('checked', true);
					Ember.$('#empresa_campos_mexico').show();
					Ember.$('#empresa_campos_usa').hide();
					Ember.$('#empresa_campos_otropais').hide();
				} else if (usuario.extras.data && usuario.extras.data.empresa_ubicacion && usuario.extras.data.empresa_ubicacion == '2') {
					Ember.$('#seccion_empresario input[name="opt_pais"][value="2"]').prop('checked', true);
					Ember.$('#empresa_campos_mexico').hide();
					Ember.$('#empresa_campos_usa').hide();
					setTimeout(function () {
						Ember.$('#empresa_campos_otropais').show();
						Ember.$('select[name="empresario_otros_paises"] option:contains("' + usuario.extras.data.empresa_pais + '")').prop('selected', true);
					}, 2500);
				} else {
					Ember.$('#seccion_empresario input[name="opt_pais"][value="3"]').prop('checked', true);
					Ember.$('#empresa_campos_mexico').hide();
					Ember.$('#empresa_campos_otropais').hide();
					setTimeout(function () {
						Ember.$('#empresa_campos_usa').show();
						Ember.$('select[name="empresario_estados_usa"] option:contains("' + usuario.direccion_empresa.data[0].estado + '")').prop('selected', true);
					}, 2500);
				}

				if (usuario.telefonos_empresa && usuario.telefonos_empresa.data && usuario.telefonos_empresa.data.length > 0) {
					Ember.$('#empresa_telefono').val(usuario.telefonos_empresa.data[0].numero);
				}
			}
			Ember.$('input[name="opt_sector"]').change(function () {
				console.log(Ember.$(this).val());
				var op = Ember.$(this).val();
				Ember.$('#empresa_giro option').remove();
				Object.keys(giros).forEach(function (key) {
					if (giros[key].sector_k == op) {
						Ember.$('#empresa_giro').append('<option value="' + giros[key].giro_k + '">' + giros[key].giro + '</option>');
					}
				});
			});

			this.changeInputEdition(true);
		},

		saveuser: function saveuser() {

			var self = this;

			var usuario = {};
			var errores = [];

			usuario.tipo_usuario = Ember.$('input[name="opt_type"]:checked').val();
			usuario.perfil_publico = Ember.$('input[name="opt_profile"]:checked').val();
			usuario.sexo = Ember.$('input[name="opt_gender"]:checked').val();
			usuario.foto = Ember.$('#nueva_imagen_perfil').attr('src');

			if (Ember.$('#usuario_nombre').val() != '') {
				usuario.nombre = Ember.$('#usuario_nombre').val();
			} else {
				errores.push('El nombre del usuario es obligatorio');
			}
			if (Ember.$('#usuario_apellidopaterno').val() != '') {
				usuario.apellido_pat = Ember.$('#usuario_apellidopaterno').val();
			} else {
				errores.push('El apellido paterno del usuario es obligatorio');
			}
			if (Ember.$('#usuario_apellidomaterno').val() != '') {
				usuario.apellido_mat = Ember.$('#usuario_apellidomaterno').val();
			}
			usuario.fecha_nac = Ember.$('#usuario_anio').val() + '-' + ("0" + Ember.$('#usuario_mes').val()).slice(-2) + '-' + Ember.$('#usuario_dia').val();
			usuario.rfc = Ember.$('#usuario_rfc').val();
			//usuario.rfc3 = $('#usuario_rfc').attr('maxlength', 13);
			usuario.curp = null;
			usuario.imss = null;

			if (Ember.$('#inputGrade').val() == 0) {
				errores.push('El grado de estudios del usuario es obligatorio');
			} else {
				usuario.grado_estudios = Ember.$('#inputGrade').val();
			}

			usuario.estado_civil = Ember.$('#inputCivilStatus').val();
			usuario.dedonde = Ember.$('input[name="opt_origin"]:checked').val();
			usuario.casa = Ember.$('#usuario_telefono').val();
			usuario.movil = Ember.$('#usuario_celular').val();

			if (Ember.$('#usuario_email').val() != '') {
				usuario.email = Ember.$('#usuario_email').val();
				var formatoEmail = Ember.$('#usuario_email').val();
				var formato = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
				if (!formato.test(formatoEmail)) {
					errores.push('El formato del email es inválido');
				}
			} else {
				errores.push('El email del usuario es obligatorio');
			}

			var dia = Ember.$('#usuario_dia').val();
			var mes = ("0" + Ember.$('#usuario_mes').val()).slice(-2);
			var anio = Ember.$('#usuario_anio').val();
			if (mes != null && dia != '' && anio != "0000") {
				if (anio != '') {
					if (mes > 0 && mes < 13 && anio > 1900 && anio < 2019 && dia > 0 && dia <= new Date(anio, mes, 0).getDate()) {} else {
						errores.push('El código postal del usuario es obligatorio');
						Ember.$('#usuario_dia').val('');
						Ember.$('#usuario_mes').val('');
						Ember.$('#usuario_anio').val('');
					}
				}
			}

			if (!self.isValidDate(Ember.$('#usuario_anio').val(), Ember.$('#usuario_mes').val(), Ember.$('#usuario_dia').val()) || parseInt(Ember.$('#usuario_anio').val()) < 1900 || isNaN(parseInt(Ember.$("#usuario_anio").val()))) {
				errores.push('La fecha de nacimiento es obligatoria.');
			}
			usuario.ubicacion = Ember.$('#seccion_persona input[name="opt_pais"]:checked').val();
			usuario.ubicacion_empresa = Ember.$('#seccion_empresario input[name="opt_pais"]:checked').val();

			//usuario.estadousa = $('#seccion_persona select[name="estados_usa"]').val();
			if (usuario.ubicacion == "1") {
				/*México */
				usuario.pais = Ember.$('#seccion_persona input[name="opt_pais"]:checked').parent().text().trim();
				if (Ember.$('#usuario_codigopostal').val() != '') {
					usuario.cp1 = Ember.$('#usuario_codigopostal').val();
				} else {
					if (usuario.ubicacion && usuario.ubicacion_empresa == 1) {
						errores.push('El código postal del usuario es obligatorio');
					}
				}
				if (Ember.$('#usuario_estado').val() != '') {
					usuario.edo1 = Ember.$('#usuario_estado').val();
				} else {
					if (usuario.ubicacion && usuario.ubicacion_empresa == 1) {
						errores.push('El estado del usuario es obligatorio');
					}
				}
				if (Ember.$('#usuario_municipio').val() != '') {
					usuario.ciudad1 = Ember.$('#usuario_municipio').val();
				} else {
					if (usuario.ubicacion && usuario.ubicacion_empresa == 1) {
						errores.push('El municipio del usuario es obligatorio');
					}
				}
				if (Ember.$('#usuario_colonia').val() != '') {
					usuario.colonia1 = Ember.$('#usuario_colonia').val();
				} else {
					if (usuario.ubicacion && usuario.ubicacion_empresa == 1) {
						errores.push('La colonia del usuario es obligatoria');
					}
				}

				usuario.calle1 = Ember.$('#usuario_calle').val();
				usuario.numero1 = Ember.$('#usuario_numero').val();
			}

			if (usuario.ubicacion == "1") {
				if (Ember.$('#usuario_codigopostal').val() == '' || Ember.$('#usuario_codigopostal').val().length != 5) {
					errores.push('El código postal del usuario es obligatorio');
				}
				if (Ember.$('#usuario_calle').val() == '') {
					errores.push('La calle del usuario es obligatoria');
				}
				if (Ember.$('#usuario_numero').val() == '') {
					errores.push('El número del domicilio del usuario es obligatorio');
				}
			}

			if (usuario.ubicacion_empresa == '1') {
				if (Ember.$('#empresa_codigopostal').val() == '') {
					errores.push('El código postal de la empresa es obligatorio');
				}
				if (Ember.$('#empresa_calle').val() == '') {
					errores.push('La calle de la empresa es obligatoria');
				}
				if (Ember.$('#empresa_numero').val() == '') {
					errores.push('El numero del domicilio de la empresa es obligatorio');
				}
			}

			if (usuario.ubicacion == "3") {
				if (Ember.$('#seccion_persona select[name="estados_usa"] option:selected').text() == "Selecciona una opción") {
					errores.push('La selección del estado en Estados Unidos del usuario es obligatoria');
				}
			}

			if (usuario.ubicacion_empresa == "3") {
				if (Ember.$('#seccion_empresario select[name="empresario_estados_usa"] option:selected').text() == "Selecciona una opción") {
					errores.push('La selección del estado en Estados Unidos en la empresa es obligatoria');
				}
			}

			if (usuario.ubicacion == "2") {
				if (Ember.$('#seccion_persona select[name="otros_paises"] option:selected').text() == "Selecciona una opción") {
					errores.push('La selección del país del usuario es obligatoria');
				}
			}

			if (usuario.ubicacion_empresa == "2") {
				if (Ember.$('#seccion_empresario select[name="empresario_otros_paises"] option:selected').text() == "Selecciona una opción") {
					errores.push('La selección del país en la empresa es obligatoria');
				}
			}

			if (usuario.ubicacion == "3") {
				/*USA */
				usuario.pais = Ember.$('#seccion_persona input[name="opt_pais"]:checked').parent().text().trim();
				if (Ember.$('#seccion_persona select[name="estados_usa"]').val() != '') {
					usuario.edo1 = Ember.$('#seccion_persona select[name="estados_usa"] option:selected').text();
				} else {
					errores.push('El estado del usuario es obligatorio');
				}
			}
			if (usuario.ubicacion == "2") {
				/*Otro país */
				if (Ember.$('#seccion_persona select[name="otros_paises"]').val() != '') {
					usuario.pais = Ember.$('#seccion_persona select[name="otros_paises"] option:selected').text();
				} else {

					errores.push('El país del usuario es obligatorio');
				}
			}

			if (usuario.tipo_usuario == 2) {
				usuario.razonsocial = Ember.$('#empresa_razonsocial').val();
				usuario.rfc2 = Ember.$('#empresa_rfc').val();
				//usuario.rfc4 = $('#empresa_rfc').attr('maxlength', 12);
				usuario.antiguedad = Ember.$('#empresa_anio').val();
				usuario.antiguedad2 = Ember.$('#empresa_anio').attr('maxlength', 4);
				usuario.sector = Ember.$('input[name="opt_sector"]:checked').val();
				usuario.giro = Ember.$('#empresa_giro').val();

				usuario.ubicacion_empresa = Ember.$('#seccion_empresario input[name="opt_pais"]:checked').val();

				if (usuario.ubicacion_empresa == "1") {
					/*Mexico*/
					usuario.empresa_pais = Ember.$('#seccion_empresario input[name="opt_pais"]:checked').parent().text().trim();
					//usuario.estadousa_empresa = $('#empresa_campos_usa select :selected').text();
					usuario.cp2 = Ember.$('#empresa_codigopostal').val();
					//usuario.edo2 = $('#empresa_estado').val();
					usuario.edo2 = Ember.$('#empresa_estado').val();
					usuario.ciudad2 = Ember.$('#empresa_municipio').val();
					usuario.colonia2 = Ember.$('#empresa_colonia').val();
					usuario.calle2 = Ember.$('#empresa_calle').val();
					usuario.numero2 = Ember.$('#empresa_numero').val();
				}
				if (usuario.ubicacion_empresa == "3") {
					/*USA*/
					usuario.empresa_pais = Ember.$('#seccion_empresario input[name="opt_pais"]:checked').parent().text().trim();
					if (Ember.$('#seccion_empresario select[name="empresario_estados_usa"]').val() != '') {
						usuario.edo2 = Ember.$('#seccion_empresario select[name="empresario_estados_usa"] option:selected').text();
					} else {
						errores.push('El estado de la empresa es obligatorio');
					}
				}
				if (usuario.ubicacion_empresa == "2") {
					/*Otro Pais*/

					if (Ember.$('#seccion_empresario select[name="empresario_otros_paises"]').val() != '') {
						usuario.empresa_pais = Ember.$('#seccion_empresario select[name="empresario_otros_paises"] option:selected').text();
					} else {

						errores.push('El país del usuario es obligatorio');
					}
				}

				usuario.regimen = null;
				usuario.tamano_empresa = Ember.$('#empresa_numeroempleados').val();
				usuario.redes_sociales = Ember.$('#empresa_redes').val();
				usuario.empresa_familiar = Ember.$('input[name="opt_yn"]:checked').val();

				usuario.telefono2 = Ember.$('#empresa_telefono').val();
				usuario.web = Ember.$('#empresa_pagina').val();

				if (usuario.tamano_empresa == "Número de empleados") {
					errores.push('El Número de empleados es obligatorio');
				}

				if (Ember.$('#empresa_pagina').val() != '') {

					var formatoPag = Ember.$('#empresa_pagina').val();
					var formatop = /^[A-Za-z0-9_-]+\.+[A-Za-z0-9.\/%&=\?_:;-]+$/i;
					if (!formatop.test(formatoPag)) {
						errores.push('El formato del página web es inválido');
					}
				}

				if (!usuario.razonsocial) {
					errores.push('La razón social es obligatoria');
				}
			} else {
				usuario.razonsocial = null;
				usuario.rfc2 = null;
				usuario.antiguedad = null;
				usuario.sector = null;
				usuario.giro = null;
				usuario.ubicacion_empresa = null;
				usuario.empresa_pais = null;
				//usuario.estadousa_empresa = null;
				usuario.regimen = null;
				usuario.tamano_empresa = null;
				usuario.redes_sociales = null;
				usuario.empresa_familiar = null;
				usuario.cp2 = null;
				usuario.edo2 = null;
				usuario.ciudad2 = null;
				usuario.colonia2 = null;
				usuario.calle2 = null;
				usuario.numero2 = null;
				usuario.telefono2 = null;
				usuario.web = null;
			}

			if (errores.length) {
				var html = '<ul>';
				errores.forEach(function (error) {
					html += '<li>';
					html += error;
					html += '</li>';
				});
				html += '</ul>';
				Ember.$('#alerts').removeClass('alert-warning').removeClass('alert-danger').removeClass('alert-success');
				Ember.$('#alerts').addClass('alert-danger');
				Ember.$('#alerts p').html(html);
				Ember.$('#alerts').show();
			} else {
				Ember.$('#alerts').removeClass('alert-warning').removeClass('alert-danger').removeClass('alert-success');
				Ember.$('#alerts').hide();

				var urldata = Object.keys(usuario).map(function (k) {
					return encodeURIComponent(k) + '=' + encodeURIComponent(usuario[k]);
				}).join('&');
				console.log(usuario, urldata);

				Ember.$.ajax({
					url: constant.APIURL + 'usuario/savedatosperfilparanafin',
					type: 'POST',
					xhrFields: { withCredentials: true },
					contentType: 'application/x-www-form-urlencoded',
					data: urldata,
					success: function success(response) {
						response = JSON.parse(response);

						if (response.success == true) {

							Ember.$('#alerts').addClass('alert-success');
							Ember.$('#alerts p').html('Los datos se guardaron exitosamente. ');
							Ember.$('#alerts').show();

							self.changeInputEdition(true);

							setTimeout(function () {
								location.reload();
							}, 500);
						} else {
							Ember.$('#alerts').addClass('alert-danger');
							Ember.$('#alerts p').html('Error. ' + response.msg);
							Ember.$('#alerts').show();
						}
					},
					error: function error(e) {
						Ember.$('#alerts').addClass('alert-danger');
						Ember.$('#alerts p').html('Error por favor envíanos un correo a ayuda@nafintecapacita.com');
						Ember.$('#alerts').show();
						console.log(e);
					}
				});
			}
		},

		loadgiros: function loadgiros() {
			window.giros = [];
			Ember.$.ajax({
				url: constant.APIURL + 'publico/usuariopublicctr/listargiros',
				type: 'POST',
				xhrFields: { withCredentials: true },
				contentType: 'application/x-www-form-urlencoded',
				data: 'params={"sector_k":1}',
				success: function success(response) {
					response = JSON.parse(response);
					var giros = response.data;
					//Suponemos que inicia con 1
					Object.keys(giros).forEach(function (key) {
						window.giros.push(giros[key]);
						if (giros[key].sector_k == 1) {
							Ember.$('#empresa_giro').append('<option value="' + giros[key].giro_k + '">' + giros[key].giro + '</option>');
						}
					});
				},
				error: function error(e) {
					console.log(e);
				}
			}), Ember.$.ajax({
				url: constant.APIURL + 'publico/usuariopublicctr/listargiros',
				type: 'POST',
				xhrFields: { withCredentials: true },
				contentType: 'application/x-www-form-urlencoded',
				data: 'params={"sector_k":2}',
				success: function success(response) {
					response = JSON.parse(response);
					var giros = response.data;
					//Suponemos que inicia con 1
					Object.keys(giros).forEach(function (key) {
						window.giros.push(giros[key]);
						if (giros[key].sector_k == 1) {
							Ember.$('#empresa_giro').append('<option value="' + giros[key].giro_k + '">' + giros[key].giro + '</option>');
						}
					});
				},
				error: function error(e) {
					console.log(e);
				}
			}), Ember.$.ajax({
				url: constant.APIURL + 'publico/usuariopublicctr/listargiros',
				type: 'POST',
				xhrFields: { withCredentials: true },
				contentType: 'application/x-www-form-urlencoded',
				data: 'params={"sector_k":3}',
				success: function success(response) {
					response = JSON.parse(response);
					var giros = response.data;
					//Suponemos que inicia con 1
					Object.keys(giros).forEach(function (key) {
						window.giros.push(giros[key]);
						if (giros[key].sector_k == 1) {
							Ember.$('#empresa_giro').append('<option value="' + giros[key].giro_k + '">' + giros[key].giro + '</option>');
						}
					});
				},
				error: function error(e) {
					console.log(e);
				}
			});
		},
		loadpaises: function loadpaises() {
			Ember.$.ajax({
				url: constant.APIURL + 'publico/usuariopublicctr/listarpais',
				type: 'POST',
				xhrFields: {
					withCredentials: true
				},
				contentType: 'application/x-www-form-urlencoded',
				data: '',
				success: function success(response) {
					response = JSON.parse(response);
					var paises = response.data;
					Object.keys(paises).forEach(function (key) {
						//console.log(key,paises[key]);
						Ember.$('select[name="otros_paises"],select[name="empresario_otros_paises"],select[name="empresa_otros_paises"]').append('<option value="' + paises[key].pais_k + '">' + paises[key].nombre + '</option>');
					});
				},
				error: function error(e) {
					console.log(e);
				}
			});
		},

		loadestadosusa: function loadestadosusa() {

			Ember.$.ajax({
				url: constant.APIURL + 'publico/usuariopublicctr/listarestadosusa',
				type: 'POST',
				xhrFields: {
					withCredentials: true
				},
				contentType: 'application/x-www-form-urlencoded',
				data: '',
				success: function success(response) {
					response = JSON.parse(response);
					var estados = response.data;
					Object.keys(estados).forEach(function (key) {
						//console.log(key,estados[key]);
						Ember.$('select[name="estados_usa"],select[name="empresario_estados_usa"],select[name="empresa_estados_usa"]').append('<option value="' + key + '">' + estados[key].d_estado + '</option>');
					});
				},
				error: function error(e) {
					console.log(e);
				}
			});
		},

		actions: {

			changePhoto: function changePhoto(file) {
				try {
					//** Podemos obtener la INFO de la Imagen y mandar a una vista previa ***
					//file.readAsDataURL().then(function (url) {
					//console.log(url);
					//});

					file.upload(constant.APIURL + 'usuario/uploadimgperfil', {
						file: file, //El archivo a enviar
						withCredentials: true, //Mantener la sesión
						fileKey: 'userfile' //El nombre esperado en el backend
					}).then(function (response) {
						//Primero hará una petición OPTIONS y depues la POST con la INFO
						//La respuesta esperada esta en el BODY de response

						if (response.status == 200) {
							console.log("s");
							//Se subió correctamente
							var parsedResponse = JSON.parse(response.body[0].innerText);
							if (parsedResponse.error) {
								Ember.$('#modal-avisos .modal-header').text('Error');
								Ember.$('#modal-avisos .modal-body').text(parsedResponse.error);
								Ember.$('#modal-avisos').modal('show');
							} else {
								if (parsedResponse.path.indexOf('http') != 0) {
									parsedResponse.path = constant.BASEURL + parsedResponse.path;
								}
								file.set('avatar', parsedResponse.path);
							}
							Ember.$('#modal-avisos .modal-header').text('Tu imagen de perfil se cambió exitosamente. Da clic en Cerrar y Guardar para ver reflejados los cambios.');
							Ember.$('#modal-avisos .modal-body').html('<img src="' + parsedResponse.path + '"alt="Imagen de perfil" width="50px">');
							Ember.$('#modal-avisos').modal('show');
						} else {
							file.set('avatar', null);
						}
					});
				} catch (e) {
					console.log(e);
				}
			},

			disableInputEdition: function disableInputEdition() {

				this.changeInputEdition(true);
			},

			enableInputEdition: function enableInputEdition() {

				this.changeInputEdition(false);
			},
			eliminarFotoPerfil: function eliminarFotoPerfil() {
				Ember.$.ajax({

					url: constant.APIURL + 'usuario/resetimgperfil/',
					type: 'POST',
					contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
					xhrFields: { withCredentials: true },
					success: function success(r) {
						var data = JSON.parse(r).data;

						Ember.$('#modal-avisos .modal-header').text('Imagen de perfil eliminada, da clic en el botón "Guardar"');
						Ember.$('#modal-avisos .modal-body').html('<p>Tu imagen de perfil fue eliminada. La imagen se cambiará por una default. Da clic en Cerrar y Guardar para ver reflejados los cambios.</p><img src="' + data.foto + '"alt="Imagen de perfil" width="50px"/>');
						Ember.$('#modal-avisos').modal('show');
					},
					error: function error(e) {
						console.log(e);
					}
				});
			}

		}

	});
});