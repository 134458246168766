define('comunidades-nafin/routes/catalogo-evaluaciones', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        start_int: 0,
        searchCategory: 0,
        evaluationSelected: {},
        categories: {},
        beforeModel: function beforeModel() {
            var promise = new Ember.RSVP.Promise(function (resolve) {
                Ember.run.later(this, function () {
                    resolve("ok");
                }, 1000);
            });
            return promise;
        },
        getEvaluations: function getEvaluations(params) {
            Ember.$('#show-more-evaluations').attr("disabled", "disabled");
            var controller = this.controller;
            var paramsData = typeof params == "undefined" ? "" : params;
            var start_int = this.get("start_int");
            if (start_int == 0) {
                controller.set("showLoad", true);
            }
            Ember.$.ajax({
                url: constant.APIURL + 'lms/evaluacion/listar',
                type: 'POST',
                contentType: 'application/x-www-form-urlencoded',
                data: 'start=' + start_int + '&limit=' + constant.PAGESIZE + paramsData,
                xhrFields: {
                    withCredentials: true
                },
                success: function success(r) {
                    var respons = JSON.parse(r);
                    var evaluations = [];
                    evaluations = respons.data;
                    if (evaluations.length >= 1) {
                        if (start_int > 0) {
                            var evaluaciones_previas = controller.get("evaluaciones-cards");
                            evaluations.forEach(function (evalation) {
                                evaluaciones_previas.pushObject(evalation);
                            });
                            controller.set("evaluaciones-cards", evaluaciones_previas);
                        } else {
                            controller.set("evaluaciones-cards", evaluations);
                        }
                        controller.set('showLoad', false);
                        controller.set("isEmpty", false);
                        Ember.$('#show-more-evaluations').attr("disabled", false);
                    } else {
                        controller.set("showLoad", false);
                        if (start_int == 0) {
                            controller.set("isEmpty", true);
                            controller.set("evaluaciones-cards", []);
                        } else {
                            Ember.$('#show-more-evaluations').text('Ups! No hay más evaluaciones que mostrar.');
                        }
                        return false;
                    }
                },
                error: function error(e) {
                    console.log(e);
                }
            });
        },
        //getEvaluations
        setupController: function setupController(controller) {
            controller.set('showLoad', true);
            this.getEvaluations();
            this.fillCategory(0);
        }, //setupController
        fillCategory: function fillCategory(a) {
            var self = this;
            var controller = this.controller;
            Ember.$.ajax({
                url: constant.APIURL + 'categoria/listar',
                type: 'POST',
                contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                xhrFields: {
                    withCredentials: true
                },
                data: {
                    _dc: '',
                    limit: 20,
                    params: 'lms_preguntas',
                    query: '',
                    start: a
                },
                success: function success(r) {
                    var categories = JSON.parse(r);
                    if (categories.data.length >= 1) {
                        var categorias_previas = controller.get("categories");
                        categorias_previas = typeof categorias_previas === "undefined" ? [] : categorias_previas;
                        categories.data.forEach(function (category) {
                            categorias_previas.pushObject(category);
                        });
                        controller.set("categories", categorias_previas);
                        Ember.$.each(categories.data, function (key, val) {
                            Ember.$('[name="categoria"]').each(function (i) {
                                Ember.$(this).append('<option value="' + val.categoria_k + '">' + val.nombre + '</option>');
                            });
                        });
                        self.fillCategory(a + 20);
                    }
                },
                error: function error(e) {
                    console.log(e.status);
                }
            });
        }, //fillCategory
        confirmEvaluation: function confirmEvaluation() {
            var self = this;
            var endpoint;
            if (Ember.$('#k').val().length > 0) {
                endpoint = "lms/evaluacion/editar";
            } else {
                endpoint = "lms/evaluacion/agregar";
            }
            Ember.$("#tablaPreguntas > tr").each(function () {
                if (Ember.$.isNumeric(Ember.$(this).find("[name='cantidad']").text())) {
                    var data = {};
                    data.cantidad = Ember.$(this).find("[name='cantidad']").text();
                    data.nombre = Ember.$(this).find("[name='nombre']").text();
                    data.categoria_k = Ember.$(this).find("[name='catId']").text();
                    preguntasArr.push(data);
                }
            });

            Ember.$.ajax({
                url: constant.APIURL + endpoint,
                type: 'POST',
                contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                data: {
                    agregar_preguntas_repositorio: Ember.$('[name="repositorio"][checked="checked"]').val(),
                    categoria_k: Ember.$('#categoria').val(),
                    cronometro_activado: Ember.$('[name="cronometro"][checked="checked"]').val(),
                    cronometro_tiempo: Ember.$('#minutos').val(),
                    descripcion: Ember.$('#descripcion').val(),
                    limite_intentos: 1,
                    mostrar_calificacion: Ember.$('[name="calificacion"][checked="checked"]').val(),
                    mostrar_respuestas: Ember.$('[name="respuestas"][checked="checked"]').val(),
                    nombre: Ember.$('#nombre').val(),
                    numero_preguntas_repositorio: 0,
                    preguntas: JSON.stringify(preguntasArr),
                    preguntas_aleatorias: Ember.$('[name="random"][checked="checked"]').val(),
                    preguntas_por_pagina: 1,
                    tabla_evaluaciones_dinamicas: 'lms_evaluaciones',
                    tabla_propietario: '',
                    vigencia: 1,
                    encuesta_k: Ember.$('#k').val()
                },
                xhrFields: {
                    withCredentials: true
                },
                success: function success(r) {

                    var respons = JSON.parse(r);
                    if (respons.success) {
                        alert("La evaluación se agregó");
                        Ember.$('#modal-evaluaciones').modal('hide');
                        Ember.$('.modal-backdrop').css({ 'opacity': '0' });
                        self.clearForm();
                    }
                },
                error: function error(e) {
                    console.log(e.status);
                }
            });
        }, //confirmEvaluation
        clearForm: function clearForm() {

            Ember.$('#minutos').val("");
            Ember.$('#nombre').val("");
            Ember.$('#categoria').val(0);
            Ember.$('#descripcion').val("");
            Ember.$('#cantidadP').val(1);
            Ember.$('#minutos').val("");
            Ember.$('#minutos').attr("disabled", "disabled");
            Ember.$("input[name=cronometro][value=0]").prop('checked', true);
            Ember.$("input[name=random][value=0]").prop('checked', true);
            Ember.$("input[name=repositorio][value=0]").prop('checked', true);
            Ember.$("input[name=calificacion][value=0]").prop('checked', true);
            Ember.$("input[name=respuestas][value=0]").prop('checked', true);
            Ember.$("#tablaPreguntas").empty();
            preguntasArr = [];
        },
        //clearForm
        actions: {
            nextEval: function nextEval() {},
            //nextEval
            showMore: function showMore() {
                var start_int = this.get("start_int");
                start_int = start_int + constant.PAGESIZE;
                this.set("start_int", start_int);
                this.send('searchEvaluation', true);
            },

            searchEvaluationByCategory: function searchEvaluationByCategory(seleccion) {
                var categoria_k = seleccion != null ? seleccion.categoria_k : 0;
                this.set("searchCategory", categoria_k);
                this.send('searchEvaluation');
            }, //searchEvaluationBy
            searchEvaluation: function searchEvaluation(showMore) {
                var showMoreFlag = typeof showMore == "undefined" ? false : showMore;
                var controller = this.controller;
                var params = "";
                var txtSearch = Ember.$('#txt-search').val();
                var searchCategory = this.get("searchCategory");
                var evaluationq = {};
                if (txtSearch != "") {
                    evaluationq.nombre = txtSearch;
                }
                if (searchCategory != 0) {
                    evaluationq.categoria = searchCategory;
                }
                if (Object.keys(evaluationq).length > 0) {
                    params = '&params=' + JSON.stringify(evaluationq);
                }
                if (!showMoreFlag) {
                    this.set("start_int", 0);
                }
                this.getEvaluations(params);
            }, //searchEvaluation     
            addEvaluation: function addEvaluation() {
                if (Ember.$('#repositorio-0').is(':checked')) {
                    Ember.$('#nextBtn').hide();
                    //this.fillCategory(0);
                    Ember.$('.add-eva').show();
                    Ember.$('.edit-eva').hide();
                    Ember.$('#modal-evaluaciones').modal('show');
                    Ember.$('.modal-backdrop').css({
                        'opacity': '1'
                    });
                    Ember.$('#step-1').show();
                    Ember.$('#step-2').hide();
                    Ember.$('#prevBtn').hide();
                }
            }, //addEvaluation
            confirmDeleteEvaluation: function confirmDeleteEvaluation(seleccion) {
                var controller = this.controller;
                this.set('evaluationSelected', seleccion);
                controller.set("evaluationSelected", seleccion);
                Ember.$('#modal-evaluaciones-eliminar').modal('show');
                Ember.$('.modal-backdrop').css({
                    'opacity': '1'
                });
            },
            //confirmDeleteEvaluation
            deleteEvaluation: function deleteEvaluation() {
                var self = this;
                var evaluationSelected = this.get("evaluationSelected");
                if (evaluationSelected) {
                    Ember.$.ajax({
                        url: constant.APIURL + 'lms/evaluacion/eliminar',
                        type: 'POST',
                        contentType: 'application/x-www-form-urlencoded',
                        data: 'i=' + evaluationSelected.evaluacion_k,
                        xhrFields: {
                            withCredentials: true
                        },
                        success: function success(r) {
                            var respons = JSON.parse(r);
                            if (respons.success) {
                                // TODO refresh
                                Ember.$('#modal-evaluaciones-eliminar').modal('hide');
                                Ember.$('.modal-backdrop').css({
                                    'opacity': '0'
                                });
                                self.send('searchEvaluation');
                            } else {
                                //TODO: validar este caso con dueño de negocio
                            }
                        },
                        error: function error(e) {
                            console.log(e);
                        }
                    });
                }
            },
            //deleteEvaluation
            closeDialogDelete: function closeDialogDelete() {
                Ember.$('#modal-evaluaciones-eliminar').modal('hide');
                Ember.$('.modal-backdrop').css({
                    'opacity': '0'
                });
            },
            //closeDialogDelete
            closeDialogDeleteCat: function closeDialogDeleteCat() {
                Ember.$('#modal-categoria-eliminar').modal('hide');
            },
            //closeDialogDeleteCat
            closeDialogCategories: function closeDialogCategories() {
                Ember.$('#modal-categorias').modal('hide');
                Ember.$('.modal-backdrop').css({
                    'opacity': '0'
                });
            },
            //closeDialogCategories
            editEvaluation: function editEvaluation(i) {
                Ember.$('#step-1').show();
                Ember.$('#step-2').hide();
                Ember.$.ajax({
                    url: constant.APIURL + '/lms/evaluacion/form',
                    type: 'POST',
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    data: 'i=' + i,
                    xhrFields: {
                        withCredentials: true
                    },
                    success: function success(r) {
                        Ember.$('#modal-evaluaciones').modal('show');
                        Ember.$('.modal-backdrop').css({
                            'opacity': '1'
                        });
                        var data = JSON.parse(r);
                        if (data.data.agregar_preguntas_repositorio === "0") {
                            Ember.$('#repositorio-0').attr("checked", true);
                            Ember.$('#nextBtn').hide();
                            Ember.$('#prevBtn').hide();
                        } else {
                            Ember.$('#repositorio-1').attr("checked", true);
                            Ember.$('#nextBtn').show();
                        }
                        Ember.$('[name="categoria"]').val(data.data.categoria_k);
                        if (data.data.cronometro_activado === "0") {
                            Ember.$('#cronometro-0').attr("checked", true);
                        } else {
                            Ember.$('#cronometro-1').attr("checked", true);
                        }
                        Ember.$('#descripcion').val(data.data.descripcion);
                        Ember.$('#nombre').val(data.data.nombre);
                        Ember.$('#minutos').val(data.data.cronometro_tiempo);
                        Ember.$('#k').val(data.data.encuesta_k);
                        if (data.data.mostrar_calificacion === "0") {
                            Ember.$('#calificacion-0').attr("checked", true);
                        } else {
                            Ember.$('#calificacion-1').attr("checked", true);
                        }
                        if (data.data.mostrar_respuestas === "0") {
                            Ember.$('#respuestas-0').attr("checked", true);
                        } else {
                            Ember.$('#respuestas-1').attr("checked", true);
                        }
                        // preguntas
                    },
                    error: function error(e) {
                        console.log(e);
                    }
                });
            },
            //editEvaluation
            showCategories: function showCategories() {
                Ember.$('#modal-categorias').modal('show');
                Ember.$('.modal-backdrop').css({
                    'opacity': '1'
                });
            },
            //showCategories
            confirmDeleteCategory: function confirmDeleteCategory(seleccion) {
                var controller = this.controller;
                this.set('categorySelectedDelete', seleccion);
                controller.set("categorySelectedDelete", seleccion);
                Ember.$('#modal-categoria-eliminar').modal('show');
            },
            //showCategories
            addCategory: function addCategory() {
                var txtCategory = Ember.$('#txt-add-category').val();
                if (txtCategory.trim() != '') {
                    var self = this;
                    Ember.$.ajax({
                        url: constant.APIURL + 'categoria/agregarcatego',
                        type: 'POST',
                        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                        data: {
                            tabla: 'lms_preguntas',
                            txtn: txtCategory
                        },
                        xhrFields: {
                            withCredentials: true
                        },
                        success: function success(r) {
                            var respons = JSON.parse(r);
                            if (respons.success) {
                                Ember.$('#txt-add-category').val('');
                                self.fillCategory(0);
                                alert("Categoría agregada");
                            }
                        },
                        error: function error(e) {
                            console.log(e);
                        }
                    });
                }
            },
            //addCategory
            deleteCategory: function deleteCategory() {
                var categorySelectedDelete = this.get('categorySelectedDelete');
                if (categorySelectedDelete) {
                    var self = this;
                    var controller = this.controller;
                    Ember.$.ajax({
                        url: constant.APIURL + 'categoria/eliminarcatego',
                        type: 'POST',
                        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                        data: {
                            i: categorySelectedDelete.categoria_k
                        },
                        xhrFields: {
                            withCredentials: true
                        },
                        success: function success(r) {
                            Ember.$('#modal-categoria-eliminar').modal('hide');
                            if (r = '{success: true}') {
                                Ember.$('#modal-categorias').modal('hide');
                                Ember.$('.modal-backdrop').css({
                                    'opacity': '0'
                                });
                                controller.set("categories", []);
                                self.fillCategory(0);
                                alert("Categoría eliminada");
                                Ember.$('#modal-categorias').modal('show');
                            } else {
                                alert("Categoría no eliminada");
                            }
                        },
                        error: function error(e) {
                            Ember.$('#modal-categoria-eliminar').modal('hide');
                            alert("Categoría no eliminada");
                            console.log(e);
                        }
                    });
                }
            },
            //deleteCategory   
            confirmEditEvaluation: function confirmEditEvaluation(i) {
                Ember.$('#step-1').show();
                Ember.$('#step-2').hide();
                Ember.$.ajax({
                    url: constant.APIURL + '/lms/evaluacion/form',
                    type: 'POST',
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    data: 'i=' + i,
                    xhrFields: {
                        withCredentials: true
                    },
                    success: function success(r) {
                        Ember.$('#modal-evaluaciones').modal('show');
                        Ember.$('.modal-backdrop').css({
                            'opacity': '1'
                        });
                        var data = JSON.parse(r);
                        if (data.data.agregar_preguntas_repositorio === "0") {
                            Ember.$('#repositorio-0').attr("checked", true);
                            Ember.$('#nextBtn').hide();
                            Ember.$('#prevBtn').hide();
                        } else {
                            Ember.$('#repositorio-1').attr("checked", true);
                            Ember.$('#nextBtn').show();
                        }
                        Ember.$('[name="categoria"]').val(data.data.categoria_k);
                        if (data.data.cronometro_activado === "0") {
                            Ember.$('#cronometro-0').attr("checked", true);
                        } else {
                            Ember.$('#cronometro-1').attr("checked", true);
                        }
                        Ember.$('#descripcion').val(data.data.descripcion);
                        Ember.$('#nombre').val(data.data.nombre);
                        Ember.$('#minutos').val(data.data.cronometro_tiempo);
                        Ember.$('#k').val(data.data.encuesta_k);
                        if (data.data.mostrar_calificacion === "0") {
                            Ember.$('#calificacion-0').attr("checked", true);
                        } else {
                            Ember.$('#calificacion-1').attr("checked", true);
                        }
                        if (data.data.mostrar_respuestas === "0") {
                            Ember.$('#respuestas-0').attr("checked", true);
                        } else {
                            Ember.$('#respuestas-1').attr("checked", true);
                        }
                        // preguntas
                    },
                    error: function error(e) {
                        console.log(e);
                    }
                });
            },
            //confirmEditEvaluation
            goPreviewEvaluation: function goPreviewEvaluation(i) {
                var controller = this.controller;

                Ember.$.ajax({
                    url: constant.APIURL + 'lms/evaluacion/preview',
                    type: 'POST',
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    data: 'enc_ev_k=' + i,
                    xhrFields: {
                        withCredentials: true
                    },
                    success: function success(r) {
                        var data = JSON.parse(r);
                        controller.set("data", data);

                        var win = window.open(constant.APIURL + '/vista-previa-evaluacion', '_blank');
                        if (win) {
                            win.focus();
                        } else {
                            alert('No se permiten pop ups');
                        }
                    },
                    error: function error(e) {
                        console.log(e);
                    }
                });
            },
            //goPreviewEvaluation
            validarEvaluation: function validarEvaluation() {

                var controller = this.controller;
                var cronometro = Ember.$('#cronometro-1').is(':checked') ? 1 : 0;
                var minutos = Ember.$('#minutos').val();
                var nombre = Ember.$('#nombre').val().trim();
                var categoria = Ember.$('#categoria').val();
                var descripcion = Ember.$('#descripcion').val().trim();

                if (nombre == "") {
                    controller.set("hasError", true);
                    controller.set("errorMessage", 'El campo nombre es requerido');
                    return false;
                }

                if (categoria == 0) {
                    controller.set("hasError", true);
                    controller.set("errorMessage", 'El campo categoría es requerido');
                    return false;
                }

                if (descripcion == "") {
                    controller.set("hasError", true);
                    controller.set("errorMessage", 'El campo descripción es requerido');
                    return false;
                }

                if (cronometro == 1 && !Ember.$.isNumeric(minutos)) {
                    controller.set("hasError", true);
                    controller.set("errorMessage", 'El campo minutos debe ser un entero');
                    return false;
                }

                this.confirmEvaluation();
            },
            //validarEvaluation
            toggleMinutes: function toggleMinutes() {

                if (Ember.$('#cronometro-1').is(':checked')) {
                    Ember.$('#minutos').attr("disabled", false);
                } else {
                    Ember.$('#minutos').attr("disabled", "disabled");
                    Ember.$('#minutos').val("");
                }
            }
        }
    });
});