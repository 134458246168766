define('comunidades-nafin/router', ['exports', 'comunidades-nafin/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    // RUTAS PARA USUARIOS

    this.route('inicio', { path: '/' });
    this.route('inicio');

    this.route('comunidades');
    this.route('comunidades/comunidad-especifica', { path: '/comunidades/:comunidadid' });

    this.route('cursos');
    this.route('cursos/curso-especifico', { path: '/cursos/:cursoid' });

    this.route('diagnostico-empresarial');
    this.route('diagnostico-empresarial', { path: '/diagnostico-empresarial/:mis_diagnosticos' });

    this.route('webinars');

    this.route('noticias');

    this.route('eventos');

    this.route('foros');
    this.route('foros/foro-especifico', { path: '/foros/:foroid' });

    this.route('mediateca');

    this.route('consultas');
    this.route('consultas/consulta-especifica', { path: '/consultas/:consultaid' });

    this.route('directorio');

    this.route('resultados-busqueda');

    this.route('notificaciones');

    this.route('logros');

    this.route('perfil');
    this.route('cambiar-contrasenia');

    this.route('buscador');

    // RUTAS PARA ADMINISTRADORES
    this.route('dashboard');
    this.route('catalogo-cursos');
    this.route('catalogo-evaluaciones');
    this.route('vista-previa-evaluacion');
    this.route('catalogo-evaluaciones-especifico');
    this.route('catalogo-certificado');
    this.route('catalogo-encuestas-especifico');
    this.route('rutas-aprendizaje-especifico', function () {
      this.route('rutas-objetivo');
      this.route('rutas-alumnos');
      this.route('rutas-agrupamiento');
      this.route('rutas-documentos');
    });
    this.route('administracion-comunidades');
    this.route('administracion-logros');
    this.route('administracion-diccionario');

    this.route('administracion-usuarios');
    this.route('nueva-comunidad');
    this.route('comunidad-detalle');
    this.route('repositorio-preguntas');
    this.route('ruta-apredizaje');
    this.route('catalogo-encuestas');
    this.route('respuestas-predefinidas');
    this.route('tipos-de-respuesta');
    this.route('usuarios');
    this.route('reportes');

    this.route('examples');
    //this.route('contactos');
    this.route('historico', { path: '/historico/:historicoid' });
    this.route('historico');
    this.route('preguntas-diagnostico');
    this.route('preguntas-diagnostico', { path: '/preguntas-diagnostico/:preguntasdiagnosticoid' });
    this.route('aviso-privacidad');
    this.route('admin-nuevousuario');

    this.route('experiments', function () {
      this.route('testing-area');
    });

    this.route('administracion-cursos');
    this.route('administracion-cursos-curso-especifico', { path: '/administracion-cursos/:cursoid' });

    this.route('recurso-no-disponible');
    this.route('faqs');
  });

  exports.default = Router;
});