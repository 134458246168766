define('comunidades-nafin/components/diagnostic-box', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		didInsertElement: function didInsertElement() {
			var self = this._super.apply(this, arguments);
			var url_page = window.location.pathname;
			var id = url_page.substring(url_page.lastIndexOf('/') + 1);

			var arrayDiagnostic = [];

			var diagnosticsNotAnswered = Ember.$.ajax({
				url: constant.APIURL + 'evaluacionesdinamicas/evaluaciondinamica/listarevaluacionesdinamicasdesdecatalogodisponibleporusuario',
				type: 'POST',
				async: false,
				xhrFields: {
					withCredentials: true
				},
				contentType: 'application/x-www-form-urlencoded',
				//data: 'evaluacion_dinamica_k=',
				success: function success(response) {
					var r = JSON.parse(response);
					Object.keys(r.data).forEach(function (idx) {
						console.log(r.data[idx].ultimo_calificacion);
						r.data[idx].ruta_imagen = solveImageURL(r.data[idx].ruta_imagen);
						r.data[idx].ultimo_calificacion = isNaN(parseFloat(r.data[idx].ultimo_calificacion).toFixed(2)) ? "" : parseFloat(r.data[idx].ultimo_calificacion).toFixed(2);
						if (!(r.data[idx] == 0)) {
							arrayDiagnostic.push(r.data[idx]);
						}

						if (r.data[idx].ultimo_estatus == "1" || r.data[idx].ultimo_estatus == "60") {
							r.data[idx].cursoIniciado = true;
							if (r.data[idx].ultimo_estatus == "1") {
								r.data[idx].evaluacionPendiente = true;
							} else {
								r.data[idx].evaluacionPendiente = false;
							}
						} else {
							r.data[idx].cursoIniciado = false;
						}

						console.log(r.data.length);
						if (r.data == 0) {
							self.withoutDiagnosticBox();
						}
					});
				},
				error: function error(e) {
					console.log(e);
				}
			});
			var diagnosticsAnswered = Ember.$.ajax({
				url: constant.APIURL + 'evaluacionesdinamicas/evaluaciondinamica/listarevaluacionesdinamicasdesdecatalogoinscritasporusuario',
				type: 'POST',
				xhrFields: {
					withCredentials: true
				},
				contentType: 'application/x-www-form-urlencoded',
				//data: 'evaluacion_dinamica_k=',
				success: function success(response) {
					var r = JSON.parse(response);
					Object.keys(r.data).forEach(function (idx) {
						console.log(r.data[idx].ultimo_calificacion);
						r.data[idx].ultimo_calificacion = isNaN(parseFloat(r.data[idx].ultimo_calificacion).toFixed(2)) ? "Pendiente" : parseFloat(r.data[idx].ultimo_calificacion).toFixed(2);
						r.data[idx].ruta_imagen = solveImageURL(r.data[idx].ruta_imagen);
						if (!(r.data[idx] == 0)) {
							arrayDiagnostic.push(r.data[idx]);
						}

						if (r.data[idx].ultimo_estatus == "1" || r.data[idx].ultimo_estatus == "60") {
							r.data[idx].cursoIniciado = true;
							if (r.data[idx].ultimo_estatus == "1") {
								r.data[idx].evaluacionPendiente = true;
							} else {
								r.data[idx].evaluacionPendiente = false;
							}
						} else {
							r.data[idx].cursoIniciado = false;
						}

						if (r.data == 0) {
							self.withoutDiagnosticBox();
						}
					});
				},
				error: function error(e) {
					console.log(e);
				}
			});
			Ember.$.when(diagnosticsAnswered, diagnosticsNotAnswered).then(function () {
				console.log(arrayDiagnostic);
				self.formatter(arrayDiagnostic, self);
			});
			/*$.ajax({
   	url: constant.APIURL + 'comunidad/enumerarevaluacionesdinamicas',
   	type: 'POST',
   	xhrFields: {
   		withCredentials: true
   	},
   	contentType: 'application/x-www-form-urlencoded',
   	data: 'params={"comunidad_k":"' + id + '"}',
   	success(response) {
   		var r = JSON.parse(response)
   		var datum = r.data;
   		if ( typeof(datum) != 'undefined' && datum != null) {
   			Object.keys(datum).forEach(function (idx) {
   			});
   		} else {
   			self.withoutDiagnosticBox();
   		}
   	},
   	error(e) {
   		console.log(e);
   	}
   });*/
		},
		didRender: function didRender() {
			Ember.$('.carousel-item:first-child').addClass('active');
		},

		withoutDiagnosticBox: function withoutDiagnosticBox() {
			var html = '';

			html += '<div class="carousel-inner box_shado">';
			html += '<div class="carousel-item">';
			html += '<div class="card text-center">';
			html += '<img class="card-img" src="' + window.constant.BASEURL + '/img/evaluacion_dinamica_default.jpg" alt="img_diagnostic" />';
			html += '<div class="card-img-overlay margin_title_diag" id="bg_transparent">';
			html += '<h2 class="card-text bold" style = "color: #000;">No hay diagnósticos para esta comunidad.</h2>';
			html += '</div>';
			html += '</div>';
			html += '</div>';
			html += '</div>';

			Ember.$('#tarjeta_diagnostico').html(html);
		},
		//Dar formato deseado a mes y fechas
		formatter: function formatter(data, self) {
			Object.keys(data).forEach(function (idx) {
				if (data[idx].ultimo_fecha_hora_inicio) {
					var date_time = data[idx].ultimo_fecha_hora_inicio.split(" ");
					var date = date_time[0].split('-');
					var month = date[1];
					data[idx].monthFormath = self.monthFormatter(month);
					data[idx].dayFormath = date[2];
					data[idx].yearFormath = date[0];
				}
				data[idx].titulo = data[idx].nombre;
			});
			self.setMonth(data, self);
		},

		// Asignar el més en el que nos encontramos
		monthFormatter: function monthFormatter(month) {
			if (month === "01") {
				return "Enero";
			} else if (month === "02") {
				return "Febrero";
			} else if (month === "03") {
				return "Marzo";
			} else if (month === "04") {
				return "Abril";
			} else if (month === "05") {
				return "Mayo";
			} else if (month === "06") {
				return "Junio";
			} else if (month === "07") {
				return "Julio";
			} else if (month === "08") {
				return "Agosto";
			} else if (month === "09") {
				return "Septiembre";
			} else if (month === "10") {
				return "Octubre";
			} else if (month === "11") {
				return "Noviembre";
			} else {
				return "Diciembre";
			}
		},
		setMonth: function setMonth(data, self) {
			self.set('diagnostico', data);
		},


		actions: {
			prevSlide: function prevSlide() {
				Ember.$('.carousel-inner.diagnostic-cards').carousel('prev');
			},
			nextSlide: function nextSlide() {
				Ember.$('.carousel-inner.diagnostic-cards').carousel('next');
			}
		}
	});
});