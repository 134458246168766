define('comunidades-nafin/components/admin-components/boton-admin-comunidad', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            editarComunidad: function editarComunidad() {
                document.location.href = '/administracion-comunidades';
            }
        }
    });
});