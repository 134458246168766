define('comunidades-nafin/components/menu-header', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        router: Ember.inject.service(),
        conver_vis: false,
        didInsertElement: function didInsertElement() {
            Ember.$('#chat_box_component').hide();
            var self = this._super.apply(this, arguments);
            self.set("logo", "/lookandfeel/" + window.constant.THEME + "/img/logo.png");
            Ember.$.ajax({
                url: constant.APIURL + 'usuario/datospersonales/',
                type: 'POST',
                contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                xhrFields: { withCredentials: true },
                success: function success(r) {

                    var data = JSON.parse(r);

                    if (!data.fatal) {

                        if (data.foto.split('/')[0] === 'uploads') {
                            data.foto = constant.BASEURL + data.foto;
                        }

                        self.set('avatar', data.foto);
                        self.loadConversations();
                        Ember.$('#notificacionChat').hide();
                    }
                    if (data.configuracion.general_navegacion_comunidades_visible != false) self.set("comun_vis", true);

                    if (data.configuracion.general_navegacion_cursos_visible != false) self.set("cursos_vis", true);

                    //Explora
                    self.set("explora_vis", true);
                    if (data.configuracion.general_navegacion_diagnostico_empresarial_visible == false && data.configuracion.general_navegacion_webinars_visible == false && data.configuracion.general_navegacion_noticias_visible == false && data.configuracion.general_navegacion_eventos_visible == false && data.configuracion.general_navegacion_foros_visible == false && data.configuracion.general_navegacion_mediateca_visible == false && data.configuracion.general_navegacion_consultas_visible == false && data.configuracion.general_navegacion_contactos_visible == false) {
                        self.set("explora_vis", false);
                    }

                    if (data.configuracion.general_navegacion_diagnostico_empresarial_visible != false) {
                        self.set("diag_vis", true);
                        Ember.$(".diagnosticos_cards").removeClass("d-none");
                    }

                    if (data.configuracion.general_navegacion_webinars_visible != false) {
                        self.set("web_vis", true);
                    }
                    if (data.configuracion.general_navegacion_noticias_visible != false) {
                        self.set("noti_vis", true);
                        Ember.$(".noticias_cards").removeClass("d-none");
                    }

                    if (data.configuracion.general_navegacion_eventos_visible != false) {
                        self.set("even_vis", true);
                        Ember.$(".eventos_cards").removeClass("d-none");
                    }

                    if (data.configuracion.general_navegacion_foros_visible != false) {
                        self.set("foro_vis", true);
                        Ember.$(".foros_cards").removeClass("d-none");
                    }

                    if (data.configuracion.general_navegacion_mediateca_visible != false) {
                        self.set("media_vis", true);
                        Ember.$(".mediateca_cards").removeClass("d-none");
                    }

                    if (data.configuracion.general_navegacion_consultas_visible != false) self.set("consul_vis", true);

                    if (data.configuracion.general_navegacion_contactos_visible != false) self.set("dico_vis", true);

                    //Búsqueda, Preguntas, Logros, Mensajes y Notificaciones.
                    if (data.configuracion.general_navegacion_busqueda_visible != false) self.set("bus_vis", true);

                    if (data.configuracion.general_navegacion_preguntas_frecuentes_visible != false) self.set("preg_vis", true);

                    if (data.configuracion.general_navegacion_logros_reconocimientos_visible != false) self.set("award_vis", true);

                    if (data.configuracion.general_navegacion_conversaciones_visible != false) self.set("conver_vis", true);

                    if (data.configuracion.general_navegacion_notificaciones_visible != false) {
                        self.set("notifica_vis", true);
                    } else {
                        Ember.$("#notifica_vis").show();
                    }

                    //Perfil
                    self.set("avatar_vis", true);
                    if (data.configuracion.general_navegacion_mi_perfil_nafin_visible == false && data.configuracion.general_navegacion_cambiar_password_visible == false && data.configuracion.general_navegacion_aviso_privacidad_visible == false && data.configuracion.general_navegacion_finalizar_sesion_visible == false) {
                        self.set("avatar_vis", false);
                    }

                    if (data.configuracion.general_navegacion_mi_perfil_nafin_visible != false) self.set("perfil_vis", true);

                    if (data.configuracion.general_navegacion_cambiar_password_visible != false) self.set("contra_vis", true);

                    if (data.configuracion.general_navegacion_aviso_privacidad_visible != false) self.set("aviso_vis", true);

                    if (data.configuracion.general_navegacion_finalizar_sesion_visible != false) self.set("sesion_vis", true);

                    if (data.configuracion.general_navegacion_comunidades_diccionario_visible != false) self.set("diccionario_vis", true);

                    self.set("navegacion-administracion-comunidades-comunidades", true);
                    if (data.configuracion.general_navegacion_adm_comunidades_visible == undefined || data.configuracion.general_navegacion_adm_comunidades_visible == false) {
                        self.set("navegacion-administracion-comunidades-comunidades", false);
                    }

                    //Logros

                    Ember.$("#navegacion-administracion-logros-logros").show();
                    if (data.configuracion.general_navegacion_adm_logros_visible == undefined || data.configuracion.general_navegacion_adm_logros_visible == false) {
                        Ember.$("#navegacion-administracion-logros-logros").remove();
                    }
                    //Usuarios


                    Ember.$("#navegacion-administracion-usuarios-usuarios").hide();
                    if (data.configuracion.general_navegacion_adm_usuarios_visible == undefined || data.configuracion.general_navegacion_adm_usuarios_visible != false) {
                        Ember.$("#navegacion-administracion-usuarios-usuarios").show();
                    }

                    //Reportes

                    Ember.$("#navegacion-administracion-reportes-reportes").hide();
                    if (data.configuracion.general_navegacion_adm_reportes_visible == undefined || data.configuracion.general_navegacion_adm_reportes_visible != false) {
                        Ember.$("#navegacion-administracion-reportes-reportes").show();
                    }

                    Ember.$("#navegacion-administracion-reportes-dashboard").hide();
                    if (data.configuracion.general_navegacion_adm_dashboard_visible == undefined || data.configuracion.general_navegacion_adm_dashboard_visible != false) {
                        Ember.$("#navegacion-administracion-reportes-dashboard").show();
                    }

                    //Cursos

                    Ember.$("#navegacion-administracion-cursos-cursos").show();
                    if (data.configuracion.general_navegacion_adm_cursos_visible == undefined || data.configuracion.general_navegacion_adm_cursos_visible == false) {
                        Ember.$("#navegacion-administracion-cursos-cursos").remove();
                    }

                    Ember.$("#navegacion-administracion-cursos-encuestas").show();
                    if (data.configuracion.general_navegacion_adm_encuestas_visible == undefined || data.configuracion.general_navegacion_adm_encuestas_visible == false) {
                        Ember.$("#navegacion-administracion-cursos-encuestas").remove();
                    }

                    Ember.$("#navegacion-administracion-cursos-certificados").show();
                    if (data.configuracion.general_navegacion_adm_certificados_visible == undefined || data.configuracion.general_navegacion_adm_certificados_visible == false) {
                        Ember.$("#navegacion-administracion-cursos-certificados").remove();
                    }

                    Ember.$("#selectEscolaridad").show();
                    if (data.configuracion.usuarios_filtro_escolaridad == undefined || data.configuracion.usuarios_filtro_escolaridad == false) {
                        Ember.$("#selectEscolaridad").remove();
                    }

                    Ember.$("#selectTipo").show();
                    if (data.configuracion.usuarios_filtro_tipo == undefined || data.configuracion.usuarios_filtro_tipo == false) {
                        Ember.$("#selectTipo").remove();
                    }

                    Ember.$("#selectEstado").show();
                    if (data.configuracion.usuarios_filtro_estado == undefined || data.configuracion.usuarios_filtro_estado == false) {
                        Ember.$("#selectEstado").remove();
                    }

                    Ember.$("#selectMunicipio").show();
                    if (data.configuracion.usuarios_filtro_municipio == undefined || data.configuracion.usuarios_filtro_municipio == false) {
                        Ember.$("#selectMunicipio").remove();
                    }

                    Ember.$("#formBusqueda").removeClass("d-none");
                    setTimeout(function () {
                        self.showOrHide();
                    }, 1000);

                    Ember.$('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
                        if (!Ember.$(this).next().hasClass('show')) {
                            Ember.$(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
                        }
                        var $subMenu = Ember.$(this).next(".dropdown-menu");
                        $subMenu.toggleClass('show');

                        Ember.$(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
                            Ember.$('.dropdown-submenu .show').removeClass("show");
                        });

                        return false;
                    });
                },
                error: function error(e) {

                    console.log(e);
                }
            });
        },
        showOrHide: function showOrHide() {

            Ember.$(".menu-list").each(function () {
                var menuList = Ember.$(this);
                menuList.hide();
                menuList.find("ul:first .submenu-list").each(function () {
                    var submenuList = Ember.$(this);

                    submenuList.hide();
                    if (submenuList.find(" ul:first li").length) {
                        submenuList.show();
                        menuList.show();
                    }
                });
            });
        },

        loadConversations: function loadConversations() {},

        search: function search(_searchValue) {

            if (!_searchValue) {
                return;
            }

            if (_searchValue.trim().length == 0) {
                return;
            }

            document.location.href = '/buscador?search=' + _searchValue.trim();
        },


        actions: {
            closeSession: function closeSession() {
                Ember.$.ajax({
                    url: constant.APIURL + 'usuario/logout/',
                    type: 'POST',
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    xhrFields: {
                        withCredentials: true
                    },
                    success: function success(r) {
                        Ember.$('#modal-avisos').modal('show');
                        Ember.$('.modal-backdrop').css({ 'opacity': '1' });
                        Ember.$('#modal-avisos .modal-body').html('Tu sesión ha finalizado correctamente, ahora serás redirigido al entorno público, hasta pronto.');
                        Ember.$("#modal-avisos").on("hidden.bs.modal", function () {
                            document.location.href = constant.LANDINGURL;
                        });
                        setTimeout(function () {
                            document.location.href = constant.LANDINGURL;
                        }, 5000);
                    },
                    error: function error(e) {
                        console.log(e);
                    }
                });
            },
            goInicio: function goInicio() {
                this.get('router').transitionTo('inicio');
            },
            goCommunities: function goCommunities() {
                this.get('router').transitionTo('comunidades');
            },
            goCourses: function goCourses() {
                this._super.apply(this, arguments);
                var currentRoute = this.get('router').get('currentRouteName');
                if (currentRoute != 'cursos') {
                    this.get('router').transitionTo('cursos');
                } else {
                    document.location.href = '/cursos';
                }
            },
            goDiagnostic: function goDiagnostic() {
                this.get('router').transitionTo('diagnostico-empresarial', 0);
            },
            goNews: function goNews() {
                this.get('router').transitionTo('noticias');
            },
            goEvents: function goEvents() {
                this.get('router').transitionTo('eventos');
            },
            goWebinars: function goWebinars() {
                this.get('router').transitionTo('webinars');
            },
            goForums: function goForums() {
                this.get('router').transitionTo('foros');
            },
            goMediateca: function goMediateca() {
                this.get('router').transitionTo('mediateca');
            },
            goConsultas: function goConsultas() {
                this.get('router').transitionTo('consultas');
            },
            goContacts: function goContacts() {
                this.get('router').transitionTo('directorio');
            },
            goFaqs: function goFaqs() {
                this.get('router').transitionTo('faqs');
            },
            goMyProfile: function goMyProfile() {
                this.get('router').transitionTo('perfil');
            },
            goChangePassword: function goChangePassword() {
                this.get('router').transitionTo('cambiar-contrasenia');
            },
            goAchievments: function goAchievments() {
                this.get('router').transitionTo('logros');
            },
            goDataPolicy: function goDataPolicy() {
                window.open(constant.APIURL + 'publico/avisoprivacidad/activo', '_blank');
            },


            //ADMIN
            goComunidadesAdmin: function goComunidadesAdmin() {
                this.get('router').transitionTo('administracion-comunidades');
            },
            goLogrosAdmin: function goLogrosAdmin() {
                this.get('router').transitionTo('administracion-logros');
            },
            goEmailAdmin: function goEmailAdmin() {
                alert('En construcción');
            },
            goDictionaryAdmin: function goDictionaryAdmin() {
                this.get('router').transitionTo('administracion-diccionario');
            },
            goPrivacyAdmin: function goPrivacyAdmin() {
                alert('En construcción');
            },
            goTicketsAdmin: function goTicketsAdmin() {
                this.get('router').transitionTo('consultas');
            },
            goPredefAswersAdmin: function goPredefAswersAdmin() {
                this.get('router').transitionTo('respuestas-predefinidas');
            },
            goAswersTypeAdmin: function goAswersTypeAdmin() {
                this.get('router').transitionTo('tipos-de-respuesta');
            },
            goUsersAdmin: function goUsersAdmin() {
                this.get('router').transitionTo('administracion-usuarios');
            },
            goRolesAdmin: function goRolesAdmin() {
                alert('En construcción');
            },
            goReportsAdmin: function goReportsAdmin() {
                this.get('router').transitionTo('reportes');
            },
            goDashboardAdmin: function goDashboardAdmin() {
                this.get('router').transitionTo('dashboard');
            },
            goCoursesAdmin: function goCoursesAdmin() {
                this.get('router').transitionTo('administracion-cursos');
            },
            goLearningCoursesAdmin: function goLearningCoursesAdmin() {
                alert('En construcción');
            },
            goCoursesCatalogAdmin: function goCoursesCatalogAdmin() {
                this.get('router').transitionTo('catalogo-cursos');
            },
            goEvaluationsAdmin: function goEvaluationsAdmin() {
                this.get('router').transitionTo('catalogo-evaluaciones');
            },
            goSurveysAdmin: function goSurveysAdmin() {
                this.get('router').transitionTo('catalogo-encuestas');
            },
            goCertificatesAdmin: function goCertificatesAdmin() {
                this.get('router').transitionTo('catalogo-certificado');
            },
            goQuestionsAdmin: function goQuestionsAdmin() {
                this.get('router').transitionTo('repositorio-preguntas');
            },
            search: function search() {
                var self = this;
                Ember.$('.none_border').each(function () {
                    self.search(Ember.$(this).val());
                });
            },
            searchEnter: function searchEnter(event) {
                var self = this;
                if (event.which == 13) {
                    event.preventDefault();
                    Ember.$('.none_border').each(function () {
                        self.search(Ember.$(this).val());
                    });
                }
            },
            chat: function chat() {
                Ember.$('#notificacionChat').hide();
                Ember.$('#chat_box_component').show();
            }
        }
    });
});