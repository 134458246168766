define('comunidades-nafin/components/historic-table', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		didInsertElement: function didInsertElement() {
			var self = this._super.apply(this, arguments);
			self.set("diagnostic_state", true);

			var url_page = new URLSearchParams(window.location.search);
			Ember.$.ajax({
				url: constant.APIURL + 'evaluacionesdinamicas/evaluaciondinamica/listarevaluacionesusuario',
				type: 'POST',
				xhrFields: {
					withCredentials: true
				},
				contentType: 'application/x-www-form-urlencoded',
				data: 'evaluacion_dinamica_k=' + url_page.get('evaluacion'),
				success: function success(response) {
					if (sessionStorage.getItem('seccion-anterior') == "") {
						Ember.$("#tipoRegreso").text("Ir a diagnósticos");
					} else {
						Ember.$("#tipoRegreso").text("Regresar a ");
						Ember.$("#seccionAnterior").text(sessionStorage.getItem('seccion-anterior') != null ? sessionStorage.getItem('seccion-anterior') : " sección anterior");
					}

					var r = JSON.parse(response);
					var arrCalif = r.data;
					console.log(arrCalif);
					var calificaciones = [];
					var fechas = [];
					Object.keys(arrCalif).reverse().forEach(function (idx_calf) {
						//console.log(parseInt(arrCalif[idx_calf].calificacion)); 
						var date_time = arrCalif[idx_calf].fecha_hora_termino.split(' ');
						var date = date_time[0].split('-');
						var month = date[1];
						if (month === "01") {
							arrCalif[idx_calf].monthFormath = "Enero";
						} else if (month === "02") {
							arrCalif[idx_calf].monthFormath = "Febrero";
						} else if (month === "03") {
							arrCalif[idx_calf].monthFormath = "Marzo";
						} else if (month === "04") {
							arrCalif[idx_calf].monthFormath = "Abril";
						} else if (month === "05") {
							arrCalif[idx_calf].monthFormath = "Mayo";
						} else if (month === "06") {
							arrCalif[idx_calf].monthFormath = "Junio";
						} else if (month === "07") {
							arrCalif[idx_calf].monthFormath = "Julio";
						} else if (month === "08") {
							arrCalif[idx_calf].monthFormath = "Agosto";
						} else if (month === "09") {
							arrCalif[idx_calf].monthFormath = "Septiembre";
						} else if (month === "10") {
							arrCalif[idx_calf].monthFormath = "Octubre";
						} else if (month === "11") {
							arrCalif[idx_calf].monthFormath = "Noviembre";
						} else {
							arrCalif[idx_calf].monthFormath = "Diciembre";
						}
						arrCalif[idx_calf].dayFormath = date[2];
						arrCalif[idx_calf].yearFormath = date[0];

						arrCalif[idx_calf].calificacion = parseFloat(arrCalif[idx_calf].calificacion).toFixed(2);
						arrCalif[idx_calf].fecha_hora_termino_2 = arrCalif[idx_calf].dayFormath + "/" + arrCalif[idx_calf].monthFormath + "/" + arrCalif[idx_calf].yearFormath;
						calificaciones.push(arrCalif[idx_calf].calificacion);
						fechas.push(arrCalif[idx_calf].fecha_hora_termino_2);
						//console.log(fechas);
						//console.log(calificaciones.reverse());
						self.set('numberData', {
							labels: fechas,
							datasets: [{
								label: 'Resultados',
								data: calificaciones,
								borderColor: window.theme.historico.resultados.grafico.borderColor,
								lineTension: 0,
								backgroundColor: 'transparent',
								pointBackgroundColor: window.theme.historico.resultados.grafico.backgroundColor,
								responsive: true
							}]
						});
					});
					self.formatter(r.data, self);
					self.set('titulo', url_page.get('descripcion'));

					var url_descarga = '/evaluacionesdinamicas/evaluaciondinamica/descargarReporte';
					self.set('url_descarga', constant.APIURL + url_descarga);
				},
				error: function error(e) {
					console.log(e);
				}
			});
		},

		//Dar formato deseado a mes y fechas
		formatter: function formatter(data, self) {
			Object.keys(data).forEach(function (idx) {
				var date_time = data[idx].fecha_hora_termino.split(" ");
				var date = date_time[0].split('-');
				var month = date[1];
				data[idx].monthFormath = self.monthFormatter(month);
				data[idx].dayFormath = date[2];
				data[idx].yearFormath = date[0];
				//data[idx].titulo = title;
			});
			self.setMonth(data, self);
		},

		// Asignar el més en el que nos encontramos
		monthFormatter: function monthFormatter(month) {
			if (month === "01") {
				return "enero";
			} else if (month === "02") {
				return "febrero";
			} else if (month === "03") {
				return "marzo";
			} else if (month === "04") {
				return "abril";
			} else if (month === "05") {
				return "mayo";
			} else if (month === "06") {
				return "junio";
			} else if (month === "07") {
				return "julio";
			} else if (month === "08") {
				return "agosto";
			} else if (month === "09") {
				return "septiembre";
			} else if (month === "10") {
				return "octubre";
			} else if (month === "11") {
				return "noviembre";
			} else {
				return "diciembre";
			}
		},
		setMonth: function setMonth(data, self) {
			//console.log(data); 
			self.set('diagnostico', data);
		},

		actions: {
			questions: function questions() {
				Ember.$.ajax({
					url: constant.APIURL + 'usuario/datospersonales/',
					type: 'POST',
					xhrFields: {
						withCredentials: true
					},
					contentType: 'application/x-www-form-urlencoded',
					success: function success(r) {
						var usuario = JSON.parse(r);
						var url_page = new URLSearchParams(window.location.search);
						Ember.$.ajax({
							url: constant.APIURL + '/evaluacionesdinamicas/evaluaciondinamica/inscribirusuarioenevaluaciondinamica',
							type: 'POST',
							xhrFields: {
								withCredentials: true
							},
							contentType: 'application/x-www-form-urlencoded',
							data: 'evaluacion_dinamica_k=' + url_page.get('evaluacion') + '&usuario_k=' + usuario.usuario_k,
							success: function success(response) {
								var res = JSON.parse(response);
								console.log(res);
								if (res.error) {
									Ember.$('#modal-avisos .modal-header').text('Aviso');
									Ember.$('#modal-avisos .modal-body').text(res.error);
									Ember.$('#modal-avisos').modal('show');
								} else {
									sessionStorage.setItem('seccion-anterior', "Historico");
									document.location.href = '/preguntas-diagnostico?evaluacion=' + url_page.get('evaluacion') + '&descripcion=' + url_page.get('descripcion');
								}
							}
						});
					},
					error: function error(e) {
						console.log(e);
					}
				});
			}
		}
	});
});